import { Card, CardContent, CardHeader } from "@mui/material";
import { SectionHeader } from "common";
import React from "react";

import { Header } from "../Header/Header";
import { AuthCard } from "./AuthCard";
import styles from "./Login.module.scss";

export const Login = () => {
  return (
    <>
      <Header />
      <div className={styles.loginPage}>
        <Card className={styles.authCard}>
          <CardHeader
            title={SectionHeader.BXWEALTH_ADMIN}
            titleTypographyProps={{
              className: styles.authCardTitle,
            }}
          />
          <CardContent className={styles.authCardContent}>
            <AuthCard />
          </CardContent>
        </Card>
      </div>
    </>
  );
};
