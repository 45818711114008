import { ThemeOptions } from "@mui/material";

import colors from "../../_colors.scss";

// Shared constants we may want to move in unison

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiCheckbox: {
      defaultProps: {
        disableFocusRipple: true,
      },
      styleOverrides: {
        colorSecondary: colors.darkest_grey,
        root: {
          padding: "2px",
          "&.Mui-checked": {
            color: colors.darkest_grey,
          },
          "&.MuiCheckbox-indeterminate": {
            color: colors.darkest_grey,
          },
          "&:hover": {
            backgroundColor: "transparent",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "22px",
          },
        },
      },
    },
  },
};

export const CustomCheckboxTheme = partialTheme.components?.MuiCheckbox;
