import { createSelector } from "@reduxjs/toolkit";
import {
  convertAnnualCommitmentToGridFormat,
  convertLOFCommitmentDataToGridFormat,
  groupForecastedCapitalCallsByStrategy,
  IBaseStore,
  ICommitment,
  ICommitmentAnnual,
  ICommitmentLOF,
  ICommitmentsAnnual,
  ICommitmentsLOF,
  IForecastedCapCallByStrategy,
  IForecastedCapCallsByStrategy,
  isSomething,
  nothing,
  Optional,
  some,
} from "common";

import { CommitmentType } from "../../constants/enums";
import { selectActiveInvestmentEntityData } from "./baseStoreSelectors";

export const selectCommitmentsForClient = createSelector(
  //select only for client level, not for IV level
  (store: IBaseStore) => selectActiveInvestmentEntityData(store, true),
  ({ commitmentData }) => {
    return commitmentData;
  }
);

export const selectForecastedCommitmentByStrategy = createSelector(
  selectCommitmentsForClient,
  (commitmentData) => {
    if (
      !isSomething(commitmentData) ||
      !isSomething(commitmentData.value.forecastedCapitalCalls) ||
      commitmentData.value.forecastedCapitalCalls.value.capitalCalls.length ===
        0
    ) {
      return {
        forecastedCommitmentByStrategy:
          nothing as Optional<IForecastedCapCallsByStrategy>,
      };
    }

    const forecastedGrouped: IForecastedCapCallByStrategy[] =
      groupForecastedCapitalCallsByStrategy(
        commitmentData.value.forecastedCapitalCalls.value.capitalCalls
      );
    const forecastedByStrategy: IForecastedCapCallsByStrategy = {
      capitalCalls: forecastedGrouped,
      asOfDate: commitmentData.value.forecastedCapitalCalls.value.asOfDate,
    };
    return {
      forecastedCommitmentByStrategy: some(forecastedByStrategy),
    };
  }
);

export const selectLifeOfFundCommitments = createSelector(
  selectCommitmentsForClient,
  (commitmentData) => {
    // Check if user has any commitment data at all
    if (
      !isSomething(commitmentData) ||
      !isSomething(commitmentData.value.fundCommitments) ||
      commitmentData.value.fundCommitments.value.commitments.length === 0
    ) {
      return {
        lifeOfFundCommitments: nothing as Optional<ICommitmentsLOF>,
      };
    }

    // after confirming user has data - filter by life of fund commitments only
    const lofCommitments =
      commitmentData.value.fundCommitments.value.commitments.filter(
        (commitment: ICommitment) =>
          commitment.commitmentType === CommitmentType.LIFE_OF_FUND
      );

    // check if user has life of funds data specifically
    if (lofCommitments.length === 0) {
      return { lifeOfFundCommitments: nothing as Optional<ICommitmentsLOF> };
    }

    // convert generic ICommitment structure to specific LOF structure with percent calcs
    const commitments: ICommitmentLOF[] =
      convertLOFCommitmentDataToGridFormat(lofCommitments);

    // create obj with list of LOF commitments and as of date
    const lof: ICommitmentsLOF = {
      commitments: commitments,
      asOfDate: commitmentData.value.fundCommitments.value.asOfDate,
    };
    return {
      lifeOfFundCommitments: some(lof),
    };
  }
);

export const selectAnnualCommitments = createSelector(
  selectCommitmentsForClient,
  (commitmentData) => {
    // Check if user has any commitment data at all
    if (
      !isSomething(commitmentData) ||
      !isSomething(commitmentData.value.fundCommitments) ||
      commitmentData.value.fundCommitments.value.commitments.length === 0
    ) {
      return {
        annualCommitments: nothing as Optional<ICommitmentsAnnual>,
      };
    }
    // after confirming user has data - filter by annual commitments only
    const annualCommitments =
      commitmentData.value.fundCommitments.value.commitments.filter(
        (commitment) => commitment.commitmentType === CommitmentType.ANNUAL
      );
    // check if user has annual data specifically
    if (annualCommitments.length === 0) {
      // if no annual specific data return nothing
      return { annualCommitments: nothing as Optional<ICommitmentsAnnual> };
    }

    // convert generic ICommitment structure to specific Annual structure with percent calcs
    const commitments: ICommitmentAnnual[] =
      convertAnnualCommitmentToGridFormat(annualCommitments);

    const annual: ICommitmentsAnnual = {
      commitments: commitments,
      asOfDate: commitmentData.value.fundCommitments.value.asOfDate,
    };
    return {
      annualCommitments: some(annual),
    };
  }
);
