// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import {
  CalloutPersona,
  CommitmentsLabels,
  CommitmentType,
  convertAnnualCommitmentToGridFormat,
  convertLOFCommitmentDataToGridFormat,
  convertToInvestmentBreakdownGrouped,
  downloadFile,
  entityHasData,
  getEntityDataById,
  getFormattedDateYYYYMMDD,
  getICalloutData,
  getLevelZeroTree,
  groupForecastedCapitalCallsByStrategy,
  groupHistoricalSummary,
  IBaseStore,
  ICallout,
  ICommitmentAnnual,
  ICommitmentData,
  ICommitmentLOF,
  ICommitmentsAnnual,
  ICommitmentsLOF,
  IEntityDataStore,
  IEquityData,
  IForecastedCapCallByStrategy,
  IForecastedCapCallsByStrategy,
  IHistoricalSummaryGrouped,
  IInvestmentBreakdownDatum,
  IInvestmentBreakdownGrouped,
  InvestmentValueDisplay,
  isSomething,
  nothing,
  Optional,
  PeriodType,
  SectionHeader,
  some,
  StockSymbol,
  worksheetGenerators,
} from "common";
import ExcelJS from "exceljs";
import JSZip from "jszip";
import { useSelector } from "react-redux";
import sanitize from "sanitize-filename";

export const defaultExcelSettings = {
  valueDisplay: InvestmentValueDisplay.NONCUMULATIVE,
  periodType: PeriodType.QUARTERLY,
  showEmptyColumns: false,
  selectedStockSymbol: StockSymbol.BX,
};

export enum DataCategoryName {
  ANNUAL_COMMITMENTS = "AnnualCommitments",
  CARRY_DATA = "CarryData",
  FORCASTED_DATA = "ForecastedCapitalCalls",
  OVERVIEW_DATA = "OverviewData",
  LIFE_OF_FUNDS = "LifeOfFundCommitments",
  LOANS = "Loans",
  SEGREGATED_TRUST_BALANCE = "SegregatedTrustBalance",
  INVESTMENT_BREAKDOWN = "InvestmentBreakdown",
  PORTFOLIO_PERFORMANCE = "PortfolioPerformance",
  CASH_FLOW = "CashFlow",
  PORTFOLIO_BALANCE = "PortfolioBalance",
  ACTIVE_AWARD_DETAILS = "ActiveAwardDetails",
  EQUITY_DISTRIBUTIONS = "EquityDistributions",
}

export interface DataCategory {
  label: string;
  name: DataCategoryName;
  show: boolean;
  isAdmin?: boolean;
  valueDisplay?: InvestmentValueDisplay;
}
export type AdminDataCategories = {
  [key in DataCategoryName]: DataCategory;
};

export const hasNonZeroValueRealized = (
  data: IRealizedCalloutData
): boolean => {
  return (
    data.realizedCarry.value !== 0 ||
    data.realizedGainLoss.value !== 0 ||
    data.returnOfCapital.value !== 0 ||
    data.totalRealizedProceeds.value !== 0 ||
    data.realizedOptionalAndMandatoryInvestments.value !== 0
  );
};

export const hasNonZeroValueUnrealized = (
  data: IUnrealizedCalloutData
): boolean => {
  return (
    data.unrealizedCarry.value !== 0 ||
    data.unrealizedGainLoss.value !== 0 ||
    data.unrealizedCapitalInvested.value !== 0 ||
    data.unrealizedOptionalAndMandatoryInvestments.value !== 0 ||
    data.totalUnrealizedValue.value !== 0 ||
    data.loans.value !== 0 ||
    data.netUnrealizedValue.value !== 0
  );
};

const calculateAdminDataCategories = (
  entityData: IEntityDataStore,
  callout: ICallout,
  historicalSummaryGrouped: Optional<IHistoricalSummaryGrouped>,
  investmentBreakdownGrouped: Optional<IInvestmentBreakdownGrouped>,
  groupForecastedCapitalCallsByStrategy: Optional<IForecastedCapCallsByStrategy>,
  lofCommitments: Optional<ICommitmentsLOF>,
  annualCommitments: Optional<ICommitmentsAnnual>
) => {
  // check what exists and what categories to show
  const showLoans = isSomething(entityData.financingBalance) ? true : false;
  const showSegTrust = isSomething(entityData.segregatedTrustBalance)
    ? true
    : false;
  const showOverview =
    (isSomething(callout.calloutData.realized) &&
      hasNonZeroValueRealized(callout.calloutData.realized.value)) ||
    (isSomething(callout.calloutData.unrealized) &&
      hasNonZeroValueUnrealized(callout.calloutData.unrealized.value))
      ? true
      : false;
  const showHistorical = isSomething(historicalSummaryGrouped) ? true : false;
  const showInvestment = isSomething(investmentBreakdownGrouped) ? true : false;
  const showForecasted = isSomething(groupForecastedCapitalCallsByStrategy)
    ? true
    : false;
  const showLOF = isSomething(lofCommitments) ? true : false;
  const showAnnual = isSomething(annualCommitments) ? true : false;
  return {
    CashFlow: {
      name: DataCategoryName.CASH_FLOW,
      label: SectionHeader.CASH_FLOW,
      show: showHistorical,
      valueDisplay: InvestmentValueDisplay.NONCUMULATIVE,
    },
    OverviewData: {
      name: DataCategoryName.OVERVIEW_DATA,
      label: SectionHeader.INVESTMENTS_AND_CARRY_OVERVIEW,
      show: showOverview,
    },
    InvestmentBreakdown: {
      name: DataCategoryName.INVESTMENT_BREAKDOWN,
      label: SectionHeader.INVESTMENT_BREAKDOWN,
      show: showInvestment,
    },
    Loans: {
      name: DataCategoryName.LOANS,
      label: SectionHeader.LOANS,
      show: showLoans,
    },
    PortfolioBalance: {
      name: DataCategoryName.PORTFOLIO_BALANCE,
      label: SectionHeader.PORTFOLIO_BALANCE,
      show: showHistorical,
      valueDisplay: InvestmentValueDisplay.CUMULATIVE,
    },
    PortfolioPerformance: {
      name: DataCategoryName.PORTFOLIO_PERFORMANCE,
      label: SectionHeader.PORTFOLIO_PERFORMANCE,
      show: showHistorical,
      valueDisplay:
        InvestmentValueDisplay.CUMULATIVE_UNREALIZED_NONCUMULATIVE_REALIZED,
    },
    SegregatedTrustBalance: {
      name: DataCategoryName.SEGREGATED_TRUST_BALANCE,
      label: SectionHeader.SEG_TRUST_BALANCE,
      show: showSegTrust,
    },
    CarryData: {
      name: DataCategoryName.CARRY_DATA,
      label: SectionHeader.CARRY_DATA,
      show: showInvestment,
    },
    // Commitments / Capital Call Data
    AnnualCommitments: {
      name: DataCategoryName.ANNUAL_COMMITMENTS,
      label: CommitmentsLabels.ANNUAL_EXCEL_TAB,
      show: showAnnual,
    },
    ForecastedCapitalCalls: {
      name: DataCategoryName.FORCASTED_DATA,
      label: SectionHeader.FORECASTED_CAPITAL_CALLS,
      show: showForecasted,
    },
    LifeOfFundCommitments: {
      name: DataCategoryName.LIFE_OF_FUNDS,
      label: CommitmentsLabels.LOF_EXCEL_TAB,
      show: showLOF,
    },
  };
};

export const useAdminDownload = () => {
  const equityData = useSelector((store: IBaseStore) => store.equityData);

  const handleDownload = async (
    selectedClient: IInteralInvestmentClient,
    loadedEntities: IEntityDataStore[],
    onSuccess: () => void,
    onFailure: () => void
  ) => {
    try {
      // get client data
      const clientData = getEntityDataById(loadedEntities, selectedClient.id);
      // create excel for client level
      const clientFileBlob = await getExcelFile(clientData, equityData);

      const clientFilename = `Admin Export_${sanitize(
        selectedClient.name
      )}_${getFormattedDateYYYYMMDD(new Date(), false)}.xlsx`;
      // download single file if no investment vehicles
      if (selectedClient.investmentVehicles.length === 1) {
        const clientFile = new File([clientFileBlob], clientFilename);
        downloadFile(clientFile, clientFilename);
      } else {
        // add client file to blob for zip download
        const zip = new JSZip();

        zip.file(clientFilename, clientFileBlob);

        // loop through investment vehicles if applicable
        for (const entity of selectedClient.investmentVehicles) {
          const internalInvestmentData = getEntityDataById(
            loadedEntities,
            entity.id
          );
          // check if entity has data
          if (entityHasData(internalInvestmentData)) {
            const fileBlob = await getExcelFile(internalInvestmentData);
            const filename = `Admin Export_IV_${sanitize(
              entity.name
            )}_${getFormattedDateYYYYMMDD(new Date(), false)}.xlsx`;

            zip.file(filename, fileBlob);
          }
        }
        // generate zip
        const zipFilename = `Admin Export_${sanitize(
          selectedClient.name
        )}_${getFormattedDateYYYYMMDD(new Date(), false)}.zip`;

        zip.generateAsync({ type: "blob" }).then((content) => {
          const zipFile = new File([content], zipFilename);
          downloadFile(zipFile, zipFilename);
        });
      }
      onSuccess();
    } catch (error) {
      onFailure(error);
    }
  };

  return handleDownload;
};

const getExcelFile = async (
  internalInvestmentData: IEntityDataStore,
  equityData?: IEquityData
) => {
  const isAdmin = true;
  const callout = getCalloutData(internalInvestmentData);

  const investmentBreakdownGrouped = getInvestmentBreakdownGrouped(
    internalInvestmentData.investmentBreakdown
  );

  const historicalSummaryGrouped = getHistoricalSummaryGrouped(
    internalInvestmentData.historicalSummary,
    internalInvestmentData.asOfDates.latestAsOfDateWithUnrealizedData
  );

  const forecastedCommitmentByStrategy = getForecastedByStrategy(
    internalInvestmentData.commitmentData
  );

  const lifeOfFundCommitments = getLifeOfFundCommitments(
    internalInvestmentData.commitmentData
  );

  const annualCommitments = getAnnualCommitments(
    internalInvestmentData.commitmentData
  );

  // build data categories
  const dataCategories = calculateAdminDataCategories(
    internalInvestmentData,
    callout,
    historicalSummaryGrouped,
    investmentBreakdownGrouped,
    forecastedCommitmentByStrategy,
    lifeOfFundCommitments,
    annualCommitments
  );

  // set up excel workbook
  const workbook = new ExcelJS.Workbook();
  workbook.created = new Date();

  Object.values(dataCategories).forEach((category) => {
    const generator = worksheetGenerators[category.name];
    const params = {
      workbook,
      equityData,
      internalInvestmentData,
      callout,
      investmentBreakdownGrouped,
      historicalSummaryGrouped,
      forecastedCommitmentByStrategy,
      lifeOfFundCommitments,
      annualCommitments,
      isAdmin,
      settings: {
        showEmptyColumns: defaultExcelSettings.showEmptyColumns,
        valueDisplay: category.valueDisplay
          ? category.valueDisplay
          : defaultExcelSettings.valueDisplay,
        periodType: defaultExcelSettings.periodType,
        selectedStockSymbol: defaultExcelSettings.selectedStockSymbol,
      },
    };
    if (category.show && generator !== undefined) {
      generator(params);
    }
  });

  // create file blob from generated excel
  const excelFileBlob: Blob = await workbook.xlsx.writeBuffer();

  return excelFileBlob;
};

const getInvestmentBreakdownGrouped = (
  investmentBreakdown: IInvestmentBreakdownDatum[]
) => {
  if (investmentBreakdown.length === 0) {
    return nothing as Optional<IInvestmentBreakdownGrouped>;
  }

  const investmentBreakdownGrouped =
    convertToInvestmentBreakdownGrouped(investmentBreakdown);
  return some(investmentBreakdownGrouped);
};

const getHistoricalSummaryGrouped = (
  historicalSummary: IInvestmentBreakdownDatum[],
  latestAsOfDateWithUnrealizedData: Optional<Date>
) => {
  if (historicalSummary.length === 0) {
    return nothing as Optional<IHistoricalSummaryGrouped>;
  }
  const historicalSummaryGrouped = groupHistoricalSummary(
    historicalSummary,
    latestAsOfDateWithUnrealizedData
  );
  return some(historicalSummaryGrouped);
};

const getForecastedByStrategy = (commitmentData: Optional<ICommitmentData>) => {
  if (
    !isSomething(commitmentData) ||
    !isSomething(commitmentData.value.forecastedCapitalCalls) ||
    commitmentData.value.forecastedCapitalCalls.value.capitalCalls.length === 0
  ) {
    return nothing as Optional<IForecastedCapCallsByStrategy>;
  }
  const capCallsByStrategy: IForecastedCapCallByStrategy[] =
    groupForecastedCapitalCallsByStrategy(
      commitmentData.value.forecastedCapitalCalls.value.capitalCalls
    );
  const forecasted: IForecastedCapCallsByStrategy = {
    capitalCalls: capCallsByStrategy,
    asOfDate: commitmentData.value.forecastedCapitalCalls.value.asOfDate,
  };
  return some(forecasted);
};

const getLifeOfFundCommitments = (
  commitmentData: Optional<ICommitmentData>
) => {
  if (
    !isSomething(commitmentData) ||
    !isSomething(commitmentData.value.fundCommitments) ||
    commitmentData.value.fundCommitments.value.commitments.length === 0
  ) {
    return nothing as Optional<ICommitmentsLOF>;
  }
  const lofCommitments: ICommitmentLOF[] = convertLOFCommitmentDataToGridFormat(
    commitmentData.value.fundCommitments.value.commitments.filter(
      (commitment) => commitment.commitmentType === CommitmentType.LIFE_OF_FUND
    )
  );
  const lof: ICommitmentsLOF = {
    commitments: lofCommitments,
    asOfDate: commitmentData.value.fundCommitments.value.asOfDate,
  };
  return some(lof);
};

const getAnnualCommitments = (commitmentData: Optional<ICommitmentData>) => {
  if (
    !isSomething(commitmentData) ||
    !isSomething(commitmentData.value.fundCommitments) ||
    commitmentData.value.fundCommitments.value.commitments.length === 0
  ) {
    return nothing as Optional<ICommitmentsAnnual>;
  }
  const annualCommitments: ICommitmentAnnual[] =
    convertAnnualCommitmentToGridFormat(
      commitmentData.value.fundCommitments.value.commitments.filter(
        (commitment) => commitment.commitmentType === CommitmentType.ANNUAL
      )
    );
  const annual: ICommitmentsAnnual = {
    commitments: annualCommitments,
    asOfDate: commitmentData.value.fundCommitments.value.asOfDate,
  };
  return some(annual);
};

const getCalloutData = (data: IEntityDataStore) => {
  const emptyCallout: ICallout = {
    calloutData: {
      realized: nothing,
      unrealized: nothing,
    },
    calloutPersona: CalloutPersona.NON_CARRY,
  };

  const hasTotalFinancingBalanceQSTMT = isSomething(data.financingBalance);

  if (data.investmentBreakdown.length === 0 && !hasTotalFinancingBalanceQSTMT) {
    return emptyCallout;
  }

  const levelZeroTree = getLevelZeroTree(data.investmentBreakdown);
  const calloutData = getICalloutData(
    levelZeroTree,
    isSomething(data.financingBalance)
      ? data.financingBalance.value.totalFinancingBalanceQSTMT
      : 0
  );

  const hasRealizedCarry =
    isSomething(calloutData.realized) &&
    calloutData.realized.value.realizedCarry.value !== 0;
  const hasUnrealizedCarry =
    isSomething(calloutData.unrealized) &&
    calloutData.unrealized.value.unrealizedCarry.value !== 0;
  const calloutPersona =
    hasRealizedCarry || hasUnrealizedCarry
      ? CalloutPersona.CARRY
      : CalloutPersona.NON_CARRY;

  const callout: ICallout = {
    calloutData: calloutData,
    calloutPersona,
  };

  return callout;
};
