import { CommitmentsLabels } from "../../constants/LabelAndTooltipConstants";
import {
  ForecastedCapCallsLabel,
  SectionHeader,
} from "../../constants/LabelAndTooltipConstants";
import { IForecastedCapCallByStrategy } from "../../types/dataTypes";
import { WorksheetGeneratorParams } from "../../types/excelTypes";
import { isSomething } from "../../types/typeGuards";
import {
  sortAndAggregateAnnualCommitments,
  sortForecastedCapitalCallsAndAddTotalsRow,
} from "../commitmentsUtils";
import {
  ExcelConstants,
  formatBottomRow,
  formatHeaderRows,
  getAsOfHeader,
} from "./excelUtils";

export const buildForecastedCapCallsWorksheet = (
  params: WorksheetGeneratorParams
) => {
  const { workbook, forecastedCommitmentByStrategy } = params;
  if (
    isSomething(forecastedCommitmentByStrategy) &&
    forecastedCommitmentByStrategy.value.capitalCalls.length > 0
  ) {
    const forecasted = forecastedCommitmentByStrategy.value;

    const forecastedCapCallsByStrategyWithTotal: IForecastedCapCallByStrategy[] =
      sortForecastedCapitalCallsAndAddTotalsRow(forecasted.capitalCalls);

    const worksheet = workbook.addWorksheet(
      SectionHeader.FORECASTED_CAPITAL_CALLS,
      {
        views: [{ state: "frozen", ySplit: 1 }],
      }
    );
    worksheet.columns = [
      {
        header: ForecastedCapCallsLabel.STRATEGY,
        key: ExcelConstants.KEYS.STRATEGY_NAME,
        width: ExcelConstants.SIZES.MEDIUM,
      },
      {
        header:
          ForecastedCapCallsLabel.FORECASTED_CAP_CALL +
          " " +
          getAsOfHeader(forecasted.asOfDate),
        key: ExcelConstants.KEYS.FORECASTED_CAP_CALL_AMNT,
        width: ExcelConstants.SIZES.MEDIUM,
        style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
      },
    ];
    worksheet.addRows(forecastedCapCallsByStrategyWithTotal);
    formatHeaderRows(worksheet);
    formatBottomRow(worksheet);
  }
  return workbook;
};

export const buildLOFCommitmentsWorksheet = (
  params: WorksheetGeneratorParams
) => {
  const { workbook, lifeOfFundCommitments, isAdmin } = params;
  if (
    isSomething(lifeOfFundCommitments) &&
    lifeOfFundCommitments.value.commitments.length > 0
  ) {
    const lof = lifeOfFundCommitments.value;
    const worksheet = workbook.addWorksheet(CommitmentsLabels.LOF_EXCEL_TAB, {
      views: [{ state: "frozen", ySplit: 1 }],
    });
    worksheet.columns = isAdmin
      ? [
          {
            header: CommitmentsLabels.FUND_ID,
            key: ExcelConstants.KEYS.FUND_ID,
            width: ExcelConstants.SIZES.XSMALL,
            style: { alignment: { horizontal: "left" } },
          },
          {
            header: CommitmentsLabels.FUND,
            key: ExcelConstants.KEYS.FUND_NAME,
            width: ExcelConstants.SIZES.MEDIUM,
          },
          {
            header: CommitmentsLabels.ELECTED_AMOUNT,
            key: ExcelConstants.KEYS.ELECTED_AMOUNT,
            width: ExcelConstants.SIZES.MEDIUM,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header:
              CommitmentsLabels.CAPITAL_CALLED +
              " " +
              getAsOfHeader(lof.asOfDate),
            key: ExcelConstants.KEYS.CAPITAL_CALLED,
            width: ExcelConstants.SIZES.MEDIUM,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header: CommitmentsLabels.CAPITAL_CALLED_PERCENT,
            key: ExcelConstants.KEYS.CAPITAL_CALLED_PERCENT,
            width: ExcelConstants.SIZES.MEDIUM,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.PERCENT },
          },
        ]
      : [
          {
            header: CommitmentsLabels.FUND,
            key: ExcelConstants.KEYS.FUND_NAME,
            width: ExcelConstants.SIZES.MEDIUM,
          },
          {
            header: CommitmentsLabels.ELECTED_AMOUNT,
            key: ExcelConstants.KEYS.ELECTED_AMOUNT,
            width: ExcelConstants.SIZES.MEDIUM,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header:
              CommitmentsLabels.CAPITAL_CALLED +
              " " +
              getAsOfHeader(lof.asOfDate),
            key: ExcelConstants.KEYS.CAPITAL_CALLED,
            width: ExcelConstants.SIZES.MEDIUM,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header: CommitmentsLabels.CAPITAL_CALLED_PERCENT,
            key: ExcelConstants.KEYS.CAPITAL_CALLED_PERCENT,
            width: ExcelConstants.SIZES.MEDIUM,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.PERCENT },
          },
        ];
    worksheet.addRows(lof.commitments);
    formatHeaderRows(worksheet);
  }
  return workbook;
};

export const buildAnnualCommitmentsWorksheet = (
  params: WorksheetGeneratorParams
) => {
  const { workbook, annualCommitments, isAdmin } = params;
  if (
    isSomething(annualCommitments) &&
    annualCommitments.value.commitments.length > 0
  ) {
    const annual = annualCommitments.value;
    const worksheet = workbook.addWorksheet(
      CommitmentsLabels.ANNUAL_EXCEL_TAB,
      {
        views: [{ state: "frozen", ySplit: 1 }],
      }
    );
    worksheet.columns = isAdmin
      ? [
          {
            header: CommitmentsLabels.COMMITMENT_YEAR,
            key: ExcelConstants.KEYS.ELECTION_YEAR,
            width: ExcelConstants.SIZES.SMALL,
            style: { alignment: { horizontal: "left" } },
          },
          {
            header: CommitmentsLabels.FUND_ID,
            key: ExcelConstants.KEYS.FUND_ID,
            width: ExcelConstants.SIZES.XSMALL,
            style: { alignment: { horizontal: "left" } },
          },
          {
            header: CommitmentsLabels.FUND,
            key: ExcelConstants.KEYS.FUND_NAME,
            width: ExcelConstants.SIZES.SMALL,
            style: { alignment: { indent: 1 } },
          },
          {
            header: CommitmentsLabels.ELECTED_AMOUNT,
            key: ExcelConstants.KEYS.ELECTED_AMOUNT,
            width: ExcelConstants.SIZES.MEDIUM,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header:
              CommitmentsLabels.CAPITAL_CALLED +
              " " +
              getAsOfHeader(annual.asOfDate),
            key: ExcelConstants.KEYS.CAPITAL_CALLED,
            width: ExcelConstants.SIZES.MEDIUM,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header: CommitmentsLabels.CAPITAL_CALLED_PERCENT,
            key: ExcelConstants.KEYS.CAPITAL_CALLED_PERCENT,
            width: ExcelConstants.SIZES.SMALL,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.PERCENT },
          },
        ]
      : [
          {
            header: CommitmentsLabels.COMMITMENT_YEAR,
            key: ExcelConstants.KEYS.ELECTION_YEAR,
            width: ExcelConstants.SIZES.SMALL,
            style: { alignment: { horizontal: "left" } },
          },
          {
            header: CommitmentsLabels.FUND,
            key: ExcelConstants.KEYS.FUND_NAME,
            width: ExcelConstants.SIZES.SMALL,
            style: { alignment: { indent: 1 } },
          },
          {
            header: CommitmentsLabels.ELECTED_AMOUNT,
            key: ExcelConstants.KEYS.ELECTED_AMOUNT,
            width: ExcelConstants.SIZES.MEDIUM,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header:
              CommitmentsLabels.CAPITAL_CALLED +
              " " +
              getAsOfHeader(annual.asOfDate),
            key: ExcelConstants.KEYS.CAPITAL_CALLED,
            width: ExcelConstants.SIZES.MEDIUM,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header: CommitmentsLabels.CAPITAL_CALLED_PERCENT,
            key: ExcelConstants.KEYS.CAPITAL_CALLED_PERCENT,
            width: ExcelConstants.SIZES.SMALL,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.PERCENT },
          },
        ];
    const yearlyData = sortAndAggregateAnnualCommitments(annual.commitments);

    for (const year of yearlyData) {
      const yearRow = worksheet.addRow(year);
      yearRow.font = { ...yearRow, bold: true };

      if (isAdmin) {
        yearRow.getCell("C").style.alignment = { indent: 0 };
        yearRow.getCell("B").value = null;
      } else {
        yearRow.getCell("B").style.alignment = { indent: 0 };
      }

      const relevantFunds = annual.commitments
        .filter((fund) => fund.electionYear === year.electionYear)
        .sort((a, b) => a.fundName.localeCompare(b.fundName));
      worksheet.addRows(relevantFunds);
    }
    formatHeaderRows(worksheet);
    const headerRow = worksheet.getRow(1);
    headerRow.alignment = { indent: 0 };
  }
  return workbook;
};
