import { Circle } from "@mui/icons-material";
import { Grid } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { Cell, Label, Legend, Pie, PieChart, Tooltip } from "recharts";

import {
  LoanBalanceTooltip,
  ThirdPartyFinancingLabel,
} from "../../../constants/LabelAndTooltipConstants";
import { MUIConstants } from "../../../constants/MUIConstants";
import colors from "../../../styles/_colors.scss";
import { IFinancingBalance } from "../../../types/dataTypes";
import {
  getCurrencyFormattedValue,
  getPercentageFormattedValue,
} from "../../../utils/formatters";
import { DashboardCardTable } from "../../DashboardCardTable/DashboardCardTable";
import { ResponsiveRechartsWrapper } from "../../ResponsiveRechartsWrapper/ResponsiveRechartsWrapper";
import styles from "./ThirdPartyFinancingRow.module.scss";

const COLORS_MAPPING = {
  [ThirdPartyFinancingLabel.THIRD_PARTY_LOAN]: colors.taupe,
  [ThirdPartyFinancingLabel.PENDING_THIRD_PARTY_LOAN]: colors.taupe_shade,
  [ThirdPartyFinancingLabel.AVAILABLE_LOAN_CAPACITY]: colors.slate_shade,
};

const cy = 120;
const innerRadius = 75;
const outerRadius = 90;

interface IThirdPartyFinancingProps {
  financingData: IFinancingBalance;
  shouldRenderGraph: boolean;
  headerCols: string[];
}

interface FinancingDatum {
  type: string;
  value: number | undefined;
  color: string;
}

export const ThirdPartyFinancing = (props: IThirdPartyFinancingProps) => {
  const { financingData, shouldRenderGraph, headerCols } = props;

  const thirdPartyFinancing: FinancingDatum[] = useMemo(() => {
    const financing: FinancingDatum[] = [
      {
        type: ThirdPartyFinancingLabel.THIRD_PARTY_LOAN,
        value: financingData.thirdPartyFinancingBalance,
        color: COLORS_MAPPING[ThirdPartyFinancingLabel.THIRD_PARTY_LOAN],
      },
      {
        type: ThirdPartyFinancingLabel.PENDING_THIRD_PARTY_LOAN,
        value: financingData.thirdPartyPendingLoanBalance,
        color:
          COLORS_MAPPING[ThirdPartyFinancingLabel.PENDING_THIRD_PARTY_LOAN],
      },
    ];

    // only add remaining loan balance if greater than 0
    if (financingData.thirdPartyRemainingLoanCapacity > 0) {
      financing.push({
        type: ThirdPartyFinancingLabel.AVAILABLE_LOAN_CAPACITY,
        value: financingData.thirdPartyRemainingLoanCapacity,
        color: COLORS_MAPPING[ThirdPartyFinancingLabel.AVAILABLE_LOAN_CAPACITY],
      });
    }

    return financing;
  }, [financingData]);

  const [isRowOpen, setIsRowOpen] = useState<boolean>(false);

  /*
  helper function to convert an angle in degrees to radians
  */
  const convertDegreesToRadians = (degrees: number) => {
    return (degrees * Math.PI) / 180;
  };

  /*
  Helper function to get the angle of the pie chart where the 100 label and line should be rendered.

  sectorValue: the number value of the sector of the pie chart
  hundredPercentMarker: number value denoting where in the sector the 100 label should go
  startAngle: the start angle of the sector in the pie chart
  endAngle: the end angle of the pie chart in the sector
  */
  const getHundredPercentAngle = (
    sectorValue: number,
    hundredPercentMarker: number,
    sectorStartAngle: number,
    sectorEndAngle: number
  ) => {
    const markerPercentage = hundredPercentMarker / sectorValue;
    const angularSpan = sectorStartAngle - sectorEndAngle;
    const tickPlacement = markerPercentage * angularSpan;
    return sectorStartAngle - tickPlacement;
  };

  const OneHundredPercentMark = useCallback(
    ({
      cx,
      cy,
      innerRadius,
      outerRadius,
    }: {
      cx: number;
      cy: number;
      innerRadius: number;
      outerRadius: number;
    }) => {
      // only display this if we are over the loan limit
      if (financingData.thirdPartyRemainingLoanCapacity < 0) {
        const totalPieChart =
          financingData.thirdPartyFinancingBalance +
          financingData.thirdPartyPendingLoanBalance;
        const loanLimit = financingData.thirdPartyLoanLimit;
        const loanimitAngle = getHundredPercentAngle(
          totalPieChart,
          loanLimit,
          180,
          0
        );

        const startRadius = innerRadius - 5; // line should start just inside the circle of the pie chart
        const endRadius = outerRadius + 5; // line should end just outside the circle of the pie chart
        const labelPlacement = outerRadius + 10;

        // Calculate the starting point of the tick mark
        const startX =
          cx + startRadius * Math.cos(convertDegreesToRadians(-loanimitAngle));
        const startY =
          cy + startRadius * Math.sin(convertDegreesToRadians(-loanimitAngle));

        // Calculate the ending point of the tick mark
        const endX =
          cx + endRadius * Math.cos(convertDegreesToRadians(-loanimitAngle));
        const endY =
          cy + endRadius * Math.sin(convertDegreesToRadians(-loanimitAngle));

        const linePath = `M${startX} ${startY}L${endX} ${endY}`;

        // calculate position of the 100% label
        const labelX =
          cx +
          labelPlacement * Math.cos(convertDegreesToRadians(-loanimitAngle));
        const labelY =
          cy +
          labelPlacement * Math.sin(convertDegreesToRadians(-loanimitAngle));

        return (
          <g>
            <text
              key={0}
              x={labelX}
              y={labelY}
              fill="red"
              textAnchor={labelX > cx ? "start" : "end"}
              dominantBaseline="central"
            >
              {`100%`}
            </text>
            <path key={1} d={linePath} stroke="black" fill="none" />
          </g>
        );
      }
      return <></>;
    },
    [financingData]
  );

  return (
    <Grid
      container
      columnSpacing={MUIConstants.DASHBOARD_WIDGET_COLUMN_SPACING}
    >
      {shouldRenderGraph && (
        <Grid
          item
          xs={12}
          md={MUIConstants.DASHBOARD_WIDGET_LEFT_ITEM_SIZE}
          className={styles.pie}
        >
          <ResponsiveRechartsWrapper>
            <PieChart>
              <Pie
                data={thirdPartyFinancing}
                nameKey="type"
                dataKey="value"
                startAngle={180}
                endAngle={0}
                cx={"50%"}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
              >
                {thirdPartyFinancing.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS_MAPPING[entry.type]}
                  />
                ))}
                <Label
                  position="center"
                  value={ThirdPartyFinancingLabel.THIRD_PARTY}
                  className={styles.labelTop}
                  fill={colors.bx_primary_grey_text}
                />
                <Label
                  position="center"
                  value={ThirdPartyFinancingLabel.LOAN_UTILIZATION}
                  className={styles.labelBottom}
                  fill={colors.bx_primary_grey_text}
                />
                <Label
                  position="center"
                  value={getPercentageFormattedValue(
                    financingData.thirdPartyFinancingBalance +
                      financingData.thirdPartyPendingLoanBalance,
                    financingData.thirdPartyLoanLimit
                  )}
                  className={styles.labelNumber}
                />
              </Pie>
              <Pie
                data={thirdPartyFinancing}
                nameKey="type"
                dataKey="value"
                stroke="none"
                fill="none"
                cx={"50%"}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                activeIndex={1}
                activeShape={OneHundredPercentMark}
              />
              <Tooltip
                cursor={false}
                content={({ payload }) => {
                  return (
                    <div className={styles.tooltip}>
                      <div>
                        <ul className={styles.itemList}>
                          {payload &&
                            payload.map((item) => (
                              <li key={item.dataKey} className={styles.item}>
                                <Circle
                                  sx={{ fontSize: 10, marginBottom: 30 }}
                                  className={styles.circle}
                                  style={{ color: item.payload.color }}
                                ></Circle>
                                <span className={styles.itemLabel}>
                                  {item.name}:{" "}
                                </span>
                                <span>
                                  {getCurrencyFormattedValue(
                                    item.value as number
                                  )}
                                </span>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  );
                }}
              />
              <Legend
                cursor="pointer"
                iconSize={14}
                verticalAlign="bottom"
                payload={thirdPartyFinancing
                  .map((datum) => datum.type)
                  // Explicitly hide remaining from legend in accordance with design
                  .filter(
                    (type) =>
                      type !== ThirdPartyFinancingLabel.AVAILABLE_LOAN_CAPACITY
                  )
                  .map((type) => {
                    return {
                      value: type,
                      type: "circle",
                      color: COLORS_MAPPING[type],
                    };
                  })}
              />
            </PieChart>
          </ResponsiveRechartsWrapper>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={
          shouldRenderGraph ? MUIConstants.DASHBOARD_WIDGET_RIGHT_ITEM_SIZE : 12
        }
      >
        <DashboardCardTable
          header={ThirdPartyFinancingLabel.THIRD_PARTY_LOANS}
          headerCols={headerCols}
          rows={[
            {
              name: ThirdPartyFinancingLabel.LOAN_LIMIT,
              value: financingData.thirdPartyLoanLimit,
              isOpen: isRowOpen,
              setIsOpen: setIsRowOpen,
            },
            {
              name: ThirdPartyFinancingLabel.THIRD_PARTY_LOAN,
              value: financingData.thirdPartyFinancingBalance,
              tooltipParagraphs: [LoanBalanceTooltip.THIRD_PARTY_LOAN],
              isOpen: isRowOpen,
              setIsOpen: setIsRowOpen,
            },
            {
              name: ThirdPartyFinancingLabel.PENDING_THIRD_PARTY_LOAN,
              value: financingData.thirdPartyPendingLoanBalance,
              tooltipParagraphs: [LoanBalanceTooltip.PENDING_THIRD_PARTY_LOAN],
              isOpen: isRowOpen,
              setIsOpen: setIsRowOpen,
            },
          ]}
          totalRow={{
            name: ThirdPartyFinancingLabel.AVAILABLE_LOAN_CAPACITY,
            value: financingData.thirdPartyRemainingLoanCapacity,
            tooltipParagraphs: [LoanBalanceTooltip.AVAILABLE_LOAN_CAPACITY],
          }}
        />
      </Grid>
    </Grid>
  );
};
