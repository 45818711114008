import {
  EquityHeader,
  IAuthorizedComponentProps,
  InvestmentsTabsDisplayOrder,
  IPage,
  Page,
  PageWithSubTabs,
  PathFragment,
  SectionHeader,
  StringConstants,
} from "common";
import {
  ElectionTabsDisplayOrder,
  EquityTabsDisplayOrder,
} from "common/src/constants/SubTabPages";
import React from "react";

import InvestmentEntityDropdownWrapper from "../../common/components/InvestmentEntityDropdownWrapper/InvestmentEntityDropdownWrapper";
import PageWithHeader from "../../common/components/PageWithHeader/PageWithHeader";
import CommitmentsWrapper from "../../features/Commitments/CommitmentsWrapper";
import DashboardWrapper from "../../features/Dashboard/DashboardWrapper";
import { EquityLandingPage } from "../../features/EquityLandingPage/EquityLandingPage";
import { LandingPage } from "../../features/LandingPage/LandingPage";
import { RelativePath } from "../Paths";
import {
  ElectionAdminPages,
  EquityPages,
  InvestmentsPages,
} from "./SubTabPages";

export enum PageName {
  LANDING_PAGE = "LandingPage",
  ELECTION_ADMIN_PAGE = "ElectionAdminPage",
  EQUITY_LANDING_PAGE = "EquityLandingPage",
  EQUITY = "Equity",
  DASHBOARD = "Dashboard",
  INVESTMENTS_AND_CARRY = "InvestmentsAndCarry",
  COMMITMENTS = "Commitments",
}

export enum ClientLevelPageName {
  DASHBOARD = "Dashboard",
  INVESTMENTS_AND_CARRY = "InvestmentsAndCarry",
  COMMITMENTS = "Commitments",
}

type Pages = {
  [key in PageName]: IPage;
};

type ITab = {
  name: string;
  getPath: (clientId: string | undefined) => string;
};

type ClientLevelPages = {
  [key in ClientLevelPageName]: ITab;
};

export const ClientTabDisplayOrder: ClientLevelPageName[] = [
  ClientLevelPageName.DASHBOARD,
  ClientLevelPageName.INVESTMENTS_AND_CARRY,
  ClientLevelPageName.COMMITMENTS,
];

const investmentsAndCarryComponent = () => (
  <PageWithSubTabs
    pageMenu={<InvestmentEntityDropdownWrapper />}
    pageHeader={StringConstants.INVESTMENTS_AND_CARRY_DETAILS}
    pages={InvestmentsPages}
    tabDisplayOrder={InvestmentsTabsDisplayOrder}
    defaultTab={`${PathFragment.OVERVIEW}`}
  />
);

const electionsAdminComponent = () => (
  <PageWithSubTabs
    pageHeader={StringConstants.ELECTION_ADMIN}
    pages={ElectionAdminPages}
    tabDisplayOrder={ElectionTabsDisplayOrder}
    defaultTab={`${PathFragment.ELECTION_EDITOR}`}
  />
);

const equityAdminComponent = () => (
  <PageWithSubTabs
    pageHeader={<EquityHeader />}
    pages={EquityPages}
    tabDisplayOrder={EquityTabsDisplayOrder}
    defaultTab={`${PathFragment.EQUITY_VALUE}`}
  />
);

export const Pages: Pages = {
  LandingPage: {
    name: SectionHeader.LANDING_PAGE,
    path: RelativePath.LANDING_PAGE,
    element: (e: IAuthorizedComponentProps) => (
      <PageWithHeader pageBody={LandingPage} user={e.user} />
    ),
    hasError: false,
  },
  ElectionAdminPage: {
    name: SectionHeader.ELECTION_ADMIN_PAGE,
    path: `/${RelativePath.ELECTIONS_ADMIN}/*`,
    element: (e: IAuthorizedComponentProps) => (
      <PageWithHeader pageBody={electionsAdminComponent} user={e.user} />
    ),
    hasError: false,
  },
  EquityLandingPage: {
    name: SectionHeader.LANDING_PAGE,
    path: RelativePath.EQUITY_LANDING_PAGE,
    element: (e: IAuthorizedComponentProps) => (
      <PageWithHeader pageBody={EquityLandingPage} user={e.user} />
    ),
    hasError: false,
  },
  Equity: {
    name: SectionHeader.EQUITY,
    path: `/${RelativePath.EQUITY}/*`,
    element: (e: IAuthorizedComponentProps) => (
      <PageWithHeader pageBody={equityAdminComponent} user={e.user} />
    ),
    rootPath: `/${RelativePath.EQUITY}`,
    hasError: false,
    parentNavBarName: SectionHeader.EQUITY,
  },
  Dashboard: {
    name: SectionHeader.DASHBOARD,
    path: RelativePath.DASHBOARD,
    element: (e: IAuthorizedComponentProps) => (
      <PageWithHeader pageBody={DashboardWrapper} user={e.user} />
    ),
    hasError: false,
  },
  InvestmentsAndCarry: {
    name: SectionHeader.INVESTMENTS_AND_CARRY_DETAILS,
    path: `/${RelativePath.INVESTMENTS_AND_CARRY}/*`,
    hasError: false,
    element: (e: IAuthorizedComponentProps) => (
      <PageWithHeader pageBody={investmentsAndCarryComponent} user={e.user} />
    ),
    rootPath: `/${RelativePath.INVESTMENTS_AND_CARRY}`,
    parentNavBarName: SectionHeader.INVESTMENTS_AND_CARRY,
  },
  Commitments: {
    name: SectionHeader.COMMITMENTS,
    path: `/${RelativePath.COMMITMENTS}/*`,
    hasError: false,
    element: (e: IAuthorizedComponentProps) => (
      <PageWithHeader
        pageBody={() => (
          <Page
            pageHeader={SectionHeader.COMMITMENTS}
            content={<CommitmentsWrapper />}
          />
        )}
        user={e.user}
      />
    ),
    rootPath: `/${RelativePath.COMMITMENTS}`,
    parentNavBarName: SectionHeader.COMMITMENTS,
  },
};

const replaceClient = (path: RelativePath, clientId: string) =>
  path.replace(":clientId", clientId);

export const ClientLevelPages: ClientLevelPages = {
  Dashboard: {
    name: SectionHeader.DASHBOARD,
    getPath: (clientId: string | undefined) =>
      replaceClient(RelativePath.DASHBOARD, clientId ?? ""),
  },
  InvestmentsAndCarry: {
    name: SectionHeader.INVESTMENTS_AND_CARRY,
    getPath: (clientId: string | undefined) =>
      replaceClient(RelativePath.INVESTMENTS_AND_CARRY, clientId ?? ""),
  },
  Commitments: {
    name: SectionHeader.COMMITMENTS,
    getPath: (clientId: string | undefined) =>
      replaceClient(RelativePath.COMMITMENTS, clientId ?? ""),
  },
};
