import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Button } from "@mui/material";
import React from "react";

import { ErrorStringConstants } from "../../constants/ErrorStringConstants";
import { StringConstants } from "../../constants/StringConstants";
import styles from "./PageLoadError.module.scss";

interface IPageLoadErrorProps {
  errorCode: number;
}

export const PageLoadError = (props: IPageLoadErrorProps) => {
  return (
    <div className={styles.error}>
      <ErrorOutlineOutlinedIcon className={styles.warningIcon} />
      <div className={styles.message}>
        <div className={styles.header}>
          {ErrorStringConstants.SOMETHING_WENT_WRONG}
        </div>
        <div>{`${ErrorStringConstants.PAGE_LOAD_INCORRECT} ${ErrorStringConstants.HELP_MESSAGE}`}</div>
        <a className={styles.link} href={`${StringConstants.MAIL_TO}`}>
          {ErrorStringConstants.BXWEALTH_SUPPORT}
        </a>
        <div className={styles.errorCode}>{`Error: [${props.errorCode}]`}</div>
        <Button
          className={styles.reloadButton}
          onClick={() => window.location.reload()}
        >
          {ErrorStringConstants.RELOAD}
        </Button>
      </div>
    </div>
  );
};
