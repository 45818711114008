import { User } from "oidc-client-ts";
import React from "react";
import { AuthContextProps } from "react-oidc-context";
import { Navigate, useLocation } from "react-router-dom";

import { PageLoadError } from "../components/PageLoadError/PageLoadError";
import { RelativePath } from "../constants/Paths";
import { storePathForLoginRedirect } from "../utils/authUtils";

export interface IAuthorizedComponentProps {
  user: User;
}

interface IRequireAuthenticationProps {
  pageElement: (e: IAuthorizedComponentProps) => JSX.Element;
  auth: AuthContextProps;
  pageLoadStatus: number;
}

export const RequireAuthentication = (props: IRequireAuthenticationProps) => {
  const location = useLocation();
  const { auth, pageLoadStatus } = props;

  if (!auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
    storePathForLoginRedirect(location.pathname);
    return <Navigate to={RelativePath.LOGIN} replace />;
  }

  // TODO: Logic regarding what to display based on the pageLoadStatus should probably
  // be deferred to another component, it isn't inherently related to auth.
  return auth.user ? (
    pageLoadStatus < 400 ? (
      <props.pageElement user={auth.user} />
    ) : (
      <PageLoadError errorCode={pageLoadStatus} />
    )
  ) : (
    <></>
  );
};
