import { Grid, Stack, Typography } from "@mui/material";
import { CopyToClipboardButton, TextEditor } from "common";
import React, { useState } from "react";

import styles from "./ElectionEditor.module.scss";

export const ElectionsAdminPage = () => {
  const [input, setInput] = useState("");
  const [formattedText, setFormattedText] = useState("");

  const updateText = (input: string) => {
    const scappedInput = input.replace(/\n/g, "\\n").replaceAll('"', '\\"');
    setInput(input);
    setFormattedText(scappedInput);
  };

  return (
    <Grid
      className={styles.page}
      container
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography variant="h2">Election text editor</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h4">Formatted text</Typography>
        <TextEditor
          markdown={input}
          className={styles.gridItem}
          onChange={updateText}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h4">
          Unformatted text (Use this to copy into payload)
        </Typography>
        <Stack
          className={styles.gridItem}
          direction="row"
          justifyContent="space-evenly"
        >
          <Typography variant="body1">{formattedText}</Typography>
          <CopyToClipboardButton text={formattedText}></CopyToClipboardButton>
        </Stack>
      </Grid>
    </Grid>
  );
};
