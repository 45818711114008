import { ThemeOptions } from "@mui/material";

import colors from "../../_colors.scss";
import { Font } from "../sharedStyleConstants";

// Shared constants we may want to move in unison

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiStepper: {
      styleOverrides: {
        root: {
          fontFamily: Font.Family.GUARDIAN_SANS,
          fontWeight: Font.Weight.STANDARD,
          marginTop: "12px",
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          padding: "0px",
          marginRight: "32px",
          cursor: "pointer",
          "&:hover": {
            "&.Mui-completed": {
              backgroundColor: colors.avocado_10,
            },
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          minWidth: "unset",
        },
        iconContainer: {
          alignSelf: "stretch",
          paddingBottom: "2px",
          "&.Mui-active": {
            borderBottom: `2px solid ${colors.black}`,
            marginTop: "-2px",
            marginBottom: "-2px",
            paddingBottom: "0px",
          },
        },
        label: {
          fontFamily: Font.Family.GUARDIAN_SANS,
          fontWeight: Font.Weight.STANDARD,
          fontSize: "12px",
          padding: "12px 0px 9px 0px",
          letterSpacing: "0.0699em",
          color: colors.black,
          "&.Mui-active": {
            borderBottom: `2px solid ${colors.black}`,
            marginTop: "-2px",
            marginBottom: "-2px",
            fontWeight: Font.Weight.SEMI_BOLD,
          },
          "&.Mui-completed": {
            color: colors.avocado,
          },
          "&.Mui-disabled": {
            color: colors.disabled_text_grey,
          },
        },
      },
    },
  },
};

export const CustomStepperTheme = partialTheme.components?.MuiStepper;
export const CustomStepTheme = partialTheme.components?.MuiStep;
export const CustomStepLabelTheme = partialTheme.components?.MuiStepLabel;
