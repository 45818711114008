import { CardHeader, Typography } from "@mui/material";
import {
  CardFooter,
  DashboardLabel,
  DataCard,
  EquityRow,
  getAsOfLine,
  IBaseStore,
  isEmptyResponse,
  isSomething,
  isUnsuccessful,
  reqEquityData,
  reqStockData,
  SectionHeader,
  StockSymbol,
  useFetchDatasetIfIdDefined,
  useFetchDatasetWithoutId,
} from "common";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { StockSymbolToggle } from "../../../../components/StockSymbolToggle/StockSymbolToggle";
import { selectEquityDataCards } from "../../../../redux/selectors/equitySelectors";

interface IEquityProps {
  hasViewRestrictedEquity: boolean;
}

export const Equity = (props: IEquityProps) => {
  const { hasViewRestrictedEquity } = props;

  const tableHeaderCols = [""];

  const { equityDataLoadStatus, stockDataLoadStatus, activeEquityDataId } =
    useSelector((store: IBaseStore) => store.equityData);

  const [selectedStockSymbol, setSelectedStockSymbol] = useState<StockSymbol>(
    StockSymbol.BX
  );

  const { loading, cards } = useSelector((store: IBaseStore) =>
    selectEquityDataCards(store)
  );

  const currentCard = cards.find((x) => x.symbol === selectedStockSymbol);

  const data = currentCard && currentCard.card;

  const userStockTypes = useMemo(() => {
    return loading ? [] : cards.filter((x) => !!x.card).map((x) => x.symbol);
  }, [cards, loading]);

  useEffect(() => {
    if (userStockTypes.length) {
      setSelectedStockSymbol(userStockTypes[0]);
    }
  }, [userStockTypes]);

  const equityRetryFunction = useFetchDatasetIfIdDefined(
    reqEquityData,
    activeEquityDataId,
    equityDataLoadStatus
  );

  const stockRetryFunction = useFetchDatasetWithoutId(
    reqStockData,
    stockDataLoadStatus
  );

  return (
    <DataCard
      hide={data === undefined}
      loading={loading}
      failed={isUnsuccessful(equityDataLoadStatus, stockDataLoadStatus)}
      noData={isEmptyResponse(equityDataLoadStatus, stockDataLoadStatus)}
      onReload={[equityRetryFunction, stockRetryFunction]}
    >
      <CardHeader
        title={
          <>
            <Typography variant="h3">{SectionHeader.EQUITY}</Typography>
            {data && isSomething(data.vestedAndExchangeableAsOfDate) ? (
              <Typography variant="subtitle1">
                {getAsOfLine(
                  new Date(data.vestedAndExchangeableAsOfDate.value)
                )}
              </Typography>
            ) : (
              <></>
            )}
          </>
        }
        action={
          userStockTypes.length > 1 ? (
            <StockSymbolToggle
              availableStockSymbols={userStockTypes}
              selectedStockSymbol={selectedStockSymbol}
              onChange={setSelectedStockSymbol}
            />
          ) : null
        }
      />
      {data !== undefined && (
        <>
          <EquityRow
            equityCardData={data}
            headerCols={tableHeaderCols}
            selectedStockSymbol={selectedStockSymbol}
            hasViewRestrictedEquity={hasViewRestrictedEquity}
          />
          <CardFooter footerLines={[DashboardLabel.Equity.FOOTER]} />
        </>
      )}
    </DataCard>
  );
};
