import { Box } from "@mui/material";
import {
  AdminClientLabels,
  DataLoadStatus,
  isSomething,
  LoadingIndicator,
  NoDataAvailableError,
  reqAllEntitlements,
  resetSelectedClient,
} from "common";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AdminUIStore } from "../../redux/store";
import styles from "./LandingPage.module.scss";

export const LandingPage = () => {
  const {
    internalInvestmentAdminEntitlementLoadStatus,
    hasInternalInvestmentAdminEntitlement,
  } = useSelector((store: AdminUIStore) => store.entitlements);
  const { filteredBy } = useSelector(
    (store: AdminUIStore) => store.internalInvestmentData
  );
  const dispatch = useDispatch();

  useEffect(() => {
    async function checkData() {
      if (
        internalInvestmentAdminEntitlementLoadStatus ===
        DataLoadStatus.NOT_REQUESTED
      ) {
        dispatch(reqAllEntitlements());
      }
      if (isSomething(filteredBy)) {
        dispatch(resetSelectedClient());
      }
    }
    checkData();
  }, [internalInvestmentAdminEntitlementLoadStatus, filteredBy, dispatch]);

  const nonSuccessComponent =
    internalInvestmentAdminEntitlementLoadStatus ===
      DataLoadStatus.UNSUCCESSFUL ||
    internalInvestmentAdminEntitlementLoadStatus ===
      DataLoadStatus.EMPTY_RESPONSE ? (
      <NoDataAvailableError />
    ) : (
      <LoadingIndicator />
    );

  return (
    <div className={styles.page}>
      {hasInternalInvestmentAdminEntitlement &&
      internalInvestmentAdminEntitlementLoadStatus ===
        DataLoadStatus.SUCCESSFUL ? (
        <>
          <Box
            component="img"
            alt={"Landing Page background"}
            src="/assets/images/AdminLanding.png"
            className={styles.landingImage}
          />
          <h1 className={styles.landingText}>
            {AdminClientLabels.PLEASE_SEARCH}
          </h1>
        </>
      ) : (
        nonSuccessComponent
      )}
    </div>
  );
};
