import { WebStorageStateStore } from "oidc-client-ts";
import { AuthProviderProps } from "react-oidc-context";

import { RelativePath } from "../constants/Paths";
import { EnvironmentVariables } from "../services/environmentResolver";
import { onSigninCallback } from "../utils/authUtils";

export const OIDCConfig = (env: EnvironmentVariables): AuthProviderProps => ({
  authority: env.REACT_APP_OIDC_AUTHORITY_URL_BASE as string,
  client_id: env.REACT_APP_OIDC_CLIENT_ID as string,
  onSigninCallback: onSigninCallback,
  redirect_uri: `${window.location.protocol}//${window.location.host}${RelativePath.LOGIN}`,
  scope: env.REACT_APP_OIDC_SCOPE,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
});
