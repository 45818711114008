import { createSelector } from "@reduxjs/toolkit";

import { ICountry, ICurrency } from "../../types/bankAccountDataTypes";
import { IBankAccountsStore } from "../reducers/bankAccountsReducer";
import { selectBaseStore } from "./baseStoreSelectors";

export const selectBankAccounts = createSelector(
  selectBaseStore,
  ({ bankAccounts }): IBankAccountsStore => bankAccounts
);

export const selectBankAccountCountries = createSelector(
  selectBankAccounts,
  ({ countries }): ICountry[] => countries
);

export const selectBankAccountCurrencies = createSelector(
  selectBankAccounts,
  ({ currencies }): ICurrency[] => currencies
);
