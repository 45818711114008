import { AccountCircle } from "@mui/icons-material";
import {
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  PathFragment,
  resetSelectedClient,
  SearchParam,
  StringConstants,
} from "common";
import React, { useState } from "react";
import { useAuth } from "react-oidc-context";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RelativePath } from "../../constants/Paths";
import { AdminUIStore } from "../../redux/store";
import { ElectionResultPage } from "../ElectionResultPage/ElectionResultPage";
import { EquitySubHeader } from "../EquitySubHeader/EquitySubHeader";
import { SubHeader } from "../SubHeader/SubHeader";
import styles from "./Header.module.scss";

export const Header = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [profileMenuAnchor, setProfileMenuAnchor] =
    useState<HTMLElement | null>(null);

  const { canViewElectionResult } = useSelector(
    (store: AdminUIStore) => store.entitlements
  );

  const handleSBSElectionButtonClick = auth.isAuthenticated
    ? () => {
        navigate(`${RelativePath.ELECTIONS_ADMIN}/`);
        dispatch(resetSelectedClient());
      }
    : undefined;

  const handleLogoClick = auth.isAuthenticated
    ? () => {
        navigate(RelativePath.HOME);
        dispatch(resetSelectedClient());
      }
    : undefined;

  const handleLogout = () => {
    auth.removeUser();
    navigate(`${RelativePath.LOGIN}?${SearchParam.FROM_LOGOUT}=true`);
  };

  const showFullHeader =
    auth.isAuthenticated &&
    location.pathname !== RelativePath.LOGIN &&
    !location.pathname.startsWith(RelativePath.ELECTIONS_ADMIN);

  const showElectionResultPage =
    auth.isAuthenticated &&
    location.pathname ===
      `${RelativePath.ELECTIONS_ADMIN}/${PathFragment.ELECTION_RESULTS}`;

  const showEquityAdminPage =
    location.pathname.split("/")[1] === PathFragment.ADMIN_EQUITY;

  return (
    <>
      <div className={styles.navbar}>
        <Container maxWidth={false}>
          <Toolbar disableGutters className={styles.toolbar}>
            <Box
              id={styles.logoBox}
              className={auth.isAuthenticated ? styles.active : ""}
            >
              <Box
                id={styles.logo}
                component="img"
                alt={StringConstants.BLACKSTONE_GROUP}
                src="/assets/images/Blackstone_Logo.png"
                onClick={handleLogoClick}
              />
              <Typography
                noWrap
                id={styles.title}
                component="div"
                onClick={handleLogoClick}
              >
                BXWealth <span id={styles.adminTag}>Admin</span>
              </Typography>
            </Box>
            {showFullHeader && (
              <>
                <Box id={styles.profileBox}>
                  {canViewElectionResult && (
                    <Box id={styles.electionResultBox}>
                      <Typography
                        component="div"
                        onClick={handleSBSElectionButtonClick}
                      >
                        SBS Election Admin
                      </Typography>
                    </Box>
                  )}
                  <IconButton
                    id={styles.profileButton}
                    onClick={(e) => setProfileMenuAnchor(e.currentTarget)}
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    anchorEl={profileMenuAnchor}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={profileMenuAnchor !== null}
                    onClose={() => setProfileMenuAnchor(null)}
                    disableScrollLock={true}
                  >
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </Box>
              </>
            )}
          </Toolbar>
        </Container>
        <Container />
      </div>
      {showEquityAdminPage && <EquitySubHeader />}
      {!showElectionResultPage && !showEquityAdminPage && showFullHeader && (
        <SubHeader />
      )}
      {showElectionResultPage && <ElectionResultPage />}
    </>
  );
};
