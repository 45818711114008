import { Circle } from "@mui/icons-material";
import { Tooltip, useMediaQuery } from "@mui/material";
import React from "react";

import { BreakpointConstants } from "../../constants/BreakpointConstants";
import {
  IHoverableChartLegend,
  IHoverableChartLegends,
} from "../../types/dataTypes";
import { POSSIBLE_OTHER_VALUES } from "../../utils/charting";
import { replaceBlackstoneString } from "../../utils/formatters";
import styles from "./HoverableChartLegends.module.scss";

interface HoverableChartLegendProps {
  value: string;
  color: string;
  xOffset: number;
  yOffset: number;
  onClick?: (value: string) => void;
}

const HoverableChartLegend = ({
  value,
  color,
  xOffset,
  yOffset,
  onClick,
}: HoverableChartLegendProps) => {
  const text = replaceBlackstoneString(value);
  const isMobile = useMediaQuery(
    `(max-width:${BreakpointConstants.EXTRA_SMALL_MAX_WIDTH}px)`
  );

  const handleClick = () => {
    if (onClick) {
      onClick(value);
    }
  };

  return (
    <li className={styles.chartLegend}>
      <Tooltip
        title={text}
        placement={isMobile ? "top" : "right"}
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [xOffset, yOffset],
              },
            },
          ],
        }}
        enterTouchDelay={0}
        leaveTouchDelay={5000}
      >
        <span className={styles.legendText} onClick={handleClick}>
          <Circle className={styles.circle} style={{ color: color }} />
          {text}
        </span>
      </Tooltip>
    </li>
  );
};

const sortLegend = (legends: IHoverableChartLegend[]) => {
  const sortedList = legends
    .slice()
    .sort((a, b) =>
      POSSIBLE_OTHER_VALUES.includes(a.value)
        ? 1
        : POSSIBLE_OTHER_VALUES.includes(b.value)
        ? -1
        : a.value > b.value
        ? 1
        : -1
    );
  return sortedList;
};
export const HoverableChartLegends = ({ legends }: IHoverableChartLegends) => {
  const sortedLegends = sortLegend(legends);
  const isMobile = useMediaQuery(
    `(max-width:${BreakpointConstants.EXTRA_SMALL_MAX_WIDTH}px)`
  );

  const tooltipXOffset = isMobile ? 6 : 0;
  const tooltipYOffset = isMobile ? -24 : 16;

  return (
    <ul className={styles.chartLegends}>
      {sortedLegends &&
        sortedLegends.map((legend: IHoverableChartLegend, index) => {
          return (
            <HoverableChartLegend
              key={index}
              value={legend.value}
              color={legend.color}
              xOffset={tooltipXOffset}
              yOffset={tooltipYOffset}
              onClick={legend.handleClick}
            />
          );
        })}
    </ul>
  );
};
