import { Box } from "@mui/material";
import {
  DataLoadStatus,
  ElectionAdminLabels,
  LoadingIndicator,
  NoDataAvailableError,
  reqAllEntitlements,
} from "common";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AdminUIStore } from "../../redux/store";
import styles from "./ElectionResultBody.module.scss";

export const ElectionResultBody = () => {
  const { canViewElectionResultLoadStatus, canViewElectionResult } =
    useSelector((store: AdminUIStore) => store.entitlements);

  const dispatch = useDispatch();

  useEffect(() => {
    async function checkData() {
      if (canViewElectionResultLoadStatus === DataLoadStatus.NOT_REQUESTED) {
        dispatch(reqAllEntitlements());
      }
    }
    checkData();
  }, [canViewElectionResultLoadStatus, dispatch]);

  const nonSuccessComponent =
    canViewElectionResultLoadStatus === DataLoadStatus.UNSUCCESSFUL ||
    canViewElectionResultLoadStatus === DataLoadStatus.EMPTY_RESPONSE ? (
      <NoDataAvailableError />
    ) : (
      <LoadingIndicator />
    );

  return (
    <div className={styles.page}>
      {canViewElectionResult &&
      canViewElectionResultLoadStatus === DataLoadStatus.SUCCESSFUL ? (
        <>
          <Box
            component="img"
            alt={"Landing Page background"}
            src="/assets/images/AdminLanding.png"
            className={styles.landingImage}
          />
          <h1 className={styles.landingText}>
            {ElectionAdminLabels.PLEASE_ENTER_ROUND_ID}
          </h1>
        </>
      ) : (
        nonSuccessComponent
      )}
    </div>
  );
};
