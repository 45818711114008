import {
  CalloutPersona,
  CarryDataKey,
  ElectionPeriodTreeLevel,
  TreeNumber,
} from "../../constants/enums";
import {
  CarryLabel,
  SectionHeader,
} from "../../constants/LabelAndTooltipConstants";
import {
  ICarryDataRow,
  WorksheetGeneratorParams,
} from "../../types/excelTypes";
import { isSomething } from "../../types/typeGuards";
import {
  createCarryChartList,
  filterToPositiveCarryByFund,
  getTotalCarry,
} from "../carryCardUtils";
import { ExcelConstants, formatHeaderRows, getAsOfHeader } from "./excelUtils";

export const buildCarryDataWorksheet = (params: WorksheetGeneratorParams) => {
  const { investmentBreakdownGrouped, workbook, callout, isAdmin } = params;
  if (
    isSomething(investmentBreakdownGrouped) &&
    callout.calloutPersona === CalloutPersona.CARRY
  ) {
    const data = investmentBreakdownGrouped.value;
    const filteredData = filterToPositiveCarryByFund(
      data[TreeNumber.BUSINESS_UNIT],
      true
    );
    const carryChartData = createCarryChartList(filteredData).sort((a, b) =>
      a.fundShortName.localeCompare(b.fundShortName)
    );
    carryChartData.push(getTotalCarry(data));
    const worksheet = workbook.addWorksheet(SectionHeader.UNREALIZED_CARRY, {
      views: [
        {
          state: "frozen",
          ySplit: 1,
        },
      ],
    });
    const totalData = data[TreeNumber.ELECTION_PERIOD].filter(
      (datum) => datum.treeLevel === ElectionPeriodTreeLevel.ENTITY
    );
    worksheet.columns = isAdmin
      ? [
          {
            header: CarryLabel.FUNDID,
            key: CarryDataKey.MDM_FUND_ID,
            width: ExcelConstants.SIZES.SMALL,
            style: { alignment: { horizontal: "left" } },
          },
          {
            header: CarryLabel.FUND,
            key: CarryDataKey.FUND_NAME,
            width: ExcelConstants.SIZES.MEDIUM,
          },
          {
            header: `${CarryLabel.UNREALIZED_CARRY} ${getAsOfHeader(
              isSomething(totalData[0].unrealizedValue)
                ? totalData[0].unrealizedValue.value.asOfDate
                : new Date()
            )}`,
            key: CarryDataKey.UNREALIZED_CARRY,
            width: ExcelConstants.SIZES.MEDIUM,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header: `${
              CarryLabel.REALIZED_CARRY_AND_INCENTIVE_FEES
            } ${getAsOfHeader(
              isSomething(totalData[0].realizedProceeds)
                ? totalData[0].realizedProceeds.value.asOfDate
                : new Date()
            )}`,
            key: CarryDataKey.REALIZED_CARRY,
            width: ExcelConstants.SIZES.MEDIUM,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
        ]
      : [
          {
            header: CarryLabel.FUND,
            key: CarryDataKey.FUND_NAME,
            width: ExcelConstants.SIZES.MEDIUM,
          },
          {
            header: `${CarryLabel.UNREALIZED_CARRY} ${getAsOfHeader(
              isSomething(totalData[0].unrealizedValue)
                ? totalData[0].unrealizedValue.value.asOfDate
                : new Date()
            )}`,
            key: CarryDataKey.UNREALIZED_CARRY,
            width: ExcelConstants.SIZES.MEDIUM,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header: `${
              CarryLabel.REALIZED_CARRY_AND_INCENTIVE_FEES
            } ${getAsOfHeader(
              isSomething(totalData[0].realizedProceeds)
                ? totalData[0].realizedProceeds.value.asOfDate
                : new Date()
            )}`,
            key: CarryDataKey.REALIZED_CARRY,
            width: ExcelConstants.SIZES.MEDIUM,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
        ];
    for (const datum of carryChartData) {
      const row: ICarryDataRow = {
        fundShortName: datum.fundShortName,
        mdmFundId: datum.mdmFundId,
        totalRealized: datum.totalRealized,
        totalUnrealized: datum.totalUnrealized,
      };
      worksheet.addRow(row);
    }
    const totalRow = worksheet.lastRow;
    if (totalRow) {
      const totalFundCell = totalRow.getCell(ExcelConstants.KEYS.MDM_FUND_ID);
      totalFundCell.value = null;
    }
    formatHeaderRows(worksheet, 1);
  }
  return workbook;
};
