import { ClientLevelPageName, PageName } from "../constants/Pages/Pages";

export const isPageVisible = (
  pageName: PageName | ClientLevelPageName,
  canViewCommitmentsPage: boolean,
  canViewEquityPage: boolean
) => {
  switch (pageName) {
    case PageName.COMMITMENTS || ClientLevelPageName.COMMITMENTS:
      return canViewCommitmentsPage;
    case PageName.EQUITY_LANDING_PAGE:
      return canViewEquityPage;
    default:
      return true;
  }
};
