import { Tooltip } from "@mui/material";
import React, { useState } from "react";

import styles from "./DoubleTapTooltip.module.scss";

interface ITooltipCellRendererProps {
  value: object | string;
  children?: JSX.Element | undefined;
  yOffset?: number | undefined;
}

const RenderedTooltipClassName = "renderedTooltip";

export const hideTooltips = () => {
  const elements: HTMLCollectionOf<HTMLElement> =
    document.getElementsByClassName(
      RenderedTooltipClassName
    ) as HTMLCollectionOf<HTMLElement>;
  for (const element of elements) {
    if (element.parentElement) {
      element.parentElement.style.display = "none";
    }
  }
};

export const DoubleTapTooltip = (props: ITooltipCellRendererProps) => {
  const { value, children, yOffset } = props;
  const stringValue: string = value + "";
  const tooltipTimeoutNumber = 5000; //in ms
  const secondEventTimeoutNumber = 1000; //in ms
  const [isSecondEvent, setIsSecondEvent] = useState<boolean>(false);
  const [isToolTipVisible, setIsToolTipVisible] = useState<boolean>(false);

  const showToolTip = () => {
    setIsToolTipVisible(true);
    setTimeout(() => setIsToolTipVisible(false), tooltipTimeoutNumber);
  };

  // Handles cell tap event as it needs to show tooltip on double tap on mobile device (ref. JIRA# 'BXAC-8361')
  const handleCellTouch = () => {
    if (isSecondEvent) {
      setIsSecondEvent(false);
      showToolTip();
    } else {
      setIsSecondEvent(true);
      setTimeout(() => setIsSecondEvent(false), secondEventTimeoutNumber);
    }
  };

  return (
    <Tooltip
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, yOffset ? yOffset : -10],
            },
          },
        ],
      }}
      placement={"bottom-start"}
      open={isToolTipVisible}
      onTouchEndCapture={handleCellTouch}
      className={styles.tooltip}
      title={<span className={RenderedTooltipClassName}>{stringValue}</span>}
    >
      {children ?? <span>{stringValue}</span>}
    </Tooltip>
  );
};
