import { ThemeOptions } from "@mui/material";

import colors from "../../_colors.scss";
import { Font } from "../sharedStyleConstants";

// Shared constants we may want to move in unison

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontFamily: Font.Family.GUARDIAN_SANS,
          fontWeight: Font.Weight.STANDARD,
          marginLeft: "0px",
          marginBottom: "8px",
          paddingRight: "6px",
          // styles for focus from keyboard events
          "&:has(.Mui-focusVisible)": {
            backgroundColor: colors.pale_azure,
            borderRadius: "4px",
            boxShadow: "none",
            color: colors.black,
          },
        },
        label: {
          color: colors.black,
          fontFamily: Font.Family.GUARDIAN_SANS,
          fontSize: Font.Size.STANDARD,
          fontWeight: Font.Weight.LIGHT,
        },
      },
    },
  },
};

export const CustomFormControlLabelTheme =
  partialTheme.components?.MuiFormControlLabel;
