import { Grid } from "@mui/material";
import { GridReadyEvent, RowClassParams } from "ag-grid-community";
import { ColDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import {
  CommitmentsLabels,
  CommitmentsTooltips,
  currencyFormatter,
  GridAsTable,
  GridHeaderWithTooltip,
  IForecastedCapCallByStrategy,
  sortForecastedCapitalCallsAndAddTotalsRow,
  TooltipCellRenderer,
  useGridExtensions,
} from "common";
import React, { useMemo, useRef, useState } from "react";

interface IForecastedCapitalCallsGridProps {
  forecastedCapCallsByStrategy: IForecastedCapCallByStrategy[];
}

export const ForecastedCapitalCallsGrid = (
  props: IForecastedCapitalCallsGridProps
) => {
  const { forecastedCapCallsByStrategy } = props;
  const [, setIsGridReady] = useState<boolean>(false);

  const gridRef = useRef<AgGridReact>(null);

  const {
    setHeaderHeight,
    resizeColumns,
    onGridReady: OnGridReadyCommon,
  } = useGridExtensions("viewByColumn");

  const onGridReady = (params: GridReadyEvent) => {
    setIsGridReady(true);
    OnGridReadyCommon(params);
  };

  const forecastedAmountTooltipContent = (
    <>
      {CommitmentsTooltips.FORECASTED_TOOLTIP[0]}
      <ul>
        <li>{CommitmentsTooltips.FORECASTED_TOOLTIP[1]}</li>
        <li>{CommitmentsTooltips.FORECASTED_TOOLTIP[2]}</li>
      </ul>
    </>
  );

  const forecastedColumnDefs: ColDef<IForecastedCapCallByStrategy>[] = useMemo(
    () => [
      {
        field: "strategyName",
        headerName: CommitmentsLabels.STRATEGY,
        minWidth: 160,
        cellRenderer: TooltipCellRenderer,
      },
      {
        field: "amount",
        headerName: CommitmentsLabels.AMOUNT,
        minWidth: 150,
        type: "rightAligned",
        valueFormatter: currencyFormatter,
        headerComponent: GridHeaderWithTooltip,
        headerComponentParams: {
          labelClass: "ag-sub-header-group-cell-label",
          textClass: "ag-sub-header-group-text",
          rightAlign: true,
          tooltipContent: forecastedAmountTooltipContent,
        },
      },
    ],
    []
  );

  // sort the strategies alphabetically and add totals row
  const forecastedCapCallsByStrategyWithTotal: IForecastedCapCallByStrategy[] =
    useMemo(
      () =>
        sortForecastedCapitalCallsAndAddTotalsRow(forecastedCapCallsByStrategy),
      [forecastedCapCallsByStrategy]
    );

  // styles the totals row differently
  const getRowStyle = (params: RowClassParams) => {
    if (params.node.data.strategyId === undefined) {
      return {
        fontWeight: "500",
        borderBottom: "1px solid black",
      };
    }
  };

  return (
    <Grid item xs={12} className={`ag-theme-alpine`}>
      <GridAsTable<IForecastedCapCallByStrategy>
        ref={gridRef}
        rowData={forecastedCapCallsByStrategyWithTotal}
        columnDefs={forecastedColumnDefs}
        domLayout="autoHeight"
        onFirstDataRendered={setHeaderHeight}
        onColumnResized={setHeaderHeight}
        cacheQuickFilter={true}
        suppressAggFuncInHeader={true}
        suppressContextMenu={true}
        suppressCellFocus={true}
        onRowDataUpdated={resizeColumns}
        onGridSizeChanged={resizeColumns}
        onDisplayedColumnsChanged={resizeColumns}
        onGridReady={onGridReady}
        suppressMenuHide={true}
        getRowStyle={getRowStyle}
      />
    </Grid>
  );
};
