import { IHoldbackPerFund } from "../types/dataTypes";

export const finalizeHoldbackPerFund = (
  holdbackPerFundData: IHoldbackPerFund[]
): IHoldbackPerFund[] => {
  // sort data in descending order
  const sortedData = [...holdbackPerFundData].sort((a, b) => {
    if ((a.holdback ?? 0) > (b.holdback ?? 0)) {
      return -1;
    } else {
      return 1;
    }
  });
  const MAX_ITEMS_TO_SHOW = 6;
  if (sortedData.length > MAX_ITEMS_TO_SHOW) {
    // when we have more than MAX funds, we want only want to show top MAX - 1 then group rest into an Other category
    // sum holdback of the funds after the top MAX - 1
    const leftOverSum = sortedData
      .slice(MAX_ITEMS_TO_SHOW - 1)
      .reduce((a, v) => a + (v.holdback ?? 0), 0);
    // return top MAX - 1 and new Other category
    return [
      ...sortedData.slice(0, MAX_ITEMS_TO_SHOW - 1),
      { fundName: "Other", holdback: leftOverSum },
    ];
  }
  return sortedData;
};
