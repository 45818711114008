import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import {
  IHistoricalSummaryDatum,
  InvestmentBreakdownKey,
  InvestmentBreakDownValueSelectors,
  InvestmentHistoryLabel,
  InvestmentHistoryValueItem,
  PeriodType,
} from "common";
import React from "react";

import { HoverableLegendLabel } from "../../../../components/HoverableLegendLabel/HoverableLegendLabel";
import styles from "./PortfolioPerformanceToolbar.module.scss";

interface PortfolioPerformanceToolbarProps {
  periodType: PeriodType;
  setPeriodType: (value: PeriodType) => void;
  dataTypes: InvestmentHistoryValueItem[];
  setDataTypes: (value: InvestmentHistoryValueItem[]) => void;
  isCumulative: boolean;
  setIsCumulative: (value: boolean) => void;
  historicalSummaryData: IHistoricalSummaryDatum[];
  setHighlightedBar: (value: InvestmentBreakdownKey | null) => void;
  disabled: boolean;
}

export interface ExcelRow {
  [key: string]: string | number;
}

export const PortfolioPerformanceToolbar = (
  props: PortfolioPerformanceToolbarProps
) => {
  const handleUnrealizedChange = (checked: boolean) => {
    const newDataTypes = props.dataTypes.map((type) => ({
      ...type,
      isSelected: type.isUnrealized ? checked : type.isSelected,
    }));
    props.setDataTypes(newDataTypes);
  };

  const handleRealizedChange = (checked: boolean) => {
    const newDataTypes = props.dataTypes.map((type) => ({
      ...type,
      isSelected: type.isUnrealized ? type.isSelected : checked,
    }));
    props.setDataTypes(newDataTypes);
  };

  return (
    <div className={styles.toolbar}>
      <Grid container className={styles.boxes}>
        <Grid item xs={12}>
          <FormControlLabel
            className={styles.unrealizedCheck}
            disabled={props.disabled}
            checked={
              !props.dataTypes.some(
                (type) =>
                  type.isUnrealized === true && type.isSelected === false
              )
            }
            control={<Checkbox className={styles.groupCheckBox} />}
            label={InvestmentHistoryLabel.UNREALIZED_VALUE}
            onChange={(_, checked) => handleUnrealizedChange(checked)}
          />
        </Grid>
        {props.dataTypes
          .filter((val) => val.isUnrealized)
          .map(
            (type, key) =>
              props.historicalSummaryData.some((item) =>
                props.isCumulative
                  ? InvestmentBreakDownValueSelectors[type.key](
                      item.cumulative
                    ) !== 0
                  : InvestmentBreakDownValueSelectors[type.key](
                      item.nonCumulative
                    ) !== 0
              ) && (
                <HoverableLegendLabel
                  className={styles.subLabel}
                  dataType={type}
                  key={key}
                  onMouseEnter={
                    type.isSelected
                      ? () => props.setHighlightedBar(type.key)
                      : undefined
                  }
                  onMouseLeave={() => props.setHighlightedBar(null)}
                />
              )
          )}
        <Grid item xs={12}>
          <FormControlLabel
            className={styles.realizedCheck}
            disabled={props.disabled}
            checked={
              !props.dataTypes.some(
                (type) =>
                  type.isUnrealized === false && type.isSelected === false
              )
            }
            control={<Checkbox className={styles.groupCheckBox} />}
            label={InvestmentHistoryLabel.REALIZED_VALUE}
            onChange={(_, checked) => handleRealizedChange(checked)}
          />
        </Grid>
        {props.dataTypes
          .filter((val) => !val.isUnrealized)
          .map(
            (type, key) =>
              props.historicalSummaryData.some((item) =>
                props.isCumulative
                  ? InvestmentBreakDownValueSelectors[type.key](
                      item.cumulative
                    ) !== 0
                  : InvestmentBreakDownValueSelectors[type.key](
                      item.nonCumulative
                    ) !== 0
              ) && (
                <HoverableLegendLabel
                  className={styles.subLabel}
                  dataType={type}
                  key={key}
                  onMouseEnter={
                    type.isSelected
                      ? () => props.setHighlightedBar(type.key)
                      : undefined
                  }
                  onMouseLeave={() => props.setHighlightedBar(null)}
                />
              )
          )}
      </Grid>
    </div>
  );
};
