import { ICellRendererParams } from "ag-grid-community";
import React from "react";

import { DoubleTapTooltip } from "../../DoubleTapTooltip/DoubleTapTooltip";
import styles from "./HighlightCellRenderer.module.scss";
import { IPerformanceDetailsGridContext } from "./PerformanceDetailsGrid";

/*
    AG grid doesn't have the option to highlight the search term on the grid cells out of the box yet,
    but it's on their backlog https://www.ag-grid.com/ag-grid-pipeline/  (search AG-119)
    For the meantime, we defined this custom cell renderer to highlight the 
    search term on the filtered rows
*/
export const HighlightCellRenderer = (props: ICellRendererParams) => {
  let hightlightedCell = props.value;
  const context = props.context as IPerformanceDetailsGridContext;
  const filter = context.filter;

  if (
    filter &&
    props.value &&
    filter
      .toLowerCase()
      .split(" ")
      .every((searchTerm) =>
        props.value.toLowerCase().includes(searchTerm.trim())
      )
  ) {
    /*
      Case insensitive regex to replace the search term in the grid cell, with a span containing 
      the search term and highlight style.
    */
    const searchTerms = filter
      .trim()
      .replace(/[.*+?^${}()|[\]\\]/g, "\\$&") // escape special characters
      .replace(/ +/g, "|"); // AG grid filters by word, rather than phrase, so we need to highlight all word matches

    const reg = new RegExp("(" + searchTerms + ")", "gi");
    hightlightedCell = hightlightedCell.replaceAll(
      reg,
      `<span class=${styles.highlight}>$1</span>`
    );
  }
  return (
    <DoubleTapTooltip value={props.value} yOffset={-25}>
      <span dangerouslySetInnerHTML={{ __html: hightlightedCell }}></span>
    </DoubleTapTooltip>
  );
};
