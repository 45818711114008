import { ThemeOptions } from "@mui/material";

import colors from "../../_colors.scss";
import { Font } from "../sharedStyleConstants";

// Shared constants we may want to move in unison

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&.Mui-error": {
            fontFamily: Font.Family.GUARDIAN_SANS,
            color: colors.crimson_50,
            marginLeft: "0px",
          },
        },
      },
    },
  },
};

export const CustomMuiFormHelperTextTheme =
  partialTheme.components?.MuiFormHelperText;
