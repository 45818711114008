import Typography from "@mui/material/Typography";
import React from "react";

import { ILabeledDataPoint } from "../../../../types/labelTypes";
import { OverviewDataPoint } from "../OverviewDataPoint/OverviewDataPoint";
import styles from "./OverviewCard.module.scss";

export interface IOverviewCardProps {
  cardName: string;
  subHeader: string;
  overviewDataList: ILabeledDataPoint[];
  footer?: string;
}

/**
 * Component that encapsulates the Callout Banner for a user with carry
 */
export const OverviewCard = (props: IOverviewCardProps) => {
  return (
    <div className={styles.overviewCard}>
      <div className={styles.overviewContent}>
        <div className={styles.header}>
          {props.cardName}
          {props.subHeader && (
            <Typography variant="subtitle1">{props.subHeader}</Typography>
          )}
        </div>
        <div className={styles.sections}>
          {props.overviewDataList.map((data, idx) => {
            const showSeparator = idx === props.overviewDataList.length - 1;
            return (
              <OverviewDataPoint
                key={idx}
                label={data.label}
                tooltipParagraphs={data.tooltipParagraphs}
                calloutDatum={data.calloutDatum}
                getFormattedValue={data.getFormattedValue}
                shouldHide={(data.calloutDatum?.value ?? 0) === 0}
                isSubLabel={data.isSubLabel}
                isTotalLabel={data.isTotalLabel}
                className={showSeparator ? styles.topBorder : ""}
              />
            );
          })}
        </div>
        {props.footer && (
          <div className={styles.footer}>
            <Typography variant="subtitle1">{props.footer}</Typography>
          </div>
        )}
      </div>
    </div>
  );
};
