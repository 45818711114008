import Grid from "@mui/material/Grid";
import React from "react";

import { IFinancingBalance } from "../../types/dataTypes";
import { IDashboardCardContentProps } from "../DashboardCard/DashboardCard";
import { BalanceRow } from "./BalanceRow/BalanceRow";
import styles from "./LoansCard.module.scss";
import { ThirdPartyFinancing } from "./ThirdPartyFinancingRow/ThirdPartyFinancingRow";

export const LoansCard = (
  props: IDashboardCardContentProps<IFinancingBalance>
) => {
  const financingData = props.data;

  const tableHeaderCols = [""];

  return (
    <Grid container flex="column" className={styles.container}>
      <BalanceRow
        financingData={financingData}
        shouldRenderGraph={true}
        headerCols={tableHeaderCols}
      />
      {financingData.thirdPartyLoanLimit !== 0 && (
        <ThirdPartyFinancing
          financingData={financingData}
          shouldRenderGraph={true}
          headerCols={tableHeaderCols}
        />
      )}
    </Grid>
  );
};
