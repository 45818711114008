import { ThemeOptions } from "@mui/material";

import colors from "../../_colors.scss";
import { Font } from "../sharedStyleConstants";

// Shared constants we may want to move in unison

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: Font.Family.GUARDIAN_SANS,
          fontWeight: Font.Weight.STANDARD,
          padding: "12px 0px",
          marginRight: "16px",
          fontSize: "12px",
          color: colors.dark_grey,
          minWidth: "unset",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          fontFamily: Font.Family.GUARDIAN_SANS,
          fontWeight: Font.Weight.STANDARD,
        },
        scrollButtons: {
          "&.Mui-disabled": {
            width: 0,
          },
          overflow: "hidden",
          transition: "width 0.0s",
          width: 28,
        },
      },
    },
  },
};

export const CustomTabTheme = partialTheme.components?.MuiTab;
export const CustomTabsTheme = partialTheme.components?.MuiTabs;
