import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
} from "@reduxjs/toolkit";
import { DataLoadStatus, isNotRequested, isSomething, Optional } from "common";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

export const useFetchDatasetIfIdDefined = <T>(
  actionCreator: ActionCreatorWithPayload<T>,
  dataSetId: Optional<T>,
  loadStatus: DataLoadStatus
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSomething(dataSetId) && isNotRequested(loadStatus)) {
      dispatch(actionCreator(dataSetId.value));
    }
  }, [dataSetId, loadStatus, actionCreator, dispatch]);

  const retryFunction = useCallback(() => {
    if (isSomething(dataSetId)) {
      dispatch(actionCreator(dataSetId.value));
    }
  }, [actionCreator, dataSetId, dispatch]);

  return retryFunction;
};

export const useFetchDatasetWithoutId = (
  actionCreator: ActionCreatorWithoutPayload,
  loadStatus: DataLoadStatus
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (loadStatus !== DataLoadStatus.SUCCESSFUL) {
      dispatch(actionCreator());
    }
  }, [actionCreator, dispatch, loadStatus]);

  const retryFunction = useCallback(() => {
    dispatch(actionCreator());
  }, [actionCreator, dispatch]);

  return retryFunction;
};
