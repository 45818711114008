import { VestingEvents } from "common";
import React from "react";
export const VestingEventsWrapper = () => {
  return (
    <VestingEvents
      // set to true for admin
      hasViewRestrictedEquity={true}
    />
  );
};
