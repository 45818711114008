import { ThemeOptions } from "@mui/material";

import colors from "../../_colors.scss";
import { Font } from "../sharedStyleConstants";

// Shared constants we may want to move in unison

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontSize: Font.Size.STANDARD,
          fontFamily: Font.Family.GUARDIAN_SANS,
          fontWeight: Font.Weight.STANDARD,
          paddingBottom: "12px",
          color: colors.dark_grey,
          cursor: "pointer",
        },
      },
    },
  },
};

export const CustomBreadcrumbsTheme = partialTheme.components?.MuiBreadcrumbs;
