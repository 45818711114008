import { ThemeOptions } from "@mui/material";

import colors from "../../_colors.scss";
import { breakpointsTheme } from "../sharedStyleConstants";

// Shared constants we may want to move in unison
const Layout = {
  Desktop: {
    MARGIN: "32px",
    PADDING: "32px",
  },

  Mobile: {
    MARGIN: "16px",
    PADDING: "20px",
  },
};

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderColor: colors.bx_slate_shade,
          borderStyle: "solid",
          borderWidth: "1px",
          borderRadius: "4px",
          boxShadow: "none",
          [breakpointsTheme.breakpoints.down("md")]: {
            marginBottom: Layout.Mobile.MARGIN,
            padding: Layout.Mobile.PADDING,
          },
          marginBottom: Layout.Desktop.MARGIN,
          padding: Layout.Desktop.PADDING,
          width: "100%",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: "0px",
          marginBottom: Layout.Desktop.MARGIN,
        },
        action: {
          marginBottom: "0px",
          marginRight: "0px",
          marginTop: "0px",
        },
      },
    },
  },
};

export const CustomCardTheme = partialTheme.components?.MuiCard;
export const CustomCardHeaderTheme = partialTheme.components?.MuiCardHeader;
