import { createSvgIcon } from "@mui/material/utils";
import React from "react";

export const DocumentDownloadIcon = createSvgIcon(
  // credit: plus icon from https://heroicons.com/
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="20"
    viewBox="-3 -2.4 19.063 20"
  >
    <path
      id="Document_Download"
      data-name="Document Download"
      d="M1.875-.937V-14.062H8.438v3.75h3.75v1.875h.938v-2.542L9.1-15H.938V0H9.375V-.937Zm7.5-10.312V-13.4l2.146,2.146Zm3.75,7.771V-7.5h-.937v4.021L10.994-4.666l-.659.659,2.322,2.322,2.322-2.322-.659-.659ZM15-.937H10.313V0H15Z"
      transform="translate(-0.938 15)"
    />
  </svg>,
  "DocumentDownload"
);
