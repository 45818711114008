import { Grid } from "@mui/material";
import {
  CardFooter,
  getAsOfLine,
  getFirstDayOfQuarter,
  getPortfolioBalanceFooter,
  IBalancePerformanceData,
  IDashboardCardContentProps,
  IHistoricalSummaryDatum,
  InvestmentBreakdownKey,
  InvestmentHistoryLabel,
  InvestmentHistoryValueItem,
  InvestmentValueDisplay,
  isSomething,
  PeriodType,
  SectionHeader,
  trimData,
} from "common";
import React, { useEffect, useMemo, useState } from "react";

import { HoverableLegendLabel } from "../../../components/HoverableLegendLabel/HoverableLegendLabel";
import { TitleWithPeriodToggle } from "../../../components/TitleWithPeriodToggle/TitleWithPeriodToggle";
import colors from "../../../styles/_colors.scss";
import { initializeChartDataAndToolbar } from "../../../utils/investmentHistoryUtils";
import styles from "./PortfolioBalance.module.scss";
import { PortfolioBalanceChart } from "./PortfolioBalanceChart/PortfolioBalanceChart";

const portfolioBalanceDataTypes: InvestmentHistoryValueItem[] = [
  {
    key: InvestmentBreakdownKey.TOTAL_UNREALIZED_VALUE,
    labelOverride: InvestmentHistoryLabel.TOTAL_UNREALIZED_VALUE,
    color: colors.avocado,
    isSelected: true,
  },
  {
    key: InvestmentBreakdownKey.REMAINING_CAPITAL_INVESTED,
    labelOverride: InvestmentHistoryLabel.REMAINING_CAPITAL_INVESTED,
    color: colors.cobalt,
    isSelected: true,
  },
];

export const PortfolioBalance = (
  props: IDashboardCardContentProps<IBalancePerformanceData>
) => {
  const { historicalSummaryGrouped, asOfDate } = props.data;

  const [graphData, setGraphData] = useState<IHistoricalSummaryDatum[]>([]);
  const [periodType, setPeriodType] = useState<PeriodType>(
    PeriodType.QUARTERLY
  );

  const [graphInitialized, setGraphInitialized] = useState<boolean>(false);
  const [dataTypes, setDataTypes] = useState<InvestmentHistoryValueItem[]>([]);
  const [highlightedBar, setHighlightedBar] =
    useState<InvestmentBreakdownKey | null>(null);
  const valueDisplay = InvestmentValueDisplay.CUMULATIVE;

  const firstQuarterDate = useMemo(() => {
    return asOfDate.earliestAsOfDate;
  }, [asOfDate]);

  useEffect(() => {
    if (isSomething(historicalSummaryGrouped)) {
      const trimmedData = trimData(
        historicalSummaryGrouped.value[periodType],
        portfolioBalanceDataTypes.map((type) => type.key),
        valueDisplay
      );
      setGraphData(trimmedData);
      initializeChartDataAndToolbar(
        trimmedData,
        portfolioBalanceDataTypes,
        setDataTypes,
        setGraphInitialized
      );
    }
  }, [historicalSummaryGrouped, valueDisplay, periodType]);

  return (
    <div className={styles.card}>
      <Grid item xs={12} md={12} className={styles.header}>
        <TitleWithPeriodToggle
          sectionHeader={SectionHeader.PORTFOLIO_BALANCE}
          sectionSubHeader={getAsOfLine(
            isSomething(asOfDate.latestAsOfDateWithUnrealizedData)
              ? asOfDate.latestAsOfDateWithUnrealizedData.value
              : new Date()
          )}
          tabSelected={"PortfolioBalance"}
          historicalSummaryData={graphData}
          periodType={periodType}
          setPeriodType={setPeriodType}
          valueDisplay={valueDisplay}
          dataTypes={dataTypes}
          setDataTypes={setDataTypes}
          toggleSize={3}
        />
      </Grid>
      <Grid item xs={12} md={12} className={styles.chart}>
        <PortfolioBalanceChart
          summaryData={graphData}
          firstQuarterDate={firstQuarterDate}
          periodType={periodType}
          dataTypes={dataTypes}
          highlightedLine={highlightedBar}
          setHighlightedLine={setHighlightedBar}
          graphInitialized={graphInitialized}
        />
      </Grid>
      <Grid item xs={12} className={styles.legend}>
        {dataTypes.map((type, key) => (
          <HoverableLegendLabel
            className={styles.label}
            dataType={type}
            key={key}
            onMouseEnter={() => setHighlightedBar(type.key)}
            onMouseLeave={() => setHighlightedBar(null)}
            isOutlined={type.key === InvestmentBreakdownKey.NET_CASH_FLOW}
          />
        ))}
      </Grid>
      <Grid item xs={12} className={styles.footer}>
        <CardFooter
          footerLines={[
            getPortfolioBalanceFooter(
              getFirstDayOfQuarter(asOfDate.earliestAsOfDate)
            ),
          ]}
        />
      </Grid>
    </div>
  );
};
