import { FileDownloadOutlined } from "@mui/icons-material";
import {
  IBaseStore,
  IconButtonWithTooltip,
  StringConstants,
  useExcelDownload,
  worksheetGenerators,
} from "common";
import React from "react";
import { useSelector } from "react-redux";

import styles from "./DistributionsHeader.module.scss";

export const DistributionsHeader = () => {
  const { selectedStockSymbol } = useSelector(
    (store: IBaseStore) => store.viewData
  );

  const handleExcelDownload = useExcelDownload();
  const handleDownloadClick = async () => {
    const fileName = `${StringConstants.EQUITY} ${StringConstants.DISTRIBUTIONS}_${selectedStockSymbol}`;
    await handleExcelDownload(
      worksheetGenerators.EquityDistributions,
      fileName,
      { selectedStockSymbol: selectedStockSymbol }
    );
  };

  return (
    <div className={styles.distributionsHeader}>
      <IconButtonWithTooltip
        onClickFunction={handleDownloadClick}
        buttonIcon={FileDownloadOutlined}
        hoverText={StringConstants.DOWNLOAD}
      />
    </div>
  );
};
