import { Box } from "@mui/material";
import {
  AdminEquityLabels,
  DataLoadStatus,
  LoadingIndicator,
  NoDataAvailableError,
  reqAllEntitlements,
  resetSelectedEmployee,
} from "common";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AdminUIStore } from "../../redux/store";
import styles from "./EquityLandingPage.module.scss";

export const EquityLandingPage = () => {
  const { equityAdminEntitlementLoadStatus, hasEquityAdminEntitlement } =
    useSelector((store: AdminUIStore) => store.entitlements);
  const { selectedEmployee } = useSelector((store: AdminUIStore) => store);
  const dispatch = useDispatch();

  useEffect(() => {
    async function checkData() {
      if (equityAdminEntitlementLoadStatus === DataLoadStatus.NOT_REQUESTED) {
        dispatch(reqAllEntitlements());
      }
      if (selectedEmployee.id !== "") {
        dispatch(resetSelectedEmployee());
      }
    }
    checkData();
  }, [equityAdminEntitlementLoadStatus, selectedEmployee, dispatch]);

  const nonSuccessComponent =
    equityAdminEntitlementLoadStatus === DataLoadStatus.UNSUCCESSFUL ||
    equityAdminEntitlementLoadStatus === DataLoadStatus.EMPTY_RESPONSE ? (
      <NoDataAvailableError />
    ) : (
      <LoadingIndicator />
    );

  return (
    <div className={styles.page}>
      {hasEquityAdminEntitlement &&
      equityAdminEntitlementLoadStatus === DataLoadStatus.SUCCESSFUL ? (
        <>
          <Box
            component="img"
            alt={"Landing Page background"}
            src="/assets/images/AdminLanding.png"
            className={styles.landingImage}
          />
          <h1 className={styles.landingText}>
            {AdminEquityLabels.PLEASE_SEARCH}
          </h1>
        </>
      ) : (
        nonSuccessComponent
      )}
    </div>
  );
};
