import { ThemeOptions } from "@mui/material";

import { Font } from "../sharedStyleConstants";

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontFamily: Font.Family.GUARDIAN_SANS,
          fontSize: "12px",
          fontWeight: 400,
        },
      },
    },
  },
};

export const CustomBadgeTheme = partialTheme.components?.MuiBadge;
