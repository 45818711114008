import { ColDef } from "ag-grid-community";

import { filterIconOutlined } from "./FilterIcons/FilterIcons";

export const DEFAULT_COLUMN_DEF: ColDef = {
  resizable: true,
  icons: {
    menu: filterIconOutlined,
  },
  filter: false,
  minWidth: 110,
  menuTabs: [],
  sortable: true,
  lockPinned: true,
  suppressHeaderMenuButton: true,
};
