import "ag-grid-enterprise";

import { GroupCellRendererParams } from "ag-grid-enterprise";
import { DoubleTapTooltip, EquityLabel } from "common";
import React from "react";

import { StockSymbol } from "../../constants/enums";
import { formatDateMMMDDYYYYOrNull } from "../../utils/formatters";
import { StockChip } from "../StockChip/StockChip";

export const AwardNameCellRenderer = (props: GroupCellRendererParams) => {
  const tooltipYOffset = -30;
  if (props.node.rowPinned) {
    return (
      <DoubleTapTooltip
        value={EquityLabel.ACTIVE_AWARDS_DETAILS_FOOTER}
        yOffset={tooltipYOffset}
      />
    );
  }

  if (props.data) {
    return (
      <DoubleTapTooltip
        value={formatDateMMMDDYYYYOrNull(props.value)}
        yOffset={tooltipYOffset}
      />
    );
  }

  const awardName = props.value;
  const stockSymbol = props.node.aggData.stockSymbol as StockSymbol;

  return (
    <DoubleTapTooltip value={awardName} yOffset={tooltipYOffset}>
      <span>
        <StockChip stockSymbol={stockSymbol} />
        <span>{awardName}</span>
      </span>
    </DoubleTapTooltip>
  );
};
