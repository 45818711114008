import { FileDownloadOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import {
  DataLoadStatus,
  DocumentsLabel,
  DownloadRequestStatus,
  ErrorStringConstants,
  isSomething,
  isSuccessful,
  openAlert,
  reqEquityEmployees,
  setDownloadReqStatus,
  StringConstants,
} from "common";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AdminUIStore } from "../../../redux/store";
import styles from "./AdminDownloadButton.module.scss";
import { useEquityAdminDownload } from "./adminEquityDownloadExcel";

const isEquityDataReady = (
  equityDataLoadStatus: DataLoadStatus,
  distributionsDataLoadStatus: DataLoadStatus
) => {
  return (
    (equityDataLoadStatus === DataLoadStatus.SUCCESSFUL ||
      equityDataLoadStatus === DataLoadStatus.EMPTY_RESPONSE) &&
    (distributionsDataLoadStatus === DataLoadStatus.SUCCESSFUL ||
      distributionsDataLoadStatus === DataLoadStatus.EMPTY_RESPONSE)
  );
};

export const AdminEquityDownloadButton = () => {
  const {
    equityDataLoadStatus,
    distributionsDataLoadStatus,
    activeEquityDataId,
    supportedStocks,
  } = useSelector((store: AdminUIStore) => store.equityData);

  const { selectedEmployee, employees } = useSelector(
    (store: AdminUIStore) => store
  );

  const { downloadRequestStatus } = useSelector(
    (store: AdminUIStore) => store.viewData
  );

  const dispatch = useDispatch();
  const handleDownload = useEquityAdminDownload();

  // effect tracks changes to data load status
  useEffect(() => {
    // triggers download if data load is successful and employee has supported stocks
    if (
      isEquityDataReady(equityDataLoadStatus, distributionsDataLoadStatus) &&
      downloadRequestStatus === DownloadRequestStatus.REQUESTED &&
      isSomething(supportedStocks)
    ) {
      handleDownload(
        selectedEmployee,
        supportedStocks.value,
        () =>
          dispatch(
            openAlert({
              severity: "success",
              message: StringConstants.DOWNLOAD_COMPLETE,
            })
          ),
        () =>
          dispatch(
            openAlert({
              severity: "error",
              message: ErrorStringConstants.DOWNLOAD_FAILED,
            })
          )
      );

      dispatch(setDownloadReqStatus(DownloadRequestStatus.NOT_REQUESTED));
    }
  }, [
    handleDownload,
    equityDataLoadStatus,
    distributionsDataLoadStatus,
    downloadRequestStatus,
    supportedStocks,
    selectedEmployee,
    dispatch,
  ]);

  const handleClick = async () => {
    // check if employee has been selected/is active
    if (isSomething(activeEquityDataId)) {
      // set progress toast
      dispatch(
        openAlert({
          severity: "info",
          message: DocumentsLabel.PREPARING_DOCUMENTS,
        })
      );
      // set download requested value to REQUESTED
      dispatch(setDownloadReqStatus(DownloadRequestStatus.REQUESTED));

      // check if data is loaded
      if (!isSuccessful(employees.employeesLoadStatus)) {
        // req data
        dispatch(reqEquityEmployees());
      }
    }
  };

  return (
    <>
      <Button
        className={styles.adminDownload}
        onClick={handleClick}
        variant="outlined"
        endIcon={<FileDownloadOutlined />}
        disabled={
          equityDataLoadStatus !== DataLoadStatus.SUCCESSFUL &&
          distributionsDataLoadStatus !== DataLoadStatus.SUCCESSFUL
        }
      >
        {StringConstants.EQUITY_ADMIN_DOWNLOAD}
      </Button>
    </>
  );
};
