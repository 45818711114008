import { ThemeOptions } from "@mui/material";

import colors from "../../_colors.scss";
import { Font } from "../sharedStyleConstants";

// Shared constants we may want to move in unison
const HoverStyle = {
  BACKGROUND_COLOR: colors.charcoal,
  BOX_SHADOW: "0px 3px 6px #00000040",
  COLOR: colors.white,
};

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiToggleButton: {
      defaultProps: {
        disableFocusRipple: true,
        disableTouchRipple: true,
        size: "small",
      },
      styleOverrides: {
        root: {
          color: colors.black,
          fontFamily: Font.Family.GUARDIAN_SANS,
          fontSize: Font.Size.STANDARD,
          fontWeight: Font.Weight.STANDARD,
          "&.Mui-selected": {
            color: colors.white,
            backgroundColor: colors.black,
            "&:hover": {
              backgroundColor: colors.charcoal,
              boxShadow: "none",
              color: colors.white,
            },
          },
          // styles for focus from keyboard events
          "&:focus": {
            backgroundColor: colors.pale_azure,
            boxShadow: "none",
            color: colors.black,
          },
          // styles for focus from mouse/touch events
          "&:focus:not(:focus-visible)": {
            backgroundColor: colors.black,
            color: colors.white,
            boxShadow: HoverStyle.BOX_SHADOW,
          },
          "&:hover": {
            backgroundColor: colors.light_grey,
            boxShadow: "none",
          },
          "&:active": {
            backgroundColor: colors.black,
            boxShadow: HoverStyle.BOX_SHADOW,
          },
        },
        sizeSmall: {
          height: "36px",
          fontSize: "14px",
          padding: "8px 16px",
          minWidth: "0px",
        },
      },
    },
    MuiToggleButtonGroup: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          height: "36px",
        },
      },
    },
  },
};

export const CustomToggleButtonTheme = partialTheme.components?.MuiToggleButton;
export const CustomToggleButtonGroup =
  partialTheme.components?.MuiToggleButtonGroup;
