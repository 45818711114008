import { DocumentsSortBy, TaxDocumentStatus } from "../constants/enums";
import { DocumentsLabel } from "../constants/LabelAndTooltipConstants";
import {
  createDocumentFilterRequest,
  DocumentRequest,
} from "../services/documentsService";
import {
  isDateFilterActive,
  isMultiSelectFilterActive,
  isSingleSelectFilterActive,
} from "../utils/documentUtils";
import {
  IDocument,
  IDocumentsClient,
  IDocumentSortOptions,
  IDocumentType,
  IDocumentTypeCategory,
  IEntity,
} from "./dataTypes";
import { isSomething } from "./typeGuards";
import { arrayOfAll, Flatten, nothing, Optional, some } from "./typeUtils";

// Section Headers on the Doc Grid Filter and Select Columns Panel
export enum DocumentColumnSectionHeaders {
  DOC_PROPERTIES = "Document Properties",
  RELATIONSHIPS = "Relationships",
  TAX = "Tax",
}
type ColumnDefinitionBase<TValue> = {
  readonly label: string;
  filter: ColumnDefinitionFilter<TValue>;
};
type ColumnDefinitionFilter<TValue> = {
  showFilterCheck: (options: Optional<NonNullable<TValue>>[]) => boolean;
  filterActiveCheck: (
    selectedValues: Optional<NonNullable<TValue>>[]
  ) => boolean;
};

// TYPES USED FOR COLUMN SELECTION
type ColumnStateBase<TValue> = {
  show: boolean;
  disabled: boolean;
  filter: ColumnFilterState<TValue>;
  sortField?: DocumentsSortBy | undefined;
};
type ColumnFilterState<TValue> = {
  options: Optional<NonNullable<TValue>>[];
  values: Optional<NonNullable<TValue>>[];
  disabled: boolean;
  errors: string[];
};

// We do not have a column for documentOId, but we do have a column Document Type Category
// Create a mapped type with exactly what we need
export type DocumentGridRow = Omit<IDocument, "documentOId"> & {
  documentTypeCategory: IDocumentTypeCategory;
};
export type DocumentColumnName = keyof DocumentGridRow;

// Enum of DocumentColumnName. For easy reference and replacement
export enum DocumentGridColumnKeys {
  DOWNLOADED_DATE = "downloadedDate",
  DOCUMENT_TYPE = "documentType",
  DOCUMENT_NAME = "documentName",
  PERIOD = "period",
  FUNDS = "funds",
  PUBLISHED_DATE = "publishedDate",
  DOCUMENT_TYPE_CATEGORY = "documentTypeCategory",
  EFFECTIVE_DATE = "effectiveDate",
  INVESTMENT_VEHICLES = "investmentVehicles",
  PARTNERSHIPS = "partnerships",
  TAX_DOCUMENT_STATUS = "taxDocumentStatus",
  JURISDICTIONS = "jurisdictions",
}

export const DocumentGridSortableColumnNames = [
  DocumentGridColumnKeys.DOCUMENT_NAME,
  DocumentGridColumnKeys.PERIOD,
  DocumentGridColumnKeys.PUBLISHED_DATE,
  DocumentGridColumnKeys.EFFECTIVE_DATE,
  DocumentGridColumnKeys.JURISDICTIONS,
  DocumentGridColumnKeys.DOCUMENT_TYPE,
];

// Grab just the date columns, so we can make sure we only use the columns with Date filters
export type DateColumns = {
  [K in DocumentColumnName]: Flatten<DocumentGridRow[K]> extends Date | null
    ? K
    : never;
}[DocumentColumnName];

export type EntityColumns = {
  [K in DocumentColumnName]: Flatten<DocumentGridRow[K]> extends IEntity | null
    ? K
    : never;
}[DocumentColumnName];

export const DocumentColumnSectionOrder = [
  DocumentColumnSectionHeaders.DOC_PROPERTIES,
  DocumentColumnSectionHeaders.RELATIONSHIPS,
  DocumentColumnSectionHeaders.TAX,
];

type DocumentColumnSections = {
  [Key in DocumentColumnSectionHeaders]: DocumentColumnName[];
};

export type PageSize = 50 | 100 | 250;

// Doc Column Section order for manage columns panel
export const ManageColumnsDocumentSections: DocumentColumnSections = {
  [DocumentColumnSectionHeaders.DOC_PROPERTIES]: [
    DocumentGridColumnKeys.DOWNLOADED_DATE,
    DocumentGridColumnKeys.DOCUMENT_NAME,
    DocumentGridColumnKeys.DOCUMENT_TYPE,
    DocumentGridColumnKeys.DOCUMENT_TYPE_CATEGORY,
    DocumentGridColumnKeys.PERIOD,
    DocumentGridColumnKeys.PUBLISHED_DATE,
    DocumentGridColumnKeys.EFFECTIVE_DATE,
  ],
  [DocumentColumnSectionHeaders.RELATIONSHIPS]: [
    DocumentGridColumnKeys.FUNDS,
    DocumentGridColumnKeys.INVESTMENT_VEHICLES,
    DocumentGridColumnKeys.PARTNERSHIPS,
  ],
  [DocumentColumnSectionHeaders.TAX]: [
    DocumentGridColumnKeys.TAX_DOCUMENT_STATUS,
    DocumentGridColumnKeys.JURISDICTIONS,
  ],
};

// Doc Column Section order for filter panel
export const FilterPanelDocumentSections: DocumentColumnSections = {
  [DocumentColumnSectionHeaders.DOC_PROPERTIES]: [
    DocumentGridColumnKeys.DOCUMENT_TYPE_CATEGORY,
    DocumentGridColumnKeys.DOCUMENT_TYPE,
    DocumentGridColumnKeys.PERIOD,
    DocumentGridColumnKeys.PUBLISHED_DATE,
    DocumentGridColumnKeys.EFFECTIVE_DATE,
    DocumentGridColumnKeys.DOWNLOADED_DATE,
    DocumentGridColumnKeys.DOCUMENT_NAME,
  ],
  [DocumentColumnSectionHeaders.RELATIONSHIPS]: [
    DocumentGridColumnKeys.INVESTMENT_VEHICLES,
    DocumentGridColumnKeys.FUNDS,
    DocumentGridColumnKeys.PARTNERSHIPS,
  ],
  [DocumentColumnSectionHeaders.TAX]: [
    DocumentGridColumnKeys.TAX_DOCUMENT_STATUS,
    DocumentGridColumnKeys.JURISDICTIONS,
  ],
};

// For type safe iteration over columns.
const arrayOfAllColumns = arrayOfAll<DocumentGridColumnKeys>();
export const PossibleDocumentColumns: DocumentColumnName[] = arrayOfAllColumns([
  DocumentGridColumnKeys.DOWNLOADED_DATE,
  DocumentGridColumnKeys.DOCUMENT_NAME,
  DocumentGridColumnKeys.DOCUMENT_TYPE,
  DocumentGridColumnKeys.PERIOD,
  DocumentGridColumnKeys.PUBLISHED_DATE,
  DocumentGridColumnKeys.DOCUMENT_TYPE_CATEGORY,
  DocumentGridColumnKeys.EFFECTIVE_DATE,
  DocumentGridColumnKeys.FUNDS,
  DocumentGridColumnKeys.INVESTMENT_VEHICLES,
  DocumentGridColumnKeys.PARTNERSHIPS,
  DocumentGridColumnKeys.TAX_DOCUMENT_STATUS,
  DocumentGridColumnKeys.JURISDICTIONS,
]);

// Maps a ColumnDefinition for Each Document Column
// ColumnDefinitions are the constant atrributes of a column (i.e.
//    label, type, etc.)
export type ColumnDefinition<
  K extends DocumentColumnName = DocumentColumnName
> = {
  [P in K]: ColumnDefinitionBase<Flatten<DocumentGridRow[P]>> & { type: P };
}[K];
export type ColumnDefinitions = {
  [Key in DocumentColumnName]: ColumnDefinition<Key>;
};

// Maps a ColumnState for Each Document Column
// ColumnStates are the mutable attributes of the column (i.e.
//    whether the column is shown, its filter options/values, etc)
export type ColumnState<K extends DocumentColumnName = DocumentColumnName> = {
  [P in K]: ColumnStateBase<Flatten<DocumentGridRow[P]>>;
}[K];
export type ColumnStates = {
  [Key in DocumentColumnName]: ColumnState<Key>;
};
export type DocumentColumnFilterOptions = {
  [Key in DocumentColumnName]?: Optional<
    Flatten<NonNullable<DocumentGridRow[Key]>>
  >[];
};

// Maps a filter component for each Document Column
export type DocumentFilterComponents = {
  [K in DocumentColumnName]?: (
    columnDefinition: ColumnDefinition<K>,
    columnState: ColumnState<K>,
    onChange: (
      column: K,
      value:
        | Optional<Flatten<NonNullable<DocumentGridRow[K]>>>
        | Optional<Flatten<NonNullable<DocumentGridRow[K]>>>[],
      errors: string[],
      index?: number
    ) => void
  ) => JSX.Element;
};

// counts the number of filters applied in the provided column states
export const getNumFiltersApplied = (columnStates: ColumnStates) => {
  let countFiltersApplied = 0;
  for (const column of PossibleDocumentColumns) {
    if (
      !columnStates[column].filter.disabled &&
      isColumnFilterActive<typeof column>(
        DefaultColumnDefinitions[column],
        columnStates[column]
      )
    ) {
      countFiltersApplied++;
    }
  }
  return countFiltersApplied;
};

// checks if there are any filters applied in the provided column states
export const hasFiltersSelected = (columnStates: ColumnStates) => {
  return getNumFiltersApplied(columnStates) > 0;
};

/*
Maps a list of Optional<TaxDocumentStatus> to a list of strings
*/
export const mapTaxDocumentStatusToString = (
  list: Optional<TaxDocumentStatus>[]
): string[] => {
  const filteredList: string[] = [];
  list.forEach((value: Optional<TaxDocumentStatus>) => {
    if (isSomething(value)) {
      filteredList.push(value.value);
    }
  });
  return filteredList;
};

// typesafe copy of a column's state
export const copyColumnState = <K extends keyof ColumnStates>(
  originalCopy: ColumnStates,
  newCopy: ColumnStates,
  columnToCopy: K
) => {
  const originalColumn = originalCopy[columnToCopy];
  const newColumn: ColumnStates[K] = {
    ...originalColumn,
    filter: {
      ...originalColumn.filter,
      options: [...originalColumn.filter.options],
      values: [...originalColumn.filter.values],
      errors: [...originalColumn.filter.errors],
    },
  };
  newCopy[columnToCopy] = newColumn;
};

// typesafe copy of all column states
export const copyColumnStates = (originalCopy: ColumnStates): ColumnStates => {
  const newCopy = {} as ColumnStates;
  for (const column of PossibleDocumentColumns) {
    copyColumnState(originalCopy, newCopy, column);
  }
  return newCopy;
};

// typesafe check of whether a filter is active
export const isColumnFilterActive = <K extends DocumentColumnName>(
  columnDefinition: ColumnDefinition<K>,
  columnState: ColumnState<K>
): boolean => {
  if (columnDefinition.filter.filterActiveCheck(columnState.filter.values)) {
    return true;
  }
  return false;
};

// typesafe update of an individual filter's options
export const setColumnFilterOptions = <K extends DocumentColumnName>(
  columnDefinition: ColumnDefinition<K>,
  columnState: ColumnState<K>,
  options: Optional<Flatten<NonNullable<DocumentGridRow[K]>>>[]
) => {
  columnState.filter.options = options;
  if (!columnDefinition.filter.showFilterCheck(options)) {
    columnState.filter.disabled = true;
  }
};

// typesafe update of an individual filter
export const setColumnFilterValue = <
  K extends keyof ColumnStates,
  T extends Optional<Flatten<NonNullable<DocumentGridRow[K]>>>
>(
  columnDefinition: ColumnState<K>,
  value: T,
  index: number
) => {
  columnDefinition.filter.values[index] = value;
};
// typesafe update of an individual filter
export const setColumnFilterValues = <
  K extends keyof ColumnStates,
  T extends Optional<Flatten<NonNullable<DocumentGridRow[K]>>>
>(
  columnDefinition: ColumnState<K>,
  values: T[]
) => {
  columnDefinition.filter.values = values;
};

/*
  resets each column's visibility to the default value
*/
export const resetAllColumnVisibility = (columnStates: ColumnStates) => {
  for (const column of PossibleDocumentColumns) {
    const defaultColumnVisibility = DefaultColumnStates[column].show;
    columnStates[column].show = defaultColumnVisibility;
  }
};

export const createDocumentRequest = (
  page: number,
  pageSize: PageSize,
  columnStates: ColumnStates,
  searchTerm: Optional<string>,
  sort: Optional<IDocumentSortOptions>,
  activeDocumentClient: Optional<IDocumentsClient>
): Optional<DocumentRequest> => {
  if (isSomething(activeDocumentClient)) {
    const docRequest: DocumentRequest = {
      clientOId: activeDocumentClient.value.oId,
      body: {
        offset: (page - 1) * pageSize,
        limit: pageSize,
        filters: createDocumentFilterRequest(columnStates),
        //only filter by search term if string is longer than 2 characters due to solr constraints
        searchTerm:
          isSomething(searchTerm) && searchTerm.value.length > 2
            ? searchTerm.value
            : undefined,
        sort: isSomething(sort) ? sort.value : undefined,
      },
    };
    return some(docRequest);
  }
  return nothing;
};

/*
  resets each column's filter values to the default value
*/
export const resetAllColumnFilterValues = (columnStates: ColumnStates) => {
  for (const column of PossibleDocumentColumns) {
    const defaultColumnFilterState = DefaultColumnStates[column].filter;
    columnStates[column].filter.values = defaultColumnFilterState.values;
    columnStates[column].filter.errors = defaultColumnFilterState.errors;
  }
};

// Function to update document type options based on selected document type categories
export const filterDocumentTypesForSelectedDocumentTypeCategories = (
  documentTypes: Optional<IDocumentType>[],
  selectedDocumentTypeCategoryValues: Optional<IDocumentTypeCategory>[]
): Optional<IDocumentType>[] => {
  return documentTypes.filter(
    (documentType) =>
      isSomething(documentType) &&
      // If there are document type categories selected
      (selectedDocumentTypeCategoryValues.length === 0 ||
        // Filter out document types that don't match the selected document type categories
        selectedDocumentTypeCategoryValues.find(
          (documentTypeCategoryValue) =>
            isSomething(documentTypeCategoryValue) &&
            documentType.value.category.id ===
              documentTypeCategoryValue.value.id
        ))
  );
};

// The immutable column definitions
export const DefaultColumnDefinitions: ColumnDefinitions = {
  [DocumentGridColumnKeys.DOWNLOADED_DATE]: {
    type: DocumentGridColumnKeys.DOWNLOADED_DATE,
    label: DocumentsLabel.DOWNLOAD_DATE,
    filter: {
      // TODO: update these once filter is done because its kind of an extreme custom type filter
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      showFilterCheck: (options: Optional<Date>[]) => {
        return false; // TODO update this logix
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      filterActiveCheck: (selectedVals: Optional<Date>[]) => {
        return false;
      },
    },
  },
  [DocumentGridColumnKeys.DOCUMENT_NAME]: {
    type: DocumentGridColumnKeys.DOCUMENT_NAME,
    label: DocumentsLabel.DOCUMENT_NAME,
    filter: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      showFilterCheck: (options: Optional<string>[]) => {
        return false;
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      filterActiveCheck: (selectedVals: Optional<string>[]) => {
        return false;
      },
    },
  },
  [DocumentGridColumnKeys.DOCUMENT_TYPE]: {
    type: DocumentGridColumnKeys.DOCUMENT_TYPE,
    label: DocumentsLabel.DOCUMENT_TYPE,
    filter: {
      showFilterCheck: (options: Optional<IDocumentType>[]) => {
        return options.length > 1;
      },
      filterActiveCheck: (selectedVals: Optional<IDocumentType>[]) => {
        return isMultiSelectFilterActive(selectedVals);
      },
    },
  },
  [DocumentGridColumnKeys.PERIOD]: {
    type: DocumentGridColumnKeys.PERIOD,
    label: DocumentsLabel.PERIOD,
    filter: {
      showFilterCheck: (options: Optional<string>[]) => {
        return options.length > 0;
      },
      filterActiveCheck: (selectedVals: Optional<string>[]) => {
        return isMultiSelectFilterActive(selectedVals);
      },
    },
  },
  [DocumentGridColumnKeys.PUBLISHED_DATE]: {
    type: DocumentGridColumnKeys.PUBLISHED_DATE,
    label: DocumentsLabel.PUBLISHED_DATE,
    filter: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      showFilterCheck: (options: Optional<Date>[]) => {
        return true;
      },
      filterActiveCheck: (selectedVals: Optional<Date>[]) => {
        return isDateFilterActive(selectedVals);
      },
    },
  },
  [DocumentGridColumnKeys.DOCUMENT_TYPE_CATEGORY]: {
    type: DocumentGridColumnKeys.DOCUMENT_TYPE_CATEGORY,
    label: DocumentsLabel.DOCUMENT_TYPE_CATEGORY,
    filter: {
      showFilterCheck: (options: Optional<IDocumentTypeCategory>[]) => {
        return options.length > 1;
      },
      filterActiveCheck: (selectedVals: Optional<IDocumentTypeCategory>[]) => {
        return isMultiSelectFilterActive(selectedVals);
      },
    },
  },
  [DocumentGridColumnKeys.EFFECTIVE_DATE]: {
    type: DocumentGridColumnKeys.EFFECTIVE_DATE,
    label: DocumentsLabel.EFFECTIVE_DATE,
    filter: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      showFilterCheck: (options: Optional<Date>[]) => {
        return true;
      },
      filterActiveCheck: (selectedVals: Optional<Date>[]) => {
        return isDateFilterActive(selectedVals);
      },
    },
  },
  [DocumentGridColumnKeys.FUNDS]: {
    type: DocumentGridColumnKeys.FUNDS,
    label: DocumentsLabel.FUND,
    filter: {
      showFilterCheck: (options: Optional<IEntity>[]) => {
        return options.length > 0;
      },
      filterActiveCheck: (selectedVals: Optional<IEntity>[]) => {
        return isMultiSelectFilterActive(selectedVals);
      },
    },
  },
  [DocumentGridColumnKeys.INVESTMENT_VEHICLES]: {
    type: DocumentGridColumnKeys.INVESTMENT_VEHICLES,
    label: DocumentsLabel.IV,
    filter: {
      showFilterCheck: (options: Optional<IEntity>[]) => {
        return options.length > 0;
      },
      filterActiveCheck: (selectedVals: Optional<IEntity>[]) => {
        return isMultiSelectFilterActive(selectedVals);
      },
    },
  },
  [DocumentGridColumnKeys.PARTNERSHIPS]: {
    type: DocumentGridColumnKeys.PARTNERSHIPS,
    label: DocumentsLabel.PARTNERSHIP,
    filter: {
      showFilterCheck: (options: Optional<IEntity>[]) => {
        return options.length > 0;
      },
      filterActiveCheck: (selectedVals: Optional<IEntity>[]) => {
        return isMultiSelectFilterActive(selectedVals);
      },
    },
  },
  [DocumentGridColumnKeys.TAX_DOCUMENT_STATUS]: {
    type: DocumentGridColumnKeys.TAX_DOCUMENT_STATUS,
    label: DocumentsLabel.ESTIMATE_FINAL,
    filter: {
      showFilterCheck: (options: Optional<TaxDocumentStatus>[]) => {
        return options.length > 0;
      },
      filterActiveCheck: (selectedVals: Optional<TaxDocumentStatus>[]) => {
        return isSingleSelectFilterActive(selectedVals);
      },
    },
  },
  [DocumentGridColumnKeys.JURISDICTIONS]: {
    type: DocumentGridColumnKeys.JURISDICTIONS,
    label: DocumentsLabel.JURISDICTION,
    filter: {
      showFilterCheck: (options: Optional<string>[]) => {
        return options.length > 0;
      },
      filterActiveCheck: (selectedVals: Optional<string>[]) => {
        return isMultiSelectFilterActive(selectedVals);
      },
    },
  },
};

// The default column states that are stroed in redix state and can be changed via user interaction
export const DefaultColumnStates: ColumnStates = {
  [DocumentGridColumnKeys.DOWNLOADED_DATE]: {
    show: true,
    disabled: true,
    filter: {
      options: [],
      values: [nothing, nothing],
      disabled: true,
      errors: [],
    },
  },
  [DocumentGridColumnKeys.DOCUMENT_NAME]: {
    show: true,
    disabled: true,
    filter: {
      options: [],
      values: [],
      disabled: true,
      errors: [],
    },
    sortField: DocumentsSortBy.DOCUMENT_NAME,
  },
  [DocumentGridColumnKeys.DOCUMENT_TYPE]: {
    show: true,
    disabled: true,
    filter: {
      options: [],
      values: [],
      disabled: false,
      errors: [],
    },
    sortField: DocumentsSortBy.DOCUMENT_TYPE_NAME,
  },
  [DocumentGridColumnKeys.PERIOD]: {
    show: true,
    disabled: false,
    filter: {
      options: [],
      values: [],
      disabled: false,
      errors: [],
    },
    sortField: DocumentsSortBy.PERIOD,
  },
  [DocumentGridColumnKeys.PUBLISHED_DATE]: {
    show: true,
    disabled: false,
    filter: {
      options: [],
      values: [nothing, nothing],
      disabled: false,
      errors: [],
    },
    sortField: DocumentsSortBy.PUBLISHED_DATE,
  },
  [DocumentGridColumnKeys.DOCUMENT_TYPE_CATEGORY]: {
    show: false,
    disabled: false,
    filter: {
      options: [],
      values: [],
      disabled: false,
      errors: [],
    },
  },
  [DocumentGridColumnKeys.EFFECTIVE_DATE]: {
    show: false,
    disabled: false,
    filter: {
      options: [],
      values: [nothing, nothing],
      disabled: false,
      errors: [],
    },
    sortField: DocumentsSortBy.EFFECTIVE_DATE,
  },
  [DocumentGridColumnKeys.FUNDS]: {
    show: true,
    disabled: false,
    filter: {
      options: [],
      values: [],
      disabled: false,
      errors: [],
    },
  },
  [DocumentGridColumnKeys.INVESTMENT_VEHICLES]: {
    show: false,
    disabled: false,
    filter: {
      options: [],
      values: [],
      disabled: true,
      errors: [],
    },
  },
  [DocumentGridColumnKeys.PARTNERSHIPS]: {
    show: false,
    disabled: false,
    filter: {
      options: [],
      values: [],
      disabled: false,
      errors: [],
    },
  },
  [DocumentGridColumnKeys.TAX_DOCUMENT_STATUS]: {
    show: false,
    disabled: false,
    filter: {
      options: [],
      values: [],
      disabled: false,
      errors: [],
    },
  },
  [DocumentGridColumnKeys.JURISDICTIONS]: {
    show: false,
    disabled: false,
    filter: {
      options: [],
      values: [],
      disabled: true,
      errors: [],
    },
    sortField: DocumentsSortBy.JURISDICTIONS,
  },
};
