import React from "react";

import {
  getAsOfLine,
  getRealizedFooterLine,
  OverviewLabel,
  OverviewTooltip,
  SectionHeader,
} from "../../constants/LabelAndTooltipConstants";
import { IAsOfDate, IRealizedCalloutData } from "../../types/dataTypes";
import { ILabeledDataPoint } from "../../types/labelTypes";
import { getFirstDayOfQuarter } from "../../utils/formatters";
import { IDashboardCardContentProps } from "../DashboardCard/DashboardCard";
import { OverviewCard } from "./components/OverviewCard/OverviewCard";

/**
 * Component that encapsulates the Realized Proceeds overview card
 */

export interface RealizedCalloutCard {
  callouts: IRealizedCalloutData;
  asOfDates: IAsOfDate;
}

export const RealizedProceedsOverview = (
  props: IDashboardCardContentProps<RealizedCalloutCard>
) => {
  const realizedData = props.data.callouts;

  const asOfDates = props.data.asOfDates;
  const allCardData: ILabeledDataPoint[] = [
    {
      label: OverviewLabel.CARRY_AND_INCENTIVE,
      tooltipParagraphs: [OverviewTooltip.CARRY_AND_INCENTIVE_FEES],
      calloutDatum: realizedData.realizedCarry,
    },
    {
      label: OverviewLabel.OPTIONAL_AND_MANDATORY,
      tooltipParagraphs: [],
      calloutDatum: realizedData.realizedOptionalAndMandatoryInvestments,
    },
    {
      label: OverviewLabel.RETURN_OF_CAPITAL,
      tooltipParagraphs: [OverviewTooltip.RETURN_OF_CAPITAL],
      calloutDatum: realizedData.returnOfCapital,
      isSubLabel: true,
    },
    {
      label: OverviewLabel.GAIN_LOSS,
      tooltipParagraphs: [OverviewTooltip.REALIZED_GAIN_LOSS],
      calloutDatum: realizedData.realizedGainLoss,
      isSubLabel: true,
    },
    {
      label: OverviewLabel.TOTAL_REALIZED_PROCEEDS,
      tooltipParagraphs: [OverviewTooltip.TOTAL_REALIZED_PROCEEDS],
      calloutDatum: realizedData.totalRealizedProceeds,
      isTotalLabel: true,
    },
  ];

  return (
    <OverviewCard
      cardName={SectionHeader.REALIZED_PROCEEDS_OVERVIEW}
      subHeader={getAsOfLine(asOfDates.latestAsOfDate)}
      overviewDataList={allCardData}
      footer={getRealizedFooterLine(
        getFirstDayOfQuarter(asOfDates.earliestAsOfDate)
      )}
    />
  );
};
