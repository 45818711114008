import { CellClassParams } from "ag-grid-community";

// Determine how much to indent a row group when exporting to Excel
// See https://www.ag-grid.com/react-data-grid/excel-export-styles/#example-styling-row-groups
export const getIndentClass = (params: CellClassParams): string => {
  let node = params.node;
  let indent = 0;

  while (node && node.parent) {
    indent++;
    node = node.parent;
  }

  return `indent-${indent}`;
};
