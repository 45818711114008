import { Grid } from "@mui/material";
import {
  CarryLabel,
  getChartCellColorByIndex,
  getCurrencyFormattedValueWithZeroDefault,
  ICarryChartData,
  PieConstants,
  ResponsiveRechartsWrapper,
} from "common";
import React from "react";
import { Cell, Pie, PieChart, Tooltip } from "recharts";

import colors from "../../../../../styles/_colors.scss";
import styles from "./CarryChart.module.scss";
import { CarryChartTooltip } from "./CarryChartTooltip";

interface CarryChartProps {
  carryChartData: ICarryChartData[];
  unrealizedCarry: number | undefined;
  handleSliceClick: (carryChartData: ICarryChartData) => void;
}

const NoValueCarryChart: ICarryChartData[] = [
  {
    businessUnitName: "",
    fundShortName: "",
    mdmFundId: 0,
    totalUnrealized: 1,
    totalRealized: 0,
  },
];

export const CarryChart = (props: CarryChartProps) => {
  const { carryChartData, unrealizedCarry, handleSliceClick } = props;
  //Validates if the list of holdback per fund has 1 or more values
  const carryChartHasValues = carryChartData.length > 0;
  //Position for the X Axis from pie chart
  const xAxis = "50%";
  //Position for the Y Axis from pie chart
  const yAxis = 115;
  const pieStyle = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  };

  return (
    <>
      <Grid item xs={12} md={12} className={styles.pie}>
        <ResponsiveRechartsWrapper>
          <PieChart className={styles.pie} margin={pieStyle}>
            {carryChartHasValues && (
              <Tooltip
                cursor={{
                  /* Recharts adds a gray hover when there's no data by default, 
                this sets the hover color to white, so we don't have a weird hover when there's no data selected*/
                  stroke:
                    carryChartData.length === 0
                      ? colors.white
                      : colors.lightest_grey,
                }}
                content={({ payload }) =>
                  payload && <CarryChartTooltip payload={payload} />
                }
              />
            )}
            <text
              dx={xAxis}
              y={yAxis - 15}
              textAnchor="middle"
              dominantBaseline="middle"
              className={styles.pieCenterText}
              fill={colors.bx_primary_grey_text}
            >
              {CarryLabel.UNREALIZED_CARRY}
            </text>
            <text
              dx={xAxis}
              y={yAxis + 15}
              textAnchor="middle"
              dominantBaseline="middle"
              className={styles.pieCenterValue}
            >
              {getCurrencyFormattedValueWithZeroDefault(unrealizedCarry)}
            </text>
            <Pie
              data={carryChartHasValues ? carryChartData : NoValueCarryChart}
              cx={xAxis}
              cy={yAxis}
              innerRadius={PieConstants.INNER_RADIUS}
              outerRadius={PieConstants.OUTER_RADIUS}
              fill={colors.white}
              blendStroke={carryChartData.length <= 1}
              minAngle={carryChartData.length > 1 ? 10 : 0}
              dataKey="totalUnrealized"
              nameKey="businessUnitName"
              startAngle={90} // pie chart should begin at 12 o'clock position
              endAngle={-270} // pie chart should go clockwise, which is negative direction
            >
              {carryChartHasValues &&
                carryChartData.map((carryData, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      carryData.totalUnrealized !== 0
                        ? getChartCellColorByIndex(index)
                        : colors.bx_slate
                    }
                    onClick={() => handleSliceClick(carryData)}
                  />
                ))}
              {NoValueCarryChart.map((index) => (
                <Cell key={`cell-${index}`} fill={colors.medium_grey} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveRechartsWrapper>
      </Grid>
    </>
  );
};
