export enum CalloutPersona {
  CARRY,
  NON_CARRY,
}

export enum DataLoadStatus {
  NOT_REQUESTED,
  LOADING,
  EMPTY_RESPONSE,
  SUCCESSFUL,
  UNSUCCESSFUL,
}

export enum DownloadRequestStatus {
  NOT_REQUESTED,
  REQUESTED,
}

// it'll replace TreeNumber
export enum TreeNumber {
  ELECTION_PERIOD = 1,
  BUSINESS_UNIT = 2,
}

export const TreeDepth: { [key in TreeNumber]: number } = {
  [TreeNumber.ELECTION_PERIOD]: 2,
  [TreeNumber.BUSINESS_UNIT]: 3,
};

export enum ElectionPeriodTreeLevel {
  ENTITY = 0,
  PERIOD = 1,
  FUND = 2,
}

export enum BusinessUnitTreeLevel {
  ENTITY = 0,
  BUSINESS_UNIT = 1,
  FUND = 2,
  TRANCHE = 3,
}

export enum PeriodType {
  QUARTERLY = "Quarterly",
  YEARLY = "Yearly",
}

export enum CarryDataKey {
  FUND_NAME = "fundShortName",
  MDM_FUND_ID = "mdmFundId",
  UNREALIZED_CARRY = "totalUnrealized",
  REALIZED_CARRY = "totalRealized",
}

export enum InvestmentBreakdownKey {
  // investment
  NET_CASH_FLOW = "netCashFlow",
  CAPITAL_INVESTED = "capitalInvested",
  ABSOLUTE_CAPITAL_INVESTED = "absoluteCapitalInvested",

  // unrealized value
  REMAINING_CAPITAL_INVESTED = "remainingCapitalInvested",
  UNREALIZED_GAIN_LOSS = "unrealizedGainLoss",
  UNREALIZED_CARRIED_INTEREST = "unrealizedCarriedInterest",
  TOTAL_UNREALIZED_VALUE = "totalUnrealizedValue",

  // realized proceeds
  RETURN_OF_CAPITAL = "returnOfCapital",
  REALIZED_GAIN_LOSS = "realizedProfitLoss",
  REALIZED_CARRIED_INTEREST = "realizedCarriedInterest",
  TOTAL_REALIZED_PROCEEDS = "totalRealizedProceeds",
}

export enum DataSource {
  INTERNAL_INVESTMENTS = "internalInvestments",
  EQUITY = "equity",
  FEATURE_FLAGS = "features",
  ELECTIONS = "elections",
  BANK_ACCOUNTS = "bankAccounts",
}

export enum StockSymbol {
  BX = "BX",
  BXMT = "BXMT",
  BXRT = "BXRT",
}

export const UISupportedStocks: StockSymbol[] = [
  StockSymbol.BX,
  StockSymbol.BXMT,
];

export enum InvestmentValueDisplay {
  CUMULATIVE = 1,
  NONCUMULATIVE = 2,
  CUMULATIVE_UNREALIZED_NONCUMULATIVE_REALIZED = 3,
}

export enum DocumentsPageName {
  EQUITY = "Equity",
  DISTRIBUTIONS = "Distributions",
}

export enum InvestmentsAndCarryPageName {
  OVERVIEW = "Overview",
  INVESTMENT_BREAKDOWN = "InvestmentBreakdown",
}

export enum EquitySubTabPageName {
  VESTING_EVENTS = "VestingEvents",
  DISTRIBUTIONS = "Distributions",
}
export enum SubTabPageName {
  INVESTMENTS_OVERVIEW = "InvestmentsOverview",
  INVESTMENT_BREAKDOWN = "InvestmentBreakdown",
  VESTING_EVENTS = "VestingEvents",
  DISTRIBUTIONS = "Distributions",
  ELECTION_RESULTS = "ElectionResults",
  ELECTION_EDITOR = "ElectionEditor",
}

export const enum VestingEventsRange {
  THREE_YEARS,
  ALL,
}

export enum DistributionUnitType {
  HOLDINGS = "Holdings",
  COMMON = "Common",
}

export enum DocumentTypeName {
  CASH_DIST = "Cash Distribution",
  DIST = "Distribution",
  STOCK_DIST = "Stock Distribution",
  YTP_DIST_SUMMARY = "YTD Distribution Summary",
  CAP_CALL_LP_MEMOS = "Capital Call LP Memos",
  CAP_CALL_NOTICES = "Capital Call Notices",
  CAP_CALL_SCHEDULE_A = "Capital Call Schedule A",
  MANAGEMENT_FEE_NOTICES = "Management Fee Notices",
  ANNUAL_CERTS = "Annual Certifications",
  ANNUAL_MEETING_INFO = "Annual Meeting Information",
  CO_INVEST_PERC = "Co-Investment Percentages",
  CONSENTS = "Consents",
  CONTRACT_NOTES = "Contract Notes",
  ELECTIONS = "Elections",
  GP_CORRESPONDENCE = "GP Correspondence",
  INVESTMENT_ANNOUNCEMENTS = "Investment Announcements",
  LP_ID_CODE = "Limited Partner Identification Code",
  LP_CORRESPONDENCE = "LP Correspondence",
  PORT_UPDATES = "Portfolio Updates",
  PORT_VALUATION = "Portfolio Valuation",
  PRESS_RELEASES = "Press Releases",
  PRIVACY_NOTICE = "Privacy Notice",
  PRIVACY_CONSUMER_FIN_INFO_NOTICE = "Privacy of Consumer Financial Information Notice",
  TRANSFER_LP_INTEREST = "Transfer of Limited Partner Interest",
  VALUATION_POLICY = "Valuation Policy",
  ADV_BROCHURES = "ADV Brochures",
  AIFMD_DISCLOSURE = "AIFMD Disclosure",
  ERISA_OPINION = "ERISA Opinions",
  GEN_LEGAL_CORRESPONDENCE = "General Legal Correspondence",
  GUARANTEES = "Guarantees",
  INVESTMENT_ADVISORY_AGREEMENT = "Investment Advisory Agreement",
  LEGAL_OPINIONS = "Legal Opinions",
  LP_PARTNER_AGREEMENT_AMENDMENTS = "Limited Partnership Agreement Amendments",
  LP_AGREEMENTS = "Limited Partnership Agreements",
  PRIVATE_PLACEMENT_MEMORANDUM = "Private Placement Memorandum",
  PROSPECTUS = "Prospectus",
  PROXY_VOTING_POLICY = "Proxy Voting Policy",
  SUBSCRIPTION_DOC = "Subscription Document",
  SUSTAINABILITY_RELATED_DISCLOSURES = "Sustainability Related Disclosures",
  VCOC_OPINIONS = "VCOC Opinions",
  DUE_DILIGENCE_QUESTIONNAIRE = "Due Diligence Questionnaire",
  FINANCIAL_DILIGENCE = "Financial Diligence",
  FUND_DILIGENCE = "Fund Diligence",
  FUNDRAISING_MATERIALS = "Fundraising Materials",
  LEGAL_DOCS = "Legal Documents",
  LP_ADVISORY_COM_MATERIALS = "Limited Partner Advisory Committee Materials",
  OPERATIONAL_DILIGENCE = "Operational Diligence",
  BX_INVESTOR_STATEMENTS = "Blackstone Investor Statements",
  BSAF_LETTER = "BSAF Letter",
  CAPITAL_ACCOUNT_STATEMENT = "Capital Account Statements",
  COMMENTARY = "Commentary",
  CRED_COUNTER_PARTY_INFO = "Credit Counter Party Information",
  CUM_RETURNS_SUMMARY = "Cumulative Returns Summary",
  EQUITY_STATEMENT = "Equity Statement",
  FINAL_MONTH_END_PERF = "Final - Month End Performance",
  FINANCIAL_STATEMENTS = "Financial Statements",
  FUND_AUDIT = "Fund Audit",
  ILPA_FEE_REPORTING = "ILPA Fee Reporting",
  INVESTMENT_RECORDS = "Investment Records",
  PARTNER_STATEMENT = "Partner Statement",
  PERF_ESTIMATES = "Performance Estimates",
  PERF_FEE_SUMMARY = "Performance Fee Summary",
  PERF_SUMMARY_LETTER = "Performance Summary Letter",
  QUARTERLY_COM_REPORT = "Quarterly Commitments Report",
  STRAT_SUMMARY = "Strategy Summary",
  TRADE_CONFIRMATION = "Trade Confirmations",
  EIGHTY_THREE_B = "83-B",
  PFIC_DISCLOSURE = "Annual Passive Foreign Investment Companies PFIC Disclosure",
  CONVERSION_AND_SALE = "Conversion and Sale",
  TEN_NINETY_NINE = "Form 1099",
  TEN_NINETY_NINE_B = "Form 1099-B",
  TEN_NINETY_NINE_DIV = "Form 1099-DIV",
  TEN_NINETY_NINE_INT = "Form 1099-INT",
  FIFTY_FOUR_SEVENTY_ONE = "Form 5471",
  FIFTY_FIVE_HUNDRED = "Form 5500",
  EIGHTY_NINE_THIRTY_SEVEN = "Form 8937",
  NINE_TWENTY_SIX = "Form 926",
  NINE_TWENTY_SEVEN = "Form 972",
  GEN_TAX_CORRESPONDENCE = "General Tax Correspondence",
  HOLDINGS_BASIC = "Holdings Basis",
  HOLDINGS_EXCHANGE = "Holdings Exchange",
  HOLDINGS_PARTNER = "Holdings Partner",
  HOLDINGS_PARTNER_STATE = "Holdings Partner State",
  INTEREST_EXPENSE = "Interest Expense",
  QUARTERLY_ADVANCE_DIST = "Quarterly Advance and Distribution",
  K_ONE = "Schedule K-1",
  SEG_TRUST_STATEMENT = "Segregated Trust Statement",
  TAX_ESTIMATE = "Tax Estimate",
  TRA = "TRA Statement",
  UBT_CREDIT = "UBT Credit",
  WITHHOLDING_STATMENT = "Withholding Statement",
}

export enum DocumentTypeCategoryName {
  DISTRIBUTIONS = "Distributions",
  FUNDING = "Funding",
  GEN_CORRESPONDENCE = "General Correspondence",
  LEGAL = "Legal",
  MARKETING_MATERIALS = "Marketing Materials",
  REPORTING = "Reporting",
  TAX = "Tax",
}

export enum TaxDocumentStatus {
  ESTIMATE = "Estimate",
  FINAL = "Final",
}

export enum DocumentsSortBy {
  DOCUMENT_NAME = "documentName",
  PERIOD = "period",
  PUBLISHED_DATE = "publishedDate",
  EFFECTIVE_DATE = "effectiveDate",
  JURISDICTIONS = "jurisdictions",
  DOCUMENT_TYPE_NAME = "documentTypeName",
}

export enum SortDirection {
  ASC = "asc",
  DESC = "desc",
}

export enum PeriodHash {
  QUARTERLY = "quarterly",
  LATEST = "latest",
}

export enum ElectionStatus {
  READY_TO_START = 1,
  IN_PROGRESS = 2,
  READY_FOR_REVIEW = 3,
  SUBMITTED = 4,
  CLOSED = 5,
}

export const ElectionStatusName: { [key in ElectionStatus]: string } = {
  [ElectionStatus.READY_TO_START]: "Get Started",
  [ElectionStatus.IN_PROGRESS]: "Continue",
  [ElectionStatus.READY_FOR_REVIEW]: "Review",
  [ElectionStatus.SUBMITTED]: "Submitted",
  [ElectionStatus.CLOSED]: "Closed",
};

export const ElectionStatusTitle: { [key in ElectionStatus]: string } = {
  [ElectionStatus.READY_TO_START]: "Begin election process",
  [ElectionStatus.IN_PROGRESS]: "Continue election process",
  [ElectionStatus.READY_FOR_REVIEW]:
    "Review or revise election before deadline",
  [ElectionStatus.SUBMITTED]: "View submitted election",
  [ElectionStatus.CLOSED]: "No election submitted",
};

export enum ElectionDocumentType {
  UNKNOWN = 0,
  OVERVIEW = 1,
  STRATEGY_OVERVIEW = 2,
  FINANCING_OPTIONS = 3,
}

export enum TooltipPlacement {
  BOTTOM_START = "bottom-start",
  RIGHT = "right",
}

export enum ElectionRoundType {
  UNKNOWN = 0,
  NON_PROD_RATED = 1,
  PROD_RATED = 2,
  CARRY_CONFIRMATION = 3,
}

export enum CommitmentType {
  LIFE_OF_FUND = "LifeOfFund",
  ANNUAL = "Annual",
}

export enum unitTypes {
  CURRENCY = "currency",
  UNIT = "unit",
}
export enum ElectionStepperButton {
  SAVE = 0,
  BACK = 1,
  NEXT = 2,
}

export enum ElectionStepperButtonState {
  Hide,
  Show,
  Disable,
}
