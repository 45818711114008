import { Link } from "@mui/material";
import React from "react";

import { ErrorStringConstants } from "../../constants/ErrorStringConstants";
import styles from "./FailedToLoadError.module.scss";

/**
 * the link that will trigger a retry of a load when the button is pressed
 */
interface IFailedToLoadErrorProps {
  /** ActionCreator that, on dispatch, will request for and store data. */
  retryRequest?: () => void;
}

/**
 * Generic component used to signal a failure to load in a single section/card
 *  of BXWealth
 * @param props
 * @returns the failed to load component
 */
export const FailedToLoadError = (props: IFailedToLoadErrorProps) => {
  return (
    <div className={styles.errorMessage}>
      <div>{ErrorStringConstants.FAILED_TO_LOAD}</div>
      {props.retryRequest && (
        <Link
          component={"button"}
          underline="always"
          onClick={props.retryRequest}
        >
          {ErrorStringConstants.RETRY}
        </Link>
      )}
    </div>
  );
};
