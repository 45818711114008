import { unitTypes } from "../constants/enums";
import { EquityLabel } from "../constants/LabelAndTooltipConstants";
import { IRestrictedEquityValueProps } from "../features/Equity/VestingEvents/Callouts/Callout/RestrictedEquityCallout";

/**
 * Generates an array of equity values based on provided parameters.
 *
 * @param {string} unvestedTotal - The total unvested equity value.
 * @param {boolean} isMobile - Flag to indicate if the view is on a mobile device.
 * @param {string} unit - The unit type (e.g., currency, unit).
 * @param {boolean} showVestedExchangeable - Flag to indicate whether vested exchangeable equity should be shown.
 * @param {string[] | undefined} unvestedTooltipsParagraphs - Tooltip paragraphs for unvested equity.
 * @param {string | undefined} vestedRestrictedTotal - The total vested restricted equity value.
 * @param {string[] | undefined} vestedRestrictedTooltipsParagraphs - Tooltip paragraphs for vested restricted equity.
 * @param {string | undefined} vestedExchangeableTotal - The total vested exchangeable equity value.
 * @param {string[] | undefined} vestedExchangeableTooltipsParagraphs - Tooltip paragraphs for vested exchangeable equity.
 * @param {boolean | undefined} showTooltip - Flag to indicate whether tooltips should be shown.
 *
 * @returns {Array} An array of objects representing the equity values to be displayed.
 */
export const getEquityValues = (
  unvestedTotal: string,
  isMobile: boolean,
  unit: string,
  showVestedExchangeable: boolean,
  unvestedTooltipsParagraphs?: string[],
  vestedRestrictedTotal?: string,
  vestedRestrictedTooltipsParagraphs?: string[],
  vestedExchangeableTotal?: string,
  vestedExchangeableTooltipsParagraphs?: string[],
  showTooltip?: boolean
): IRestrictedEquityValueProps[] => {
  // Initialize an empty array to hold the equity values.
  const values: IRestrictedEquityValueProps[] = [];

  // Check if unvestedTotal is defined and not zero.
  if (
    unvestedTotal !== undefined &&
    unvestedTotal !== "0" &&
    unvestedTotal !== "$0"
  ) {
    // Add an object for unvested equity value to the values array.
    values.push({
      label: isMobile
        ? getMobileLabels(unit, EquityLabel.UNVESTED) // Use mobile-specific label if on mobile.
        : EquityLabel.UNVESTED, // Use standard label otherwise.
      value: unvestedTotal,
      tooltipsParagraphs: unvestedTooltipsParagraphs,
      showTooltip: showTooltip,
    });
  }

  // Check if vestedRestrictedTotal is defined and not zero.
  if (
    vestedRestrictedTotal !== undefined &&
    vestedRestrictedTotal !== "0" &&
    vestedRestrictedTotal !== "$0"
  ) {
    // Add an object for vested restricted equity value to the values array.
    values.push({
      label: isMobile
        ? getMobileLabels(unit, EquityLabel.VESTED_RESTRICTED) // Use mobile-specific label if on mobile.
        : EquityLabel.VESTED_RESTRICTED, // Use standard label otherwise.
      value: vestedRestrictedTotal,
      tooltipsParagraphs: vestedRestrictedTooltipsParagraphs,
      showTooltip: showTooltip,
    });
  }

  // Check if vested exchangeable equity should be shown and is defined and not zero.
  if (
    showVestedExchangeable &&
    vestedExchangeableTotal !== undefined &&
    vestedExchangeableTotal !== "0" &&
    vestedExchangeableTotal !== "$0"
  ) {
    // Add an object for vested exchangeable equity value to the values array.
    values.push({
      label: isMobile
        ? getMobileLabels(unit, EquityLabel.VESTED_EXCHANGEABLE) // Use mobile-specific label if on mobile.
        : EquityLabel.VESTED_EXCHANGEABLE, // Use standard label otherwise.
      value: vestedExchangeableTotal,
      tooltipsParagraphs: vestedExchangeableTooltipsParagraphs,
      showTooltip: showTooltip,
    });
  }

  // Return the array of equity values.
  return values;
};

export const getMobileLabels = (unit: string, label: string) => {
  switch (unit) {
    case unitTypes.CURRENCY:
      return `${label} value`;
    case unitTypes.UNIT:
      return `${label} units`;
    default:
      return label;
  }
};
