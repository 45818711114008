import { FileDownloadOutlined } from "@mui/icons-material";
import { CardHeader, Grid, Typography } from "@mui/material";
import {
  CardFooter,
  CommitmentsLabels,
  getAsOfLine,
  ICommitmentsLOF,
  IconButtonWithTooltip,
  SectionHeader,
  StringConstants,
  useExcelDownload,
  worksheetGenerators,
} from "common";
import React from "react";

import { LifeOfFundsGrid } from "./LifeOfFundCommitmentsGrid/LifeOfFundCommitmentsGrid";

interface ILifeOfFundsProps {
  lifeOfFundCommitments: ICommitmentsLOF;
}

export const LifeOfFundCommitments = (props: ILifeOfFundsProps) => {
  const { lifeOfFundCommitments } = props;

  const handleExcelDownload = useExcelDownload();
  const handleDownloadClick = async () => {
    const fileName = `${SectionHeader.LIFE_OF_FUNDS}`;
    await handleExcelDownload(
      worksheetGenerators.LifeOfFundCommitments,
      fileName
    );
  };

  return (
    <>
      <CardHeader
        title={
          <Typography variant="h3">{SectionHeader.LIFE_OF_FUNDS}</Typography>
        }
        subheader={
          <Typography variant="subtitle1">
            {getAsOfLine(lifeOfFundCommitments.asOfDate)}
          </Typography>
        }
        action={
          <IconButtonWithTooltip
            onClickFunction={handleDownloadClick}
            buttonIcon={FileDownloadOutlined}
            hoverText={StringConstants.DOWNLOAD}
          />
        }
      />
      <Grid container flex="column">
        <LifeOfFundsGrid
          lifeOfFundCommitments={lifeOfFundCommitments.commitments}
        />
      </Grid>
      <CardFooter footerLines={[CommitmentsLabels.COMMITMENTS_FOOTNOTE]} />
    </>
  );
};
