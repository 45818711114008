import React from "react";
import { default as ReactmarkDown } from "react-markdown";
import rehypeExternalLinks from "rehype-external-links";

export const Markdown = ({ text }: { text: string }) => {
  return (
    <ReactmarkDown
      rehypePlugins={[[rehypeExternalLinks, { target: "_blank" }]]}
    >
      {text}
    </ReactmarkDown>
  );
};
