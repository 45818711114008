import {
  ALL_NAME_PLACEHOLDER,
  getCurrencyFormattedValueWithZeroDefault,
  UnrealizedValueLabels,
} from "common";
import React from "react";
import {
  NameType,
  Payload,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import styles from "./InvestmentChart.module.scss";

export const InvestmentChartTooltip = ({
  payload,
}: {
  payload: Payload<ValueType, NameType>[];
}) => {
  return (
    <div className={styles.tooltip}>
      <div>
        <ul className={styles.itemList}>
          {payload &&
            payload.map((item: Payload<ValueType, NameType>) => {
              const unrealizedValue = item.payload?.unrealizedValue;
              const remainingCapitalExists =
                unrealizedValue?.remainingCapitalInvested !== 0;
              const gainLossExists = unrealizedValue?.gainLoss !== 0;
              return (
                <li key={item.name} className={styles.item}>
                  <span
                    className={`${styles.tooltipDataRow} ${styles.tooltipLabel}`}
                  >
                    {item.payload.fundShortName !== ALL_NAME_PLACEHOLDER
                      ? item.payload.fundShortName
                      : item.payload.businessUnitName}
                  </span>
                  {remainingCapitalExists && (
                    <div className={styles.tooltipDataRow}>
                      <span className={styles.tooltipLabel}>
                        {UnrealizedValueLabels.REMAINING_CAPITAL_INVESTED}
                      </span>
                      <span className={styles.itemValue}>
                        {getCurrencyFormattedValueWithZeroDefault(
                          unrealizedValue?.remainingCapitalInvested as number
                        )}
                      </span>
                    </div>
                  )}
                  {gainLossExists && (
                    <div className={styles.tooltipDataRow}>
                      <span className={styles.tooltipLabel}>
                        {UnrealizedValueLabels.GAIN_LOSS}
                      </span>
                      <span className={styles.itemValue}>
                        {getCurrencyFormattedValueWithZeroDefault(
                          unrealizedValue?.gainLoss as number
                        )}
                      </span>
                    </div>
                  )}
                  {remainingCapitalExists && gainLossExists && (
                    <div
                      className={`${styles.tooltipDataRow} ${styles.tooltipTotalData}`}
                    >
                      <span className={styles.tooltipLabel}>
                        {UnrealizedValueLabels.TOTAL}
                      </span>
                      <span className={styles.itemValue}>
                        {getCurrencyFormattedValueWithZeroDefault(
                          unrealizedValue?.optionalAndMandatoryInvestments as number
                        )}
                      </span>
                    </div>
                  )}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};
