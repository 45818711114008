import { createTheme } from "@mui/material";

export const Font = {
  Family: {
    GUARDIAN_SANS: `"Guardian Sans", sans-serif`,
  },
  Size: {
    STANDARD: "14px",
  },
  Weight: {
    LIGHT: "300",
    STANDARD: "400",
    MEDIUM: "500",
    SEMI_BOLD: "600",
    BOLD: "900",
  },
};

// Theme to set breakpoints for mobile
export const breakpointsTheme = createTheme({});
