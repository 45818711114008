export const ElectionResultConstants = {
  COMMA_SEPARATED_DOLLAR: "$#,##0",
  SIZES: {
    BIG: 35,
    MEDIUM: 25,
    SMALL: 15,
  },
  KEYS: {
    IV_ID: "ivId",
    IV_NAME: "ivName",
    IS_ACCREDITED: "isAccredited",
    IS_ACCREDITED_INDIVIDUAL_NET: "isAccreditedIndividualNet",
    IS_ACCREDITED_INDIVIDUAL: "isAccreditedIndividual",
    IS_ACCREDITED_PRO: "isAccreditedProfessional",
    IS_QUALIFIED: "isQualified",
    IS_QUALIFIED_INDIVIDUAL_NET: "isQualifiedIndividualNet",
    IS_QUALIFIED_COMPANY: "isQualifiedCompany",
    IS_QUALIFIED_TRUST: "isQualifiedTrust",
    IS_QUALIFIED_INDIVIDUAL: "isQualifiedIndividual",
    NOT_QUALIFIED_ACCREDITED: "notQualifiedAccredited",
    CAN_REALLOCATE: "canReallocate",
    STRATEGY_NAME: "strategyName",
    OFFERED_AMOUNT: "offeredAmount",
    ADDITIONAL_LIMIT: "additionalLimit",
    STRATEGY_MAX: "strategyMax",
    ELECTED_AMOUNT: "electedAmount",
    ADDITIONAL_REQUESTED: "additionalRequested",
    OVER_MAX: "overMax",
    IS_SUBMITTED: "isSubmitted",
    SUBMIT_DATE: "submitDate",
    USER_EMAIL: "userEmail",
    USER_NAME: "userName",
    USER_UPN: "userUPN",
  },
};
