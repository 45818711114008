import { ThemeOptions } from "@mui/material";

import { Font } from "../sharedStyleConstants";

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: {
          fontFamily: Font.Family.GUARDIAN_SANS,
          fontWeight: Font.Weight.LIGHT,
        },
      },
    },
  },
};

export const CustomDayCalendarTheme = partialTheme.components?.MuiDayCalendar;
