import "ag-grid-enterprise";

import { GroupCellRendererParams } from "ag-grid-enterprise";
import React from "react";

import { StockSymbol } from "../../constants/enums";
import { StockChip } from "../StockChip/StockChip";

export const TickerCellRenderer = (props: GroupCellRendererParams) => {
  return <StockChip stockSymbol={props.value as StockSymbol} />;
};
