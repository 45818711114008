import { ThemeOptions } from "@mui/material";

import { Font } from "../sharedStyleConstants";

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiPickersCalendarHeader: {
      styleOverrides: {
        label: {
          fontFamily: Font.Family.GUARDIAN_SANS,
          fontWeight: Font.Weight.STANDARD,
        },
      },
    },
  },
};

export const CustomPickersCalendarHeaderTheme =
  partialTheme.components?.MuiPickersCalendarHeader;
