import {
  ElectionStepperButton,
  ElectionStepperButtonState,
} from "../constants/enums";
import { ElectionWorkflowStageId } from "../types/electionDataTypes";

export const ELECTION_STEPPER_BUTTON_CONSTRAINTS: Partial<
  Record<
    ElectionWorkflowStageId,
    Partial<Record<ElectionStepperButton, ElectionStepperButtonState>>
  >
> = {
  [ElectionWorkflowStageId.OVERVIEW]: {
    [ElectionStepperButton.SAVE]: ElectionStepperButtonState.Hide,
    [ElectionStepperButton.BACK]: ElectionStepperButtonState.Hide,
  },
  [ElectionWorkflowStageId.BANK_ACCOUNT]: {
    [ElectionStepperButton.SAVE]: ElectionStepperButtonState.Hide,
  },
};

export const ELECTION_STEPPER_BUTTON_CLASSNAMES: Record<
  ElectionStepperButtonState,
  string
> = {
  [ElectionStepperButtonState.Hide]: "hiddenButton",
  [ElectionStepperButtonState.Show]: "visibleButton",
  [ElectionStepperButtonState.Disable]: "visibleButton",
};
