import { ThemeOptions } from "@mui/material";

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: 0,
        },
      },
    },
  },
};

export const CustomIconButtonTheme = partialTheme.components?.MuiIconButton;
