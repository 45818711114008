import { createSelector } from "@reduxjs/toolkit";

import { ElectionDocumentType } from "../../constants/enums";
import {
  ElectionWorkflowStageId,
  ElectionWorkflowStageIdToKey,
  IElectionDocument,
  IElectionInvestmentPortfolio,
  IElectionsForClient,
  IElectionsForClientListItem,
} from "../../types/electionDataTypes";
import { isSomething } from "../../types/typeGuards";
import { nothing, Optional, some } from "../../types/typeUtils";
import { isInProgress } from "../../utils/dataLoadUtils";
import { mapElectionRoundConfigurationToModel } from "../../utils/electionsUtils";
import { IBaseStore } from "../store";

const selectElectionsForClient = (state: IBaseStore) => ({
  electionsForClient: state.electionsData.electionsForClient,
  electionsForClientLoadStatus:
    state.electionsData.electionsForClientLoadStatus,
});

export const selectActiveElectionClient = (state: IBaseStore) =>
  state.electionsData.activeElectionClient;
export const selectActiveElectionClientId = (state: IBaseStore) =>
  isSomething(state.electionsData.activeElectionClient)
    ? some(state.electionsData.activeElectionClient.value.clientId)
    : nothing;

export const selectOptionalElectionWorkflowStateLocal = (state: IBaseStore) =>
  state.electionsData.electionWorkflowStateLocal;
export const selectOptionalElectionRoundConfiguration = (state: IBaseStore) =>
  state.electionsData.electionRoundConfiguration;
export const selectOptionalElectionIVConfiguration = (state: IBaseStore) =>
  state.electionsData.electionIVConfiguration;
export const selectOptionalElectionInvestmentPortfolio = (state: IBaseStore) =>
  state.electionsData.electionInvestmentPortfolio;
export const selectElectionWorkflowStageOrder = (state: IBaseStore) =>
  state.electionsData.electionWorkflowStageOrder;
export const selectActiveElection = (state: IBaseStore) =>
  state.electionsData.activeElection;

export const selectElectionWorkflowStateLocal = createSelector(
  selectOptionalElectionWorkflowStateLocal,
  (workflowState) => {
    if (!isSomething(workflowState)) {
      throw Error(
        "Selector must be used after Local Election Workflow State is loaded"
      );
    }
    return workflowState.value;
  }
);

export const selectElectionRoundConfiguration = createSelector(
  selectOptionalElectionRoundConfiguration,
  (configuration) => {
    if (!isSomething(configuration)) {
      throw Error(
        "Selector must be used after Election Round Configuration is loaded"
      );
    }

    return mapElectionRoundConfigurationToModel(configuration.value);
  }
);

export const selectUnrealizedValues = createSelector(
  selectOptionalElectionInvestmentPortfolio,
  (electionInvestmentPortfolio: Optional<IElectionInvestmentPortfolio>) => {
    if (!isSomething(electionInvestmentPortfolio)) {
      throw Error(
        "Selector must be used after Election Investment Portfolio is loaded"
      );
    }
    return electionInvestmentPortfolio.value.unrealizedValuesData;
  }
);

export const selectPriorElections = createSelector(
  selectOptionalElectionInvestmentPortfolio,
  (electionInvestmentPortfolio: Optional<IElectionInvestmentPortfolio>) => {
    if (!isSomething(electionInvestmentPortfolio)) {
      throw Error(
        "Selector must be used after Election Investment Portfolio is loaded"
      );
    }
    return electionInvestmentPortfolio.value.priorElectionsData;
  }
);

export const selectElectionInvestmentPortfolioLoadStatus = (
  state: IBaseStore
) => state.electionsData.electionInvestmentPortfolioLoadStatus;

export const selectElectionIVConfiguration = createSelector(
  selectOptionalElectionIVConfiguration,
  (ivConfiguration) => {
    if (!isSomething(ivConfiguration)) {
      throw Error(
        "Selector must be used after Election IV Configuration is loaded"
      );
    }
    return ivConfiguration.value;
  }
);

export const selectLegalAttestation = createSelector(
  selectElectionRoundConfiguration,
  (electionRoundConfiguration) => {
    return electionRoundConfiguration.stages.overview.legalAttestation;
  }
);

export const selectOverviewStage = createSelector(
  selectElectionWorkflowStateLocal,
  (electionWorkflowState) => {
    return electionWorkflowState.electionStages.overview;
  }
);

export const selectReallocationState = createSelector(
  selectElectionWorkflowStateLocal,
  (electionWorkflowState) => {
    return electionWorkflowState.electionStages.elect.useReallocation;
  }
);

export const selectReallocationTooltip = createSelector(
  selectElectionRoundConfiguration,
  (electionRoundConfiguration) => {
    return electionRoundConfiguration.stages.elect.reallocationTooltip;
  }
);

export const selectPossibleNextStageId = createSelector(
  selectElectionWorkflowStateLocal,
  selectElectionWorkflowStageOrder,
  (electionWorkflowState, electionWorkflowStageOrder) => {
    const currentStageIndex = electionWorkflowStageOrder.indexOf(
      electionWorkflowState.currentStage
    );
    return currentStageIndex >= electionWorkflowStageOrder.length - 1
      ? ElectionWorkflowStageId.COMPLETED
      : electionWorkflowStageOrder[currentStageIndex + 1];
  }
);

export const selectCurrentStageId = createSelector(
  selectOptionalElectionWorkflowStateLocal,
  (electionWorkflowState) => {
    if (!isSomething(electionWorkflowState)) {
      return ElectionWorkflowStageId.OVERVIEW;
    }
    return electionWorkflowState.value.currentStage;
  }
);

const activeStageId = (
  state: IBaseStore,
  activeStageId: ElectionWorkflowStageId
) => activeStageId;

export const selectCurrentStageConfiguration = createSelector(
  [selectOptionalElectionRoundConfiguration, activeStageId],
  (electionRoundConfiguration, activeStageId) => {
    if (!isSomething(electionRoundConfiguration)) {
      return nothing;
    }

    const stageKey = ElectionWorkflowStageIdToKey[activeStageId];

    if (stageKey) {
      const config = electionRoundConfiguration.value.stages[stageKey];
      if (config) {
        return some(config);
      }
    }
    return nothing;
  }
);

export const selectFinancingOptionsDocumentId = createSelector(
  selectElectionRoundConfiguration,
  (electionRoundConfiguration) => {
    return electionRoundConfiguration.documents.find(
      (doc) => doc.type === ElectionDocumentType.FINANCING_OPTIONS
    )?.id;
  }
);

export const selectCanUseFinancing = createSelector(
  selectElectionIVConfiguration,
  (ivConfiguration) => ivConfiguration.canUseFinancing
);

export const selectOfferAmountTooltip = createSelector(
  selectElectionRoundConfiguration,
  (electionRoundConfiguration) => {
    return electionRoundConfiguration.stages.overview.offerAmountTooltip;
  }
);

export const selectOfferedAmount = createSelector(
  selectElectionIVConfiguration,
  (ivConfiguration) => ivConfiguration.offeredAmount
);

export const selectElectStage = createSelector(
  selectElectionWorkflowStateLocal,
  (electionWorkflowState) => {
    return electionWorkflowState.electionStages.elect;
  }
);

export const selectElectionRoundDocuments = createSelector(
  [
    selectOptionalElectionRoundConfiguration,
    selectOptionalElectionIVConfiguration,
  ],
  (electionRoundConfiguration, ivConfig) => {
    if (!isSomething(electionRoundConfiguration) || !isSomething(ivConfig)) {
      return [];
    }

    if (ivConfig.value.canUseFinancing) {
      return electionRoundConfiguration.value.documents;
    }

    return electionRoundConfiguration.value.documents.filter(
      (document: IElectionDocument) =>
        document.type !== ElectionDocumentType.FINANCING_OPTIONS
    );
  }
);

export const selectFinancingPercent = createSelector(
  selectElectionIVConfiguration,
  (ivConfiguration) => ivConfiguration.financingPercentage
);

export const selectExistingLoanBalance = createSelector(
  selectElectionIVConfiguration,
  (ivConfiguration) => ivConfiguration.loanBalance
);

export const selectLoanLimit = createSelector(
  selectElectionIVConfiguration,
  (ivConfiguration) => ivConfiguration.loanLimit
);

export const selectInvestmentVehicles = createSelector(
  selectElectionsForClient,
  ({ electionsForClient, electionsForClientLoadStatus }) => {
    return isInProgress(electionsForClientLoadStatus)
      ? new Set()
      : new Set(
          electionsForClient.map((x) => x.investmentVehicle.investmentVehicleId)
        );
  }
);

export const selectSortedElections = createSelector(
  selectElectionsForClient,
  ({ electionsForClient, electionsForClientLoadStatus }) => {
    if (isInProgress(electionsForClientLoadStatus)) {
      return { sortedElections: [], electionsForClientLoadStatus };
    }

    const sortedElections = electionsForClient
      .map((x: IElectionsForClient) => ({
        ...x,
        electionSubmissionDeadline: isSomething(x.electionSubmissionDeadline)
          ? x.electionSubmissionDeadline.value
          : x.systemCloseDate,
        electedAmount:
          x.currentStage === ElectionWorkflowStageId.COMPLETED
            ? x.electedAmount
            : 0,
      }))
      .sort(
        (x: IElectionsForClientListItem, y: IElectionsForClientListItem) => {
          if (
            x.electionSubmissionDeadline.getTime() ===
            y.electionSubmissionDeadline.getTime()
          ) {
            return (
              x.name.localeCompare(y.name) ||
              x.investmentVehicle.name.localeCompare(y.investmentVehicle.name)
            );
          }
          return x.electionSubmissionDeadline > y.electionSubmissionDeadline
            ? -1
            : 1;
        }
      );

    return { sortedElections, electionsForClientLoadStatus };
  }
);

export const selectHasIVMoreThanOneStrategy = createSelector(
  selectElectionIVConfiguration,
  (ivConfiguration) => ivConfiguration.strategies.length > 1
);

export const selectHasUSBankAccount = createSelector(
  selectElectionWorkflowStateLocal,
  (electionWorkflowState) => {
    return electionWorkflowState.electionStages.bankAccount.hasUSBankAccount;
  }
);
