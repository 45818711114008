import {
  CalendarMonthOutlined,
  FileDownloadOutlined,
} from "@mui/icons-material";
import { MenuItem, Select, Stack } from "@mui/material";
import {
  getLabelFromCamelCase,
  IconButtonWithTooltip,
  IHistoricalSummaryDatum,
  InvestmentHistoryValueItem,
  InvestmentValueDisplay,
  PeriodType,
  StringConstants,
  useExcelDownload,
  WorksheetGeneratorName,
  worksheetGenerators,
} from "common";
import React from "react";

import styles from "./PeriodToggle.module.scss";

interface PeriodToggleProps {
  tabSelected: WorksheetGeneratorName;
  periodType: PeriodType;
  setPeriodType: (value: PeriodType) => void;
  dataTypes: InvestmentHistoryValueItem[];
  setDataTypes: (value: InvestmentHistoryValueItem[]) => void;
  valueDisplay: InvestmentValueDisplay;
  historicalSummaryData: IHistoricalSummaryDatum[];
}

const periodTypeOrder = [PeriodType.QUARTERLY, PeriodType.YEARLY];

export interface ExcelRow {
  [key: string]: string | number;
}

export const PeriodToggle = (props: PeriodToggleProps) => {
  const { valueDisplay, periodType, tabSelected } = props;

  const handleExcelDownload = useExcelDownload();
  const handleDownloadClick = async () => {
    const generator = worksheetGenerators[tabSelected];
    const fileName = `Investment History_${getLabelFromCamelCase(
      props.tabSelected
    )}`;
    await handleExcelDownload(generator, fileName, {
      valueDisplay: valueDisplay,
      periodType,
    });
  };

  return (
    <Stack direction="row" alignItems="center" className={styles.periodToggle}>
      <Select
        className={styles.periodSelector}
        onChange={(e) => props.setPeriodType(e.target.value as PeriodType)}
        renderValue={(value) => {
          return (
            <div className={styles.periodSelectorInput}>
              <CalendarMonthOutlined className={styles.calendar} />
              <span className={styles.selectorText}>{value}</span>
            </div>
          );
        }}
        value={props.periodType}
      >
        {periodTypeOrder.map((e: string, i: number) => (
          <MenuItem key={i} value={e}>
            {e}
          </MenuItem>
        ))}
      </Select>
      <IconButtonWithTooltip
        buttonIcon={FileDownloadOutlined}
        hoverText={StringConstants.DOWNLOAD}
        onClickFunction={() => handleDownloadClick()}
        disabled={
          props.historicalSummaryData === undefined ||
          props.historicalSummaryData.length === 0
        }
      />
    </Stack>
  );
};
