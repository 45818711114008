// TODO: this file in general has portions of the data models
//  we will need for bank accounts. These models are not yet complete
//  but can be added to as more dev work is done

import { ClientId } from "./electionDataTypes";
import { Nothing, Optional } from "./typeUtils";

export interface IBankAccountsCapabilities {
  clientCapabilities: BankClientCapabilityMap;
  globalCapabilities: string[];
}

export type BankClientCapabilityMap = {
  [clientId: ClientId]: IBankAccountClient;
};

export interface IBankAccountClient {
  clientName: string;
  investmentVehicles: IBankAccountInvestmentVehicle[];
}

export interface IBankAccountInvestmentVehicle {
  investmentVehicleId: number;
  name: string;
  capabilities: string[];
}

export interface IBankAccountsEntitlements {
  globalCapabilities: string[];
  permittedBankAccountClients: IBankAccountPermittedClient[];
}

export interface IBankAccountPermittedClient {
  clientId: ClientId;
  clientName: string;
  investmentVehicles: IBankAccountInvestmentVehicle[];
}

export interface BankAccountsResponse {
  bankAccounts: GPBankAccount[];
  pendingBankAccounts: GPPendingBankAccount[];
}

export interface GPBankAccount {
  investmentVehicle: NamedEntity;
  bankAccount: WSSBankAccount;
}

export interface WSSBankAccount {
  main: WSSBankAccountDetail;
  intermediary: Optional<WSSBankAccountDetail>;
  assignments: BankAccountAssignment[];
}

export interface IBankAccount {
  bankName: string;
  bankId: string;
  bankIdType: BankIdType;
  accountType: AccountType;
  accountHolderName: string;
  accountNumber: string;
  accountNumberType: AccountNumberType;
  country: string;
  currency: string;
}

export type WSSBankAccountDetail = IBankAccount & {
  bankAccountUniqueId: string;
  routingNumber: string;
  swiftCode: string;
  lastModified: Date;
  paymentRule: string;
};

export interface NamedEntity {
  id: number;
  name: string;
}

export enum BankAccountAssignment {
  _ = "_", // Not an actual Bank Account Assignment type, used as way to get around AgGrid limitations and display a custom row
  CONTRIBUTION = "Contribution",
  DISTRIBUTION = "Distribution",
}

export enum AccountType {
  CHECKING = "Checking",
  SAVINGS = "Savings",
}

export enum AccountIntermediaryType {
  INTERMEDIARY = "Intermediary",
  CORRESPONDENT = "Correspondent",
}

export enum AccountNumberType {
  ACCOUNT_NUMBER = "Account Number",
  IBAN = "IBAN",
  BBAN = "BBAN",
}

export enum BankIdType {
  ABA_ROUTING_NUMBER = "ABA/Routing Number",
  SWIFT = "SWIFT",
  SORT = "Sort",
  OTHER = "Other Routing Codes",
}

export interface GPPendingBankAccount {
  investmentVehicle: NamedEntity;
  main: PendingBankAccountDetail;
  intermediary: Optional<PendingBankAccountDetail>;
  assignments: BankAccountAssignment[];
  effectiveDate: Optional<Date>;
  requestId: Optional<number>;
}

export interface UserAddedBankAccount {
  main: IBankAccount;
  intermediary: IntermediaryBankAccount | Nothing;
  assignment: BankAccountAssignment;
  eligibleForBothAssignments: boolean;
}

export type PendingBankAccountDetail = IBankAccount;

export type IntermediaryBankAccount = Pick<
  IBankAccount,
  | "accountHolderName"
  | "accountNumberType"
  | "accountNumber"
  | "bankIdType"
  | "bankName"
  | "bankId"
> & {
  accountType: AccountIntermediaryType;
};

export interface ICountry {
  id: number;
  name: string;
  countryCode: string;
}

export interface ICurrency {
  id: number;
  name: string;
  currencyCode: string;
  currencySymbol: string;
}
