import { CircularProgress } from "@mui/material";
import React from "react";

import styles from "./LoadingIndicator.module.scss";

interface LoadingIndicatorProps {
  className?: string;
  message?: string;
  size?: number | string;
}

export const LoadingIndicator = (props: LoadingIndicatorProps) => {
  const finalClassName = props.className
    ? `${props.className} ${styles.loading}`
    : styles.loading;

  return (
    <div className={finalClassName}>
      <CircularProgress size={props.size} />
      {props.message && <p className={styles.message}>{props.message}</p>}
    </div>
  );
};
