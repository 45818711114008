import { ColDef } from "ag-grid-community";

// this config essentially prevents all user interactivity
export function getDefaultColumnDef<T>(): ColDef<T> {
  return {
    // prevent user column resize
    resizable: false,
    filter: false,
    minWidth: 110,
    menuTabs: [],
    sortable: false,
    suppressMovable: true,
    suppressHeaderMenuButton: true,
  };
}
