import { Breadcrumbs, Grid, Typography } from "@mui/material";
import {
  ALL_FUNDS_PLACEHOLDER,
  createCarryChartList,
  filterToPositiveCarryByBusinessUnit,
  filterToPositiveCarryByFund,
  HoverableChartLegends,
  ICarryCardData,
  ICarryChartData,
  IDashboardCardContentProps,
  IInvestmentBreakdownDatum,
  isSomething,
  mapCarryDataToChartLegends,
  nothing,
  Optional,
  some,
  sortAndReduceCarryChartData,
  TreeNumber,
  UnrealizedValueLabels,
} from "common";
import React, { useCallback, useMemo, useState } from "react";

import styles from "./Carry.module.scss";
import { CarryChart } from "./CarryChart/CarryChart";

export const Carry = (props: IDashboardCardContentProps<ICarryCardData>) => {
  const { investmentBreakdownGrouped, calloutData } = props.data;
  const [selectedBusinessUnit, setSelectedBusinessUnit] =
    useState<Optional<ICarryChartData>>(nothing);
  //Set unrealized carry value
  const unrealizedCarry = isSomething(calloutData.unrealized)
    ? calloutData.unrealized.value.unrealizedCarry.value
    : undefined;

  const carryCardChartData: ICarryChartData[] | undefined = useMemo(() => {
    if (isSomething(investmentBreakdownGrouped)) {
      let filtererdInvestments: IInvestmentBreakdownDatum[] = [];

      if (isSomething(selectedBusinessUnit)) {
        /*
          When there's a business unit selected, we should show
          investments grouped by funds for that selected 
          business unit.
        */
        filtererdInvestments = filterToPositiveCarryByFund(
          investmentBreakdownGrouped.value[TreeNumber.BUSINESS_UNIT],
          false,
          selectedBusinessUnit.value.businessUnitName
        );
      } else {
        /*
          When there's not business unit selected, we should show
          investments grouped by business unit level.
        */
        filtererdInvestments = filterToPositiveCarryByBusinessUnit(
          investmentBreakdownGrouped.value[TreeNumber.BUSINESS_UNIT]
        );
      }
      // Convert IInvestmentBreakdownDatum list into ICarryChartData list
      const carryChartList = createCarryChartList(filtererdInvestments);
      /* 
        Sort and reduce the data to show the top 5 and all the remaining
        as 'Other' category
      */
      return sortAndReduceCarryChartData(
        carryChartList,
        isSomething(selectedBusinessUnit)
      );
    }
  }, [investmentBreakdownGrouped, selectedBusinessUnit]);

  /*
    handleSliceClick function handles the click on pie slices
    On business unit level, we should show data grouped by funds.
    On funds level, the slice click shouldn't change anything.
  */
  const handleSliceClick = useCallback((carryChartData: ICarryChartData) => {
    if (carryChartData.mdmFundId === ALL_FUNDS_PLACEHOLDER) {
      setSelectedBusinessUnit(some(carryChartData));
    }
  }, []);

  /*
    handleLegendClick function handles the click on left side legends
    On business unit level, we should show data grouped by funds.
    On funds level, the legends shouldn't change anything.
  */
  const handleLegendClick = useCallback(
    (businessUnitName: string) => {
      const carryChartData = carryCardChartData?.find(
        (data) => data.businessUnitName === businessUnitName
      );
      if (
        carryChartData &&
        carryChartData.mdmFundId === ALL_FUNDS_PLACEHOLDER
      ) {
        setSelectedBusinessUnit(some(carryChartData));
      }
    },
    [carryCardChartData]
  );

  /*
    handleAllBusinessClick function handles the click on
    "All Business Unit" navigation crumb.
  */
  const handleAllBusinessClick = () => {
    if (isSomething(selectedBusinessUnit)) {
      setSelectedBusinessUnit(nothing);
    }
  };

  return (
    <Grid container className={styles.container}>
      <Grid item xs={12} md={4} className={styles.legends}>
        <Breadcrumbs className={styles.breadcrumbs}>
          <span onClick={handleAllBusinessClick}>
            <Typography variant="subtitle1">
              {UnrealizedValueLabels.ALL_BUSINESS_UNITS}
            </Typography>
          </span>
          {isSomething(selectedBusinessUnit) && (
            <Typography variant="subtitle1" className={styles.funds}>
              {selectedBusinessUnit.value.businessUnitName}
            </Typography>
          )}
        </Breadcrumbs>
        {carryCardChartData && carryCardChartData.length > 0 && (
          <HoverableChartLegends
            legends={mapCarryDataToChartLegends(
              carryCardChartData,
              handleLegendClick,
              isSomething(selectedBusinessUnit)
            )}
          />
        )}
      </Grid>
      <Grid item xs={12} md={8} className={styles.chartContainer}>
        <CarryChart
          carryChartData={carryCardChartData ?? []}
          unrealizedCarry={
            isSomething(selectedBusinessUnit)
              ? selectedBusinessUnit.value.totalUnrealized
              : unrealizedCarry
          }
          handleSliceClick={handleSliceClick}
        />
      </Grid>
    </Grid>
  );
};
