import { FileDownloadOutlined } from "@mui/icons-material";
import { CardHeader, Grid, Typography } from "@mui/material";
import {
  CardFooter,
  CommitmentsLabels,
  getAsOfLine,
  ICommitmentsAnnual,
  IconButtonWithTooltip,
  SectionHeader,
  StringConstants,
  useExcelDownload,
  worksheetGenerators,
} from "common";
import React from "react";

import { AnnualCommitmentsGrid } from "./AnnualCommitmentsGrid/AnnualCommitmentsGrid";

interface IAnnualCommitmentsProps {
  annualCommitments: ICommitmentsAnnual;
}

export const AnnualCommitments = (props: IAnnualCommitmentsProps) => {
  const { annualCommitments } = props;

  const handleExcelDownload = useExcelDownload();
  const handleDownloadClick = async () => {
    const fileName = `${SectionHeader.ANNUAL_COMMITMENTS}`;
    await handleExcelDownload(worksheetGenerators.AnnualCommitments, fileName);
  };

  return (
    <>
      <CardHeader
        title={
          <Typography variant="h3">
            {SectionHeader.ANNUAL_COMMITMENTS}
          </Typography>
        }
        subheader={
          <Typography variant="subtitle1">
            {getAsOfLine(annualCommitments.asOfDate)}
          </Typography>
        }
        action={
          <IconButtonWithTooltip
            onClickFunction={handleDownloadClick}
            buttonIcon={FileDownloadOutlined}
            hoverText={StringConstants.DOWNLOAD}
          />
        }
      />
      <Grid container flex="column">
        <AnnualCommitmentsGrid
          annualCommitments={annualCommitments.commitments}
        />
      </Grid>

      <CardFooter footerLines={[CommitmentsLabels.COMMITMENTS_FOOTNOTE]} />
    </>
  );
};
