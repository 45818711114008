export enum RelativePath {
  HOME = "/",
  LOGIN = "/login",
  LANDING_PAGE = "/client-data",
  ELECTIONS_ADMIN = "/internal-elections-admin",
  EQUITY_LANDING_PAGE = "/employee-data",
  DASHBOARD = "/client-data/:clientId/dashboard",
  INVESTMENTS_AND_CARRY = "/client-data/:clientId/investments-and-carry",
  COMMITMENTS = "/client-data/:clientId/commitments",
  EQUITY = "/employee-data/:employeeId/equity",
}

export enum SearchParam {
  FROM_LOGOUT = "fromLogout",
  ERROR = "error",
}
