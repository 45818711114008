import { createAction } from "@reduxjs/toolkit";

import { DataLoadStatus } from "../../constants/enums";
import { IBankAccountsEntitlements } from "../../types/bankAccountDataTypes";
import { IPermittedEntities } from "../../types/dataTypes";
import { AdminEntitlementSummary, IAlert } from "../../types/storeTypes";
import { Optional } from "../../types/typeUtils";

type OpenAlertPayload = Omit<IAlert, "open">;
export const openAlert = createAction<OpenAlertPayload>("OPEN_ALERT");
export const closeAlert = createAction("CLOSE_ALERT");

export const errPageLoad = createAction<number>("ERR_PAGE_LOAD");

export const reqAllEntitlements = createAction("REQ_ALL_ENTITLEMENTS");

export const recvEquityEntitlement = createAction<string[]>(
  "RECV_EQUITY_ENTITLEMENT"
);
export const errEquityEntitlements = createAction<DataLoadStatus>(
  "ERR_EQUITY_DATA_ENTITLEMENTS"
);

export const recvStockEntitlement = createAction<boolean>(
  "RECV_STOCK_DATA_ENTITLEMENT"
);
export const errStockEntitlements = createAction<DataLoadStatus>(
  "ERR_STOCK_DATA_ENTITLEMENTS"
);

export const recvDocumentsEntitlement = createAction<Optional<boolean>>(
  "RECV_DOCUMENTS_ENTITLEMENT"
);
export const errDocumentsEntitlements = createAction<DataLoadStatus>(
  "ERR_DOCUMENTS_ENTITLEMENTS"
);

// Commitments Entitlements
export const recvCommitmentsEntitlement = createAction<Optional<boolean>>(
  "RECV_COMMITMENTS_ENTITLEMENT"
);

export const errCommitmentsEntitlements = createAction<DataLoadStatus>(
  "ERR_COMMITMENTS_ENTITLEMENTS"
);

/* new internal investment api actions */
export const recvInternalInvestmentDataPermittedEntities = createAction<
  Optional<IPermittedEntities>
>("RECV_INTERNAL_INVESTMENT_DATA_PERMITTED_ENTITIES");

export const errInternalInvestmentDataPermittedEntities =
  createAction<DataLoadStatus>(
    "ERR_INTERNAL_INVESTMENT_DATA_PERMITTED_ENTITIES"
  );

export const recvElectionsEntitlement = createAction<Optional<boolean>>(
  "RECV_ELECTIONS_ENTITLEMENT"
);
export const errElectionsEntitlements = createAction<DataLoadStatus>(
  "ERR_ELECTIONS_ENTITLEMENTS"
);

export const recvBankAccountsEntitlements = createAction<
  Optional<IBankAccountsEntitlements>
>("RECV_BANK_ACCOUNTS_ENTITLEMENTS");

export const errBankAccountsEntitlements = createAction<DataLoadStatus>(
  "ERR_BANK_ACCOUNTS_ENTITLEMENTS"
);

export const recvAdminEntitlements = createAction<
  Optional<AdminEntitlementSummary>
>("RECV_ADMIN_ENTITLEMENTS");

export const errAdminEntitlements = createAction<DataLoadStatus>(
  "ERR_ADMIN_ENTITLEMENTS"
);

export const recvElectionAdminEntitlements = createAction<boolean>(
  "RECV_ELECTION_ADMIN_ENTITLEMENTS"
);

export const recvCanViewElectionResultPage = createAction<boolean>(
  "RECV_CAN_VIEW_ELECTION_RESULT_PAGE"
);

export const recvRestrictedEquityEntitlement = createAction<Optional<boolean>>(
  "RECV_RESTRICTED_EQUITY_ENTITLEMENT"
);
export const errRestrictedEquityEntitlement = createAction<DataLoadStatus>(
  "ERR_RESTRICTED_EQUITY_ENTITLEMENT"
);
