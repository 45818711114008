import { createSelector } from "@reduxjs/toolkit";
import { IAsOfDate } from "common";

import { selectActiveInvestmentEntityData } from "./baseStoreSelectors";

export const selectAsOfDates = createSelector(
  [selectActiveInvestmentEntityData],
  ({ asOfDates }): IAsOfDate => {
    return asOfDates;
  }
);
