import { Circle } from "@mui/icons-material";
import { Grid } from "@mui/material";
import {
  getChartCellColorByIndex,
  getCurrencyFormattedValueWithZeroDefault,
  IHoldbackPerFund,
  PieConstants,
  ResponsiveRechartsWrapper,
  SegregatedTrustTableLabel,
} from "common";
import React from "react";
import { Cell, Pie, PieChart, Tooltip } from "recharts";

import colors from "../../../styles/_colors.scss";
import { replaceBlackstoneString } from "../../../utils/formatters";
import styles from "./SegregatedTrustBalanceChart.module.scss";

interface SegregatedTrustBalanceChartProps {
  holdbackPerFund: IHoldbackPerFund[];
  holdBackInvested: number | undefined;
}

const NoValueHoldbackPerFund: IHoldbackPerFund[] = [
  { fundName: "", holdback: 1 },
];

export default function SegregatedTrustBalanceChart(
  props: SegregatedTrustBalanceChartProps
) {
  const { holdbackPerFund, holdBackInvested } = props;
  //Validates if the list of holdback per fund has 1 or more values
  const holdbackPerFundHasValues = holdbackPerFund.length > 0;
  //Position for the X Axis from pie chart
  const xAxis = "50%";
  //Position for the Y Axis from pie chart
  const yAxis = 94;
  const pieStyle = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  };

  return (
    <>
      <Grid item xs={12} md={12} className={styles.pie}>
        <ResponsiveRechartsWrapper>
          <PieChart className={styles.pie} margin={pieStyle}>
            {holdbackPerFundHasValues && (
              <Tooltip
                cursor={{
                  /* Recharts adds a gray hover when there's no data by default, 
                this sets the hover color to white, so we don't have a weird hover when there's no data selected*/
                  stroke:
                    holdbackPerFund.length === 0
                      ? colors.white
                      : colors.lightest_grey,
                }}
                content={({ payload }) => {
                  return (
                    <div className={styles.tooltip}>
                      <div>
                        <ul className={styles.itemList}>
                          {payload &&
                            payload.map((item) => (
                              <li key={item.name} className={styles.item}>
                                <Circle
                                  sx={{ fontSize: 10 }}
                                  className={styles.circle}
                                  style={{ color: item.payload.fill }}
                                />
                                <span className={styles.itemLabel}>
                                  <span>
                                    {replaceBlackstoneString(
                                      item.name as string
                                    )}
                                  </span>
                                  :{" "}
                                </span>
                                <span className={styles.itemValue}>
                                  {getCurrencyFormattedValueWithZeroDefault(
                                    item.value as number
                                  )}
                                </span>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  );
                }}
              />
            )}
            <text
              x={xAxis}
              y={yAxis - 15}
              textAnchor="middle"
              dominantBaseline="middle"
              className={styles.pieCenterText}
              fill={colors.bx_primary_grey_text}
            >
              {SegregatedTrustTableLabel.HOLDBACK_INVESTED}
            </text>
            <text
              x={xAxis}
              y={yAxis + 15}
              textAnchor="middle"
              dominantBaseline="middle"
              className={styles.pieCenterValue}
            >
              {getCurrencyFormattedValueWithZeroDefault(holdBackInvested)}
            </text>
            <Pie
              data={
                holdbackPerFundHasValues
                  ? holdbackPerFund
                  : NoValueHoldbackPerFund
              }
              cx={xAxis}
              cy={yAxis}
              innerRadius={PieConstants.INNER_RADIUS}
              outerRadius={PieConstants.OUTER_RADIUS}
              fill={colors.white}
              blendStroke={holdbackPerFund.length <= 1}
              paddingAngle={holdbackPerFund.length > 1 ? 1.5 : 0}
              dataKey="holdback"
              nameKey="fundName"
              startAngle={90} // pie chart should begin at 12 o'clock position
              endAngle={-270} // pie chart should go clockwise, which is negative direction
            >
              {holdbackPerFundHasValues &&
                holdbackPerFund.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      entry.holdback !== 0
                        ? getChartCellColorByIndex(index)
                        : colors.bx_slate
                    }
                  />
                ))}
              {NoValueHoldbackPerFund.map((index) => (
                <Cell key={`cell-${index}`} fill={colors.medium_grey} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveRechartsWrapper>
      </Grid>
    </>
  );
}
