export const ErrorStringConstants = {
  DOWNLOAD_FAILED: "Download failed",
  NO_DATA: "No data available",
  FAILED_TO_LOAD: "Failed to Load",
  RETRY: "Retry",
  RELOAD: "Reload",
  SOMETHING_WENT_WRONG: "Oops! Something went wrong.",
  PAGE_LOAD_INCORRECT: "This page did not load correctly.",
  HELP_MESSAGE: "For help, please reach out to",
  BXWEALTH_SUPPORT: "BXWealth Support",
  NO_PERMISSION: "You do not have permission to view this page.",
  ERROR: "Error",
  START_DATE_AFTER_END_DATE: "Start Date cannot be after End Date",
  EARLIEST_DATE_ERROR: "Earliest date must be after 1/1/1970",
  NO_DOCUMENTS: "No documents found.",
  CHANGE_FILTERS: "Please try a different search or change your filters.",
  CONTACT: "Contact",
  PLEASE_CONTACT: "Please contact",
  WITH_ANY_QUESTIONS: "with any questions.",
  NO_ELECTIONS: "No elections found.",
  NO_ELECTION_RESULT: "No election result found!",
};
