import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DataLoadStatus } from "../../constants/enums";
import {
  AccountType,
  BankAccountAssignment,
  BankAccountsResponse,
  GPBankAccount,
  GPPendingBankAccount,
  IBankAccount,
  ICountry,
  ICurrency,
  UserAddedBankAccount,
} from "../../types/bankAccountDataTypes";
import { isSomething } from "../../types/typeGuards";
import { nothing, Optional, some } from "../../types/typeUtils";
import { setActiveElection } from "../actions/electionsActions";

type SelectableBankAccount = IBankAccount & {
  lastUpdated: Date;
};

export interface IBankAccountsStore {
  bankAccountsLoadStatus: DataLoadStatus;
  bankAccountsInvestmentVehicleId: Optional<number>;
  serverData: BankAccountsResponse;
  selectedContributionAccount: Optional<SelectableBankAccount>;
  selectedDistributionAccount: Optional<SelectableBankAccount>;
  userAddedAccounts: UserAddedBankAccount[];
  countries: ICountry[];
  countriesLoadStatus: DataLoadStatus;
  currencies: ICurrency[];
  currenciesLoadStatus: DataLoadStatus;
  unsubmittedChanges: {
    [BankAccountAssignment.CONTRIBUTION]: boolean;
    [BankAccountAssignment.DISTRIBUTION]: boolean;
  };
}

const BLANK_SERVER_RESPONSE = {
  bankAccounts: [],
  pendingBankAccounts: [],
};

export const initialState: IBankAccountsStore = {
  bankAccountsLoadStatus: DataLoadStatus.NOT_REQUESTED,
  bankAccountsInvestmentVehicleId: nothing,
  serverData: BLANK_SERVER_RESPONSE,
  selectedContributionAccount: nothing,
  selectedDistributionAccount: nothing,
  userAddedAccounts: [],
  countries: [],
  countriesLoadStatus: DataLoadStatus.NOT_REQUESTED,
  currencies: [],
  currenciesLoadStatus: DataLoadStatus.NOT_REQUESTED,
  unsubmittedChanges: {
    [BankAccountAssignment.CONTRIBUTION]: false,
    [BankAccountAssignment.DISTRIBUTION]: false,
  },
};

const findActiveAccount = (
  assignment: BankAccountAssignment,
  serverResponse: BankAccountsResponse
): Optional<SelectableBankAccount> => {
  const pendingAccount = serverResponse.pendingBankAccounts.find((account) =>
    account.assignments.includes(assignment)
  );
  if (pendingAccount) {
    return some(pendingAccountToSelectableAccount(pendingAccount));
  }
  const wssAccount = serverResponse.bankAccounts.find((account) =>
    account.bankAccount.assignments.includes(assignment)
  );
  if (wssAccount) {
    return some(wssAccountToSelectableAccount(wssAccount));
  }
  return nothing;
};

const pendingAccountToSelectableAccount = (
  pending: GPPendingBankAccount
): SelectableBankAccount => {
  return {
    ...pending.main,
    lastUpdated: isSomething(pending.effectiveDate)
      ? pending.effectiveDate.value
      : new Date(),
  };
};

const userAddedToSelectableAccount = (
  userAdded: UserAddedBankAccount
): SelectableBankAccount => {
  return { ...userAdded.main, lastUpdated: new Date() };
};

const wssAccountToSelectableAccount = (
  wss: GPBankAccount
): SelectableBankAccount => {
  return {
    ...wss.bankAccount.main,
    lastUpdated: wss.bankAccount.main.lastModified,
  };
};

const isEligibleContributionAccount = (account: GPBankAccount) => {
  return (
    account.bankAccount.main.currency === "USD" &&
    account.bankAccount.main.country === "US" &&
    account.bankAccount.main.accountType === AccountType.CHECKING &&
    !isSomething(account.bankAccount.intermediary)
  );
};

// Define this outside of slice because we want it to take a number as payload,
// but we would only use that in the saga.
export const addBankAccount =
  createAction<UserAddedBankAccount>("ADD_BANK_ACCOUNT");
export const setSelectedContributionAccount =
  createAction<SelectableBankAccount>("SELECT_CONTRIBUTION_ACCOUNT");
export const setSelectedDistributionAccount =
  createAction<SelectableBankAccount>("SELECT_DISTRIBUTION_ACCOUNT");
export const reqBankAccounts = createAction<number>("REQ_BANK_ACCOUNTS");

export const bankAccountsSlice = createSlice({
  name: "bankAccounts",
  initialState,
  reducers: {
    recvNoBankAccounts: (state) => {
      state.bankAccountsLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
      state.bankAccountsInvestmentVehicleId = nothing;
      state.serverData = BLANK_SERVER_RESPONSE;
      state.selectedContributionAccount = nothing;
      state.selectedDistributionAccount = nothing;
    },
    recvBankAccounts: (state, action: PayloadAction<BankAccountsResponse>) => {
      state.bankAccountsLoadStatus = DataLoadStatus.SUCCESSFUL;
      state.serverData = action.payload;
      state.selectedContributionAccount = findActiveAccount(
        BankAccountAssignment.CONTRIBUTION,
        action.payload
      );
      state.selectedDistributionAccount = findActiveAccount(
        BankAccountAssignment.DISTRIBUTION,
        action.payload
      );
    },
    errBankAccounts: (state) => {
      state.bankAccountsLoadStatus = DataLoadStatus.UNSUCCESSFUL;
    },
    recvNoBankAccountCountries: (state) => {
      state.countriesLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
      state.countries = [];
    },
    recvBankAccountCountries: (state, action: PayloadAction<ICountry[]>) => {
      state.countriesLoadStatus = DataLoadStatus.SUCCESSFUL;
      state.countries = action.payload;
    },
    errBankAccountCountries: (state) => {
      state.countriesLoadStatus = DataLoadStatus.UNSUCCESSFUL;
    },
    recvNoBankAccountCurrencies: (state) => {
      state.currenciesLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
      state.currencies = [];
    },
    recvBankAccountCurrencies: (state, action: PayloadAction<ICurrency[]>) => {
      state.currenciesLoadStatus = DataLoadStatus.SUCCESSFUL;
      state.currencies = action.payload;
    },
    errBankAccountCurrencies: (state) => {
      state.currenciesLoadStatus = DataLoadStatus.UNSUCCESSFUL;
    },
    reqBankAccountCountries: (state) => {
      state.countriesLoadStatus = DataLoadStatus.LOADING;
      state.countries = [];
    },
    reqBankAccountCurrencies: (state) => {
      state.currenciesLoadStatus = DataLoadStatus.LOADING;
      state.currencies = [];
    },
    changeContributionAccount: (state, action) => {
      /* TODO: should set to actual bank account object */
      state.selectedContributionAccount = action.payload;
      state.unsubmittedChanges[BankAccountAssignment.CONTRIBUTION] = true;
    },
    changeDistributionAccount: (state, action) => {
      /* TODO: should set to actual bank account object */
      state.selectedDistributionAccount = action.payload;
      state.unsubmittedChanges[BankAccountAssignment.DISTRIBUTION] = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reqBankAccounts, (state, action) => {
      state.bankAccountsLoadStatus = DataLoadStatus.LOADING;
      state.bankAccountsInvestmentVehicleId = some(action.payload);
      state.serverData = BLANK_SERVER_RESPONSE;
      state.selectedContributionAccount = nothing;
      state.selectedDistributionAccount = nothing;
    });
    builder.addCase(addBankAccount, (state, action) => {
      state.userAddedAccounts.push(action.payload);
      const selectableAccount = userAddedToSelectableAccount(action.payload);
      if (action.payload.assignment === BankAccountAssignment.CONTRIBUTION) {
        state.selectedContributionAccount = some(selectableAccount);
      } else {
        state.selectedDistributionAccount = some(selectableAccount);
      }
    });
    builder.addCase(setSelectedContributionAccount, (state, action) => {
      state.selectedContributionAccount = some(action.payload);
    });
    builder.addCase(setSelectedDistributionAccount, (state, action) => {
      state.selectedDistributionAccount = some(action.payload);
    });
    builder.addCase(setActiveElection, (state, action) => {
      (state.bankAccountsLoadStatus = DataLoadStatus.NOT_REQUESTED),
        (state.bankAccountsInvestmentVehicleId = some(
          action.payload.investmentVehicleId
        )),
        (state.serverData = BLANK_SERVER_RESPONSE),
        (state.selectedContributionAccount = nothing),
        (state.selectedDistributionAccount = nothing),
        (state.userAddedAccounts = []),
        (state.unsubmittedChanges = {
          [BankAccountAssignment.CONTRIBUTION]: false,
          [BankAccountAssignment.DISTRIBUTION]: false,
        });
    });
  },
  selectors: {
    getEligibleContributionAccounts: (sliceState): SelectableBankAccount[] => {
      const pending = sliceState.serverData.pendingBankAccounts.find(
        (account) =>
          account.assignments.includes(BankAccountAssignment.CONTRIBUTION)
      );
      const userAdded = sliceState.userAddedAccounts.filter(
        (account) =>
          account.assignment === BankAccountAssignment.CONTRIBUTION ||
          account.eligibleForBothAssignments
      );
      if (pending) {
        return [pendingAccountToSelectableAccount(pending)];
      } else {
        const options = sliceState.serverData.bankAccounts
          .filter(isEligibleContributionAccount)
          .map(wssAccountToSelectableAccount);
        if (userAdded) {
          options.push(...userAdded.map(userAddedToSelectableAccount));
        }
        return options;
      }
    },
    getEligibleDistributionAccounts: (sliceState): SelectableBankAccount[] => {
      const pending = sliceState.serverData.pendingBankAccounts.find(
        (account) =>
          account.assignments.includes(BankAccountAssignment.DISTRIBUTION)
      );
      const userAdded = sliceState.userAddedAccounts.filter(
        (account) =>
          account.assignment === BankAccountAssignment.DISTRIBUTION ||
          account.eligibleForBothAssignments
      );
      if (pending) {
        return [pendingAccountToSelectableAccount(pending)];
      } else {
        const options = sliceState.serverData.bankAccounts.map(
          wssAccountToSelectableAccount
        );
        if (userAdded) {
          options.push(...userAdded.map(userAddedToSelectableAccount));
        }
        return options;
      }
    },
    hasPendingContribution: (sliceState): boolean => {
      return sliceState.serverData.pendingBankAccounts.some((account) =>
        account.assignments.includes(BankAccountAssignment.CONTRIBUTION)
      );
    },
    hasPendingDistribution: (sliceState): boolean => {
      return sliceState.serverData.pendingBankAccounts.some((account) =>
        account.assignments.includes(BankAccountAssignment.DISTRIBUTION)
      );
    },
    hasUnsubmittedChangesContribution: (sliceState): boolean => {
      return sliceState.unsubmittedChanges[BankAccountAssignment.CONTRIBUTION];
    },
    hasUnsubmittedChangesDistribution: (sliceState): boolean => {
      return sliceState.unsubmittedChanges[BankAccountAssignment.DISTRIBUTION];
    },
  },
});

export const bankAccountsReducer = bankAccountsSlice.reducer;
export const {
  errBankAccounts,
  recvBankAccounts,
  recvNoBankAccounts,
  errBankAccountCountries,
  recvBankAccountCountries,
  recvNoBankAccountCountries,
  errBankAccountCurrencies,
  recvBankAccountCurrencies,
  recvNoBankAccountCurrencies,
  reqBankAccountCountries,
  reqBankAccountCurrencies,
  changeContributionAccount,
  changeDistributionAccount,
} = bankAccountsSlice.actions;
export const {
  getEligibleContributionAccounts,
  getEligibleDistributionAccounts,
  hasUnsubmittedChangesContribution,
  hasUnsubmittedChangesDistribution,
  hasPendingContribution,
  hasPendingDistribution,
} = bankAccountsSlice.selectors;
