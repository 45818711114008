import { IHeaderGroupParams } from "ag-grid-community";
import { InfoTooltip } from "common";
import React, { ReactElement } from "react";

import styles from "./GridHeaderWithTooltip.module.scss";

interface IGridHeaderWithTooltipProps extends IHeaderGroupParams {
  tooltipContent: string[] | ReactElement;
  labelClass?: string | null;
  textClass?: string | null;
  rightAlign?: boolean | null;
}

export const GridHeaderWithTooltip = (props: IGridHeaderWithTooltipProps) => {
  const {
    displayName,
    tooltipContent,
    labelClass,
    textClass,
    rightAlign = false,
  } = props;
  return (
    <div
      className={`${labelClass} ag-header-group-cell-label ${
        rightAlign ? styles.rightAlign : ""
      }`}
    >
      <div className={`${textClass} ag-header-group-text`}>{displayName}</div>{" "}
      <InfoTooltip className={styles.tooltip} tooltipContent={tooltipContent} />
    </div>
  );
};
