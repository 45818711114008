import { Circle } from "@mui/icons-material";
import {
  ALL_NAME_PLACEHOLDER,
  getCurrencyFormattedValueWithZeroDefault,
} from "common";
import React from "react";
import {
  NameType,
  Payload,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import styles from "./CarryChart.module.scss";

export const CarryChartTooltip = ({
  payload,
}: {
  payload: Payload<ValueType, NameType>[];
}) => {
  return (
    <div className={styles.tooltip}>
      <div>
        <ul className={styles.itemList}>
          {payload &&
            payload.map((item) => (
              <li key={item.name} className={styles.item}>
                <Circle
                  sx={{ fontSize: 10 }}
                  className={styles.circle}
                  style={{ color: item.payload.fill }}
                />
                <span className={styles.itemLabel}>
                  <span>
                    {item.payload.fundShortName !== ALL_NAME_PLACEHOLDER
                      ? item.payload.fundShortName
                      : item.payload.businessUnitName}
                  </span>
                  :{" "}
                </span>
                <span className={styles.itemValue}>
                  {getCurrencyFormattedValueWithZeroDefault(
                    item.value as number
                  )}
                </span>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
