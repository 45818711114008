import { DataLoadStatus } from "../constants/enums";
import { SummaryLabel } from "../constants/LabelAndTooltipConstants";
import { ICalloutData } from "../types/dataTypes";
import { isSomething } from "../types/typeGuards";
import { isInProgress } from "./dataLoadUtils";

interface ICalloutDataPoint {
  label: string;
  value: number;
}

interface ICalloutBannerData {
  showLoading: boolean;
  callouts: ICalloutDataPoint[];
  total: number;
}

const getCalloutDataPointsFromCalloutData = (calloutData: ICalloutData) => {
  const calloutsToDisplay: ICalloutDataPoint[] = [];

  if (isSomething(calloutData.unrealized)) {
    const unrealizedCarry = calloutData.unrealized.value.unrealizedCarry;
    const unrealizedOptionalAndMandatoryInvestments =
      calloutData.unrealized.value.unrealizedOptionalAndMandatoryInvestments;
    //callout data has been loaded and is defined
    if (unrealizedCarry?.value !== undefined && unrealizedCarry?.value !== 0) {
      // unrealized carry data point is defined and non-zero
      calloutsToDisplay.push({
        label: SummaryLabel.UNREALIZED_CARRY,
        value: unrealizedCarry.value,
      });
    }

    if (
      unrealizedOptionalAndMandatoryInvestments?.value !== undefined &&
      unrealizedOptionalAndMandatoryInvestments?.value !== 0
    ) {
      // unrealized investments is defined and non-zero
      calloutsToDisplay.push({
        label: SummaryLabel.UNREALIZED_INVESTMENTS,
        value: unrealizedOptionalAndMandatoryInvestments.value,
      });
    }
  }
  return calloutsToDisplay;
};

export function getCalloutBannerData(
  calloutData: ICalloutData,
  internalInvestmentDataLoadStatus: DataLoadStatus,
  hasViewStockEntitlement: boolean,
  unvestedEquity: { loading: boolean; total: number; anyEquity: boolean }
): ICalloutBannerData {
  const showLoading =
    isInProgress(internalInvestmentDataLoadStatus) ||
    (hasViewStockEntitlement && unvestedEquity.loading);
  let callouts: ICalloutDataPoint[] = [];
  if (!showLoading) {
    callouts = getCalloutDataPointsFromCalloutData(calloutData);
    if (unvestedEquity.anyEquity) {
      callouts.push({
        label: SummaryLabel.UNVESTED_EQUITY,
        value: unvestedEquity.total,
      });
    }
  }
  const total = callouts.reduce((acc, x) => acc + x.value, 0);
  return { showLoading, callouts, total };
}
