import React from "react";

import { ILabeledDataPoint } from "../../../../types/labelTypes";
import { getCurrencyFormattedValue } from "../../../../utils/formatters";
import { InfoTooltip } from "../../../InfoTooltip/InfoTooltip";
import styles from "./OverviewDataPoint.module.scss";

/**
 * Generic component that encapsulates a data point on a split callout card
 *
 * @param props
 * @returns a single row of data on a split callout card
 */
export const OverviewDataPoint = (props: ILabeledDataPoint) => {
  const {
    label,
    tooltipParagraphs,
    calloutDatum,
    getFormattedValue = getCurrencyFormattedValue,
    shouldHide,
    isSubLabel = false,
    isTotalLabel = false,
    className = "",
  } = props;

  return shouldHide ? (
    <></>
  ) : (
    <div className={`${styles.callout} ${className}`}>
      <div
        className={
          isSubLabel
            ? `${styles.calloutLabel} ${styles.subLabel}`
            : styles.calloutLabel
        }
      >
        <span>{label}</span>
        {tooltipParagraphs.length > 0 && (
          <InfoTooltip
            className={styles.tooltipIcon}
            tooltipContent={tooltipParagraphs}
          />
        )}
      </div>
      <span
        className={
          isTotalLabel
            ? `${styles.calloutValue} ${styles.totalValue}`
            : styles.calloutValue
        }
      >
        {getFormattedValue(calloutDatum?.value)}
      </span>
    </div>
  );
};
