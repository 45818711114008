import { DEFAULT_ENTITY_STATE } from "../types/defaultTypes";
import { IEntityDataStore } from "../types/storeTypes";
import { isSomething } from "../types/typeGuards";

/**
 * Gets the entity id that should be used to retrieve the data
 * @param clientId A client id
 * @param investmentVehicleId An investment vehicle id
 * @returns A entity id
 */
export const getEntityId = (
  clientId: string,
  investmentVehicleId: string | undefined
) => (investmentVehicleId === undefined ? clientId : investmentVehicleId);

/**
 * Gets the entity data by client/investment vehicle id
 * @param id An id
 * @returns An entity data
 */
export const getEntityDataById = (entities: IEntityDataStore[], id: string) => {
  const foundEntity = entities.find((entity: IEntityDataStore) =>
    isSomething(entity.entityId) ? entity.entityId.value === id : false
  );

  return foundEntity === undefined ? DEFAULT_ENTITY_STATE : foundEntity;
};

/**
 * Tells if an entity has been loaded by id
 * @param entities A list of entities
 * @param id A client/investment vehicle id
 * @returns A boolean value
 */
export const isEntityLoaded = (entities: IEntityDataStore[], id: string) => {
  return entities.find((entity) =>
    isSomething(entity.entityId) ? entity.entityId.value === id : false
  );
};

/**
 * Tells if an entity has data
 * @param entity An entity
 * @returns A boolean value
 */
export const entityHasData = (entity: IEntityDataStore) => {
  return (
    isSomething(entity.commitmentData) ||
    isSomething(entity.financingBalance) ||
    isSomething(entity.commitmentData) ||
    isSomething(entity.segregatedTrustBalance) ||
    entity.historicalSummary.length > 0 ||
    entity.investmentBreakdown.length > 0
  );
};
