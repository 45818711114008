import { ArrowOutward } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import {
  EquityLabel,
  IBaseStore,
  isInProgress,
  isSomething,
  SectionHeader,
  setSelectedStockSymbol,
  StockSymbol,
  UISupportedStocks,
} from "common";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StockSymbolToggle } from "../../../../components/StockSymbolToggle/StockSymbolToggle";
import styles from "./Header.module.scss";

export const EquityHeader = () => {
  const {
    distributionsData,
    distributionsDataLoadStatus,
    equityData,
    equityDataLoadStatus,
  } = useSelector((store: IBaseStore) => store.equityData);
  const { selectedStockSymbol } = useSelector(
    (store: IBaseStore) => store.viewData
  );

  const dispatch = useDispatch();

  const onChange = (newSymbol: StockSymbol) => {
    dispatch(setSelectedStockSymbol(newSymbol));
  };

  const userStockTypes = useMemo(() => {
    const presentStocks: StockSymbol[] = [];
    if (!isInProgress(distributionsDataLoadStatus, equityDataLoadStatus)) {
      for (const symbol of UISupportedStocks) {
        if (
          isSomething(equityData[symbol]) ||
          isSomething(distributionsData[symbol])
        ) {
          presentStocks.push(symbol);
        }
      }
    }

    return presentStocks;
  }, [
    distributionsData,
    distributionsDataLoadStatus,
    equityData,
    equityDataLoadStatus,
  ]);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
      <Typography variant="h1">{SectionHeader.EQUITY}</Typography>
      {userStockTypes.length > 1 && (
        <StockSymbolToggle
          availableStockSymbols={userStockTypes}
          selectedStockSymbol={selectedStockSymbol}
          onChange={onChange}
        />
      )}
      <Button
        className={styles.merrillLynchButton}
        href={EquityLabel.MERRILL_LYNCH_BENEFITS_URL}
        target="_blank"
        endIcon={<ArrowOutward />}
      >
        {EquityLabel.VIEW_ON_MERRILL_LYNCH}
      </Button>
    </Stack>
  );
};
