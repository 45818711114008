import { ThemeOptions } from "@mui/material";

import { Font } from "../sharedStyleConstants";

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontFamily: Font.Family.GUARDIAN_SANS,
          fontWeight: Font.Weight.LIGHT,
        },
      },
    },
  },
};

export const CustomPickersDayTheme = partialTheme.components?.MuiPickersDay;
