import { Breadcrumbs, Grid, Typography } from "@mui/material";
import {
  ALL_FUNDS_PLACEHOLDER,
  CalloutPersona,
  createInvestmentChartList,
  filterToPositiveInvestmentsByBusinessUnit,
  filterToPositiveInvestmentsByFund,
  HoverableChartLegends,
  IDashboardCardContentProps,
  IInvestmentAllocationCardData,
  IInvestmentBreakdownDatum,
  IInvestmentChartData,
  isSomething,
  mapInvestmentDataToChartLegends,
  MUIConstants,
  nothing,
  Optional,
  some,
  sortAndReduceInvestmentCardData,
  TreeNumber,
  UnrealizedValueLabels,
} from "common";
import React, { useCallback, useMemo, useState } from "react";

import styles from "./InvestmentAllocation.module.scss";
import { InvestmentChart } from "./InvestmentChart/InvestmentChart";
import { InvestmentCardTable } from "./InvestmentTable/InvestmentCardTable";

export const InvestmentAllocation = (
  props: IDashboardCardContentProps<IInvestmentAllocationCardData>
) => {
  const { investmentBreakdownGrouped, calloutData, calloutPersona } =
    props.data;
  const [selectedBusinessUnit, setSelectedBusinessUnit] =
    useState<Optional<IInvestmentChartData>>(nothing);
  const unrealizedInvestments = isSomething(calloutData.unrealized)
    ? calloutData.unrealized.value.unrealizedOptionalAndMandatoryInvestments
        .value
    : undefined;
  const remainingCapitalInvested: number | undefined = isSomething(
    calloutData.unrealized
  )
    ? calloutData.unrealized.value.unrealizedCapitalInvested.value
    : undefined;
  const gainLoss: number | undefined = isSomething(calloutData.unrealized)
    ? calloutData.unrealized.value.unrealizedGainLoss.value
    : undefined;
  const showTable: boolean =
    calloutPersona !== CalloutPersona.CARRY ||
    !(remainingCapitalInvested || gainLoss);

  const investmentCardChartData = useMemo(() => {
    if (isSomething(investmentBreakdownGrouped)) {
      let filteredInvestments: IInvestmentBreakdownDatum[] = [];

      if (isSomething(selectedBusinessUnit)) {
        /*
          When there's a business unit selected, we should show
          investments grouped by funds for that selected 
          business unit.
        */
        filteredInvestments = filterToPositiveInvestmentsByFund(
          investmentBreakdownGrouped.value[TreeNumber.BUSINESS_UNIT],
          selectedBusinessUnit.value.businessUnitName
        );
      } else {
        /*
          When there's not business unit selected, we should show
          investments grouped by business unit level.
        */
        filteredInvestments = filterToPositiveInvestmentsByBusinessUnit(
          investmentBreakdownGrouped.value[TreeNumber.BUSINESS_UNIT]
        );
      }
      // Convert IInvestmentBreakdownDatum list into IInvestmentChartData list
      const investmentChartList =
        createInvestmentChartList(filteredInvestments);
      /* 
        Sort and reduce the data to show the top 5 and all the remaining
        as 'Other' category
      */
      return sortAndReduceInvestmentCardData(
        investmentChartList,
        isSomething(selectedBusinessUnit)
      );
    }
  }, [investmentBreakdownGrouped, selectedBusinessUnit]);

  /*
    handleSliceClick handles the click on pie slices
    On business unit level, we should show data grouped by funds.
    On funds level, the slice click shouldn't change anything.
  */
  const handleSliceClick = useCallback(
    (investmentChartData: IInvestmentChartData) => {
      if (investmentChartData.mdmFundId === ALL_FUNDS_PLACEHOLDER) {
        setSelectedBusinessUnit(some(investmentChartData));
      }
    },
    []
  );

  /*
    handleLegendClick function handles the click on left side legends
    On business unit level, we should show data grouped by funds.
    On funds level, the legends shouldn't change anything.
  */
  const handleLegendClick = useCallback(
    (businessUnitName: string) => {
      const investmentChartData = investmentCardChartData?.find(
        (data) => data.businessUnitName === businessUnitName
      );
      if (
        investmentChartData &&
        investmentChartData.mdmFundId === ALL_FUNDS_PLACEHOLDER
      ) {
        setSelectedBusinessUnit(some(investmentChartData));
      }
    },
    [investmentCardChartData]
  );

  /*
  handleAllBusinessClick function handles the click on
  "All Business Unit" navigation crumb.
*/
  const handleAllBusinessClick = () => {
    if (isSomething(selectedBusinessUnit)) {
      setSelectedBusinessUnit(nothing);
    }
  };

  return (
    <Grid container className={styles.container}>
      <Grid
        item
        xs={12}
        md={
          showTable
            ? MUIConstants.WIDGET_TREE_COLUMN_LEFT_ITEM_SIZE
            : MUIConstants.WIDGET_TREE_COLUMN_CENTER_ITEM_SIZE
        }
        className={styles.legends}
      >
        <Breadcrumbs className={styles.breadcrumbs}>
          <span onClick={handleAllBusinessClick}>
            <Typography variant="subtitle1">
              {UnrealizedValueLabels.ALL_BUSINESS_UNITS}
            </Typography>
          </span>
          {isSomething(selectedBusinessUnit) && (
            <Typography variant="subtitle1" className={styles.funds}>
              {selectedBusinessUnit.value.businessUnitName}
            </Typography>
          )}
        </Breadcrumbs>
        {investmentCardChartData && investmentCardChartData.length > 0 && (
          <HoverableChartLegends
            legends={mapInvestmentDataToChartLegends(
              investmentCardChartData,
              handleLegendClick,
              isSomething(selectedBusinessUnit)
            )}
          />
        )}
      </Grid>
      <Grid
        item
        xs={12}
        md={
          showTable
            ? MUIConstants.WIDGET_TREE_COLUMN_CENTER_ITEM_SIZE
            : MUIConstants.DASHBOARD_WIDGET_COLUMN_SPACING
        }
        className={styles.chartContainer}
      >
        <InvestmentChart
          investmentChartData={investmentCardChartData || []}
          unrealizedInvestments={
            isSomething(selectedBusinessUnit)
              ? selectedBusinessUnit.value.unrealizedValue
                  .optionalAndMandatoryInvestments
              : unrealizedInvestments
          }
          handleSliceClick={handleSliceClick}
          verticalAlignCenter={!showTable}
        />
      </Grid>
      {showTable && (
        <Grid item xs={12} md={MUIConstants.DASHBOARD_WIDGET_RIGHT_ITEM_SIZE}>
          <InvestmentCardTable
            remainingCapitalInvested={remainingCapitalInvested}
            gainLoss={gainLoss}
          />
        </Grid>
      )}
    </Grid>
  );
};
