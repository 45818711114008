import { Circle } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ReferenceLine,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import {
  LoanBalanceLabel,
  LoanBalanceTooltip,
} from "../../../constants/LabelAndTooltipConstants";
import { MUIConstants } from "../../../constants/MUIConstants";
import { chartMargins, xAxisTick } from "../../../constants/RechartsSVGStyles";
import colors from "../../../styles/_colors.scss";
import { IFinancingBalance } from "../../../types/dataTypes";
import { getTicksAndLabels } from "../../../utils/charting";
import { getCurrencyFormattedValue } from "../../../utils/formatters";
import { DashboardCardTable } from "../../DashboardCardTable/DashboardCardTable";
import { ResponsiveRechartsWrapper } from "../../ResponsiveRechartsWrapper/ResponsiveRechartsWrapper";
import styles from "./BalanceRow.module.scss";

const COLORS_MAPPING = {
  [LoanBalanceLabel.FIRM_LOAN]: colors.avocado,
  [LoanBalanceLabel.THIRD_PARTY_LOAN]: colors.emerald,
  [LoanBalanceLabel.PENDING_THIRD_PARTY_LOAN]: colors.taupe,
};

interface IBalanceRowProps {
  financingData: IFinancingBalance;
  shouldRenderGraph: boolean;
  headerCols: string[];
}

export const BalanceRow = (props: IBalanceRowProps) => {
  const { financingData, shouldRenderGraph, headerCols } = props;

  const financingBalance: {
    type: string;
    value: number | undefined;
    color: string;
  }[] = [
    {
      type: LoanBalanceLabel.FIRM_LOAN,
      value: financingData.firmFinancingBalance,
      color: COLORS_MAPPING[LoanBalanceLabel.FIRM_LOAN],
    },
    {
      type: LoanBalanceLabel.PENDING_THIRD_PARTY_LOAN,
      value: financingData.thirdPartyPendingLoanBalance,
      color: COLORS_MAPPING[LoanBalanceLabel.PENDING_THIRD_PARTY_LOAN],
    },
    {
      type: LoanBalanceLabel.THIRD_PARTY_LOAN,
      value: financingData.thirdPartyFinancingBalance,
      color: COLORS_MAPPING[LoanBalanceLabel.THIRD_PARTY_LOAN],
    },
  ];

  const tickInfo = getTicksAndLabels(
    financingBalance
      .filter((e) => e.value !== undefined)
      // We know it's not undefined as undefined values have already been filtered
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .map((e) => e.value!),
    4,
    7
  );

  const formatXAxisTicks = (value: number, index: number): string => {
    return tickInfo.tickLabels[index];
  };
  //Set expand/collapse childs for firm loan
  const [isFirmLoanOpen, setFirmLoanOpen] = useState(false);
  //Set expand/collapse childs for third party loan
  const [isThirdPartLoanOpen, setThirdPartLoanOpen] = useState(false);
  //Set expand/collapse childs for pending third part loan
  const [isPendingThirdPartLoanOpen, setPendingThirdPartLoanOpen] =
    useState(false);

  return (
    <Grid
      container
      columnSpacing={MUIConstants.DASHBOARD_WIDGET_COLUMN_SPACING}
    >
      {shouldRenderGraph && (
        <Grid
          item
          xs={12}
          md={MUIConstants.DASHBOARD_WIDGET_LEFT_ITEM_SIZE}
          className={styles.bar}
        >
          <ResponsiveRechartsWrapper>
            <BarChart
              data={financingBalance}
              layout="vertical"
              margin={{ ...chartMargins, left: 30, right: -1 }} // -1 hides a final line on the right side of the chart that recharts insists on drawing
            >
              <Tooltip
                cursor={{
                  fill:
                    /* Recharts adds a gray hover when there's no data by default, 
                      this sets the hover color to white, so we don't have a weird hover when there's no data selected*/
                    tickInfo.tickValues.length === 0
                      ? colors.white
                      : colors.lightest_grey,
                }}
                content={({ payload, label }) => {
                  return (
                    <div className={styles.tooltip}>
                      <div>
                        <ul className={styles.itemList}>
                          {payload &&
                            payload.map((item) => (
                              <li key={item.dataKey} className={styles.item}>
                                <Circle
                                  sx={{ fontSize: 10 }}
                                  className={styles.circle}
                                  style={{ color: item.payload.color }}
                                ></Circle>
                                <span className={styles.itemLabel}>
                                  {label}:{" "}
                                </span>
                                <span>
                                  {getCurrencyFormattedValue(
                                    item.value as number
                                  )}
                                </span>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  );
                }}
              />
              <CartesianGrid
                horizontal={false}
                strokeDasharray="2 2"
                stroke={colors.slate}
              />
              <XAxis
                type="number"
                scale="linear"
                tick={xAxisTick}
                ticks={tickInfo.tickValues}
                tickFormatter={formatXAxisTicks}
                tickLine={false}
                domain={["dataMin, dataMax"]}
                axisLine={true}
                padding={{ right: 25 }}
                minTickGap={-100} //ensures we always render ticks even on very small screens
              />
              <YAxis
                dataKey="type"
                tick={({ y, payload }) => (
                  <Text
                    x={74}
                    y={y}
                    textAnchor="end"
                    width={100} // so text wraps instead of overflows
                    fill={colors.dark_grey}
                    verticalAnchor="middle"
                  >
                    {payload.value}
                  </Text>
                )}
                tickLine={false}
                type="category"
                axisLine={false}
              />
              <Tooltip
                cursor={false}
                formatter={(value: number | undefined) =>
                  getCurrencyFormattedValue(value)
                }
              />
              <ReferenceLine x={0} stroke={colors.dark_grey} />
              <Bar dataKey="value" barSize={35}>
                <Cell
                  key="firm-loan-bar"
                  fill={COLORS_MAPPING[LoanBalanceLabel.FIRM_LOAN]}
                />
                <Cell
                  key="pending-third-party-loan-bar"
                  fill={
                    COLORS_MAPPING[LoanBalanceLabel.PENDING_THIRD_PARTY_LOAN]
                  }
                />
                <Cell
                  key="third-party-loan-bar"
                  fill={COLORS_MAPPING[LoanBalanceLabel.THIRD_PARTY_LOAN]}
                />
              </Bar>
            </BarChart>
          </ResponsiveRechartsWrapper>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={
          shouldRenderGraph ? MUIConstants.DASHBOARD_WIDGET_RIGHT_ITEM_SIZE : 12
        }
      >
        <DashboardCardTable
          header={LoanBalanceLabel.LOAN_OVERVIEW}
          headerCols={headerCols}
          rows={[
            {
              name: LoanBalanceLabel.FIRM_LOAN,
              value: financingData.firmFinancingBalance,
              tooltipParagraphs: [LoanBalanceTooltip.FIRM_LOAN],
              isOpen: isFirmLoanOpen,
              setIsOpen: setFirmLoanOpen,
            },
            {
              name: LoanBalanceLabel.PENDING_THIRD_PARTY_LOAN,
              value: financingData.thirdPartyPendingLoanBalance,
              tooltipParagraphs: [LoanBalanceTooltip.PENDING_THIRD_PARTY_LOAN],
              isOpen: isPendingThirdPartLoanOpen,
              setIsOpen: setPendingThirdPartLoanOpen,
            },
            {
              name: LoanBalanceLabel.THIRD_PARTY_LOAN,
              value: financingData.thirdPartyFinancingBalance,
              tooltipParagraphs: [LoanBalanceTooltip.THIRD_PARTY_LOAN],
              isOpen: isThirdPartLoanOpen,
              setIsOpen: setThirdPartLoanOpen,
            },
          ]}
          totalRow={{
            name: LoanBalanceLabel.TOTAL_LOAN_BALANCE,
            value: financingData.totalFinancingBalance,
          }}
        />
      </Grid>
    </Grid>
  );
};
