import { ThemeOptions } from "@mui/material";

import colors from "../../_colors.scss";
import { Font } from "../sharedStyleConstants";

// Shared constants we may want to move in unison

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: Font.Family.GUARDIAN_SANS,
          fontWeight: Font.Weight.STANDARD,
          fontSize: Font.Size.STANDARD,
          color: colors.black,
          "&.Mui-focused": {
            color: colors.black,
          },
        },
      },
    },
  },
};

export const CustomFormLabelTheme = partialTheme.components?.MuiFormLabel;
