import { ThemeOptions } from "@mui/material";

import colors from "../../_colors.scss";
import { Font } from "../sharedStyleConstants";

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiAutocomplete: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          "& label": {
            fontFamily: Font.Family.GUARDIAN_SANS,
          },
        },
        input: {
          fontFamily: Font.Family.GUARDIAN_SANS,
          fontSize: "14px",
          color: colors.black,
          opacity: 1,
          fieldset: {
            borderColor: colors.grey,
          },
          "&::placeholder": {
            opacity: 1,
            fontWeight: 300,
          },
          "&:disabled": {
            backgroundColor: colors.lightest_grey,
          },
        },
        listbox: {
          fontFamily: Font.Family.GUARDIAN_SANS,
        },
        noOptions: {
          fontFamily: Font.Family.GUARDIAN_SANS,
        },
        popupIndicator: {
          ".MuiSvgIcon-root": {
            height: "22px",
            width: "22px",
          },
        },
        popupIndicatorOpen: {
          ".MuiSvgIcon-root": {
            height: "22px",
            width: "22px",
          },
        },
        popper: {
          border: `solid 1px ${colors.bx_slate_shade}`,
          boxShadow: `0px 3px 6px ${colors.bx_slate_shade}`,
          fontFamily: Font.Family.GUARDIAN_SANS,
        },
      },
    },
  },
};

export const CustomAutocompleteTheme = partialTheme.components?.MuiAutocomplete;
