import colors from "../styles/_colors.scss";

export const lineChartsCursor = { stroke: colors.slate, strokeWidth: 1 };

export const chartMargins = { left: 5, right: 0, top: 16, bottom: 16 };
export const chartMarginsWithAxisLabel = {
  left: 25,
  right: 0,
  top: 16,
  bottom: 16,
};

export const yAxisLabelDx = -45;
export const xAxisLabelDy = 16;

export const xAxisTick = { transform: "translate(0, 8)" };
export const yAxisTick = { transform: "translate(-8, 0)" };

export const mobile_chartMargins = { left: 0, right: 0, top: 16, bottom: 16 };

export const RechartsConstants = {
  MAX_QUARTERS_TO_LABEL_INDIVIDUALLY: 6,
};
