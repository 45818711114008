export const BreakpointConstants = {
  EXTRA_SMALL_MAX_WIDTH: 599.9,
  SMALL_MIN_WIDTH: 600,
  SMALL_MAX_WIDTH: 899.9,
  MEDIUM_MIN_WIDTH: 900,
  MEDIUM_MAX_WIDTH: 1199.9,
  LARGE_MIN_WIDTH: 1200,
  LARGE_MAX_WIDTH: 1535.9,
  EXTRA_LARGE_MIN_WIDTH: 1536,
};
