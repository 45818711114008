import { FileDownloadOutlined } from "@mui/icons-material";
import { CardHeader, Grid, Typography } from "@mui/material";
import {
  CardFooter,
  CommitmentsLabels,
  getAsOfLine,
  groupForecastedCapitalCallsByStrategy,
  IconButtonWithTooltip,
  IForecastedCapCallByStrategy,
  IForecastedCapCalls,
  SectionHeader,
  StringConstants,
  useExcelDownload,
  worksheetGenerators,
} from "common";
import React from "react";

import { ForecastedCapitalCallsGrid } from "./ForecastedCapitalCallsGrid/ForecastedCapitalCallsGrid";

interface IForecastedCapitalCallsProps {
  forecastedCapitalCalls: IForecastedCapCalls;
}

export const ForecastedCapitalCalls = (props: IForecastedCapitalCallsProps) => {
  const { forecastedCapitalCalls } = props;

  const forecastedCapCallsByStrategy: IForecastedCapCallByStrategy[] =
    groupForecastedCapitalCallsByStrategy(forecastedCapitalCalls.capitalCalls);

  const handleExcelDownload = useExcelDownload();
  const handleDownloadClick = async () => {
    const fileName = `${SectionHeader.FORECASTED_CAPITAL_CALLS}`;
    await handleExcelDownload(
      worksheetGenerators.ForecastedCapitalCalls,
      fileName
    );
  };

  return (
    <>
      <CardHeader
        title={
          <Typography variant="h3">
            {SectionHeader.FORECASTED_CAPITAL_CALLS}
          </Typography>
        }
        subheader={
          <Typography variant="subtitle1">
            {getAsOfLine(forecastedCapitalCalls.asOfDate)}
          </Typography>
        }
        action={
          <IconButtonWithTooltip
            onClickFunction={handleDownloadClick}
            buttonIcon={FileDownloadOutlined}
            hoverText={StringConstants.DOWNLOAD}
          />
        }
      />
      <Grid container flex="column">
        <ForecastedCapitalCallsGrid
          forecastedCapCallsByStrategy={forecastedCapCallsByStrategy}
        />
      </Grid>

      <CardFooter footerLines={[CommitmentsLabels.FORECASTED_FOOTNOTE]} />
    </>
  );
};
