import { PerformanceDetailsPage } from "common";
import React from "react";

interface IPerformanceDetailsPage {
  hideHeader?: boolean;
}

export const PerformanceDetailsPageWrapper = (
  props: IPerformanceDetailsPage
) => {
  return <PerformanceDetailsPage hideHeader={props.hideHeader} />;
};
