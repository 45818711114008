import { Dashboard, IAuthorizedComponentProps } from "common";
import React from "react";

const DashboardWrapper = (props: IAuthorizedComponentProps) => {
  // components renders only if user is already authenticated
  const { user } = props;
  return (
    <Dashboard
      user={user}
      showUserWelcome={false}
      //TODO: update when we integrate w equity
      hasViewStockEntitlement={false}
      hasViewRestrictedEquity={false}
    />
  );
};

export default DashboardWrapper;
