import { Circle, CircleOutlined } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { getLabelFromCamelCase, InvestmentHistoryValueItem } from "common";
import React from "react";

import styles from "./HoverableLegendLabel.module.scss";

interface HoverableLegendLabelProps {
  className?: string;
  dataType: InvestmentHistoryValueItem;
  id?: string;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
  isOutlined?: boolean;
}

export const HoverableLegendLabel = (props: HoverableLegendLabelProps) => {
  const { className, dataType, id, onMouseEnter, onMouseLeave, isOutlined } =
    props;

  let finalClassName = styles.legendLabel;
  if (className !== undefined) finalClassName += ` ${className}`;

  return (
    <Grid
      container
      className={finalClassName}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span className={styles.legendCircle} id={id || ""}>
        {isOutlined && <CircleOutlined />}
        {!isOutlined && <Circle sx={{ color: dataType.color }} />}
      </span>
      <div>{dataType.labelOverride ?? getLabelFromCamelCase(dataType.key)}</div>
    </Grid>
  );
};
