import {
  colors,
  formatTickByUnit,
  ICarryChartData,
  IHoldbackPerFund,
  IHoverableChartLegend,
  isDefinedNumber,
  UnrealizedValue,
} from "common";

import { IInvestmentChartData } from "../types/dataTypes";

export interface ChartTickInformation {
  tickValues: number[];
  tickLabels: string[];
}

export const POSSIBLE_OTHER_VALUES = ["Other", "Other Funds"];

/**
 * Returns a color based on a given index
 * @param legendIndex - The list index of the legend
 * @returns string value
 */
export const getChartCellColorByIndex = (legendIndex: number): string => {
  const COLORS = [
    colors.avocado,
    colors.emerald,
    colors.taupe,
    colors.slate,
    colors.cobalt,
    colors.chartreuse,
    colors.avocado_shade,
    colors.emerald_shade,
  ];
  // (legendIndex % COLORS.length) allows us to get a index in the range if the index is greater than the length of the color list
  return COLORS[legendIndex % COLORS.length];
};

/**
 * Returns a list of hoverable chart legends
 * @param carryDataList - A list of carry data
 * @returns IHoverableChartLegend[]
 */
export const mapCarryDataToChartLegends = (
  carryDataList: ICarryChartData[],
  handleLegendClick?: (value: string) => void,
  isFundLevel?: boolean
): IHoverableChartLegend[] => {
  return carryDataList.map((carryData, index) => {
    return {
      value: carryData[isFundLevel ? "fundShortName" : "businessUnitName"],
      color: getChartCellColorByIndex(index),
      handleClick: handleLegendClick,
    } as IHoverableChartLegend;
  });
};

/**
 * Returns a list of hoverable chart legends
 * @param investmentDataList - A list of investment data
 * @returns IHoverableChartLegend[]
 */
export const mapInvestmentDataToChartLegends = (
  investmentDataList: IInvestmentChartData[],
  handleLegendClick?: (value: string) => void,
  isFundLevel?: boolean
): IHoverableChartLegend[] => {
  return investmentDataList
    .map((investmentData: IInvestmentChartData, index: number) => {
      return {
        value:
          investmentData[isFundLevel ? "fundShortName" : "businessUnitName"],
        color: getChartCellColorByIndex(index),
        handleClick: handleLegendClick,
      } as IHoverableChartLegend;
    })
    .sort((a, b) => (a.value > b.value ? 1 : -1));
};

/**
 * Returns a list of hoverable chart legends
 * @param holdbackPerFundList - A list of holdback per fund
 * @returns IHoverableChartLegend[]
 */
export const mapHoldbackPerFundToChartLegends = (
  holdbackPerFundList: IHoldbackPerFund[]
): IHoverableChartLegend[] => {
  return holdbackPerFundList.map((holdbackPerFund, index) => {
    return {
      value: holdbackPerFund.fundName,
      color: getChartCellColorByIndex(index),
    } as IHoverableChartLegend;
  });
};

/**
 * Returns a list of hoverable chart legends
 * @param unrealizedValues - A list of unrealized values
 * @returns IHoverableChartLegend[]
 */
export const mapUnrealizedValuesToChartLegends = (
  unrealizedValues: UnrealizedValue[]
): IHoverableChartLegend[] => {
  return unrealizedValues.map((unrealizedValue, index) => {
    return {
      value: unrealizedValue.strategyName,
      color: getChartCellColorByIndex(index),
    } as IHoverableChartLegend;
  });
};

export const getTicksAndLabels = (
  values: (number | undefined)[],
  minTicks = 7,
  maxTicks = 11
): ChartTickInformation => {
  // Filter out any undefined or NaN values which can throw off recharts
  const definedValues: number[] = values.filter(isDefinedNumber);
  const maxValue = Math.max(...definedValues, 0);
  const minValue = Math.min(...definedValues, 0);
  const range = maxValue - minValue;

  // calculate base unit for ticks, starts at the same magnitude as the range
  // eg if minValue=-20,000, maxValue=90,000, range=110,000, unit=100,000
  const power = String(range).length - 1;
  let unit = Math.pow(10, power);

  // Halve unit in cases where the data range is only a little above the unit
  // This helps avoid graphs with excessive white space at the top
  if (range <= 3 * unit) unit /= 2;

  let [minTick, maxTick] = calculateMinMaxTick(minValue, maxValue, unit);
  let tickRange = maxTick - minTick;

  // if we have a unit size that will result in too many ticks, increase it
  // by multiples of 5 until we get an appropriate number to work with
  // recalculate min and max ticks based off of new unit size
  while (tickRange >= maxTicks * unit) {
    unit *= 5;
    [minTick, maxTick] = calculateMinMaxTick(minValue, maxValue, unit);
    tickRange = maxTick - minTick;
  }

  // pick appropriate number of grid lines. Default to unit size.
  // if too few ticks, divide interval by two to provide more
  let tickInterval = unit;
  while (tickRange / tickInterval < minTicks - 1 && tickInterval >= 1) {
    tickInterval /= 2;
  }

  const ticks: number[] = [];
  for (let i = minTick; i <= maxTick; i += tickInterval) {
    ticks.push(i);
  }

  // Always label zero
  // Label every other negative tick starting from most negative
  // Label every other positive tick staritng from most positive
  const tickLabels = ticks.map((tick, index, allTicks) => {
    if (
      tick === 0 ||
      (tick < 0 && index % 2 === 0) ||
      (tick > 0 && (allTicks.length - index) % 2 === 1)
    ) {
      return formatTickByUnit(tick);
    }
    return "";
  });

  return {
    tickValues: ticks,
    tickLabels: tickLabels,
  };
};

const calculateMinMaxTick = (
  minValue: number,
  maxValue: number,
  unit: number
) => {
  // min is next tick unit below our min value
  // max is next tick unit above our max value
  const minTick = Math.floor(minValue / unit) * unit;
  const maxTick = Math.ceil(maxValue / unit) * unit;
  return [minTick, maxTick];
};
