import { ThemeOptions } from "@mui/material";

import colors from "../../_colors.scss";

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          "&.Mui-checked": {
            color: colors.black,
          },
          "&.Mui-checked+.MuiSwitch-track": {
            backgroundColor: colors.dark_grey,
          },
        },
      },
    },
  },
};

export const CustomSwitchTheme = partialTheme.components?.MuiSwitch;
