import { Grid } from "@mui/material";
import {
  IHistoricalSummaryDatum,
  InvestmentBreakdownKey,
  InvestmentBreakDownValueSelectors,
  InvestmentHistoryValueItem,
} from "common";
import React from "react";

import { HoverableLegendLabel } from "../../../../components/HoverableLegendLabel/HoverableLegendLabel";
import styles from "./CashFlowLegend.module.scss";

interface CashFlowLegendProps {
  historicalSummaryData: IHistoricalSummaryDatum[];
  isCumulative: boolean;
  dataTypes: InvestmentHistoryValueItem[];
  highlightedBar: InvestmentBreakdownKey | null;
  setHighlightedBar: (value: InvestmentBreakdownKey | null) => void;
}

export const CashFlowLegend = (props: CashFlowLegendProps) => {
  return (
    <Grid className={styles.horizontalLegend}>
      {props.dataTypes.map(
        (type, key) =>
          props.historicalSummaryData.some((item) =>
            props.isCumulative
              ? InvestmentBreakDownValueSelectors[type.key](item.cumulative) !==
                0
              : InvestmentBreakDownValueSelectors[type.key](
                  item.nonCumulative
                ) !== 0
          ) && (
            <HoverableLegendLabel
              dataType={type}
              key={key}
              onMouseEnter={() => props.setHighlightedBar(type.key)}
              onMouseLeave={() => props.setHighlightedBar(null)}
              isOutlined={type.key === InvestmentBreakdownKey.NET_CASH_FLOW}
            />
          )
      )}
    </Grid>
  );
};
