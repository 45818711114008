import {
  CardHeader,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  BreakpointConstants,
  getAsOfLine,
  SectionHeader,
  VestingEventsRange,
  VestingEventsRangeLabel,
} from "common";
import React, { useState } from "react";

import styles from "./VestingEventsBar.module.scss";

interface VestingEventsBarProps {
  showDateRangeToggle: boolean;
  asOfDate?: Date;
  setDataByRangeDate: (vestingEventRange: VestingEventsRange) => void;
}

export const VestingEventsBar = ({
  showDateRangeToggle,
  setDataByRangeDate,
  asOfDate,
}: VestingEventsBarProps) => {
  const [vestingEventRange, setVestingEventRange] =
    useState<VestingEventsRange>(VestingEventsRange.THREE_YEARS);

  /**
   * Sets the state of vestingEventRange and call the setDataByRangeDate callback with
   * the vestingEventRange when fired.
   * @param event - The event source of the callback.
   * @param currentEventRange - The selected vesting event range
   * @returns
   */
  const onRangeChange = (
    event: React.MouseEvent<HTMLElement>,
    currentEventRange: VestingEventsRange
  ) => {
    if (currentEventRange !== null) {
      setVestingEventRange(currentEventRange);
      setDataByRangeDate(currentEventRange);
    }
  };

  const isMobile = useMediaQuery(
    `(max-width:${BreakpointConstants.EXTRA_SMALL_MAX_WIDTH}px)`
  );

  return (
    <div className={styles.headerBar}>
      <CardHeader
        title={
          <>
            <Typography variant="h3">
              {SectionHeader.UPCOMING_VESTING_EVENTS}
            </Typography>
            {asOfDate ? (
              <Typography variant="subtitle1">
                {getAsOfLine(asOfDate)}
              </Typography>
            ) : (
              <></>
            )}
          </>
        }
      />
      {showDateRangeToggle && (
        <ToggleButtonGroup
          value={vestingEventRange}
          exclusive
          onChange={onRangeChange}
          className={styles.dateRangeToggle}
          fullWidth={isMobile}
        >
          <ToggleButton
            className={styles.dateRangeToggleButton}
            value={VestingEventsRange.THREE_YEARS}
          >
            {VestingEventsRangeLabel.THREE_YEARS}
          </ToggleButton>
          <ToggleButton
            className={styles.dateRangeToggleButton}
            value={VestingEventsRange.ALL}
          >
            {VestingEventsRangeLabel.ALL}
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </div>
  );
};
