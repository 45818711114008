import { Box, Card, useMediaQuery } from "@mui/material";
import React from "react";

import { InfoTooltip } from "../../../../../components/InfoTooltip/InfoTooltip";
import { BreakpointConstants } from "../../../../../constants/BreakpointConstants";
import { EquityLabel } from "../../../../../constants/LabelAndTooltipConstants";
import {
  getEquityValues,
  getMobileLabels,
} from "../../../../../utils/restrictedEquityCalloutUtils";
import styles from "./RestrictedEquityCallout.module.scss";

interface IRestrictedEquityCalloutProps {
  tooltipParagraphs?: string[];
  total: string;
  unvestedTotal: string;
  unvestedTooltipsParagraphs?: string[];
  vestedRestrictedTotal: string;
  vestedRestrictedTooltipsParagraphs?: string[];
  vestedExchangeableTotal: string;
  vestedExchangeableTooltipsParagraphs?: string[];
  showTooltip?: boolean;
  unit: string;
  showVestedExchangeable: boolean;
}

export interface IRestrictedEquityValueProps {
  label: string;
  value: string;
  tooltipsParagraphs?: string[];
  showTooltip?: boolean;
}

const RestrictedEquityValue = (props: IRestrictedEquityValueProps) => {
  const { label, value, tooltipsParagraphs, showTooltip } = props;
  return (
    <Box className={styles.valueContainer}>
      <Box className={styles.valueLabel}>
        <span>{label}</span>
        {showTooltip && (
          <InfoTooltip tooltipContent={tooltipsParagraphs ?? [""]} />
        )}
      </Box>
      <Box className={styles.innerValue}>
        <span>{value}</span>
      </Box>
    </Box>
  );
};

export const RestrictedEquityCallout = (
  props: IRestrictedEquityCalloutProps
) => {
  const {
    total,
    unvestedTotal,
    unvestedTooltipsParagraphs,
    vestedRestrictedTotal,
    vestedRestrictedTooltipsParagraphs,
    vestedExchangeableTotal,
    vestedExchangeableTooltipsParagraphs,
    showTooltip,
    unit,
    showVestedExchangeable,
  } = props;

  const isMobile = useMediaQuery(
    `(max-width:${BreakpointConstants.SMALL_MAX_WIDTH}px)`
  );

  const equityValues = getEquityValues(
    unvestedTotal,
    isMobile,
    unit,
    showVestedExchangeable,
    unvestedTooltipsParagraphs,
    vestedRestrictedTotal,
    vestedRestrictedTooltipsParagraphs,
    vestedExchangeableTotal,
    vestedExchangeableTooltipsParagraphs,
    showTooltip
  );

  return (
    <Card className={styles.container}>
      <Box className={styles.totalContainer}>
        <Box className={styles.totalValueLabel}>
          <span>
            {isMobile
              ? getMobileLabels(unit, EquityLabel.TOTAL)
              : EquityLabel.TOTAL}
          </span>
        </Box>
        <Box className={styles.value}>
          <span>{total}</span>
        </Box>
      </Box>
      <Box className={styles.breakdown}>
        {equityValues.map((equityValue, index) => (
          <React.Fragment key={index}>
            <RestrictedEquityValue
              label={equityValue.label}
              value={equityValue.value}
              tooltipsParagraphs={equityValue.tooltipsParagraphs}
              showTooltip={equityValue.showTooltip}
            />
            {index < equityValues.length - 1 && (
              <Box className={styles.separator} />
            )}
          </React.Fragment>
        ))}
      </Box>
    </Card>
  );
};
