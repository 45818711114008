import ExcelJS from "exceljs";

import { StringConstants } from "../../constants/StringConstants";
import {
  WorksheetGenerator,
  WorksheetGeneratorParams,
  WorksheetGenerators,
} from "../../types/excelTypes";
import { buildCarryDataWorksheet } from "./carryDataExcelUtils";
import {
  buildAnnualCommitmentsWorksheet,
  buildForecastedCapCallsWorksheet,
  buildLOFCommitmentsWorksheet,
} from "./commitmentsExcelUtils";
import { buildElectionResultWorksheet } from "./electionResultExcelUtils";
import { buildUnrealizedValuesWorksheet } from "./electionsExcelUtils";
import {
  buildActiveAwardDetailsWorksheet,
  buildEquityDistributionsWorksheet,
} from "./equityDataExcelUtils";
import { buildLoansWorksheet } from "./financingBalanceExcelUtils";
import { buildInvestmentBreakdownWorksheet } from "./investmentBreakdownExcelUtils";
import {
  buildCashFlowWorksheet,
  buildPortfolioPerformanceWorksheet,
  guildPortfolioBalanceWorksheet,
} from "./investmentHistoryExcelUtils";
import { buildOverviewDataWorksheet } from "./overviewDataExcelUtils";
import { buildSegTrustBalanceWorksheet } from "./segTrustExcelUtils";

export const worksheetGenerators: WorksheetGenerators = {
  CarryData: buildCarryDataWorksheet,
  PortfolioPerformance: buildPortfolioPerformanceWorksheet,
  PortfolioBalance: guildPortfolioBalanceWorksheet,
  CashFlow: buildCashFlowWorksheet,
  ForecastedCapitalCalls: buildForecastedCapCallsWorksheet,
  LifeOfFundCommitments: buildLOFCommitmentsWorksheet,
  AnnualCommitments: buildAnnualCommitmentsWorksheet,
  Loans: buildLoansWorksheet,
  OverviewData: buildOverviewDataWorksheet,
  InvestmentBreakdown: buildInvestmentBreakdownWorksheet,
  SegregatedTrustBalance: buildSegTrustBalanceWorksheet,
  ActiveAwardDetails: buildActiveAwardDetailsWorksheet,
  UnrealizedValues: buildUnrealizedValuesWorksheet,
  EquityDistributions: buildEquityDistributionsWorksheet,
  ElectionResult: buildElectionResultWorksheet,
};

export const createExcel = async (
  generator: WorksheetGenerator,
  params: Pick<
    WorksheetGeneratorParams,
    Exclude<keyof WorksheetGeneratorParams, "workbook">
  >
) => {
  // create workbook and sheet
  const workbook = new ExcelJS.Workbook();
  workbook.created = new Date();
  generator({ workbook, ...params });

  const buffer = await workbook.xlsx.writeBuffer();
  const file = new File(
    [buffer],
    `${StringConstants.INVESTMENT_BREAKDOWN}.xlsx`
  );
  return file;
};
