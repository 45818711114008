import React from "react";

import { IDashboardCardContentProps } from "../../../../components/DashboardCard/DashboardCard";
import { BalanceRow } from "../../../../components/LoansCard/BalanceRow/BalanceRow";
import { IFinancingBalance } from "../../../../types/dataTypes";

export const LoanBalance = (
  props: IDashboardCardContentProps<IFinancingBalance>
) => {
  const financingData = props.data;

  const tableHeaderCols = [""];

  return (
    <>
      <BalanceRow
        financingData={financingData}
        shouldRenderGraph={true}
        headerCols={tableHeaderCols}
      />
    </>
  );
};
