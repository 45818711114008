import { Tooltip } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import React from "react";

export const TooltipCellRenderer = (props: ICellRendererParams) => (
  <Tooltip
    PopperProps={{
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, -10],
          },
        },
      ],
    }}
    placement={"bottom-start"}
    title={props.valueFormatted ?? props.value}
  >
    <span>{props.valueFormatted ?? props.value}</span>
  </Tooltip>
);
