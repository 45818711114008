import { createSelector } from "@reduxjs/toolkit";
import { isSomething } from "common";

import { builInvestmentVehicleIdForUI } from "../../utils/investmentEntityDropdownUtils";
import { selectInternalInvestmentData } from "./baseStoreSelectors";

export const selectActiveInvestmentEntityDropdownItemId = createSelector(
  [selectInternalInvestmentData],
  ({ filteredBy }) => {
    if (!isSomething(filteredBy)) {
      return "";
    }

    const clientId = filteredBy.value.clientId;
    const investmentVehicleId = filteredBy.value.investmentVehicleId;

    return investmentVehicleId === undefined
      ? clientId
      : builInvestmentVehicleIdForUI(clientId, investmentVehicleId);
  }
);
