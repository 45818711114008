import { createSelector } from "@reduxjs/toolkit";
import { isSomething, nothing, Optional, some } from "common";

import { selectInternalInvestmentData } from "./baseStoreSelectors";

export const selectActiveInternalInvestmentDataClientId = createSelector(
  [selectInternalInvestmentData],
  ({ filteredBy }) => {
    return (
      isSomething(filteredBy) && filteredBy.value
        ? some(filteredBy.value.clientId)
        : nothing
    ) as Optional<string>;
  }
);
