import {
  DashboardCardTable,
  ISegregatedTrustBalance,
  SegregatedTrustTableLabel,
} from "common";
import React, { useState } from "react";

interface IHoldbackOverviewProps {
  segTrustData: ISegregatedTrustBalance;
}

export const HoldbackOverviewRow = (props: IHoldbackOverviewProps) => {
  const { segTrustData } = props;
  //Set expand/collapse childs for total holdback
  const [isTotalHoldbackOpen, setTotalHoldbackOpen] = useState(false);
  //Set expand/collapse childs for total collateral
  const [isTotalCollateralOpen, setTotalCollateralOpen] = useState(false);

  return (
    <DashboardCardTable
      header={SegregatedTrustTableLabel.HOLDBACK_OVERVIEW}
      headerCols={[""]}
      rows={[
        {
          name: SegregatedTrustTableLabel.TOTAL_HOLDBACK,
          value: segTrustData.totalHoldbackValue,
          isOpen: isTotalHoldbackOpen,
          setIsOpen: setTotalHoldbackOpen,
          children: [
            {
              name: SegregatedTrustTableLabel.HOLDBACK_INVESTED,
              value: segTrustData.holdbackInvested,
            },
            {
              name: SegregatedTrustTableLabel.HOLDBACK_CASH,
              value: segTrustData.holdbackCash,
            },
          ],
        },
        {
          name: SegregatedTrustTableLabel.TOTAL_COLLATERAL,
          value: segTrustData.totalCollateralValue,
          isOpen: isTotalCollateralOpen,
          setIsOpen: setTotalCollateralOpen,
          children: [
            {
              name: SegregatedTrustTableLabel.REQUIRED_COLLATERALIZATION,
              value: segTrustData.requiredCollateralization,
            },
            {
              name: SegregatedTrustTableLabel.OVER_UNDER_COLLAT,
              value: segTrustData.excessCollateralization,
            },
          ],
        },
      ]}
    />
  );
};
