import { BusinessUnitTreeLevel, TreeNumber } from "../constants/enums";
import { UnrealizedValueLabels } from "../constants/LabelAndTooltipConstants";
import {
  IInvestmentBreakdownDatum,
  IInvestmentChartData,
} from "../types/dataTypes";
import { isSomething } from "../types/typeGuards";

export const MAX_INVESTMENT_ITEMS_TO_SHOW = 6;

//Return an array of IInvestmentBreakdownDatum filtered by business unit
export const filterToPositiveInvestmentsByBusinessUnit = (
  performanceData: IInvestmentBreakdownDatum[]
): IInvestmentBreakdownDatum[] => {
  const unrealizedInvestmentByBusinessUnit: IInvestmentBreakdownDatum[] =
    performanceData.filter((row: IInvestmentBreakdownDatum) => {
      // Validate if the optionalAndMandatoryInvestments has a value higher than 0
      const hasPositiveInvestments =
        isSomething(row.unrealizedValue) &&
        row.unrealizedValue.value.optionalAndMandatoryInvestments > 0;

      /*
      Validate if current performanceData treeLevel is BUSINESS_UNIT
      investor-ui: only want positive unrealized investments for the donut
        chart because negative values don't really make sense on
        that chart
      */
      return (
        row.treeLevel === BusinessUnitTreeLevel.BUSINESS_UNIT &&
        hasPositiveInvestments
      );
    });
  return unrealizedInvestmentByBusinessUnit;
};

//Return an array of IInvestmentBreakdownDatum filtered by fund
export const filterToPositiveInvestmentsByFund = (
  performanceData: IInvestmentBreakdownDatum[],
  businessUnitName?: string
): IInvestmentBreakdownDatum[] => {
  const unrealizedInvestmentByFund: IInvestmentBreakdownDatum[] =
    performanceData.filter((row: IInvestmentBreakdownDatum) => {
      // Validate if the optionalAndMandatoryInvestments has a value higher than 0
      const hasPositiveInvestments =
        isSomething(row.unrealizedValue) &&
        row.unrealizedValue.value.optionalAndMandatoryInvestments > 0;

      const belongsToBusinessUnit =
        !businessUnitName || row.businessUnitName === businessUnitName;

      /*
      Validate if current performanceData treeLevel is FUND in treeName
      and treeNumber is BUSINESS_UNIT in treeName
      investor-ui: only want positive unrealized investments for the donut
        chart because negative values don't really make sense on
        that chart
      */
      return (
        row.treeLevel === BusinessUnitTreeLevel.FUND &&
        row.treeNumber === TreeNumber.BUSINESS_UNIT &&
        belongsToBusinessUnit &&
        hasPositiveInvestments
      );
    });
  return unrealizedInvestmentByFund;
};

//Create an IInvestmentChart array with total unrealized values by business unit
export const createInvestmentChartList = (
  investmentsFilteredByBusinessUnit: IInvestmentBreakdownDatum[]
) => {
  const investmentChartList: IInvestmentChartData[] = [];

  Object.values(investmentsFilteredByBusinessUnit).forEach((row) => {
    const businessUnitName: string = row.businessUnitName;
    const fundShortName: string = row.fundShortName;
    const mdmFundId: number = row.mdmFundId;
    if (isSomething(row.unrealizedValue)) {
      const unrealizedValue = row.unrealizedValue.value;
      investmentChartList.push({
        businessUnitName,
        fundShortName,
        mdmFundId,
        unrealizedValue,
      });
    }
  });
  return investmentChartList;
};

// Receives an investment chart list, first sorts the input
// to get all sorted in decreasing order. Right after, keeps the
// top 5 items and reduces all the rest in one new group called Other.
export const sortAndReduceInvestmentCardData = (
  investmentChartList: IInvestmentChartData[],
  isFundLevel?: boolean
) => {
  const sortedList = investmentChartList.sort((a, b) =>
    a.unrealizedValue.optionalAndMandatoryInvestments >
    b.unrealizedValue.optionalAndMandatoryInvestments
      ? -1
      : 1
  );
  if (sortedList.length > MAX_INVESTMENT_ITEMS_TO_SHOW) {
    // when we have more than MAX funds, we want only want to show MAX - 1 then group rest into an Other category
    // sum unrealized of the funds after the top MAX - 1
    const otherFunds = sortedList
      .slice(MAX_INVESTMENT_ITEMS_TO_SHOW - 1)
      .reduce(
        (a, v) => {
          a.unrealizedValue = {
            asOfDate: v.unrealizedValue.asOfDate,
            remainingCapitalInvested:
              a.unrealizedValue.remainingCapitalInvested +
              v.unrealizedValue.remainingCapitalInvested,
            gainLoss: a.unrealizedValue.gainLoss + v.unrealizedValue.gainLoss,
            optionalAndMandatoryInvestments:
              a.unrealizedValue.optionalAndMandatoryInvestments +
              v.unrealizedValue.optionalAndMandatoryInvestments,
            carriedInterest:
              a.unrealizedValue.carriedInterest +
              v.unrealizedValue.carriedInterest,
            total: a.unrealizedValue.total + v.unrealizedValue.total,
          };
          return a;
        },
        {
          businessUnitName: UnrealizedValueLabels.OTHER,
          fundShortName: isFundLevel
            ? UnrealizedValueLabels.OTHER_FUNDS
            : UnrealizedValueLabels.OTHER,
          mdmFundId: 11827755,
          unrealizedValue: {
            asOfDate: new Date(),
            remainingCapitalInvested: 0,
            gainLoss: 0,
            optionalAndMandatoryInvestments: 0,
            carriedInterest: 0,
            total: 0,
          },
        }
      );
    return [
      ...sortedList.slice(0, MAX_INVESTMENT_ITEMS_TO_SHOW - 1),
      otherFunds,
    ];
  }
  return sortedList;
};
