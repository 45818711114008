import { DataLoadStatus, StockSymbol } from "../constants/enums";
import {
  IAsOfDate as IAsOfDateCollection,
  IDistributionsDataBySymbol,
  IDocumentResponse,
  IEquityDataBySymbol,
  IInvestmentBreakdownDatum,
  IInvestmentBreakdownGrouped,
  IStockDataBySymbol,
} from "./dataTypes";
import { IEntityDataStore, IInternalInvestmentDataStore } from "./storeTypes";
import { nothing } from "./typeUtils";

export const EMPTY_INVESTMENT_BREAKDOWN_DATA: IInvestmentBreakdownGrouped = {
  [2]: [],
  [1]: [],
};

export const EMPTY_EQUITY_DATA: IEquityDataBySymbol = {
  [StockSymbol.BX]: nothing,
  [StockSymbol.BXMT]: nothing,
  [StockSymbol.BXRT]: nothing,
};

export const EMPTY_STOCK_DATA: IStockDataBySymbol = {
  [StockSymbol.BX]: nothing,
  [StockSymbol.BXMT]: nothing,
  [StockSymbol.BXRT]: nothing,
};

export const EMPTY_DISTIBUTIONS_DATA: IDistributionsDataBySymbol = {
  [StockSymbol.BX]: nothing,
  [StockSymbol.BXMT]: nothing,
  [StockSymbol.BXRT]: nothing,
};

export const EMPTY_DOCUMENT_RESPONSE: IDocumentResponse = {
  documents: [],
  documentsCount: 0,
  offset: 0,
  limit: 50,
};

export const EMPTY_AS_OF_DATE_COLLECTION: IAsOfDateCollection = {
  earliestAsOfDate: new Date(),
  latestAsOfDateWithUnrealizedData: nothing,
  latestAsOfDate: new Date(),
  segTrustBalanceDate: nothing,
};

export const DEFAULT_INTERNAL_INVESTMENT_STATE: IInternalInvestmentDataStore = {
  filteredBy: nothing,
  entities: [],
  internalInvestmentDataLoadStatus: DataLoadStatus.NOT_REQUESTED,
};

export const EMPTY_INTERNAL_INVESTMENT_STATE: IInternalInvestmentDataStore = {
  entities: [],
  filteredBy: nothing,
  internalInvestmentDataLoadStatus: DataLoadStatus.EMPTY_RESPONSE,
};

export const DEFAULT_ENTITY_STATE: IEntityDataStore = {
  entityId: nothing,
  entityType: nothing,
  entityName: nothing,
  asOfDates: EMPTY_AS_OF_DATE_COLLECTION,
  commitmentData: nothing,
  financingBalance: nothing,
  historicalSummary: [],
  investmentBreakdown: [],
  segregatedTrustBalance: nothing,
};

export const EMPTY_INVESTMENT_BREAKDOWN_DATUM: IInvestmentBreakdownDatum = {
  period: "",
  treeNumber: 1,
  treeName: "",
  treeLevel: 0,
  fundId: "",
  fundName: "",
  fundShortName: "",
  mdmFundId: 0,
  businessUnitId: "",
  businessUnitName: "",
  trancheName: "",
  investmentDate: new Date(),
  investment: nothing,
  realizedProceeds: nothing,
  unrealizedValue: nothing,
};
