import { ThemeOptions } from "@mui/material";

import { Font } from "../sharedStyleConstants";

// Shared constants we may want to move in unison

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiAlert: {
      defaultProps: {
        variant: "filled",
      },
      styleOverrides: {
        root: {
          fontFamily: Font.Family.GUARDIAN_SANS,
          fontWeight: Font.Weight.STANDARD,
        },
        action: {
          padding: "0 0 0 16px",
        },
      },
    },
  },
};

export const CustomAlertTheme = partialTheme.components?.MuiAlert;
