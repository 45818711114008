import { IFinancingBalance } from "../types/dataTypes";

/*
Helper function that takes financing data and validates if should show the loan card
*/
export const showLoanCard = (
  financingData: IFinancingBalance,
  setShowFinancingData: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    //Show loans card if any of the next values isn't zero
    const showLoandCardYN =
      financingData.firmFinancingBalance !== 0 ||
      financingData.thirdPartyFinancingBalance !== 0 ||
      financingData.thirdPartyLoanLimit !== 0 ||
      financingData.thirdPartyPendingLoanBalance !== 0 ||
      financingData.thirdPartyRemainingLoanCapacity !== 0 ||
      financingData.totalFinancingBalance !== 0;
    setShowFinancingData(showLoandCardYN);
  } catch {
    setShowFinancingData(false);
  }
};

/*
Helper function that takes financing data and validates if should show the loan tab
*/
export const showLoanTab = (financingData: IFinancingBalance): boolean => {
  //Show loans tab if any of the next values isn't zero
  const showLoandTabYN =
    financingData.firmFinancingBalance !== 0 ||
    financingData.thirdPartyFinancingBalance !== 0 ||
    financingData.thirdPartyLoanLimit !== 0 ||
    financingData.thirdPartyPendingLoanBalance !== 0 ||
    financingData.thirdPartyRemainingLoanCapacity !== 0 ||
    financingData.totalFinancingBalance !== 0;
  return showLoandTabYN;
};
