import React from "react";

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  selectedTabValue: number;
}

/**
 * Simple dynamically-shown to be used with MUI's Tab component
 * Copied from https://mui.com/material-ui/react-tabs/
 */
export const TabPanel = (props: ITabPanelProps) => {
  const { children, selectedTabValue, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={selectedTabValue !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {selectedTabValue === index && children}
    </div>
  );
};
