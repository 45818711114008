import { Grid } from "@mui/material";
import { GridReadyEvent } from "ag-grid-community";
import { ColDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import {
  aggregateCapCallForGrid,
  aggregateCapCallPercentageForGrid,
  aggregateElectedAmntForGrip,
  CommitmentsLabels,
  CommitmentsTooltips,
  currencyFormatter,
  GridAsTable,
  GridHeaderWithTooltip,
  ICommitmentAnnual,
  toEntityNameString,
  TooltipCellRenderer,
  toPercentNoDecimal,
  useGridExtensions,
} from "common";
import React, { useMemo, useRef, useState } from "react";

import { SortDirection } from "../../../../../constants/enums";
import styles from "./AnnualCommitmentsGrid.module.scss";

interface IAnnualCommitmentsGridProps {
  annualCommitments: ICommitmentAnnual[];
}

export const AnnualCommitmentsGrid = (props: IAnnualCommitmentsGridProps) => {
  const { annualCommitments } = props;
  const [, setIsGridReady] = useState<boolean>(false);

  const gridRef = useRef<AgGridReact>(null);

  const {
    setHeaderHeight,
    resizeColumns,
    onGridReady: OnGridReadyCommon,
  } = useGridExtensions("viewByColumn");

  const onGridReady = (params: GridReadyEvent) => {
    setIsGridReady(true);
    OnGridReadyCommon(params);

    // Set the sort state
    params.api.applyColumnState({
      state: [
        { colId: "electionYear", sort: SortDirection.DESC },
        { colId: "fundName", sort: SortDirection.ASC },
      ],
    });
  };

  const annualColumnDefs: ColDef<ICommitmentAnnual>[] = useMemo(
    () => [
      {
        field: "electionYear",
        rowGroup: true, // forces grouping by election year
        hide: true, // hide as we want to combine this grouping col w the fund col
      },
      {
        field: "fundName",
        headerName: CommitmentsLabels.COMMITMENT_YEAR,
        minWidth: 160,
        valueFormatter: toEntityNameString,
        showRowGroup: true,
        cellRenderer: "agGroupCellRenderer", // show the grouped year within the fund data col
        cellRendererParams: {
          suppressCount: true,
          innerRenderer: TooltipCellRenderer,
        },
      },
      {
        field: "electedAmount",
        headerName: CommitmentsLabels.ELECTED_AMOUNT,
        minWidth: 150,
        type: "rightAligned",
        valueFormatter: currencyFormatter,
        headerComponent: GridHeaderWithTooltip,
        headerComponentParams: {
          labelClass: "ag-sub-header-group-cell-label",
          textClass: "ag-sub-header-group-text",
          rightAlign: true,
          tooltipContent: CommitmentsTooltips.ANNUAL_ELECTED_AMNT_TOOLTIP,
        },
        aggFunc: aggregateElectedAmntForGrip,
      },
      {
        field: "capitalCalled",
        headerName: CommitmentsLabels.CAPITAL_CALLED,
        minWidth: 150,
        type: "rightAligned",
        valueFormatter: currencyFormatter,
        headerComponent: GridHeaderWithTooltip,
        headerComponentParams: {
          labelClass: "ag-sub-header-group-cell-label",
          textClass: "ag-sub-header-group-text",
          rightAlign: true,
          tooltipContent: CommitmentsTooltips.ANNUAL_CAPITAL_CALLED_TOOLTIP,
        },
        aggFunc: aggregateCapCallForGrid,
      },
      {
        field: "capitalCalledPercent",
        headerName: CommitmentsLabels.CAPITAL_CALLED_PERCENT,
        minWidth: 150,
        type: "rightAligned",
        valueFormatter: toPercentNoDecimal,
        aggFunc: aggregateCapCallPercentageForGrid,
      },
    ],
    []
  );

  return (
    <Grid id={styles.grid} item xs={12} className={`ag-theme-alpine`}>
      <GridAsTable<ICommitmentAnnual>
        ref={gridRef}
        rowData={annualCommitments}
        columnDefs={annualColumnDefs}
        domLayout="autoHeight"
        onFirstDataRendered={setHeaderHeight}
        onColumnResized={setHeaderHeight}
        cacheQuickFilter={true}
        suppressAggFuncInHeader={true}
        suppressContextMenu={true}
        suppressCellFocus={true}
        onRowDataUpdated={resizeColumns}
        onGridSizeChanged={resizeColumns}
        onDisplayedColumnsChanged={resizeColumns}
        onGridReady={onGridReady}
        suppressMenuHide={true}
        autoGroupColumnDef={{
          maxWidth: 0, // hide the auto group column
        }}
        suppressHeaderFocus={true}
      />
    </Grid>
  );
};
