import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { getCurrencyFormattedValue, InfoTooltip } from "common";
import React from "react";

import styles from "./DashboardCardTable.module.scss";

interface IDashboardCardTableProps {
  /** Table name */
  header: string;
  /** Table header text */
  headerCols: string[];
  /** Table body rows */
  rows: IDashboardCardTableTopLevelRow[];
  /** Footer/summary table row */
  totalRow?: IDashboardCardTableRow;
}

export interface IDashboardCardTableTopLevelRow extends IDashboardCardTableRow {
  /** Expandable rows hidden by default to accompany parent rows (optional). */
  children?: IDashboardCardTableRow[];
  /** Validate if child values are collapse */
  isOpen: boolean;
  /** Set value isOpen */
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IDashboardCardTableRow {
  /** Label for each currency value. */
  name: string;
  /** Currency value to display .*/
  value: number | string | undefined;
  /** Tooltip text to accompany label (optional). */
  tooltipParagraphs?: string[];
}

/**
 * Simple table component for shared style across Dashboard Cards,
 * consisting of a header, followed by rows of labels to currency values,
 * ending with a footer/summary row with its own currency value.
 *
 * Optional extras:
 * 1. Tooltips (per label)
 * 2. Expandable rows (all rows will expand/collapse together)
 */
export const DashboardCardTable = (props: IDashboardCardTableProps) => {
  const { header, headerCols, rows, totalRow } = props;

  const containsExpandableRows = rows.some((e) => e.children !== undefined);

  const isSubHeader = (row: IDashboardCardTableTopLevelRow) => {
    return row.children && row.children.length > 0;
  };

  const expandIconCell = (currRow: IDashboardCardTableTopLevelRow) => (
    <TableCell component="th" scope="row" className={styles.expandIconCell}>
      {currRow.children !== undefined && (
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => currRow.setIsOpen(!currRow.isOpen)}
          className={styles.expandIcon}
        >
          {currRow.isOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        </IconButton>
      )}
    </TableCell>
  );

  const expandableRows = (currRow: IDashboardCardTableTopLevelRow) => {
    return (
      <TableRow
        className={styles.collapsibleRow}
        key={currRow.name + "-expand"}
      >
        <TableCell colSpan={3}>
          <Collapse in={currRow.isOpen} timeout="auto" unmountOnExit>
            {currRow.children &&
              currRow.children.map((childRow) => (
                <div key={childRow.name} className={styles.childRow}>
                  <div>{currencyLabel(childRow)}</div>
                  <div>
                    {typeof childRow.value === "string"
                      ? childRow.value
                      : getCurrencyFormattedValue(childRow.value)}
                  </div>
                </div>
              ))}
          </Collapse>
        </TableCell>
      </TableRow>
    );
  };

  /**
   * Renders the currency label for a row, with its tooltips, if present
   *
   * @param row the row to get the currency label for
   * @returns the currency label, with tooltip if one is provided
   */
  const currencyLabel = (row: IDashboardCardTableRow) => {
    return (
      <>
        {row.name}
        {row.tooltipParagraphs && (
          <InfoTooltip
            className={styles.tooltip}
            tooltipContent={row.tooltipParagraphs}
          />
        )}
      </>
    );
  };

  const renderFlattenedRows = (
    acc: JSX.Element[],
    currRow: IDashboardCardTableTopLevelRow
  ) => {
    const rows = [
      <TableRow
        key={currRow.name}
        className={isSubHeader(currRow) ? styles.totalRow : ""}
      >
        {containsExpandableRows && expandIconCell(currRow)}
        <TableCell component="th" scope="row">
          {currencyLabel(currRow)}
        </TableCell>
        <TableCell align="right">
          {typeof currRow.value === "string"
            ? currRow.value
            : getCurrencyFormattedValue(currRow.value)}
        </TableCell>
      </TableRow>,
    ];
    if (currRow.children !== undefined) {
      rows.push(expandableRows(currRow));
    }
    return acc.concat(rows);
  };

  return (
    <TableContainer className={styles.dashboardCardTable}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={containsExpandableRows ? 2 : 1}>
              {header}
            </TableCell>
            {headerCols.map((headerCol, idx) => {
              return (
                <TableCell key={idx} align="right">
                  {headerCol}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody className={styles.body}>
          {rows.reduce(renderFlattenedRows, [])}
          {totalRow && (
            <TableRow className={styles.totalRow}>
              {containsExpandableRows && <TableCell></TableCell>}
              <TableCell component="th" scope="row">
                {currencyLabel(totalRow)}
              </TableCell>
              <TableCell align="right">
                {typeof totalRow.value === "string"
                  ? totalRow.value
                  : getCurrencyFormattedValue(totalRow.value)}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
