import { IAuthorizedComponentProps } from "common";
import { User } from "oidc-client-ts";
import React from "react";

import { Header } from "../../../features/Header/Header";

interface IPageWithHeaderProps {
  user: User;
  pageBody: (props: IAuthorizedComponentProps) => JSX.Element;
}

export const PageWithHeader = (props: IPageWithHeaderProps) => {
  return (
    <>
      <Header />
      <props.pageBody user={props.user} />
    </>
  );
};

export default PageWithHeader;
