import {
  SectionHeader,
  SegregatedTrustTableLabel,
} from "../../constants/LabelAndTooltipConstants";
import { StringConstants } from "../../constants/StringConstants";
import { WorksheetGeneratorParams } from "../../types/excelTypes";
import { isSomething } from "../../types/typeGuards";
import { ExcelConstants, formatHeaderRows, getAsOfHeader } from "./excelUtils";

export const buildSegTrustBalanceWorksheet = (
  params: WorksheetGeneratorParams
) => {
  const { workbook, internalInvestmentData } = params;
  if (isSomething(internalInvestmentData.segregatedTrustBalance)) {
    const segregatedTrustData =
      internalInvestmentData.segregatedTrustBalance.value;
    const worksheet = workbook.addWorksheet(SectionHeader.SEG_TRUST_BALANCE, {
      views: [{ state: "frozen", ySplit: 1 }],
    });
    worksheet.columns = [
      { width: ExcelConstants.SIZES.MEDIUM },
      {
        width: ExcelConstants.SIZES.MEDIUM,
        style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
      },
      {},
      { width: ExcelConstants.SIZES.MEDIUM },
      {
        width: ExcelConstants.SIZES.MEDIUM,
        style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
      },
    ];
    worksheet.addRows([
      [
        `${StringConstants.HOLDBACK_OVERVIEW} ${getAsOfHeader(
          segregatedTrustData.asOfDate
        )}`,
      ],
      [
        SegregatedTrustTableLabel.TOTAL_HOLDBACK_VALUE,
        segregatedTrustData.totalHoldbackValue ||
          ExcelConstants.VALUES.NULL_VALUE,
      ],
      [
        SegregatedTrustTableLabel.HOLDBACK_INVESTED,
        segregatedTrustData.holdbackInvested ||
          ExcelConstants.VALUES.NULL_VALUE,
      ],
      [
        SegregatedTrustTableLabel.HOLDBACK_CASH,
        segregatedTrustData.holdbackCash || ExcelConstants.VALUES.NULL_VALUE,
      ],
      [
        SegregatedTrustTableLabel.TOTAL_COLLATERAL_VALUE,
        segregatedTrustData.totalCollateralValue ||
          ExcelConstants.VALUES.NULL_VALUE,
      ],
      [
        SegregatedTrustTableLabel.REQUIRED_COLLATERALIZATION,
        segregatedTrustData.requiredCollateralization ||
          ExcelConstants.VALUES.NULL_VALUE,
      ],
      [
        SegregatedTrustTableLabel.OVER_UNDER_COLLAT,
        segregatedTrustData.excessCollateralization ||
          ExcelConstants.VALUES.NULL_VALUE,
      ],
    ]);
    if (segregatedTrustData.holdbackPerFund.length > 0) {
      const LABEL_COLUMN = 4;
      const VALUE_COLUMN = 5;

      const labelHeaderCell = worksheet.getCell(1, LABEL_COLUMN);
      const valueHeaderCell = worksheet.getCell(1, VALUE_COLUMN);
      labelHeaderCell.value = StringConstants.TRANCHE;
      valueHeaderCell.value = `${
        StringConstants.HOLDBACK_CAPITAL_INVESTED
      } ${getAsOfHeader(segregatedTrustData.asOfDate)}`;

      segregatedTrustData.holdbackPerFund.forEach((holdback, index) => {
        const row = index + 2; // rows are 1-indexed, plus an extra for the header
        const labelCell = worksheet.getCell(row, LABEL_COLUMN);
        const valueCell = worksheet.getCell(row, VALUE_COLUMN);
        labelCell.value = holdback.fundName;
        valueCell.value = holdback.holdback;
      });
    }
    formatHeaderRows(worksheet);
  }
  return workbook;
};
