import { DataSource } from "../constants/enums";
import { IBankAccountsCapabilities } from "../types/bankAccountDataTypes";
import { IPermittedEntities } from "../types/dataTypes";
import { IElectionPermissions } from "../types/electionDataTypes";
import {
  AdminEntitlementSummary,
  ViewElectionResultPage,
} from "../types/storeTypes";
import { Maybe } from "../types/typeUtils";
import { getUser } from "./auth";
import { EnvironmentResolver } from "./environmentResolver";
import { handleEntitlementResponse } from "./utils";

let urlsByDataSource: Record<DataSource, string> = {
  [DataSource.INTERNAL_INVESTMENTS]: ``,
  [DataSource.EQUITY]: ``,
  [DataSource.FEATURE_FLAGS]: ``,
  [DataSource.ELECTIONS]: ``,
  [DataSource.BANK_ACCOUNTS]: ``,
};

EnvironmentResolver.configureEnvironment().then(() => {
  urlsByDataSource = {
    [DataSource.INTERNAL_INVESTMENTS]: `${EnvironmentResolver.ENV.REACT_APP_INTERNAL_INVESTMENTS_URL_BASE}/my/permitted-entities`,
    [DataSource.EQUITY]: `${EnvironmentResolver.ENV.REACT_APP_EQUITY_URL_BASE}/my/permissions`,
    [DataSource.FEATURE_FLAGS]: `${EnvironmentResolver.ENV.REACT_APP_AUTHORIZATION_URL_BASE}/my/enabled-feature-flags`,
    [DataSource.ELECTIONS]: `${EnvironmentResolver.ENV.REACT_APP_ELECTIONS_URL_BASE}/permissions/me`,
    [DataSource.BANK_ACCOUNTS]: `${EnvironmentResolver.ENV.REACT_APP_BANK_ACCOUNTS_URL_BASE}/permissions/me`,
  };
});

const getEntitlements = async <T>(url: string): Promise<Maybe<T>> => {
  const user = getUser();

  const params = {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
    },
  };

  const response = await fetch(url, params);
  return handleEntitlementResponse<T>(response);
};

const getUserHasCapability = async (brn: string, capabilityName: string) => {
  const path =
    EnvironmentResolver.ENV.REACT_APP_AUTHORIZATION_URL_BASE +
    "/has-capability";
  const url =
    path +
    `?capabilityName=${encodeURIComponent(
      capabilityName
    )}&brn=${encodeURIComponent(brn)}`;
  return await getEntitlements<boolean>(url);
};

const getEntitlementsForDataSource = <T>(
  dataSource: DataSource
): Promise<Maybe<T>> => {
  const url = urlsByDataSource[dataSource];
  return getEntitlements(url);
};

export const getAllEntitlements = async (): Promise<
  Maybe<
    | string[]
    | IPermittedEntities
    | IElectionPermissions
    | IBankAccountsCapabilities
  >[]
> => {
  const promises = Promise.all<
    Maybe<
      | IPermittedEntities
      | string[]
      | IElectionPermissions
      | IBankAccountsCapabilities
    >
  >(
    Object.values(DataSource).map((dataSource) => {
      switch (dataSource) {
        case DataSource.INTERNAL_INVESTMENTS:
          return getEntitlementsForDataSource<IPermittedEntities>(dataSource);
        case DataSource.ELECTIONS:
          return getEntitlementsForDataSource<IElectionPermissions>(dataSource);
        case DataSource.BANK_ACCOUNTS:
          return getEntitlementsForDataSource<IBankAccountsCapabilities>(
            dataSource
          );
        default:
          return getEntitlementsForDataSource<string[]>(dataSource);
      }
    })
  );

  return promises;
};

export const getAdminEntitlements = async (): Promise<
  Maybe<
    AdminEntitlementSummary | ViewElectionResultPage | IElectionPermissions
  >[]
> => {
  const electionPermissions = getEntitlements<IElectionPermissions>(
    `${EnvironmentResolver.ENV.REACT_APP_ELECTIONS_URL_BASE}/permissions/me`
  );

  const canViewAllInternalInvestmentData = getUserHasCapability(
    "bx:bxt:investor-portal:data:internal-investments",
    "view-data"
  );

  const canViewAllEquityData = getUserHasCapability(
    "bx:bxt:investor-portal:data:equity-data",
    "view"
  );

  const canViewElectionResultPage = getEntitlements<boolean>(
    `${EnvironmentResolver.ENV.REACT_APP_ELECTIONS_URL_BASE}/permissions/temp-admin-page`
  );

  return [
    {
      canViewAllInternalInvestmentData:
        (await canViewAllInternalInvestmentData) ?? false,
      canViewAllEquityData: (await canViewAllEquityData) ?? false,
    },
    {
      canViewElectionResultPage: (await canViewElectionResultPage) ?? false,
    },
    await electionPermissions,
  ];
};
