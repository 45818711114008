import { Stack, Typography } from "@mui/material";
import React from "react";

import { Markdown } from "../Markdown/Markdown";
import styles from "./CardFooter.module.scss";

interface ICardFooterProps {
  footerLines: string[];
}

export const CardFooter = (props: ICardFooterProps) => {
  const { footerLines } = props;
  return (
    <Stack direction="column" className={styles.footer}>
      {footerLines.map((line, i) => (
        <Typography key={i} variant="subtitle1">
          <Markdown text={line} />
        </Typography>
      ))}
    </Stack>
  );
};
