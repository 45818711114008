import { IEmployee, isSomething, Optional } from "common";

/**
 * Compares two internal investment employees based on their names.
 * Case insenstive.
 *
 * @param a The first internal investment employee to compare.
 * @param b The second internal investment employee to compare.
 * @returns -1 if the name of employee 'a' comes before the name of employee 'b',
 *           1 if the name of employee 'a' comes after the name of employee 'b',
 *           0 if the names of both employees are equal.
 */
export const compareEmployees = (a: IEmployee, b: IEmployee): number => {
  const nameA = a.name.toLowerCase();
  const nameB = b.name.toLowerCase();
  // Compare client names
  if (nameA < nameB) {
    return -1;
  } else if (nameA > nameB) {
    return 1;
  }
  // Ids are equal
  return 0;
};

export const getEmployeeOptionLabel = (option: IEmployee) =>
  option.name + " (" + option.id + ")";

/**
 * Searches and sorts an array of employees based on a given search string.
 * employees can be searched by exact ID match, Id beginning match, or Id containing match.
 * The employees are sorted by type of match.
 *
 * @param employees An array of internal investment employees to search and sort.
 * @param searchString The string used to search for employees.
 * @returns An array of internal investment employees sorted based on the search criteria.
 */
export const searchAndSortEmployees = (
  employees: IEmployee[],
  searchString: string
): IEmployee[] => {
  // Convert search string to lowercase for case-insensitive comparison
  const searchStringLowerCase = searchString.toLowerCase();

  // Arrays to store different types of matches
  const exactIdMatches: IEmployee[] = [];
  const beginsWithIdMatches: IEmployee[] = [];
  const containingIdMatches: IEmployee[] = [];

  // Iterate through each client to find matches
  employees.forEach((employee) => {
    if (employee.id === searchStringLowerCase) {
      exactIdMatches.push(employee);
    } else if (employee.id.toLowerCase().startsWith(searchStringLowerCase)) {
      beginsWithIdMatches.push(employee);
    } else if (employee.id.toLowerCase().includes(searchStringLowerCase)) {
      containingIdMatches.push(employee);
    }
  });

  // Concatenate and sort the matches based on name
  return [
    ...exactIdMatches,
    ...beginsWithIdMatches.sort(compareEmployees),
    ...containingIdMatches.sort(compareEmployees),
  ];
};

export const findEmployeeForId = (
  employeeId: string | undefined,
  employees: Optional<IEmployee[]>
) => {
  return employeeId && isSomething(employees)
    ? employees.value.find(
        (employee: IEmployee) => employee.id.toString() === employeeId
      )
    : undefined;
};
