import { IHeaderGroupParams } from "ag-grid-community";
import React from "react";

import styles from "./GridHeaderWithSubHeader.module.scss";

interface IGridHeaderWithSubHeaderProps extends IHeaderGroupParams {
  subHeader: string;
  labelClass?: string | null;
  textClass?: string | null;
}

export const GridHeaderWithSubHeader = (
  props: IGridHeaderWithSubHeaderProps
) => {
  const { displayName, subHeader, labelClass, textClass } = props;
  return (
    <div
      className={`${labelClass} ag-header-group-cell-label ${styles.header}`}
    >
      <div className={`${textClass} ag-header-group-text`}>{displayName}</div>{" "}
      <div className={styles.subHeader}>{subHeader}</div>
    </div>
  );
};
