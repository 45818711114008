import {
  DataLoadStatus,
  FailedToLoadError,
  isSomething,
  LoadingIndicator,
  NoDataAvailableError,
  Optional,
} from "common";
import React from "react";

import { IAsOfDate, IInvestmentBreakdownGrouped } from "../../types/dataTypes";
import styles from "./PerformanceDetails.module.scss";
import { PerformanceDetailsGrid } from "./PerformanceDetailsGrid/PerformanceDetailsGrid";

interface PerformanceDetailsProps {
  data: Optional<IInvestmentBreakdownGrouped>;
  dataLoadStatus: DataLoadStatus;
  filter: string | undefined;
  onChangeFilter: (searchTerm: string) => void;
  retryRequest: () => void;
  onDownload?: () => Promise<void>;
  dateRange: IAsOfDate;
}

export const PerformanceDetails = (props: PerformanceDetailsProps) => {
  const {
    data,
    dataLoadStatus,
    filter,
    onChangeFilter,
    retryRequest,
    onDownload,
    dateRange,
  } = props;
  switch (dataLoadStatus) {
    case DataLoadStatus.SUCCESSFUL: {
      if (isSomething(data)) {
        return (
          <div className={styles.performanceDetails}>
            <>
              <PerformanceDetailsGrid
                filter={filter}
                onChangeFilter={onChangeFilter}
                investmentBreakdownGrouped={data.value}
                onDownload={onDownload}
                asOfDates={dateRange}
              />
            </>
          </div>
        );
      } else {
        return (
          <div className={styles.performanceDetails}>
            <NoDataAvailableError />
          </div>
        );
      }
    }
    case DataLoadStatus.UNSUCCESSFUL: {
      // data load error
      return (
        <div className={styles.performanceDetails}>
          <FailedToLoadError retryRequest={retryRequest} />
        </div>
      );
    }
    case DataLoadStatus.NOT_REQUESTED:
    case DataLoadStatus.LOADING: {
      // data still loading
      return (
        <div className={styles.performanceDetails}>
          <LoadingIndicator />
        </div>
      );
    }
    case DataLoadStatus.EMPTY_RESPONSE: {
      // empty data
      return (
        <div className={styles.performanceDetails}>
          <NoDataAvailableError />
        </div>
      );
    }
  }
};
