import { createSelector } from "@reduxjs/toolkit";

import { TreeNumber } from "../../constants/enums";
import { IInvestmentBreakdownGrouped } from "../../types/dataTypes";
import { isSomething } from "../../types/typeGuards";
import { nothing, Optional, some } from "../../types/typeUtils";
import { isInProgress } from "../../utils/dataLoadUtils";
import {
  convertToInvestmentBreakdownGrouped,
  sortInvestmentBreakdown,
} from "../../utils/investmentBreakdownUtils";
import {
  selectActiveInvestmentEntityData,
  selectInternalInvestmentDataLoadStatus,
} from "./baseStoreSelectors";

const selectInvestmentBreakdown = createSelector(
  selectActiveInvestmentEntityData,
  selectInternalInvestmentDataLoadStatus,
  ({ investmentBreakdown }, internalInvestmentDataLoadStatus) => {
    return {
      internalInvestmentDataLoadStatus,
      investmentBreakdown,
    };
  }
);

export const selectInvestmentBreakdownGrouped = createSelector(
  selectInvestmentBreakdown,
  ({ internalInvestmentDataLoadStatus, investmentBreakdown }) => {
    if (
      isInProgress(internalInvestmentDataLoadStatus) ||
      investmentBreakdown.length === 0
    ) {
      return {
        investmentBreakdownGrouped:
          nothing as Optional<IInvestmentBreakdownGrouped>,
      };
    }

    const investmentBreakdownGrouped =
      convertToInvestmentBreakdownGrouped(investmentBreakdown);
    return {
      investmentBreakdownGrouped: some(investmentBreakdownGrouped),
    };
  }
);

export const selectInvestmentBreakdownGroupedAndSorted = createSelector(
  selectInvestmentBreakdownGrouped,
  ({ investmentBreakdownGrouped }) => {
    if (!isSomething(investmentBreakdownGrouped)) {
      return nothing as Optional<IInvestmentBreakdownGrouped>;
    }

    const businessUnit = sortInvestmentBreakdown(
      investmentBreakdownGrouped.value[TreeNumber.BUSINESS_UNIT]
    );
    const electionPeriod = sortInvestmentBreakdown(
      investmentBreakdownGrouped.value[TreeNumber.ELECTION_PERIOD]
    );

    const result = {
      [TreeNumber.BUSINESS_UNIT]: businessUnit,
      [TreeNumber.ELECTION_PERIOD]: electionPeriod,
    };

    return some(result);
  }
);
