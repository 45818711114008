import { isSomething, Optional } from "common";

import { getTimeZoneOffset, getTimeZoneShortName } from "./dateTimeUtils";

/*
Determines if a date filter is active
*/
export function isDateFilterActive(selections: Optional<Date>[]) {
  return (
    selections.length > 0 &&
    (isSomething(selections[0]) || isSomething(selections[1]))
  );
}

/*
Determines if a single select filter is active
*/
export function isSingleSelectFilterActive<T>(selections: Optional<T>[]) {
  return selections.length > 0 && isSomething(selections[0]);
}

/*
Determines if a multi select filter is active
*/
export function isMultiSelectFilterActive<T>(selections: Optional<T>[]) {
  return (
    selections.length > 0 &&
    selections.some((selection) => isSomething(selection))
  );
}

/*
Generates a URLSearchParams object that has the timeZone Query Parameters 
that are required for document download.
*/
export const getDocDownloadTimeZoneQueryParameters = () => {
  const params = new URLSearchParams();
  params.append("timeZoneOffset", getTimeZoneOffset().toString());
  params.append("timeZoneShortName", getTimeZoneShortName());

  return params;
};

export const buildDownloadableLink = (
  downloadUrl: string,
  documentName: string
) => {
  const link = window.document.createElement("a");
  link.href = downloadUrl;
  link.download = documentName;
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  link.remove();
};
