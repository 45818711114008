import { ThemeOptions } from "@mui/material";

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: "0px",
        },
      },
    },
  },
};

export const CustomMenuTheme = partialTheme.components?.MuiMenu;
