import { Button } from "@mui/material";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";

import { openAlert } from "../../redux/actions/entitlementActions";

export const CopyToClipboardButton = ({ text }: { text: string }) => {
  const dispatch = useDispatch();

  const showCopiedAlert = () => {
    dispatch(
      openAlert({
        severity: "info",
        message: "Copied",
      })
    );
  };

  return (
    <CopyToClipboard text={text} onCopy={showCopiedAlert}>
      <Button type="button" color="primary">
        Copy to clipboard!
      </Button>
    </CopyToClipboard>
  );
};
