import { ThemeOptions } from "@mui/material";

import colors from "../../_colors.scss";
import { Font } from "../sharedStyleConstants";

// Shared constants we may want to move in unison
const HoverStyle = {
  BACKGROUND_COLOR: colors.charcoal,
  BOX_SHADOW: "0px 3px 6px #00000040",
  COLOR: colors.white,
  Icon: {
    BACKGROUND_COLOR: colors.white,
    BORDER_COLOR: colors.white,
    COLOR: colors.black,
  },
  SecondaryIcon: {
    BACKGROUND_COLOR: colors.charcoal,
    BORDER_COLOR: colors.white,
    COLOR: colors.white,
  },
};

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
        disableFocusRipple: true,
        disableTouchRipple: true,
        size: "small",
      },
      styleOverrides: {
        root: {
          fontFamily: Font.Family.GUARDIAN_SANS,
          fontWeight: 400,
          textTransform: "none",
          // styles for focus from keyboard events
          "&:focus-visible": {
            backgroundColor: colors.pale_azure,
            boxShadow: "none",
            color: colors.black,
            outlined: "none",
            borderColor: "transparent",
            "& .MuiButton-endIcon": {
              backgroundColor: colors.pale_azure,
              borderColor: colors.black,
              color: colors.black,
            },
          },
        },
        containedPrimary: {
          backgroundColor: colors.black,
          boxShadow: "none",
          "&:hover": {
            backgroundColor: colors.charcoal,
            boxShadow: "none",
            color: colors.white,
            "& .MuiButton-endIcon": {
              backgroundColor: HoverStyle.Icon.BACKGROUND_COLOR,
              color: HoverStyle.Icon.COLOR,
              borderColor: HoverStyle.Icon.BORDER_COLOR,
            },
          },
          "&:active": {
            backgroundColor: HoverStyle.BACKGROUND_COLOR,
            boxShadow: HoverStyle.BOX_SHADOW,
            "& .MuiButton-endIcon": {
              backgroundColor: HoverStyle.Icon.BACKGROUND_COLOR,
              color: HoverStyle.Icon.COLOR,
              borderColor: HoverStyle.Icon.BORDER_COLOR,
            },
          },
          "&:disabled": {
            backgroundColor: colors.silver,
            color: colors.white,
            "& .MuiButton-endIcon": {
              backgroundColor: colors.silver,
              color: colors.white,
            },
          },
          "& .MuiButton-endIcon": {
            borderColor: colors.white,
            borderStyle: "solid",
            borderWidth: "1px",
          },
        },
        containedSecondary: {
          backgroundColor: "transparent",
          boxShadow: "none",
          color: colors.black,
          borderColor: colors.black,
          borderStyle: "solid",
          borderWidth: "1px",
          "&:hover": {
            backgroundColor: colors.charcoal,
            boxShadow: "none",
            color: colors.white,
            "& .MuiButton-endIcon": {
              backgroundColor: HoverStyle.SecondaryIcon.BACKGROUND_COLOR,
              color: HoverStyle.SecondaryIcon.COLOR,
              borderColor: HoverStyle.SecondaryIcon.BORDER_COLOR,
            },
          },
          "&:active": {
            backgroundColor: colors.black,
            boxShadow: HoverStyle.BOX_SHADOW,
            "& .MuiButton-endIcon": {
              backgroundColor: colors.black,
              color: HoverStyle.SecondaryIcon.COLOR,
              borderColor: HoverStyle.SecondaryIcon.BORDER_COLOR,
            },
          },
          "&:disabled": {
            backgroundColor: colors.white,
            color: colors.silver,
            "& .MuiButton-endIcon": {
              backgroundColor: colors.white,
              color: colors.silver,
              borderColor: colors.silver,
            },
          },
          "& .MuiButton-endIcon": {
            borderColor: colors.black,
            borderStyle: "solid",
            borderWidth: "1px",
          },
        },
        outlined: {
          backgroundColor: colors.white,
          boxShadow: "none",
          color: colors.black,
          borderColor: colors.black,
          borderStyle: "solid",
          borderWidth: "1px",
          "&:hover": {
            backgroundColor: colors.charcoal,
            boxShadow: "none",
            color: colors.white,
            borderColor: colors.black,
            "& .MuiButton-endIcon": {
              backgroundColor: HoverStyle.SecondaryIcon.BACKGROUND_COLOR,
              color: HoverStyle.SecondaryIcon.COLOR,
              borderColor: HoverStyle.SecondaryIcon.BORDER_COLOR,
            },
          },
          "&:active": {
            backgroundColor: colors.black,
            boxShadow: HoverStyle.BOX_SHADOW,
            "& .MuiButton-endIcon": {
              backgroundColor: colors.black,
              color: HoverStyle.SecondaryIcon.COLOR,
              borderColor: HoverStyle.SecondaryIcon.BORDER_COLOR,
            },
          },
          "&:disabled": {
            backgroundColor: colors.white,
            color: colors.silver,
            "& .MuiButton-endIcon": {
              backgroundColor: colors.white,
              color: colors.silver,
              borderColor: colors.silver,
            },
          },
          "& .MuiButton-endIcon": {
            borderColor: colors.black,
            borderStyle: "solid",
            borderWidth: "1px",
          },
        },
        sizeSmall: {
          height: "36px",
          fontSize: "14px",
          borderRadius: "18px",
          padding: "8px 16px",
        },
        sizeMedium: {
          fontSize: "16px",
        },
        iconSizeSmall: {
          "& > *:first-of-type": {
            fontSize: 18,
          },
          fontSize: "18px",
          height: "28px",
          marginRight: "-12px",
          padding: "4px",
          width: "28px",
        },
        endIcon: {
          borderRadius: "50%",
        },
        text: {
          color: colors.cobalt,
          padding: "0px",
          "&:hover": {
            backgroundColor: "transparent",
            textDecoration: "underline",
          },
        },
      },
    },
  },
};

export const CustomButtonTheme = partialTheme.components?.MuiButton;
