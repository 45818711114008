import "ag-grid-community/styles/ag-grid.css"; // Ensure CSS is imported as needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Theme CSS
import "./GridAsTable.scss";

import { AgGridReact } from "ag-grid-react";
import { AgGridReactProps } from "ag-grid-react";
import React, { forwardRef } from "react";

import { getDefaultColumnDef } from "./gridColumnConfigs";

const GridWrapper = <T,>(
  props: AgGridReactProps<T>,
  ref: React.Ref<AgGridReact<T>>
) => (
  <div className="gridAsTable">
    <AgGridReact<T>
      ref={ref}
      {...props}
      defaultColDef={getDefaultColumnDef<T>()}
    />
  </div>
);

export const GridAsTable = forwardRef(GridWrapper) as <T>(
  props: AgGridReactProps<T> & { ref?: React.Ref<AgGridReact<T>> }
) => ReturnType<typeof GridWrapper>;
