import { FileDownloadOutlined } from "@mui/icons-material";
import { Button, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  ElectionAdminLabels,
  ErrorStringConstants,
  getElectionRoundConfigurationData,
  getResult,
  openAlert,
  StringConstants,
  useExcelDownload,
  worksheetGenerators,
} from "common";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import sanitize from "sanitize-filename";

import styles from "./ElectionResultPage.module.scss";

export const ElectionResultPage = () => {
  const dispatch = useDispatch();
  const handleExcelDownload = useExcelDownload();

  const [inputValue, setInputValue] = useState<string>("");
  const [isdisabled, setIsDisabled] = useState<boolean>(true);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = event.target.value;
    setInputValue(data);
    setIsDisabled(data.length === 0);
  };

  const handleClick = async () => {
    const electionRoundResp = await getElectionRoundConfigurationData(
      inputValue
    ).then(
      (r) => {
        return r;
      },
      () => {
        return undefined;
      }
    );
    const jsonResponse = await getResult(inputValue).then(
      (response) => {
        return response;
      },
      () => {
        return undefined;
      }
    );

    if (
      jsonResponse === undefined ||
      electionRoundResp === undefined ||
      jsonResponse.length < 1
    ) {
      dispatch(
        openAlert({
          severity: "error",
          message: ErrorStringConstants.NO_ELECTION_RESULT,
        })
      );
      return;
    }

    try {
      const generator = worksheetGenerators.ElectionResult;
      const fileName = `ElectionResults_${sanitize(electionRoundResp.name)}`;
      await handleExcelDownload(generator, fileName, {
        resultResponse: jsonResponse,
      });
      dispatch(
        openAlert({
          severity: "success",
          message: StringConstants.DOWNLOAD_COMPLETE,
        })
      );
    } catch (e) {
      dispatch(
        openAlert({
          severity: "error",
          message: ErrorStringConstants.DOWNLOAD_FAILED,
        })
      );
    }
  };

  return (
    <div className={styles.subnavbar}>
      <Box className={styles.toolbar} alignItems="center">
        <Typography variant="h5" className={styles.searchText}>
          {ElectionAdminLabels.ENTER_ROUND_ID}
        </Typography>
        <Box className={styles.searchBar}>
          <TextField
            placeholder={ElectionAdminLabels.ENTER_ROUND_ID}
            onChange={handleInputChange}
            value={inputValue}
          />
        </Box>
        <Button
          className={styles.resultDownload}
          onClick={handleClick}
          variant="outlined"
          disabled={isdisabled}
          endIcon={<FileDownloadOutlined />}
        >
          {ElectionAdminLabels.DOWNLOAD_RESULTS}
        </Button>
      </Box>
    </div>
  );
};
