import {
  DataLoadStatus,
  FailedToLoadError,
  IBaseStore,
  isInProgress,
  isSomething,
  LoadingIndicator,
  reqEquityData,
  reqStockData,
  useFetchDatasetIfIdDefined,
  useFetchDatasetWithoutId,
  VestingEventsNoDataLabel,
} from "common";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import { Footer } from "../common/Footer/Footer";
import { NoDataPage } from "../common/NoDataPage/NoDataPage";
import { ActiveAwardDetails } from "./ActiveAwardDetails/ActiveAwardDetails";
import { Callouts } from "./Callouts/Callouts";
import styles from "./VestingEvents.module.scss";
import { VestingEventsCard } from "./VestingEventsCard/VestingEventsCard";

interface IVestingEventsProps {
  hasViewRestrictedEquity?: boolean;
}

export const VestingEvents = (props: IVestingEventsProps) => {
  const { hasViewRestrictedEquity } = props;

  const { equityData } = useSelector((store: IBaseStore) => store.equityData);

  const { selectedStockSymbol } = useSelector(
    (store: IBaseStore) => store.viewData
  );

  const { equityDataLoadStatus, stockDataLoadStatus, activeEquityDataId } =
    useSelector((store: IBaseStore) => store.equityData);

  const equityRetryFunction = useFetchDatasetIfIdDefined(
    reqEquityData,
    activeEquityDataId,
    equityDataLoadStatus
  );

  const stockRetryFunction = useFetchDatasetWithoutId(
    reqStockData,
    stockDataLoadStatus
  );

  const handleRetry = useCallback(() => {
    equityRetryFunction();
    stockRetryFunction();
  }, [equityRetryFunction, stockRetryFunction]);

  return isInProgress(equityDataLoadStatus, stockDataLoadStatus) ? (
    <LoadingIndicator />
  ) : isSomething(equityData[selectedStockSymbol]) ? (
    <div className={styles.vestingEvents}>
      <Callouts hasViewRestrictedEquity={hasViewRestrictedEquity} />
      <div className={styles.upcomingVestingEvents}>
        <VestingEventsCard />
      </div>
      <ActiveAwardDetails />
      {isSomething(equityData[selectedStockSymbol]) && (
        // display footer once equityPageData is fully loaded to ensure we don't see it until we have other cards
        <Footer />
      )}
    </div>
  ) : equityDataLoadStatus === DataLoadStatus.UNSUCCESSFUL ? (
    <FailedToLoadError retryRequest={handleRetry} />
  ) : (
    <NoDataPage
      message={VestingEventsNoDataLabel.NO_VESTING_DATA(selectedStockSymbol)}
      contactText={VestingEventsNoDataLabel.CONTACT_TEXT}
      emailId={VestingEventsNoDataLabel.EMAIL_ID}
    />
  );
};
