import { ThemeOptions } from "@mui/material";

import { Font } from "../sharedStyleConstants";

// Shared constants we may want to move in unison

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          fontSize: Font.Size.STANDARD,
          fontFamily: Font.Family.GUARDIAN_SANS,
          fontWeight: Font.Weight.STANDARD,
          marginBottom: "32px",
          "&.Mui-expanded": {
            marginBottom: "32px",
          },
        },
      },
    },
  },
};

export const CustomAccordionTheme = partialTheme.components?.MuiAccordion;
