import { createSelector } from "@reduxjs/toolkit";

import { ElectionsLabels } from "../../constants/LabelAndTooltipConstants";
import {
  ElectionAccordionObject,
  IElectionProcessAccordionStage,
} from "../../types/electionDataTypes";
import { isSomething } from "../../types/typeGuards";
import { selectElectionRoundConfiguration } from "./electionSelectors";

export const selectElectionProcessAccordionStages = createSelector(
  selectElectionRoundConfiguration,
  (configuration) => {
    const accordionStages: IElectionProcessAccordionStage[] = [];
    const { electionProcess } = configuration.stages.overview;

    if (isSomething(electionProcess.overview)) {
      accordionStages.push({
        accordionId: ElectionAccordionObject.OVERVIEW_PROCESS_OVERVIEW,
        accordionSummary: ElectionsLabels.OVERVIEW,
        accordionDetails: electionProcess.overview.value,
      });
    }
    if (isSomething(electionProcess.eligibility)) {
      accordionStages.push({
        accordionId: ElectionAccordionObject.OVERVIEW_PROCESS_ELIGIBILITY,
        accordionSummary: ElectionsLabels.ELIGIBILITY,
        accordionDetails: electionProcess.eligibility.value,
      });
    }
    if (isSomething(electionProcess.strategies)) {
      accordionStages.push({
        accordionId: ElectionAccordionObject.OVERVIEW_PROCESS_STRATEGIES,
        accordionSummary: ElectionsLabels.STRATEGIES,
        accordionDetails: electionProcess.strategies.value,
      });
    }
    if (isSomething(electionProcess.elections)) {
      accordionStages.push({
        accordionId: ElectionAccordionObject.OVERVIEW_PROCESS_ELECT,
        accordionSummary: ElectionsLabels.ELECT,
        accordionDetails: electionProcess.elections.value,
      });
    }
    if (isSomething(electionProcess.bankAccount)) {
      accordionStages.push({
        accordionId: ElectionAccordionObject.OVERVIEW_PROCESS_BANK_ACCOUNT,
        accordionSummary: ElectionsLabels.BANK_ACCOUNT,
        accordionDetails: electionProcess.bankAccount.value,
      });
    }
    if (isSomething(electionProcess.reviewAndSign)) {
      accordionStages.push({
        accordionId: ElectionAccordionObject.OVERVIEW_PROCESS_REVIEW_AND_SIGN,
        accordionSummary: ElectionsLabels.REVIEW_AND_SIGN,
        accordionDetails: electionProcess.reviewAndSign.value,
      });
    }

    return accordionStages;
  }
);
