import { createReducer } from "@reduxjs/toolkit";

import { DataLoadStatus } from "../../constants/enums";
import { ElectionWorkflowStageOrder } from "../../types/electionDataTypes";
import { IInvestorElectionsStore } from "../../types/storeTypes";
import { isSomething } from "../../types/typeGuards";
import { nothing, some } from "../../types/typeUtils";
import { getNewElectionDecisions } from "../../utils/electionsUtils";
import {
  errElectionIVConfiguration,
  errElectionRoundConfigurationData,
  errElectionsForClient,
  errElectionsInvestmentPortfolio,
  errElectionWorkflowState,
  errElectionWorkflowStateUpdate,
  recvApiElectionWorkflowState,
  recvElectionIVConfiguration,
  recvElectionRoundConfigurationData,
  recvElectionsForClient,
  recvElectionsInvestmentPortfolio,
  recvElectionWorkflowStateUpdate,
  recvLocalElectionWorkflowState,
  reqClearActiveElection,
  reqElectionIVConfiguration,
  reqElectionRoundConfigurationData,
  reqElectionsForClient,
  reqElectionsInvestmentPortfolio,
  reqElectionWorkflowState,
  reqPutElectionWorkflowState,
  resetWorkflowStageOrder,
  setActiveElection,
  setActiveElectionClient,
  setHasAcceptedLegalAttestation,
  setReviewAndSignSignature,
  setSubmissionRequested,
  updateAllElectionDecisions,
  updateBankAccountStage,
  updateEligibilityStatus,
  updateOptionalElectionByStrategy,
  updateReallocationState,
  updateResidentialInformation,
  updateWorkflowStageOrder,
} from "../actions/electionsActions";

const defaultState: IInvestorElectionsStore = {
  activeElectionClient: nothing,
  electionRoundConfigurationLoadStatus: DataLoadStatus.NOT_REQUESTED,
  electionRoundConfiguration: nothing,
  electionWorkflowStateLoadStatus: DataLoadStatus.NOT_REQUESTED,
  electionWorkflowStateUpdateStatus: DataLoadStatus.NOT_REQUESTED,
  electionWorkflowStateLocal: nothing,
  electionWorkflowStateApi: nothing,
  electionsForClient: [],
  electionsForClientLoadStatus: DataLoadStatus.NOT_REQUESTED,
  electionIVConfigurationLoadStatus: DataLoadStatus.NOT_REQUESTED,
  electionIVConfiguration: nothing,
  activeElection: nothing,
  electionInvestmentPortfolioLoadStatus: DataLoadStatus.NOT_REQUESTED,
  electionInvestmentPortfolio: nothing,
  submissionRequested: false,
  electionWorkflowStageOrder: ElectionWorkflowStageOrder,
};

export const electionsReducer = createReducer<IInvestorElectionsStore>(
  defaultState,
  (builder) => {
    builder.addCase(setActiveElectionClient, (state, action) => {
      if (isSomething(action.payload)) {
        state.activeElectionClient = action.payload;
        state.electionsForClientLoadStatus = DataLoadStatus.NOT_REQUESTED;
      }
    });
    builder.addCase(reqElectionRoundConfigurationData, (state) => {
      state.electionRoundConfigurationLoadStatus = DataLoadStatus.LOADING;
    });
    builder.addCase(recvElectionRoundConfigurationData, (state, action) => {
      state.electionRoundConfiguration = action.payload;
      if (isSomething(action.payload)) {
        state.electionRoundConfigurationLoadStatus = DataLoadStatus.SUCCESSFUL;
      } else {
        state.electionRoundConfigurationLoadStatus =
          DataLoadStatus.EMPTY_RESPONSE;
      }
    });
    builder.addCase(errElectionRoundConfigurationData, (state) => {
      state.electionRoundConfigurationLoadStatus = DataLoadStatus.UNSUCCESSFUL;
    });
    builder.addCase(setActiveElection, (state, action) => {
      if (action.payload) {
        state.activeElection = some(action.payload);
        state.electionIVConfigurationLoadStatus = DataLoadStatus.NOT_REQUESTED;
        state.electionRoundConfigurationLoadStatus =
          DataLoadStatus.NOT_REQUESTED;
        state.electionWorkflowStateLoadStatus = DataLoadStatus.NOT_REQUESTED;
        state.electionWorkflowStateUpdateStatus = DataLoadStatus.NOT_REQUESTED;
      }
    });
    builder.addCase(reqElectionWorkflowState, (state) => {
      state.electionWorkflowStateLoadStatus = DataLoadStatus.LOADING;
    });
    builder.addCase(recvLocalElectionWorkflowState, (state, action) => {
      if (isSomething(action.payload)) {
        state.electionWorkflowStateLocal = action.payload;
        state.electionWorkflowStateLoadStatus = DataLoadStatus.SUCCESSFUL;
      } else {
        state.electionWorkflowStateLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
      }
    });
    builder.addCase(recvApiElectionWorkflowState, (state, action) => {
      if (isSomething(action.payload)) {
        state.electionWorkflowStateApi = action.payload;
        state.electionWorkflowStateLoadStatus = DataLoadStatus.SUCCESSFUL;
      } else {
        state.electionWorkflowStateLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
      }
    });
    builder.addCase(errElectionWorkflowState, (state) => {
      state.electionWorkflowStateLoadStatus = DataLoadStatus.UNSUCCESSFUL;
    });
    builder.addCase(reqPutElectionWorkflowState, (state) => {
      state.electionWorkflowStateUpdateStatus = DataLoadStatus.LOADING;
    });
    builder.addCase(recvElectionWorkflowStateUpdate, (state, action) => {
      if (isSomething(action.payload)) {
        state.electionWorkflowStateUpdateStatus = DataLoadStatus.SUCCESSFUL;
      } else {
        state.electionWorkflowStateUpdateStatus = DataLoadStatus.EMPTY_RESPONSE;
      }
    });
    builder.addCase(errElectionWorkflowStateUpdate, (state) => {
      state.electionWorkflowStateUpdateStatus = DataLoadStatus.UNSUCCESSFUL;
    });
    builder.addCase(setHasAcceptedLegalAttestation, (state, action) => {
      if (isSomething(state.electionWorkflowStateLocal)) {
        state.electionWorkflowStateLocal.value.electionStages.overview.hasAcceptedLegalAttestation =
          action.payload;
      }
    });
    builder.addCase(reqElectionsForClient, (state) => {
      state.electionsForClientLoadStatus = DataLoadStatus.LOADING;
    });
    builder.addCase(recvElectionsForClient, (state, action) => {
      if (isSomething(action.payload)) {
        state.electionsForClient = action.payload.value;
        state.electionsForClientLoadStatus = DataLoadStatus.SUCCESSFUL;
      } else {
        state.electionsForClient = [];
        state.electionsForClientLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
      }
    });
    builder.addCase(errElectionsForClient, (state) => {
      state.electionsForClient = [];
      state.electionsForClientLoadStatus = DataLoadStatus.UNSUCCESSFUL;
    });
    builder.addCase(reqElectionIVConfiguration, (state) => {
      state.electionIVConfigurationLoadStatus = DataLoadStatus.LOADING;
    });
    builder.addCase(recvElectionIVConfiguration, (state, action) => {
      state.electionIVConfiguration = action.payload;
      if (isSomething(action.payload)) {
        state.electionIVConfigurationLoadStatus = DataLoadStatus.SUCCESSFUL;
      } else {
        state.electionIVConfigurationLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
      }
    });
    builder.addCase(errElectionIVConfiguration, (state) => {
      state.electionIVConfigurationLoadStatus = DataLoadStatus.UNSUCCESSFUL;
    });
    builder.addCase(updateOptionalElectionByStrategy, (state, action) => {
      if (isSomething(state.electionWorkflowStateLocal)) {
        const prevElectionDecisions =
          state.electionWorkflowStateLocal.value.electionStages.elect.elections;
        const updatedDecisons = getNewElectionDecisions(
          action.payload,
          prevElectionDecisions
        );
        state.electionWorkflowStateLocal.value.electionStages.elect.elections =
          updatedDecisons;
      }
    });
    builder.addCase(updateAllElectionDecisions, (state, action) => {
      if (isSomething(state.electionWorkflowStateLocal)) {
        state.electionWorkflowStateLocal.value.electionStages.elect.elections =
          action.payload;
      }
    });
    builder.addCase(updateResidentialInformation, (state, action) => {
      if (isSomething(state.electionWorkflowStateLocal)) {
        state.electionWorkflowStateLocal.value.electionStages.eligibility.residentialInformation =
          action.payload;
      }
    });

    builder.addCase(updateReallocationState, (state, action) => {
      if (isSomething(state.electionWorkflowStateLocal)) {
        state.electionWorkflowStateLocal.value.electionStages.elect.useReallocation =
          action.payload;
      }
    });
    builder.addCase(updateEligibilityStatus, (state, action) => {
      if (isSomething(state.electionWorkflowStateLocal)) {
        state.electionWorkflowStateLocal.value.electionStages.eligibility.eligibleStatus =
          action.payload;
      }
    });
    builder.addCase(setReviewAndSignSignature, (state, action) => {
      if (isSomething(state.electionWorkflowStateLocal))
        state.electionWorkflowStateLocal.value.electionStages.reviewAndSign.signature =
          action.payload;
    });
    builder.addCase(reqElectionsInvestmentPortfolio, (state) => {
      state.electionInvestmentPortfolioLoadStatus = DataLoadStatus.LOADING;
    });
    builder.addCase(recvElectionsInvestmentPortfolio, (state, action) => {
      state.electionInvestmentPortfolio = action.payload;
      if (isSomething(action.payload)) {
        state.electionInvestmentPortfolioLoadStatus = DataLoadStatus.SUCCESSFUL;
      } else {
        state.electionInvestmentPortfolioLoadStatus =
          DataLoadStatus.EMPTY_RESPONSE;
      }
    });
    builder.addCase(errElectionsInvestmentPortfolio, (state) => {
      state.electionInvestmentPortfolioLoadStatus = DataLoadStatus.UNSUCCESSFUL;
    });
    builder.addCase(reqClearActiveElection, (state) => {
      state.activeElection = nothing;
      state.electionIVConfiguration = nothing;
      state.electionIVConfigurationLoadStatus = DataLoadStatus.NOT_REQUESTED;
      state.electionRoundConfiguration = nothing;
      state.electionRoundConfigurationLoadStatus = DataLoadStatus.NOT_REQUESTED;
      state.electionWorkflowStateLocal = nothing;
      state.electionWorkflowStateApi = nothing;
      state.electionWorkflowStateLoadStatus = DataLoadStatus.NOT_REQUESTED;
      state.electionWorkflowStateUpdateStatus = DataLoadStatus.NOT_REQUESTED;
      state.electionInvestmentPortfolioLoadStatus =
        DataLoadStatus.NOT_REQUESTED;
      state.electionInvestmentPortfolio = nothing;
    });
    builder.addCase(setSubmissionRequested, (state, action) => {
      state.submissionRequested = action.payload;
    });
    builder.addCase(updateWorkflowStageOrder, (state, action) => {
      state.electionWorkflowStageOrder = action.payload;
    });
    builder.addCase(resetWorkflowStageOrder, (state) => {
      state.electionWorkflowStageOrder = ElectionWorkflowStageOrder;
    });
    builder.addCase(updateBankAccountStage, (state, action) => {
      if (isSomething(state.electionWorkflowStateLocal)) {
        state.electionWorkflowStateLocal.value.electionStages.bankAccount =
          action.payload;
      }
    });
  }
);
