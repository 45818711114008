import { ThemeOptions } from "@mui/material";

import colors from "../../_colors.scss";
import { Font } from "../sharedStyleConstants";

// Shared constants we may want to move in unison

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiChip: {
      styleOverrides: {
        label: {
          fontFamily: Font.Family.GUARDIAN_SANS,
          fontSize: "10px",
          color: colors.white,
          minWidth: "36px",
          padding: "4px 8px",
          textAlign: "center",
        },
      },
    },
  },
};

export const CustomChipTheme = partialTheme.components?.MuiChip;
