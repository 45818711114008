import { IClientDto, InvestmentEntityDropdown, some } from "common";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { AdminUIStore } from "../../../redux/store";
import { findInternalInvestmentClientForClientId } from "../../../utils/adminClientUtils";

const InvestmentEntityDropdownWrapper = () => {
  const clients = useSelector(
    (store: AdminUIStore) => store.clients.internalInvestmentClients
  );

  // Extract the clientId from the pathname
  const params = useParams<{ clientId: string }>();
  const clientId = params.clientId;
  const client = findInternalInvestmentClientForClientId(clientId, clients);
  const clientDtos: IClientDto[] = client ? [client] : [];

  return (
    <InvestmentEntityDropdown
      viewInternalInvestmentDataPermittedEntities={some({
        clients: clientDtos,
        permissions: [],
      })}
    />
  );
};

export default InvestmentEntityDropdownWrapper;
