import { InfoOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";

import styles from "./InfoTooltip.module.scss";

interface IInfoTooltipProps {
  className?: string;
  tooltipContent: string[] | React.ReactNode;
  leftMargin?: number;
}

export const InfoTooltip = (props: IInfoTooltipProps) => {
  const { className, tooltipContent, leftMargin = 8 } = props;

  // if we get a string array passed in for tooltip content,
  // turn it into an actual react element with spacing
  const content = () => {
    if (Array.isArray(tooltipContent))
      return tooltipContent
        .map((e, i) => <span key={i}>{e}</span>)
        .reduce((agg, curr) => (
          <>
            {agg}
            <br />
            <br />
            {curr}
          </>
        ));
    // otherwise, if we got an actual react element, just use that
    return tooltipContent;
  };

  return (
    <Tooltip
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -15],
            },
          },
        ],
      }}
      slotProps={{
        tooltip: {
          sx: {
            // If we have plain text, we default to MUI's maxWidth of 300px, else, we define our own within our component's class
            maxWidth:
              typeof props.tooltipContent === "string" ? "300px" : "inherit",
          },
        },
      }}
      placement={"bottom-start"}
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      className={`${styles.tooltip} ${className}`}
      title={content()}
      sx={{
        ml: `${leftMargin}px`,
      }}
    >
      <IconButton>
        <InfoOutlined />
      </IconButton>
    </Tooltip>
  );
};
