import { Circle } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useMediaQuery } from "@mui/material";
import {
  BreakpointConstants,
  chartMargins,
  getCurrencyFormattedValue,
  getDataAccesor,
  getLabelFromCamelCase,
  getTicksAndLabels,
  IHistoricalSummaryDatum,
  InvestmentBreakdownKey,
  InvestmentBreakDownValueSelectors,
  InvestmentHistoryValueItem,
  mobile_chartMargins,
  PeriodType,
  ResponsiveRechartsWrapper,
  xAxisTick,
  yAxisTick,
} from "common";
import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  NameType,
  Payload,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import { RechartsConstants } from "../../../../constants/RechartsSVGStyles";
import colors from "../../../../styles/_colors.scss";
import {
  calculateDateDomain,
  calculateDateTicks,
  getActiveData,
  getQuarterlyLabel,
  getQuarterlyLabelWithQTD,
  getYearLabelByQuarter,
  getYearLabelByQuarterWithSuffix,
  getYearlySuffix,
} from "../../../../utils/historicalSummaryUtils";
import styles from "./PortfolioPerformanceChart.module.scss";

interface PortfolioPerformanceChartProps {
  historicalSummaryData: IHistoricalSummaryDatum[];
  firstQuarterDate: Date;
  periodType: PeriodType;
  isCumulative: boolean;
  dataTypes: InvestmentHistoryValueItem[];
  highlightedBar: InvestmentBreakdownKey | null;
  graphInitialized: boolean;
}

export const PortfolioPerformanceChart = (
  props: PortfolioPerformanceChartProps
) => {
  const [dateTicks, setDateTicks] = useState<number[]>([]);
  const [yAxisTicks, setYAxisTicks] = useState<number[]>([]);
  const [yAxisTickLabels, setYAxisTickLabels] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<number[]>([0, 0]);
  // controls where vertical reference lines (between years) appear on graph
  const [referenceLinePlacements, setReferenceLinePlacements] = useState<
    number[]
  >([]);

  const isMobile = useMediaQuery(
    `(max-width:${BreakpointConstants.EXTRA_SMALL_MAX_WIDTH}px)`
  );

  const {
    historicalSummaryData,
    periodType,
    isCumulative,
    dataTypes,
    graphInitialized,
  } = props;

  useEffect(() => {
    // render all tick labels if the user has less than two years of data
    // otherwise render current quarter and first quarter of previous years
    const activeQuarters = getActiveData(dataTypes, historicalSummaryData).map(
      (datum) => datum.displayQuarterAsOfDate
    );

    const calculatedDateTicks = calculateDateTicks(activeQuarters);
    setDateTicks(calculatedDateTicks);
    const calculatedRange = calculateDateDomain(activeQuarters, periodType);
    setDateRange(calculatedRange);
    const activeLines = dataTypes.filter((line) => line.isSelected);

    const values: number[] = [];
    historicalSummaryData.forEach((datum) =>
      activeLines.forEach((item) =>
        values.push(
          InvestmentBreakDownValueSelectors[item.key](datum.cumulative)
        )
      )
    );
    const displayedDataTypes = dataTypes.filter((type) => type.isSelected);

    const valuesToDisplay = historicalSummaryData.map((datum) =>
      displayedDataTypes.map((item) =>
        // take cumulative value for unrealized, non-cumulative for realized
        item.isUnrealized
          ? InvestmentBreakDownValueSelectors[item.key](datum.cumulative)
          : InvestmentBreakDownValueSelectors[item.key](datum.nonCumulative)
      )
    );

    const [minNegativeSum, maxPositiveSum] = valuesToDisplay.reduce(
      ([minNegative, maxPositive], current) => {
        let currentNegative = 0;
        let currentPositive = 0;
        for (const value of current) {
          if (value < 0) currentNegative += value;
          if (value > 0) currentPositive += value;
        }
        return [
          Math.min(minNegative, currentNegative),
          Math.max(maxPositive, currentPositive),
        ];
      },
      [0, 0]
    );

    // determine if reference line should appear after a data point
    //  a whole number year denotes Q4 data, so only display reference line
    //  after those, excluding the last value, since no line is needed
    //  at the end of the graph. Quarters are zero-indexed.
    const refLines = historicalSummaryData
      .slice(0, historicalSummaryData.length - 1)
      .map((datum) => datum.displayQuarterAsOfDate.valueOf())
      .filter((quarter) => quarter % 4 === 3);

    setReferenceLinePlacements(refLines);

    const tickInfo = getTicksAndLabels([minNegativeSum, maxPositiveSum]);

    setYAxisTicks(tickInfo.tickValues);
    setYAxisTickLabels(tickInfo.tickLabels);
  }, [historicalSummaryData, periodType, isCumulative, dataTypes]);

  const formatYAxisTicks = (value: number, index: number): string => {
    return yAxisTickLabels[index] ?? "";
  };

  return (
    <div className={styles.bar}>
      {graphInitialized && historicalSummaryData.length === 0 ? (
        <div className={styles.noDataMessage}>
          <p>
            <InfoOutlinedIcon className={styles.infoOutlinedIcon} />
            Portfolio Performance data is not available on{" "}
            {periodType === PeriodType.YEARLY
              ? "an annual"
              : "a quarterly"}{" "}
            basis at this time.
          </p>
        </div>
      ) : (
        <ResponsiveRechartsWrapper>
          <BarChart
            data={historicalSummaryData}
            barGap={30}
            maxBarSize={50}
            margin={isMobile ? mobile_chartMargins : chartMargins}
            stackOffset={"sign"}
          >
            <Tooltip
              cursor={{
                fill:
                  /* Recharts adds a gray hover when there's no data by default, 
                  this sets the hover color to white, so we don't have a weird hover when there's no data selected*/
                  dateTicks.length === 0 ? colors.white : colors.lightest_grey,
              }}
              content={({ payload, label }) => {
                let tooltipPayload: Payload<ValueType, NameType>[] = [];
                if (payload) {
                  tooltipPayload = tooltipPayload
                    .concat(payload.filter((a) => a.unit == "unrealized"))
                    .concat(payload.filter((a) => a.unit == "realized"));
                }
                let yearlyLabelSuffix: string | undefined = undefined;
                if (payload && payload.length) {
                  if (payload[0].payload) {
                    yearlyLabelSuffix = getYearlySuffix(
                      payload[0].payload.asOfDate,
                      props.firstQuarterDate,
                      true
                    );
                  }
                }
                return (
                  <div className={styles.tooltip}>
                    <div>
                      <p className={styles.label}>
                        {periodType === PeriodType.QUARTERLY
                          ? getQuarterlyLabelWithQTD(label)
                          : getYearLabelByQuarterWithSuffix(
                              label,
                              yearlyLabelSuffix
                            )}
                      </p>
                      <ul className={styles.itemList}>
                        {payload &&
                          [...tooltipPayload].map((item, index) => (
                            <li
                              key={`${item.dataKey}${index}`}
                              className={styles.item}
                            >
                              <Circle
                                sx={{ fontSize: 10 }}
                                className={styles.circle}
                                style={{ color: item.color }}
                              ></Circle>
                              <span className={styles.itemLabel}>
                                {item.name}:{" "}
                              </span>
                              <span>
                                {getCurrencyFormattedValue(
                                  item.value as number
                                )}
                              </span>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                );
              }}
            />
            <CartesianGrid
              vertical={false}
              strokeDasharray="2 2"
              stroke={colors.slate}
            />
            <XAxis
              type={"number"}
              axisLine={false}
              dataKey={(data) => data.displayQuarterAsOfDate}
              domain={[dateRange[0], dateRange[1]]}
              tick={xAxisTick}
              tickLine={false}
              ticks={dateTicks.length === 0 ? [""] : dateTicks}
              tickFormatter={
                periodType === PeriodType.QUARTERLY &&
                historicalSummaryData.length <=
                  RechartsConstants.MAX_QUARTERS_TO_LABEL_INDIVIDUALLY
                  ? getQuarterlyLabel
                  : getYearLabelByQuarter
              }
            />
            <YAxis
              axisLine={true}
              domain={["dataMin", "dataMax"]}
              tickFormatter={formatYAxisTicks}
              tick={yAxisTick}
              tickLine={false}
              ticks={
                /* When no data is selected, it will render $0 in the middle of the chart by default, 
                this will force the chart to render $0 at the bottom */
                yAxisTicks.length === 1 && yAxisTicks[0] === 0
                  ? [0, 1]
                  : yAxisTicks
              }
              type="number"
            />
            <ReferenceLine y={0} stroke={colors.dark_grey} />
            {referenceLinePlacements.map((item, idx) => {
              return (
                <ReferenceLine
                  key={idx}
                  x={item + (periodType === PeriodType.YEARLY ? 2.0 : 0.5)} // need to move line over so it is between two points
                  stroke={colors.slate}
                  strokeDasharray="2 2"
                />
              );
            })}
            {dataTypes
              .filter((type) => type.isSelected)
              .map((item) => (
                <Bar
                  key={item.key}
                  dataKey={getDataAccesor(item.key, item.isUnrealized ?? true)}
                  fill={item.color}
                  name={item.labelOverride ?? getLabelFromCamelCase(item.key)}
                  stackId="a"
                  unit={item.isUnrealized ? `unrealized` : `realized`}
                  opacity={
                    props.highlightedBar === null ||
                    props.highlightedBar === item.key
                      ? 1
                      : 0.2
                  }
                ></Bar>
              ))}
          </BarChart>
        </ResponsiveRechartsWrapper>
      )}
    </div>
  );
};
