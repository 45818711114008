import {
  convertToRoundedValidNumber,
  IBXEquityCardData,
  IEquityDatum,
  isSomething,
  IStockDataValue,
  IVestingData,
} from "common";

/*
Helper function that takes all equity data and transforms it into what the Dashboard
  Equity Card needs
*/
export const getEquityCardData = (
  equityData: IEquityDatum,
  stockData: IStockDataValue[]
) => {
  try {
    const currentYear = new Date().getFullYear();

    const latestBXStockValue = stockData.reduce(
      (prev: IStockDataValue, current: IStockDataValue) =>
        prev.asOfDate > current.asOfDate ? prev : current
    );
    // get today at midnight
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    /*
    To aggregate by year, we first need to flatten all vesting data.
    We start with the BX IEquityData object, which contains a list
      of IAward objects, each of which has a list of IVestingData objects. Therefore,
      we map each IAward object to its IVestingData[], then
      reduce these into one list to get our final flattened list of vesting data.
    */
    const equityVestingData: IVestingData[] = equityData.awards
      .map((award) => award.vestingData)
      .reduce((a, v) => a.concat(v), []);
    const equityDataAggregatedByYear = equityVestingData.reduce(
      (
        group: {
          [key: string]: number;
        },
        data
      ) => {
        if (data.date >= today) {
          // we only want to include vesting data from the future
          const year = data.date.getFullYear().toString();
          const units = data.quantity;
          group[year] = (group[year] ?? 0) + units;
        }
        return group;
      },
      {}
    );

    const chartData = Object.entries(equityDataAggregatedByYear)
      .map(([year, unit]) => ({
        year: Number(year),
        value: convertToRoundedValidNumber(unit * latestBXStockValue.value),
      }))
      .filter((item) => item.year >= currentYear);

    // determine next vesting date by first filtering for only furute vesting dates,
    //    then finding the closest date to today
    const nextVestingDate = equityVestingData
      .map((item) => item.date)
      .filter((itemDate) => itemDate >= today)
      .reduce((closest, current) => {
        // compute time distance between today and closest next vesting date found so far
        const closestDiff = closest.valueOf() - today.valueOf();
        // compute time distance between today and current vesting date
        const currentDiff = current.valueOf() - today.valueOf();
        // store whichever time difference is less
        return currentDiff <= closestDiff ? current : closest;
      });

    const vestedExchangeableBXUnits = isSomething(
      equityData.vestedExchangeableUnits
    )
      ? equityData.vestedExchangeableUnits.value
      : 0;

    const vestedRestrictedBXUnits = isSomething(
      equityData.vestedRestrictedUnits
    )
      ? equityData.vestedRestrictedUnits.value
      : 0;

    const data: IBXEquityCardData = {
      chartData: chartData,
      totalUnvestedBXValue:
        equityData.totalUnvestedUnits * latestBXStockValue.value,
      vestedExchangeableBXUnits: vestedExchangeableBXUnits,
      vestedRestrictedBXUnits: vestedRestrictedBXUnits,
      vestedAndExchangeableAsOfDate: equityData.vestedAndExchangeableAsOfDate,
      totalUnvestedBXUnits: equityData.totalUnvestedUnits,
      latestBXStockValue: latestBXStockValue,
      nextVestingDate: nextVestingDate,
    };
    return data;
  } catch {
    return undefined;
  }
};
