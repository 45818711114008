import {
  BusinessUnitTreeLevel,
  ElectionPeriodTreeLevel,
  TreeNumber,
} from "../constants/enums";
import { UnrealizedValueLabels } from "../constants/LabelAndTooltipConstants";
import {
  ICarryChartData,
  IInvestmentBreakdownDatum,
  IInvestmentBreakdownGrouped,
} from "../types/dataTypes";
import { isSomething } from "../types/typeGuards";

export const MAX_CARRY_ITEMS_TO_SHOW = 6;

export const hasNegative = (
  investmentBreakdownDatums: IInvestmentBreakdownDatum[]
) => {
  return (
    investmentBreakdownDatums.find(
      //treeLevel = 2 is FUND and treeNumber = 2 is BUSINESS_UNIT
      (x) =>
        x.treeLevel === BusinessUnitTreeLevel.FUND &&
        x.treeNumber === TreeNumber.BUSINESS_UNIT &&
        isSomething(x.unrealizedValue) &&
        x.unrealizedValue.value.carriedInterest < 0
    ) != undefined
  );
};

//Return an array of IPerformanceDetailsData filtered by funds
export const filterToPositiveCarryByFund = (
  performanceData: IInvestmentBreakdownDatum[],
  isAdmin?: boolean,
  businessUnitName?: string
): IInvestmentBreakdownDatum[] => {
  const unrealizedCarryByFund: IInvestmentBreakdownDatum[] =
    performanceData.filter((row) => {
      const hasPositiveUnrealizedCarry =
        //Validate if the carriedInterest has a value higher than 0
        isSomething(row.unrealizedValue) &&
        row.unrealizedValue.value.carriedInterest > 0;
      const hasAnyCarry =
        //Validate if the carriedInterest exists
        (isSomething(row.realizedProceeds) &&
          row.realizedProceeds.value.carriedInterest != 0) ||
        //Validate if the unrealizedValue.carriedInterest exists
        (isSomething(row.unrealizedValue) &&
          row.unrealizedValue.value.carriedInterest != 0);

      const belongsToBusinessUnit =
        !businessUnitName || row.businessUnitName === businessUnitName;

      if (
        //Validate if current performanceData treeLevel is FUND in treeName
        row.treeLevel === BusinessUnitTreeLevel.FUND &&
        //Validate if current performanceData treeNumber is BUSINESS_UNIT in treeName
        row.treeNumber === TreeNumber.BUSINESS_UNIT &&
        //Validate if the fund belongs to the business unit name
        belongsToBusinessUnit
      ) {
        if (
          /*
          investor-ui: only want positive unrealized carry for the donut
            chart because negative values don't really make sense on
            that chart
          */
          (!isAdmin && hasPositiveUnrealizedCarry) ||
          /*
          admin-ui: want any value for carry because we want to show
            everything on the admin grid
          */
          (isAdmin && hasAnyCarry)
        ) {
          return true;
        }
      }
      return false;
    });
  return unrealizedCarryByFund;
};

//Return an array of IPerformanceDetailsData filtered by business unit
export const filterToPositiveCarryByBusinessUnit = (
  performanceData: IInvestmentBreakdownDatum[]
): IInvestmentBreakdownDatum[] => {
  const unrealizedCarryByBusinessUnit: IInvestmentBreakdownDatum[] =
    performanceData.filter((row) => {
      const hasPositiveUnrealizedCarry =
        //Validate if the carriedInterest has a value higher than 0
        isSomething(row.unrealizedValue) &&
        row.unrealizedValue.value.carriedInterest > 0;

      /*
      Validate if current performanceData treelevel is BUSINESS_UNIT.
      investor-ui: only want positive unrealized carry for the donut
        chart because negative values don't really make sense on
        that chart.
      */
      return (
        row.treeLevel === BusinessUnitTreeLevel.BUSINESS_UNIT &&
        hasPositiveUnrealizedCarry
      );
    });
  return unrealizedCarryByBusinessUnit;
};

//Create an ICarryChart array with total unrealized values by fund name
export const createCarryChartList = (
  filterPerformanceDataByFund: IInvestmentBreakdownDatum[]
) => {
  const carryChartList: ICarryChartData[] = [];

  Object.values(filterPerformanceDataByFund).forEach((row) => {
    const businessUnitName: string = row.businessUnitName;
    const fundShortName: string = row.fundShortName;
    const mdmFundId: number = row.mdmFundId;
    const totalUnrealized = isSomething(row.unrealizedValue)
      ? row.unrealizedValue.value.carriedInterest
      : 0;
    const totalRealized = isSomething(row.realizedProceeds)
      ? row.realizedProceeds.value.carriedInterest
      : 0;
    carryChartList.push({
      businessUnitName,
      fundShortName,
      mdmFundId,
      totalUnrealized,
      totalRealized,
    });
  });
  return carryChartList;
};

// gets the total carry columns
export const getTotalCarry = (
  investmentBreakdownGrouped: IInvestmentBreakdownGrouped
): ICarryChartData => {
  const summaryRow = investmentBreakdownGrouped[
    TreeNumber.ELECTION_PERIOD
  ].filter((data) => data.treeLevel === ElectionPeriodTreeLevel.ENTITY)[0];
  return {
    businessUnitName: "",
    fundShortName: "",
    mdmFundId: 0,
    totalUnrealized: isSomething(summaryRow.unrealizedValue)
      ? summaryRow.unrealizedValue.value.carriedInterest
      : 0,
    totalRealized: isSomething(summaryRow.realizedProceeds)
      ? summaryRow.realizedProceeds.value.carriedInterest
      : 0,
  };
};

//Sort carry chart by total unrealized in descending order
export const sortAndReduceCarryChartData = (
  carryChartList: ICarryChartData[],
  isFundLevel?: boolean
) => {
  const sortedList = carryChartList.sort((a, b) =>
    a.totalUnrealized > b.totalUnrealized ? -1 : 1
  );
  if (sortedList.length > MAX_CARRY_ITEMS_TO_SHOW) {
    // when we have more than MAX funds, we want only want to show MAX - 1 then group rest into an Other category
    // sum unrealized of the funds after the top MAX - 1
    const leftOverSum = sortedList
      .slice(MAX_CARRY_ITEMS_TO_SHOW - 1)
      .reduce((a, v) => a + v.totalUnrealized, 0);
    // return top MAX - 1 and new Other category
    return [
      ...sortedList.slice(0, MAX_CARRY_ITEMS_TO_SHOW - 1),
      {
        businessUnitName: UnrealizedValueLabels.OTHER,
        fundShortName: isFundLevel
          ? UnrealizedValueLabels.OTHER_FUNDS
          : UnrealizedValueLabels.OTHER,
        mdmFundId: 11827755,
        totalUnrealized: leftOverSum,
        totalRealized: 0,
      },
    ];
  }
  return sortedList;
};
