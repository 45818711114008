import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  AdminEquityLabels,
  DataLoadStatus,
  DropdownSearchBar,
  IEmployee,
  isSomething,
  reqAllEntitlements,
  reqDistributionsData,
  reqEquityData,
  reqSelectedEmployee,
  setActiveEquityDataId,
} from "common";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { AdminEquityDownloadButton } from "../../common/components/AdminDownload/AdminEquityDownloadButton";
import { RelativePath } from "../../constants/Paths";
import { AdminUIStore } from "../../redux/store";
import {
  findEmployeeForId,
  getEmployeeOptionLabel,
  searchAndSortEmployees,
} from "../../utils/adminEmployeeUtils";
import styles from "./EquitySubHeader.module.scss";

export const EquitySubHeader = () => {
  const { equityAdminEntitlementLoadStatus, hasEquityAdminEntitlement } =
    useSelector((store: AdminUIStore) => store.entitlements);

  const { employees } = useSelector((store: AdminUIStore) => store.employees);
  const { selectedEmployee } = useSelector((store: AdminUIStore) => store);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchableEmployees: IEmployee[] = isSomething(employees)
    ? employees.value
    : [];

  useEffect(() => {
    async function checkData() {
      if (equityAdminEntitlementLoadStatus === DataLoadStatus.NOT_REQUESTED) {
        dispatch(reqAllEntitlements());
      }
    }
    checkData();
  }, [equityAdminEntitlementLoadStatus, dispatch]);

  // Extract the employeeId from the pathname
  const params = useParams<{ employeeId: string }>();
  const employeeId = params.employeeId ?? "";

  const employeeFromPath = findEmployeeForId(employeeId, employees);

  /*
   * On user searching for an Employee,
   * Request all data for employee and navigate to vesting events.
   */
  const onSearch = (searchedEmployee: IEmployee | null) => {
    if (searchedEmployee) {
      dispatch(reqSelectedEmployee(searchedEmployee));

      // load all equity data
      dispatch(setActiveEquityDataId(searchedEmployee.id));
      dispatch(reqEquityData(searchedEmployee.id));
      dispatch(reqDistributionsData(searchedEmployee.id));

      navigate(RelativePath.EQUITY.replace(":employeeId", searchedEmployee.id));
    }
  };

  return (
    <>
      <div className={styles.subnavbar}>
        {hasEquityAdminEntitlement &&
        equityAdminEntitlementLoadStatus === DataLoadStatus.SUCCESSFUL ? (
          <Box className={styles.toolbar}>
            <Typography variant="h5" className={styles.searchText}>
              {AdminEquityLabels.SEARCH_FOR}
            </Typography>
            <DropdownSearchBar<IEmployee>
              placeholder={AdminEquityLabels.SEARCH_PLACEHOLDER}
              dropdownOptions={searchableEmployees}
              onChange={onSearch}
              getOptionLabel={getEmployeeOptionLabel}
              filterAndSortDropdownOptions={searchAndSortEmployees}
              maxDropdownOptions={7} // this is the max before a scrollbar shows, per product reqs
              getNoOptionsMessage={AdminEquityLabels.NO_RESULTS}
              className={`${styles.searchBar} ${styles.fullWidth}`}
              initialValue={employeeFromPath}
            />
            {selectedEmployee.id !== "" && <AdminEquityDownloadButton />}
          </Box>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
