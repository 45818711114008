interface InvestmentBreakdownCellPosition {
  businessUnitName: string;
  fundShortName: string;
  investment: string;
  capitalInvestedHeader: string;
  fundRealizedHeader: string;
  fundUnrealizedHeader: string;
  realizedProceedsHeader: string;
  unrealizedValueHeader: string;
}

export const defaultExcelCellPosition: InvestmentBreakdownCellPosition = {
  businessUnitName: "A",
  fundShortName: "B",
  investment: "D",
  capitalInvestedHeader: "E1",
  fundRealizedHeader: "F1",
  fundUnrealizedHeader: "J1",
  realizedProceedsHeader: "F1:I1",
  unrealizedValueHeader: "J1:M1",
};

export const adminExcelCellPosition: InvestmentBreakdownCellPosition = {
  businessUnitName: "A",
  fundShortName: "C",
  investment: "E",
  capitalInvestedHeader: "F1",
  fundRealizedHeader: "G1",
  fundUnrealizedHeader: "K1",
  realizedProceedsHeader: "G1:J1",
  unrealizedValueHeader: "K1:N1",
};
