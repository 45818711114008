import { getFormattedDateYYYYMMDD } from "./formatters";

/*
downloads file to user's browser without opening new tab
*/
export const downloadFile = (file: File, fileNameWithExtension: string) => {
  const url = URL.createObjectURL(file);

  // create a hidden link element that triggers a download of the file we created
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  link.setAttribute("download", fileNameWithExtension);

  // add the link to the document, click it to start download, and remove it
  document.body.appendChild(link);
  link.click();
  link.remove();
  URL.revokeObjectURL(url);
};

export const buildAndDownloadZipFile = (blob: Blob, name: string) => {
  const fileName = `${name}${getFormattedDateYYYYMMDD(new Date(), false)}.zip`;

  const file = new File([blob], fileName);
  downloadFile(file, fileName);
};
