import { createSelector } from "@reduxjs/toolkit";
import { IHistoricalSummaryGrouped } from "common";

import { nothing, Optional, some } from "../../types/typeUtils";
import { isInProgress } from "../../utils/dataLoadUtils";
import { groupHistoricalSummary } from "../../utils/historicalSummaryUtils";
import {
  selectActiveInvestmentEntityData,
  selectInternalInvestmentDataLoadStatus,
} from "./baseStoreSelectors";

const selectInvestmentBreakdown = createSelector(
  selectActiveInvestmentEntityData,
  selectInternalInvestmentDataLoadStatus,
  ({ historicalSummary, asOfDates }, internalInvestmentDataLoadStatus) => {
    return {
      internalInvestmentDataLoadStatus,
      historicalSummary,
      asOfDates,
    };
  }
);

export const selectHistoricalSumaryGrouped = createSelector(
  selectInvestmentBreakdown,
  ({ internalInvestmentDataLoadStatus, historicalSummary, asOfDates }) => {
    if (
      isInProgress(internalInvestmentDataLoadStatus) ||
      historicalSummary.length === 0
    ) {
      return {
        historicalSummaryGrouped:
          nothing as Optional<IHistoricalSummaryGrouped>,
      };
    }
    const historicalSummaryGrouped = groupHistoricalSummary(
      historicalSummary,
      asOfDates.latestAsOfDateWithUnrealizedData
    );
    return {
      historicalSummaryGrouped: some(historicalSummaryGrouped),
    };
  }
);
