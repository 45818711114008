import { Card } from "@mui/material";
import React, { ReactNode, useCallback } from "react";

import { FailedToLoadError } from "../FailedToLoadError/FailedToLoadError";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { NoDataAvailableError } from "../NoDataAvailableError/NoDataAvailableError";

type RetryFunction = () => void;

export interface DataCardProps {
  children: ReactNode;
  className?: string;
  id?: string;
  hide?: boolean;
  hideIfNoData?: boolean;
  failed?: boolean;
  loading?: boolean;
  noData?: boolean;
  onReload?: RetryFunction | Array<RetryFunction>;
}

const CardContent = (props: DataCardProps) => {
  const { children, failed, loading, onReload, noData } = props;

  const handleRetry = useCallback(() => {
    if (Array.isArray(onReload)) {
      onReload.map((func) => func());
    } else if (onReload) {
      onReload();
    }
  }, [onReload]);

  if (loading ?? false) {
    return <LoadingIndicator />;
  }
  if (failed ?? false) {
    return <FailedToLoadError retryRequest={handleRetry} />;
  }
  if (noData ?? false) {
    return <NoDataAvailableError />;
  }

  return <>{children}</>;
};

export const DataCard = (props: DataCardProps) => {
  return props.hide || (props.hideIfNoData && props.noData) ? null : (
    <Card className={`${props.className}`} id={props.id}>
      <CardContent {...props} />
    </Card>
  );
};
