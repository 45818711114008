import { LoadError, Maybe } from "../types/typeUtils";

/**
 * checks if data was returned by the fetch
 * @param responseStatus the response status code of a data fetch
 * @returns boolean, true if data found and false if not. throws
 *  a load error if unsuccessful response code
 */
export const foundData = (responseStatus: number): boolean => {
  if (responseStatus === 204) {
    return false;
  }
  if (responseStatus !== 200) {
    throw new LoadError();
  }

  return true;
};

/**
 * Handles the response based on status code
 * @param response the Response object returned by a fetch data call
 * @returns response josn if 200 code, undefined if 204 code and throws error
 *  if anything else
 */
export const handleResponse = <T>(response: Response): Promise<Maybe<T>> => {
  if (foundData(response.status)) {
    return response.json();
  } else {
    return Promise.resolve(undefined);
  }
};

/**
 * Handles the response based on status code and returns blob
 * @param response the Response object returned by a fetch data call
 * @returns response blob if 200, undefined otherwise
 */
export const handleBlobResponse = (
  response: Response
): Promise<Maybe<Blob>> => {
  if (response.status === 200) {
    return response.blob();
  } else {
    return Promise.resolve(undefined);
  }
};

/**
 * Handles the response based on status code
 * @param response the Response object returned by a fetch data call
 * @returns response josn if 200 code, undefined otherwise
 *  if anything else
 */
export const handleEntitlementResponse = <T>(
  response: Response
): Promise<Maybe<T>> => {
  if (response.status == 200) {
    return response.json();
  } else {
    return Promise.resolve(undefined);
  }
};
