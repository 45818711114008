import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";

import styles from "./BaseEntityDropdown.module.scss";

export interface IItems {
  id: string | number;
}

export interface IDropdownItemData {
  label: string;
  classes: string;
}
export interface IBaseEntityDropdownProps<TItem extends IItems> {
  subheaderLabel: string;
  items: TItem[];
  selectedValue: number | string;
  onSelectionChange: (event: SelectChangeEvent) => void;
  getActiveItemLabel: (id: string) => string;
  getMenuItemData: (item: TItem) => IDropdownItemData;
}

export const BaseEntityDropdown = <TItem extends IItems>(
  props: IBaseEntityDropdownProps<TItem>
) => {
  const {
    subheaderLabel,
    items,
    selectedValue,
    onSelectionChange,
    getActiveItemLabel,
    getMenuItemData,
  } = props;

  const renderActiveItemLabel = (id: string) => {
    const label = getActiveItemLabel(id);
    const classes = `${styles.activeItem} ${styles.itemLinePreWrap}`;
    return <span className={classes}>{label}</span>;
  };

  /**
   * Gets the appropiate class for the menu item being rendered
   * @returns A class name string
   */
  const getMenuItemClasses = (): string => {
    return `${styles.itemLayout} ${styles.itemLinePreWrap}`;
  };

  const buildDropdownItem = (item: TItem, i: number) => {
    const { label, classes } = getMenuItemData(item);
    return (
      <MenuItem key={i} value={item.id} className={getMenuItemClasses()}>
        <span className={classes}>{label}</span>
        {item.id === selectedValue && (
          <span>
            <CheckCircleIcon className={styles.selectedItemIcon} />
          </span>
        )}
      </MenuItem>
    );
  };

  return (
    <div className={styles.selectContainer}>
      <Select
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
        className={styles.select}
        renderValue={renderActiveItemLabel}
        value={selectedValue.toString()}
        onChange={onSelectionChange}
      >
        <ListSubheader className={styles.selectSubHeader}>
          {subheaderLabel}
        </ListSubheader>
        {items.map(buildDropdownItem)}
      </Select>
    </div>
  );
};
