import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import React from "react";

import { ErrorStringConstants } from "../../constants/ErrorStringConstants";
import styles from "./NoDataAvailableError.module.scss";

/**
 * Generic component to render when a single section/card on BXWealth
 *  has data missing
 * @returns the no data available component
 */
export const NoDataAvailableError = () => {
  return (
    <div className={styles.noData}>
      <ErrorOutlineOutlinedIcon className={styles.icon} />
      <div className={styles.message}>{ErrorStringConstants.NO_DATA}</div>
    </div>
  );
};
