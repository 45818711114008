import { Card } from "@mui/material";
import { InfoTooltip } from "common";
import React from "react";

import styles from "./Callout.module.scss";

interface ICalloutProps {
  label: string;
  headerTooltipParagraphs?: string[] | undefined;
  tooltipParagraphs?: string[] | undefined;
  value: string;
  showHeaderTooltip?: boolean | undefined;
  showTooltip?: boolean | undefined;
}

export const Callout = (props: ICalloutProps) => {
  const {
    label,
    headerTooltipParagraphs,
    tooltipParagraphs,
    value,
    showHeaderTooltip = false,
    showTooltip = false,
  } = props;

  return (
    <Card className={styles.callout}>
      <div className={styles.calloutLabel}>
        <span>{label}</span>
        {showHeaderTooltip && (
          <InfoTooltip tooltipContent={headerTooltipParagraphs ?? [""]} />
        )}
      </div>
      <span className={styles.calloutValue}>
        <span>{value}</span>
        {showTooltip && (
          <InfoTooltip
            className={styles.tooltipBody}
            tooltipContent={tooltipParagraphs ?? [""]}
          />
        )}
      </span>
    </Card>
  );
};
