import { ThemeOptions } from "@mui/material";

import colors from "../../_colors.scss";
import { Font } from "../sharedStyleConstants";

// Shared constants we may want to move in unison

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
      styleOverrides: {
        root: {
          fontFamily: Font.Family.GUARDIAN_SANS,
          fontWeight: Font.Weight.STANDARD,
          color: colors.cobalt,
          cursor: "pointer",
          "&[disabled]": {
            cursor: "default",
            "&:hover": {
              textDecoration: "none",
            },
          },
        },
      },
    },
  },
};

export const CustomLinkTheme = partialTheme.components?.MuiLink;
