import { IResultResponse } from "../types/electionDataTypes";
import { Json, Maybe } from "../types/typeUtils";
import { getUser } from "./auth";
import { EnvironmentResolver } from "./environmentResolver";
import { handleResponse } from "./utils";

export type IResultResponseSource = Json<IResultResponse[]>;

export const getResult = async (
  electionRoundId: string
): Promise<Maybe<IResultResponseSource>> => {
  const user = getUser();
  const url = `${EnvironmentResolver.ENV.REACT_APP_ELECTIONS_URL_BASE}/results/election-rounds/${electionRoundId}`;
  const request = {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
  };

  const response = await fetch(url, request);
  return await handleResponse(response);
};
