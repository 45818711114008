import { Schema } from "hast-util-sanitize";
import rehypeRemark from "rehype-remark";
import rehypeSanitize from "rehype-sanitize";
import rehypeStringify from "rehype-stringify";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import remarkStringify from "remark-stringify";
import { unified } from "unified";

const schema: Readonly<Schema> = {
  tagNames: [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p",
    "a",
    "u",
    "b",
    "ul",
    "li",
    "ol",
    "i",
    "strong",
    "br",
    "em",
  ],
  attributes: {
    a: ["href"],
  },
};

export async function SanitizeMarkdownToMarkdown(
  markdown: string
): Promise<string> {
  const result = await unified()
    .use(remarkParse)
    .use(remarkRehype)
    .use(rehypeSanitize, schema)
    .use(rehypeRemark) // convert again to markdown
    .use(remarkStringify)
    .process(markdown);

  return String(result);
}

export async function SanitizeMarkdownToHtml(
  markdown: string
): Promise<string> {
  const result = await unified()
    .use(remarkParse)
    .use(remarkRehype)
    .use(rehypeSanitize, schema)
    .use(rehypeStringify)
    .process(markdown);

  return String(result);
}
