import React from "react";

import {
  getAsOfLine,
  OverviewLabel,
  OverviewTooltip,
  SectionHeader,
} from "../../constants/LabelAndTooltipConstants";
import { IUnrealizedCalloutData } from "../../types/dataTypes";
import { ILabeledDataPoint } from "../../types/labelTypes";
import { IDashboardCardContentProps } from "../DashboardCard/DashboardCard";
import { OverviewCard } from "./components/OverviewCard/OverviewCard";

/**
 * Component that encapsulates the Unrealized Value overview card
 */
export const UnrealizedValueOverview = (
  props: IDashboardCardContentProps<IUnrealizedCalloutData>
) => {
  const unrealizedData = props.data;

  const unrealizedDate = unrealizedData.netUnrealizedValue.asOfDate;
  const allCardData: ILabeledDataPoint[] = [
    {
      label: OverviewLabel.CARRY,
      tooltipParagraphs: [OverviewTooltip.CARRY],
      calloutDatum: unrealizedData.unrealizedCarry,
    },
    {
      label: OverviewLabel.OPTIONAL_AND_MANDATORY,
      tooltipParagraphs: [],
      calloutDatum: unrealizedData.unrealizedOptionalAndMandatoryInvestments,
    },
    {
      label: OverviewLabel.REMANING_CAPITAL_INVESTED,
      tooltipParagraphs: [OverviewTooltip.REMAINING_CAPITAL_INVESTED],
      calloutDatum: unrealizedData.unrealizedCapitalInvested,
      isSubLabel: true,
    },
    {
      label: OverviewLabel.GAIN_LOSS,
      tooltipParagraphs: [OverviewTooltip.UNREALIZED_GAIN_LOSS],
      calloutDatum: unrealizedData.unrealizedGainLoss,
      isSubLabel: true,
    },
    {
      label: OverviewLabel.TOTAL_UNREALIZED_VALUE,
      tooltipParagraphs: [],
      calloutDatum: unrealizedData.totalUnrealizedValue,
    },
    {
      label: OverviewLabel.LOANS,
      tooltipParagraphs: [OverviewTooltip.LOANS],
      calloutDatum: {
        value: (unrealizedData.loans?.value ?? 0) * -1,
        asOfDate: unrealizedData.loans?.asOfDate ?? new Date(),
      },
    },
    {
      label: OverviewLabel.NET_UNREALIZED_VALUE,
      tooltipParagraphs: [],
      calloutDatum: unrealizedData.netUnrealizedValue,
      isTotalLabel: true,
    },
  ];

  return (
    <OverviewCard
      cardName={SectionHeader.UNREALIZED_VALUE_OVERVIEW}
      subHeader={getAsOfLine(unrealizedDate)}
      overviewDataList={allCardData}
    />
  );
};
