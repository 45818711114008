import { ThemeOptions } from "@mui/material";

import colors from "../../_colors.scss";

const HoverStyle = {
  BACKGROUND_COLOR: colors.charcoal,
  BOX_SHADOW: "0px 3px 6px #00000040",
  COLOR: colors.white,
};

const partialTheme: ThemeOptions = {
  components: {
    MuiFab: {
      styleOverrides: {
        root: {
          backgroundColor: colors.black,
          color: colors.white,
          "&:active, &:hover": {
            backgroundColor: HoverStyle.BACKGROUND_COLOR,
            boxShadow: HoverStyle.BOX_SHADOW,
          },
          "&:disabled": {
            backgroundColor: colors.silver,
            color: colors.white,
          },
        },
      },
    },
  },
};

export const CustomFabTheme = partialTheme.components?.MuiFab;
