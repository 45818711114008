import { ThemeOptions } from "@mui/material";

import { Font } from "../sharedStyleConstants";

// Shared constants we may want to move in unison
const Padding = {
  VERTICAL: "4px",
};

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          fontFamily: Font.Family.GUARDIAN_SANS,
          paddingTop: Padding.VERTICAL,
          paddingBottom: Padding.VERTICAL,
          fontSize: Font.Size.STANDARD,
          paddingLeft: "10px",
        },
      },
    },
  },
};

export const CustomSelectTheme = partialTheme.components?.MuiSelect;
