import { Stack } from "@mui/material";
import {
  DashboardCard,
  DataLoadStatus,
  getAsOfLine,
  IBalancePerformanceData,
  IBaseStore,
  isSomething,
  LoansCard,
  nothing,
  Optional,
  RealizedProceedsOverview,
  reqEquityData,
  reqInternalInvestmentData,
  SectionHeader,
  SegregatedTrustBalance,
  selectActiveInvestmentEntityData,
  selectAsOfDates,
  selectCallout,
  selectHistoricalSumaryGrouped,
  selectInternalInvestmentData,
  showLoanCard,
  some,
  UnrealizedValueOverview,
  useFetchDatasetIfIdDefined,
} from "common";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { CashFlow } from "../CashFlow/CashFlow";
import { PortfolioBalance } from "../PortfolioBalance/PortfolioBalance";
import { PortfolioPerformance } from "../PortfolioPerformance/PortfolioPerformance";
import styles from "./InvestmentsAndCarryOverview.module.scss";

interface OverviewWidget {
  renderFunction: (index: number) => JSX.Element;
  shouldShow: boolean;
}

export const InvestmentsAndCarryOverview = () => {
  const activeEquityDataId = useSelector(
    (state: IBaseStore) => state.equityData.activeEquityDataId
  );

  const { filteredBy, internalInvestmentDataLoadStatus } = useSelector(
    (state: IBaseStore) => selectInternalInvestmentData(state)
  );

  const { financingBalance, segregatedTrustBalance } = useSelector(
    (store: IBaseStore) => selectActiveInvestmentEntityData(store)
  );

  const asOfDate = useSelector((store: IBaseStore) => selectAsOfDates(store));

  const { historicalSummaryGrouped } = useSelector((store: IBaseStore) =>
    selectHistoricalSumaryGrouped(store)
  );

  const { calloutData } = useSelector((store: IBaseStore) =>
    selectCallout(store)
  );

  const { equityDataLoadStatus } = useSelector(
    (store: IBaseStore) => store.equityData
  );

  const [investmentsAndCarryData, setInvestmentsAndCarryData] =
    useState<Optional<IBalancePerformanceData>>(nothing);

  const [showFinancingBalance, setShowFinancingBalance] =
    useState<boolean>(false);

  //fetch vesting data to show/hide equity
  // TODO: remove this when policy server has a way of hiding permissions as this is a temporary workaround
  useFetchDatasetIfIdDefined(
    reqEquityData,
    activeEquityDataId,
    equityDataLoadStatus
  );

  useEffect(() => {
    const investmentsAndCarryData: Optional<IBalancePerformanceData> = some({
      historicalSummaryGrouped,
      asOfDate,
    });
    setInvestmentsAndCarryData(investmentsAndCarryData);
  }, [calloutData, historicalSummaryGrouped, asOfDate]);

  const unrealizedOverview = useMemo(
    () => calloutData.unrealized,
    [calloutData.unrealized]
  );
  const realizedOverview = useMemo(() => {
    if (isSomething(calloutData.realized)) {
      return some({
        callouts: calloutData.realized.value,
        asOfDates: asOfDate,
      });
    }
    return nothing;
  }, [asOfDate, calloutData.realized]);

  useEffect(() => {
    if (isSomething(financingBalance)) {
      showLoanCard(financingBalance.value, setShowFinancingBalance);
    } else {
      setShowFinancingBalance(false);
    }
  }, [financingBalance]);

  const retryFunction = useFetchDatasetIfIdDefined(
    reqInternalInvestmentData,
    filteredBy,
    internalInvestmentDataLoadStatus
  );

  const widgets: OverviewWidget[] = [
    {
      renderFunction: (index: number) => (
        <div className={`${styles.splitCallout}`} key={index}>
          <div className={styles.overviews}>
            <DashboardCard
              cardContentComponent={UnrealizedValueOverview}
              retryRequest={retryFunction}
              data={unrealizedOverview}
              dataLoadStatus={internalInvestmentDataLoadStatus}
              id={styles.unrealizedOverview}
            />
            <DashboardCard
              cardContentComponent={RealizedProceedsOverview}
              retryRequest={retryFunction}
              data={realizedOverview}
              dataLoadStatus={internalInvestmentDataLoadStatus}
              id={styles.realizedOverview}
            />
          </div>
          <div className={styles.bxWealthCard}>
            <DashboardCard
              cardContentComponent={PortfolioBalance}
              retryRequest={retryFunction}
              data={investmentsAndCarryData}
              dataLoadStatus={internalInvestmentDataLoadStatus}
              id={styles.cashFlowChart}
            />
          </div>
        </div>
      ),
      shouldShow: true,
    },
    {
      renderFunction: (index: number) => (
        <DashboardCard
          key={index}
          cardContentComponent={CashFlow}
          retryRequest={retryFunction}
          data={investmentsAndCarryData}
          dataLoadStatus={internalInvestmentDataLoadStatus}
        />
      ),
      shouldShow: true,
    },
    {
      renderFunction: (index: number) => (
        <DashboardCard
          key={index}
          cardContentComponent={PortfolioPerformance}
          retryRequest={retryFunction}
          data={investmentsAndCarryData}
          dataLoadStatus={internalInvestmentDataLoadStatus}
        />
      ),
      shouldShow: false, // temporarily hiding as per BXAC-10266
    },
    {
      renderFunction: (index: number) => (
        <DashboardCard
          key={index}
          header={SectionHeader.LOANS}
          subHeader={getAsOfLine(asOfDate.latestAsOfDate)}
          cardContentComponent={LoansCard}
          retryRequest={retryFunction}
          data={financingBalance}
          dataLoadStatus={internalInvestmentDataLoadStatus}
          hideOnEmpty={true}
          hide={!showFinancingBalance}
        />
      ),
      // hide when no data available
      shouldShow:
        internalInvestmentDataLoadStatus !== DataLoadStatus.EMPTY_RESPONSE &&
        isSomething(financingBalance),
    },
    {
      renderFunction: (index: number) => (
        <DashboardCard
          key={index}
          header={SectionHeader.SEG_TRUST_BALANCE}
          subHeader={getAsOfLine(
            isSomething(asOfDate.segTrustBalanceDate)
              ? asOfDate.segTrustBalanceDate.value
              : new Date()
          )}
          cardContentComponent={SegregatedTrustBalance}
          retryRequest={retryFunction}
          data={segregatedTrustBalance}
          dataLoadStatus={internalInvestmentDataLoadStatus}
          hideOnEmpty={true}
        />
      ),
      // hide when no data available
      shouldShow:
        internalInvestmentDataLoadStatus !== DataLoadStatus.EMPTY_RESPONSE &&
        isSomething(segregatedTrustBalance),
    },
  ].filter((widget) => widget.shouldShow);

  return (
    <div className={styles.container}>
      <Stack direction="row" className={styles.content}>
        <div className={styles.widgets}>
          {widgets.map((widgetInfo, index) => {
            return widgetInfo.renderFunction(index);
          })}
        </div>
      </Stack>
    </div>
  );
};
