import { createSelector } from "@reduxjs/toolkit";
import {
  IBaseStore,
  IBXEquityCardData,
  isInProgress,
  isSomething,
  StockSymbol,
  UISupportedStocks,
} from "common";

import { getEquityCardData } from "../../utils/equityCardUtils";

const selectEquityData = (state: IBaseStore) => state.equityData;

export interface IEquityDataCards {
  loading: boolean;
  cards: {
    symbol: StockSymbol;
    card: IBXEquityCardData | undefined;
    noData: boolean;
  }[];
}

// Resolves all the available cards from the UISupportedStocks
// returns and object with loading state (if equityData or StockData is loading = true) and cards (array with stock and related card)
export const selectEquityDataCards = createSelector(
  selectEquityData,
  ({ equityDataLoadStatus, stockDataLoadStatus, equityData, stockData }) => {
    const loading = isInProgress(equityDataLoadStatus, stockDataLoadStatus);
    const cards =
      (!loading &&
        UISupportedStocks.map((symbol) => {
          const selectedEquityData = equityData[symbol];
          const selectedStockData = stockData[symbol];
          const noData =
            !isSomething(selectedEquityData) || !isSomething(selectedStockData);
          const card =
            (!noData &&
              getEquityCardData(
                selectedEquityData.value,
                selectedStockData.value
              )) ||
            undefined;
          return { symbol, card, noData };
        })) ||
      [];
    return { loading, cards } as IEquityDataCards;
  }
);

// Resolves the sum of Unvested equity just aggregating the totalUnvestedBXValue for all the available cards
export const selectUnvestedEquityTotal = createSelector(
  selectEquityDataCards,
  (equityDataCards) => {
    const anyEquity = equityDataCards.cards.some((x) => !x.noData);
    const total =
      equityDataCards.cards
        .filter((x) => !!x.card)
        .map((x) => x.card as IBXEquityCardData)
        .reduce((acc, x) => acc + x.totalUnvestedBXValue, 0) || 0;
    return { loading: equityDataCards.loading, anyEquity, total };
  }
);

// Selector to get all Vested, Restricted and Exchangeable Totals.
export const SelectRestrictedEquityTotal = createSelector(
  selectEquityDataCards,
  (equityDataCards) => {
    const anyEquity = equityDataCards.cards.some((x) => !x.noData);
    const total =
      equityDataCards.cards
        .filter((x) => !!x.card)
        .map((x) => x.card as IBXEquityCardData)
        .reduce(
          (acc, x) =>
            acc +
            (x.totalUnvestedBXUnits +
              x.vestedExchangeableBXUnits +
              x.vestedRestrictedBXUnits) *
              x.latestBXStockValue.value,
          0
        ) || 0;
    return { loading: equityDataCards.loading, anyEquity, total };
  }
);
