import { Grid } from "@mui/material";
import {
  getChartCellColorByIndex,
  getCurrencyFormattedValueWithZeroDefault,
  PieConstants,
  ResponsiveRechartsWrapper,
  SectionHeader,
} from "common";
import React from "react";
import { Cell, Pie, PieChart, Tooltip } from "recharts";

import colors from "../../../../../styles/_colors.scss";
import { IInvestmentChartData } from "../../../../../types/dataTypes";
import styles from "./InvestmentChart.module.scss";
import { InvestmentChartTooltip } from "./InvestmentChartTooltip";

interface InvestmentChartProps {
  investmentChartData: IInvestmentChartData[];
  unrealizedInvestments: number | undefined;
  handleSliceClick: (investmentChartData: IInvestmentChartData) => void;
  verticalAlignCenter?: boolean;
}

const NoValueInvestmentChart: IInvestmentChartData[] = [
  {
    businessUnitName: "",
    fundShortName: "",
    mdmFundId: 0,
    unrealizedValue: {
      asOfDate: new Date(),
      remainingCapitalInvested: 0,
      gainLoss: 0,
      optionalAndMandatoryInvestments: 1,
      carriedInterest: 0,
      total: 0,
    },
  },
];

export const InvestmentChart = (props: InvestmentChartProps) => {
  const {
    investmentChartData,
    unrealizedInvestments,
    handleSliceClick,
    verticalAlignCenter,
  } = props;

  //Validates if the list of holdback per fund has 1 or more values
  const investmentChartHasValues = investmentChartData.length > 0;
  //Position for the X Axis from pie chart
  const xAxis = "50%";
  //Position for the Y Axis from pie chart
  const yAxis = !!verticalAlignCenter ? 115 : 94;
  const pieStyle = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  };

  return (
    <>
      <Grid item xs={12} md={12} className={styles.pie}>
        <ResponsiveRechartsWrapper>
          <PieChart className={styles.pie} margin={pieStyle}>
            {investmentChartHasValues && (
              <Tooltip
                cursor={{
                  /* Recharts adds a gray hover when there's no data by default, 
                this sets the hover color to white, so we don't have a weird hover when there's no data selected*/
                  stroke:
                    investmentChartData.length === 0
                      ? colors.white
                      : colors.lightest_grey,
                }}
                content={({ payload }) =>
                  payload && <InvestmentChartTooltip payload={payload} />
                }
              />
            )}
            <text
              dx={xAxis}
              y={yAxis - 15}
              textAnchor="middle"
              dominantBaseline="middle"
              className={styles.pieCenterText}
              fill={colors.bx_primary_grey_text}
            >
              {SectionHeader.UNREALIZED_INVESTMENTS}
            </text>
            <text
              dx={xAxis}
              y={yAxis + 15}
              textAnchor="middle"
              dominantBaseline="middle"
              className={styles.pieCenterValue}
            >
              {getCurrencyFormattedValueWithZeroDefault(unrealizedInvestments)}
            </text>
            <Pie
              data={
                investmentChartHasValues
                  ? investmentChartData
                  : NoValueInvestmentChart
              }
              cx={xAxis}
              cy={yAxis}
              innerRadius={PieConstants.INNER_RADIUS}
              outerRadius={PieConstants.OUTER_RADIUS}
              fill={colors.white}
              blendStroke={investmentChartData.length <= 1}
              minAngle={investmentChartData.length > 1 ? 10 : 0}
              dataKey="unrealizedValue.optionalAndMandatoryInvestments"
              nameKey="businessUnitName"
              startAngle={90} // pie chart should begin at 12 o'clock position
              endAngle={-270} // pie chart should go clockwise, which is negative direction
            >
              {investmentChartHasValues &&
                investmentChartData.map((investmentData, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      investmentData.unrealizedValue
                        .optionalAndMandatoryInvestments !== 0
                        ? getChartCellColorByIndex(index)
                        : colors.bx_slate
                    }
                    onClick={() => handleSliceClick(investmentData)}
                  />
                ))}
              {NoValueInvestmentChart.map((index) => (
                <Cell key={`cell-${index}`} fill={colors.medium_grey} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveRechartsWrapper>
      </Grid>
    </>
  );
};
