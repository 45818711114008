import { createReducer } from "@reduxjs/toolkit";

import { DataLoadStatus } from "../../constants/enums";
import {
  IAsOfDate,
  IDateRange,
  IInvestmentDataDto,
  ISegregatedTrustBalance,
} from "../../types/dataTypes";
import {
  DEFAULT_INTERNAL_INVESTMENT_STATE,
  EMPTY_INTERNAL_INVESTMENT_STATE,
} from "../../types/defaultTypes";
import {
  IEntityDataStore,
  IInternalInvestmentDataStore,
} from "../../types/storeTypes";
import { isSomething } from "../../types/typeGuards";
import { nothing, some } from "../../types/typeUtils";
import {
  errInternalInvestmentData,
  recvInternalInvestmentData,
  reqInternalInvestmentData,
  resetSelectedClient,
  setInternalInvestmentDataFilters,
} from "../actions/internalInvestmentActions";

const convertDateRange = (
  dateRange: IDateRange,
  segregatedTrustBalance: ISegregatedTrustBalance | null
): IAsOfDate => {
  return {
    latestAsOfDate: dateRange.latestAsOfDate,
    latestAsOfDateWithUnrealizedData: dateRange.latestAsOfDateWithUnrealizedData
      ? some(dateRange.latestAsOfDateWithUnrealizedData)
      : nothing,
    earliestAsOfDate: dateRange.earliestAsOfDate,
    segTrustBalanceDate: segregatedTrustBalance
      ? some(segregatedTrustBalance.asOfDate)
      : nothing,
  };
};

/**
 * Converts an investment data dto to entity data store
 * @param investmentData An investment data dto object
 * @returns An entity data store object
 */
export const convertToEntityDataStore = (
  investmentData: IInvestmentDataDto
): IEntityDataStore => {
  return {
    entityId: some(investmentData.entityId),
    entityType: some(investmentData.entityType),
    entityName: some(investmentData.entityName),
    asOfDates: convertDateRange(
      investmentData.dateRange,
      investmentData.segregatedTrustBalance
    ),
    commitmentData: investmentData.commitmentData
      ? some(investmentData.commitmentData)
      : nothing,
    financingBalance: investmentData.financingBalance
      ? some(investmentData.financingBalance)
      : nothing,
    historicalSummary: investmentData.historicalSummary,
    investmentBreakdown: investmentData.investmentBreakdown,
    segregatedTrustBalance: investmentData.segregatedTrustBalance
      ? some(investmentData.segregatedTrustBalance)
      : nothing,
  } as IEntityDataStore;
};

export const internalInvestmentDataReducer =
  createReducer<IInternalInvestmentDataStore>(
    DEFAULT_INTERNAL_INVESTMENT_STATE,
    (builder) => {
      builder.addCase(setInternalInvestmentDataFilters, (state, action) => {
        state.filteredBy = some(action.payload);
      });
      builder.addCase(resetSelectedClient, (state) => {
        state.internalInvestmentDataLoadStatus = DataLoadStatus.NOT_REQUESTED;
        state.filteredBy = nothing;
      });
      builder.addCase(reqInternalInvestmentData, (state, action) => {
        state.filteredBy = some(action.payload);
        state.internalInvestmentDataLoadStatus = DataLoadStatus.LOADING;
      });
      builder.addCase(recvInternalInvestmentData, (state, action) => {
        if (isSomething(action.payload)) {
          const entity = convertToEntityDataStore(action.payload.value);
          state.entities = state.entities.concat(entity);
          state.internalInvestmentDataLoadStatus = DataLoadStatus.SUCCESSFUL;
        } else {
          state = EMPTY_INTERNAL_INVESTMENT_STATE;
        }
      });
      builder.addCase(errInternalInvestmentData, (state, action) => {
        state.internalInvestmentDataLoadStatus = action.payload;
      });
    }
  );
