import { GridApi, GridReadyEvent } from "ag-grid-community";
import { useCallback, useState } from "react";

// following https://blog.ag-grid.com/wrapping-column-header-text/

const headerHeightGetter = (document: Document) => {
  const columnHeaderTexts = [
    ...(document.querySelectorAll(
      ".ag-header-cell-text"
    ) as NodeListOf<Element>),
  ];
  const clientHeights = columnHeaderTexts.map(
    (headerText) => headerText.clientHeight
  );
  const tallestHeaderTextHeight = Math.max(...clientHeights);

  return tallestHeaderTextHeight;
};

const headerHeightSetter = (document: Document, gridApi: GridApi) => {
  const padding = 8;
  const minHeight = 48;
  const height = headerHeightGetter(document) + padding;
  gridApi.setGridOption("headerHeight", Math.max(minHeight, height));
};

const setColumnsToFit = (gridApi: GridApi, autoSizeColumn?: string) => {
  if (autoSizeColumn) {
    const viewByCol = gridApi.getColumn(autoSizeColumn);
    if (viewByCol) {
      gridApi.autoSizeColumns([viewByCol]);
    }
  }
  gridApi.sizeColumnsToFit();
};

export const useGridExtensions = (autoSizeColumn?: string) => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const setHeaderHeight = () => {
    if (gridApi !== null) {
      headerHeightSetter(document, gridApi);
    }
  };

  const resizeColumns = () => {
    if (gridApi !== null) {
      setColumnsToFit(gridApi, autoSizeColumn);
    }
  };

  const onGridReady = useCallback(
    async (params: GridReadyEvent) => {
      setGridApi(params.api);
      setColumnsToFit(params.api);
    },
    [setGridApi]
  );

  return {
    setHeaderHeight,
    resizeColumns,
    onGridReady,
  };
};
