import { StockSymbol } from "../constants/enums";
import { EquityLabel } from "../constants/LabelAndTooltipConstants";
import {
  IAward,
  IAwardDetailRow,
  IAwardDetailsData,
  IEquityDatum,
  IStockDataValue,
  IVestingData,
} from "../types/dataTypes";

export const getActiveAwardsData = (
  equityData: IEquityDatum,
  stockData: IStockDataValue[],
  selectedStockSymbol: StockSymbol
): IAwardDetailsData => {
  const currActiveAwardData: IAwardDetailRow[] = [];
  let totUnitsGranted = 0;
  let totVestedUnits = 0;
  let totUnvestedUnit = 0;
  let totUnvestedValue = 0;

  const stockSymbol = selectedStockSymbol;

  const latestStockValue = stockData[0];

  equityData.awards.forEach((award: IAward) => {
    if (award.nextVestingDate.toLocaleDateString() === "Invalid Date") {
      return;
    }

    award.vestingData.forEach((vestingData: IVestingData) => {
      const isPastVestingDate = vestingData.date < new Date();
      const unitsVested = isPastVestingDate ? vestingData.quantity : 0;
      const unitsUnvested = vestingData.quantity - unitsVested;

      const awardRow: IAwardDetailRow = {
        stockSymbol: stockSymbol,
        awardName: award.name,
        grantDate: award.grantDate,
        nextVestingDate: vestingData.date,
        unitsGranted: vestingData.quantity,
        unitsVested: unitsVested,
        unitsUnvested: unitsUnvested,
        unvestedValue: unitsUnvested * latestStockValue.value,
        finalVestingDate: award.finalVestingDate,
      };

      currActiveAwardData.push(awardRow);

      totUnitsGranted += vestingData.quantity;
      totVestedUnits += unitsVested;
      totUnvestedUnit += unitsUnvested;
      totUnvestedValue += unitsUnvested * latestStockValue.value;
    });
  });

  return {
    activeAwardsData: currActiveAwardData,
    totalRow: {
      awardName: EquityLabel.ACTIVE_AWARDS_DETAILS_FOOTER,
      nextVestingDate: new Date(""),
      unitsGranted: totUnitsGranted,
      unitsVested: totVestedUnits,
      unitsUnvested: totUnvestedUnit,
      unvestedValue: totUnvestedValue,
    },
  };
};
