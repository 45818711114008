import { PathFragment, SectionHeader, SubTabPages } from "common";
import React from "react";

import { ElectionsAdminPage } from "../../features/ElectionEditor/ElectionEditor";
import { ElectionResultBody } from "../../features/ElectionResultBody/ElectionResultBody";
import { DistributionsWrapper } from "../../features/Equity/DistributionsWrapper/DistributionsWrapper";
import { VestingEventsWrapper } from "../../features/Equity/VestingEventsWrapper/VestingEventsWrapper";
import { InvestmentsOverviewWrapper } from "../../features/InvestmentsAndCarry/InvestmentsOverviewWrapper/InvestmentsOverviewWrapper";
import { PerformanceDetailsPageWrapper } from "../../features/PerformanceDetailsPage/PerformanceDetailsPageWrapper";

export const InvestmentsPages: SubTabPages = {
  InvestmentsOverview: {
    name: SectionHeader.OVERVIEW,
    path: `${PathFragment.OVERVIEW}`,
    hasError: false,
    header: SectionHeader.OVERVIEW,
    element: InvestmentsOverviewWrapper,
  },
  InvestmentBreakdown: {
    name: SectionHeader.INVESTMENT_BREAKDOWN,
    path: `${PathFragment.INVESTMENT_BREAKDOWN}`,
    hasError: false,
    header: SectionHeader.INVESTMENT_BREAKDOWN,
    element: () => <PerformanceDetailsPageWrapper hideHeader={true} />,
  },
};

export const EquityPages: SubTabPages = {
  VestingEvents: {
    name: SectionHeader.EQUITY_VALUE,
    path: `${PathFragment.EQUITY_VALUE}`,
    hasError: false,
    header: SectionHeader.EQUITY_VALUE,
    element: VestingEventsWrapper,
  },
  Distributions: {
    name: SectionHeader.DISTRIBUTIONS,
    path: `${PathFragment.DISTRIBUTIONS}`,
    hasError: false,
    header: SectionHeader.DISTRIBUTIONS,
    element: DistributionsWrapper,
  },
};

export const ElectionAdminPages: SubTabPages = {
  ElectionResults: {
    name: SectionHeader.ELECTION_RESULTS,
    path: `${PathFragment.ELECTION_RESULTS}`,
    hasError: false,
    header: SectionHeader.ELECTION_RESULTS,
    element: ElectionResultBody,
  },
  ElectionEditor: {
    name: SectionHeader.ELECTION_EDITOR,
    path: `${PathFragment.ELECTION_EDITOR}`,
    hasError: false,
    header: SectionHeader.ELECTION_EDITOR,
    element: ElectionsAdminPage,
  },
};
