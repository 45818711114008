import { CardHeader, Typography } from "@mui/material";
import {
  IHistoricalSummaryDatum,
  InvestmentHistoryValueItem,
  InvestmentValueDisplay,
  PeriodType,
  WorksheetGeneratorName,
} from "common";
import React from "react";

import { PeriodToggle } from "../PeriodToggle/PeriodToggle";
import styles from "./TitleWithPeriodToggle.module.scss";

interface ITitleWithPeriodToggleProps {
  sectionHeader: string;
  sectionSubHeader?: string | undefined;
  tabSelected: WorksheetGeneratorName;
  historicalSummaryData: IHistoricalSummaryDatum[];
  periodType: PeriodType;
  setPeriodType: (value: PeriodType) => void;
  valueDisplay: InvestmentValueDisplay;
  dataTypes: InvestmentHistoryValueItem[];
  setDataTypes: (value: InvestmentHistoryValueItem[]) => void;
  toggleSize?: number;
}

export const TitleWithPeriodToggle = (props: ITitleWithPeriodToggleProps) => {
  return (
    <CardHeader
      className={styles.header}
      classes={{ action: styles.action }}
      title={
        <Typography className={styles.title} variant="h3">
          {props.sectionHeader}
        </Typography>
      }
      subheader={
        <Typography variant="subtitle1">{props.sectionSubHeader}</Typography>
      }
      action={
        <PeriodToggle
          tabSelected={props.tabSelected}
          periodType={props.periodType}
          setPeriodType={props.setPeriodType}
          dataTypes={props.dataTypes}
          setDataTypes={props.setDataTypes}
          valueDisplay={props.valueDisplay}
          historicalSummaryData={props.historicalSummaryData}
        />
      }
    />
  );
};
