import { ThemeOptions } from "@mui/material";

import colors from "../../_colors.scss";
import { Font } from "../sharedStyleConstants";

// Shared constants we may want to move in unison
const Padding = {
  VERTICAL: "8px",
};

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: Font.Family.GUARDIAN_SANS,
          fontWeight: Font.Weight.LIGHT,
          paddingTop: Padding.VERTICAL,
          paddingBottom: Padding.VERTICAL,
          fontSize: Font.Size.STANDARD,
          backgroundColor: colors.white,
          "&.Mui-selected": {
            fontWeight: Font.Weight.MEDIUM,
            backgroundColor: colors.white,
            "&:hover": {
              backgroundColor: colors.gray_shade,
            },
          },
          "&:hover": {
            backgroundColor: colors.gray_shade,
          },
        },
      },
    },
  },
};

export const CustomMenuItemTheme = partialTheme.components?.MuiMenuItem;
