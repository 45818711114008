import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import { Grid } from "@mui/material";
import {
  ColDef,
  IAggFuncParams,
  InitialGroupOrderComparatorParams,
} from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import {
  AwardNameCellRenderer,
  BreakpointConstants,
  currencyFormatterWithZeroDefault,
  dateFormatterWithPastEvents,
  EquityLabel,
  hideTooltips,
  IAwardDetailRow,
  IAwardDetailsData,
  toLocaleString,
  toLocaleStringOrDashForChildRows,
  useGridExtensions,
} from "common";
import React, { useMemo, useRef } from "react";

import styles from "./ActiveAwardDetailsGrid.module.scss";
import { DEFAULT_COLUMN_DEF } from "./gridColumnConfigs";

interface IActiveAwardDetailsGridProps {
  awardsData: IAwardDetailsData;
}

export interface IActiveAwardDetailsGridContext {
  filter: string | undefined;
}

const getUpcomingVestingDate = (params: IAggFuncParams) => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  return params.values
    .filter((date) => date && new Date(date) >= currentDate)
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
    .shift();
};

export const ActiveAwardDetailsGrid: React.FC<IActiveAwardDetailsGridProps> = ({
  awardsData,
}) => {
  const gridRef = useRef<AgGridReact>(null);

  const { setHeaderHeight, resizeColumns, onGridReady } = useGridExtensions();

  const minWidth = () => {
    return window.innerWidth < BreakpointConstants.EXTRA_SMALL_MAX_WIDTH
      ? 200
      : 400;
  };

  const awardDetailsColumnDefs: ColDef<IAwardDetailRow>[] = useMemo(
    () => [
      {
        field: "awardName",
        headerName: EquityLabel.AWARD,
        rowGroup: true,
        hide: true,
      },
      {
        field: "stockSymbol",
        hide: true,
        aggFunc: (params) => {
          return params.values[0];
        },
      },
      {
        field: "nextVestingDate",
        aggFunc: getUpcomingVestingDate,
        headerName: EquityLabel.NEXT_VESTING_DATE,
        valueFormatter: dateFormatterWithPastEvents,
      },
      {
        field: "unitsGranted",
        aggFunc: "sum",
        headerName: EquityLabel.UNITS_GRANTED,
        type: "rightAligned",
        valueFormatter: toLocaleStringOrDashForChildRows,
      },
      {
        field: "unitsVested",
        aggFunc: "sum",
        headerName: EquityLabel.UNITS_VESTED,
        type: "rightAligned",
        valueFormatter: toLocaleString,
      },
      {
        field: "unitsUnvested",
        aggFunc: "sum",
        headerName: EquityLabel.UNITS_UNVESTED,
        type: "rightAligned",
        valueFormatter: toLocaleString,
      },
      {
        field: "unvestedValue",
        aggFunc: "sum",
        headerName: EquityLabel.UNVESTED_VALUE,
        valueFormatter: currencyFormatterWithZeroDefault,
        type: "rightAligned",
      },
    ],
    []
  );

  const autoGroupColumnDef = useMemo<ColDef>(() => {
    return {
      headerName: EquityLabel.AWARD,
      pinned: "left",
      minWidth: minWidth(),
      field: "nextVestingDate",
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: AwardNameCellRenderer,
      },
    };
  }, []);

  const initialGroupOrderComparator = (
    params: InitialGroupOrderComparatorParams
  ) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const getUpcomingVestingAward = (awardName: string | null) =>
      awardsData.activeAwardsData.find(
        (x) => x.awardName === awardName && x.nextVestingDate >= currentDate
      );

    const group1 = getUpcomingVestingAward(params.nodeA.key);
    const group2 = getUpcomingVestingAward(params.nodeB.key);

    if (!group1 || !group2) {
      return 1;
    }

    return (
      group1.grantDate.getTime() - group2.grantDate.getTime() ||
      group1.finalVestingDate.getTime() - group2.finalVestingDate.getTime()
    );
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} className={`ag-theme-alpine`} id={styles.dataGrid}>
        <AgGridReact<IAwardDetailRow>
          ref={gridRef}
          rowData={awardsData.activeAwardsData}
          defaultColDef={DEFAULT_COLUMN_DEF}
          autoGroupColumnDef={autoGroupColumnDef}
          initialGroupOrderComparator={initialGroupOrderComparator}
          columnDefs={awardDetailsColumnDefs}
          pinnedBottomRowData={[awardsData.totalRow]}
          domLayout="autoHeight"
          onFirstDataRendered={setHeaderHeight}
          onColumnResized={setHeaderHeight}
          cacheQuickFilter={true}
          suppressAggFuncInHeader={true}
          suppressContextMenu={true}
          suppressCellFocus={true}
          onRowDataUpdated={resizeColumns}
          onGridSizeChanged={resizeColumns}
          onDisplayedColumnsChanged={resizeColumns}
          onBodyScroll={hideTooltips}
          suppressMenuHide={true}
          onGridReady={onGridReady}
        />
      </Grid>
    </Grid>
  );
};
