import {
  CalloutPersona,
  CommitmentType,
  DistributionUnitType,
  DocumentsPageName,
  DocumentsSortBy,
  DocumentTypeCategoryName,
  DocumentTypeName,
  EquitySubTabPageName,
  InvestmentsAndCarryPageName,
  PeriodHash,
  PeriodType,
  SortDirection,
  StockSymbol,
  TaxDocumentStatus,
  TreeNumber,
} from "../constants/enums";
import { ISubTabPage } from "./pageTypes";
import { Optional } from "./typeUtils";

export interface ICalloutDatum {
  value: number;
  asOfDate: Date;
}

export type IHistoricalSummaryDatum = {
  displayQuarterAsOfDate: QuarterDate;
  asOfDate: QuarterDate;
  periodName: string;
  cumulative: IInvestmentBreakdownDatum;
  nonCumulative: IInvestmentBreakdownDatum;
};

export interface IHoldbackPerFund {
  fundName: string;
  holdback: number;
}

export interface ICarryChartData {
  businessUnitName: string;
  fundShortName: string;
  mdmFundId: number;
  totalUnrealized: number;
  totalRealized: number;
}

export interface IInvestmentChartData {
  businessUnitName: string;
  fundShortName: string;
  mdmFundId: number;
  unrealizedValue: IUnrealizedValue;
}

export class QuarterDate extends Date {
  constructor(value: number | string | Date) {
    super(value);
  }

  // helper method to return a 0 indexed quarter
  get quarter() {
    return Math.floor(this.getUTCMonth() / 3);
  }

  // valueOf override! Creates a new absolute quarter for ease of graphing quarterly data.
  // Represents number of quarters from Q1 0 A.D. (One of the best performing quarters for the Roman Empire!)
  valueOf(): number {
    return this.getUTCFullYear() * 4 + this.quarter;
  }
}

export interface ISupportedStocks {
  supportedStocks: StockSymbol[];
}

export interface IEquityData {
  symbol: StockSymbol;
  totalUnvestedUnits: number;
  nextVestingDate: Date;
  awards: IAward[];
  vestedRestrictedUnits: Optional<number>;
  vestedExchangeableUnits: Optional<number>;
  vestedAndExchangeableAsOfDate: Optional<Date>;
}

export type IEquityDataBySymbol = {
  [key in StockSymbol]: Optional<IEquityDatum>;
};

export interface IEquityDatum {
  totalUnvestedUnits: number;
  nextVestingDate: Date;
  awards: IAward[];
  vestedRestrictedUnits: Optional<number>;
  vestedExchangeableUnits: Optional<number>;
  vestedAndExchangeableAsOfDate: Optional<Date>;
}

export interface IAward {
  id: string;
  name: string;
  grantDate: Date;
  unvestedUnits: number;
  vestedUnits: number;
  nextVestingDate: Date;
  vestingData: IVestingData[];
  finalVestingDate: Date;
  asOfDate: Date;
}

export interface IVestingData {
  date: Date;
  quantity: number;
}

export interface IStockData {
  symbol: StockSymbol;
  data: IStockDataValue[];
}

export type IStockDataBySymbol = {
  [key in StockSymbol]: Optional<IStockDataValue[]>;
};

export interface IStockDataValue {
  asOfDate: Date;
  value: number;
}

export interface IBXEquityCardData {
  chartData: IEquityChartDatum[];
  totalUnvestedBXValue: number;
  totalUnvestedBXUnits: number;
  vestedExchangeableBXUnits: number;
  vestedRestrictedBXUnits: number;
  vestedAndExchangeableAsOfDate: Optional<Date>;
  latestBXStockValue: IStockDataValue;
  nextVestingDate: Date;
}

export interface IEquityChartDatum {
  year: number;
  value: number;
}

export interface IDocumentsPage {
  name: string;
  header: string;
  element: () => JSX.Element;
}

export interface IDocumentsTab {
  name: DocumentsPageName;
  page: IDocumentsPage;
}

export interface IInvestmentsAndCarryPage {
  name: string;
  header: string;
  element: () => JSX.Element;
}

export interface IInvestmentsAndCarryTab {
  name: InvestmentsAndCarryPageName;
  page: IInvestmentsAndCarryPage;
}

export interface IBalancePerformanceData {
  historicalSummaryGrouped: Optional<IHistoricalSummaryGrouped>;
  asOfDate: IAsOfDate;
}

export interface IEquityPageData {
  totalUnvestedValue: number;
  totalUnvestedUnits: number;
  nextVestingDate: Date;
  latestStockData: IStockValueByEquityData[];
  stockTrendData: IStockData[];
}

export interface IVestingByEquityData {
  stockName: string;
  vestingData: IVestingData[];
}

export interface IStockValueByEquityData {
  stockName: StockSymbol;
  asOfDate: Date;
  value: number;
}

export interface IAwardDetailsData {
  activeAwardsData: IAwardDetailRow[];
  totalRow: IAwardTotalRow;
}

export interface IAwardDetailRow {
  stockSymbol: StockSymbol;
  awardName: string;
  grantDate: Date;
  nextVestingDate: Date;
  unitsGranted: number;
  unitsVested: number;
  unitsUnvested: number;
  unvestedValue: number;
  finalVestingDate: Date;
}

export interface IAwardTotalRow {
  awardName: string;
  nextVestingDate: Date;
  unitsGranted: number;
  unitsVested: number;
  unitsUnvested: number;
  unvestedValue: number;
}

export interface INextVestingEvent {
  date: Date;
  value: number;
  name: string;
}

export interface IDailyVestingEvent {
  date: Date;
  value: number;
  nextVestingEvents: INextVestingEvent[];
}

export type IMonthlyVestingEventsBySymbol = {
  [key in StockSymbol]: IMonthlyVestingEvent;
};

export interface IMonthlyVestingEvent {
  date: Date;
  label: string;
  key: number;
  value: number;
  dailyVestingEvents: IDailyVestingEvent[];
}

export type ILatestStockValueSet = {
  [key in StockSymbol]: number;
};

export interface IEquityTab {
  name: EquitySubTabPageName;
  path: string;
  page: ISubTabPage;
}

export interface IOverViewValue {
  label: string | undefined;
  value: number | undefined;
}

export interface IDistributionsDatum {
  distributionDate: Date;
  ticker: StockSymbol;
  unitType: DistributionUnitType;
  distributionPerUnit: string;
  unitsEntitled: number;
  dividendAmount: number;
}

export type IDistributionsDataBySymbol = {
  [key in StockSymbol]: Optional<IDistributionsDatum[]>;
};

export interface IHoverableChartLegend {
  value: string;
  color: string;
  handleClick?: (value: string) => void;
}

export interface IHoverableChartLegends {
  legends: IHoverableChartLegend[];
}

export interface IDocumentClients {
  clients: IDocumentsClient[];
  preferredClientOId: string;
}

export interface IDocumentsClient {
  oId: string;
  name: string;
}

export interface IDocumentType {
  name: DocumentTypeName;
  id: number;
  category: IDocumentTypeCategory;
}

export interface IDocumentTypeCategory {
  name: DocumentTypeCategoryName;
  id: number;
}

export interface IEntity {
  name: string;
  oId: number;
  ein?: string[];
}

export interface IDocument {
  documentOId: number;
  documentName: string;
  documentType: IDocumentType;
  period: string | null;
  funds: IEntity[];
  publishedDate: Date;
  investmentVehicles: IEntity[];
  partnerships: IEntity[];
  taxDocumentStatus: TaxDocumentStatus | null;
  jurisdictions: string[];
  effectiveDate: Date | null;
  downloadedDate: Date | null;
}

export interface IDocumentRequest {
  offset: number;
  limit: number;
}

export interface IDocumentResponse {
  documents: IDocument[];
  documentsCount: number;
  offset: number;
  limit: number;
}

export interface IDocumentFilters {
  documentTypes: IDocumentType[];
  periods: string[];
  funds: IEntity[];
  investmentVehicles: IEntity[];
  taxDocumentStatuses: TaxDocumentStatus[];
  partnerships: IEntity[];
}

export interface IDocumentFilterRequest {
  documentTypeIds?: number[];
  documentTypeCategoryIds?: number[];
  periods?: string[];
  fundIds?: number[];
  investmentVehicleIds?: number[];
  taxDocumentStatus?: TaxDocumentStatus;
}

export type IDocumentSortOptions = ISortOptions<DocumentsSortBy>;

export type SortByEnum = string;

export interface ISortOptions<T extends SortByEnum> {
  sortBy: T;
  orderBy: SortDirection;
}

/**
 * Internal investments data types integration
 */
export interface IAsOfDate {
  earliestAsOfDate: Date;
  latestAsOfDateWithUnrealizedData: Optional<Date>; // Can be null if user has no Unrealized Data
  latestAsOfDate: Date;
  segTrustBalanceDate: Optional<Date>; //Can be null is user has no Seg Trust Data
}

export interface IInvestmentBreakdownViewModel
  extends IInvestmentBreakdownDatum {
  displayName: string;
  hierarchy: string[];
}

export type IInvestmentBreakdownGrouped = {
  [key in TreeNumber]: IInvestmentBreakdownViewModel[];
};

export type IHistoricalSummaryGrouped = {
  [key in PeriodType]: IHistoricalSummaryDatum[];
};

export type ICashFlowData = {
  historicalSummaryGrouped: Optional<IHistoricalSummaryGrouped>;
  overviewData: ICalloutData;
  asOfDate: IAsOfDate;
};

export interface ICarryCardData {
  investmentBreakdownGrouped: Optional<IInvestmentBreakdownGrouped>;
  calloutData: ICalloutData;
  calloutPersona: CalloutPersona;
  asOfDate: IAsOfDate;
}

export type IInvestmentAllocationCardData = ICarryCardData;

export interface IOverviewData {
  calloutData: ICalloutData;
  calloutPersona: CalloutPersona;
  historicalSummaryData: IHistoricalSummaryGrouped;
  investmentBreakdownGrouped: IInvestmentBreakdownGrouped;
}

export interface IRealizedCalloutData {
  realizedCarry: ICalloutDatum;
  realizedOptionalAndMandatoryInvestments: ICalloutDatum;
  returnOfCapital: ICalloutDatum;
  realizedGainLoss: ICalloutDatum;
  totalRealizedProceeds: ICalloutDatum;
}

export interface IUnrealizedCalloutData {
  unrealizedCarry: ICalloutDatum;
  unrealizedOptionalAndMandatoryInvestments: ICalloutDatum;
  unrealizedCapitalInvested: ICalloutDatum;
  unrealizedGainLoss: ICalloutDatum;
  totalUnrealizedValue: ICalloutDatum;
  loans: ICalloutDatum;
  netUnrealizedValue: ICalloutDatum;
}

export interface ICalloutData {
  realized: Optional<IRealizedCalloutData>;
  unrealized: Optional<IUnrealizedCalloutData>;
}

export interface ICallout {
  calloutData: ICalloutData;
  calloutPersona: CalloutPersona;
}

export interface IInvestmentVehicle {
  id: string;
  name: string;
  shortName: string;
}

export interface IClientDto extends IInvestmentVehicle {
  investmentVehicles: IInvestmentVehicle[];
}

export interface IClientResponse {
  id: string;
}

export interface IGrant {
  BRN: string;
}

export interface IPermission {
  view: IGrant[];
}

export interface IPermittedEntities {
  clients: IClientDto[];
  permissions: IPermission[];
}

export interface IDateRange {
  latestAsOfDate: Date;
  latestAsOfDateWithUnrealizedData: Date | null;
  earliestAsOfDate: Date;
}

export interface IFundWithStrategy {
  id: number;
  name: string;
  mdmId: number;
  strategyId: number;
  strategyName: string;
}
export interface IForecastedCapCalls {
  capitalCalls: IForecastedCapCall[];
  asOfDate: Date;
}

export interface IForecastedCapCall {
  fund: IFundWithStrategy;
  amount: number;
}

export interface IForecastedCapCallByStrategy {
  strategyName: string;
  strategyId?: number; // undefined for totals row
  amount: number;
}

export interface IForecastedCapCallsByStrategy {
  capitalCalls: IForecastedCapCallByStrategy[];
  asOfDate: Date;
}

export interface ICommitments {
  commitments: ICommitment[];
  asOfDate: Date;
}

export interface ICommitment {
  fund: IFundWithStrategy | null;
  commitmentType: CommitmentType;
  electionYear: number;
  electedAmount: number;
  capitalCalled: number;
}

export interface ICommitmentLOF {
  fundName: string;
  fundId: number;
  electedAmount: number;
  capitalCalled: number;
  capitalCalledPercent: number | null;
}

export interface ICommitmentsLOF {
  commitments: ICommitmentLOF[];
  asOfDate: Date;
}

export interface ICommitmentAnnual {
  fundName: string;
  fundId: number;
  electionYear: number;
  electedAmount: number;
  capitalCalled: number;
  capitalCalledPercent?: number | null;
}

export interface ICommitmentsAnnual {
  commitments: ICommitmentAnnual[];
  asOfDate: Date;
}

export interface ICommitmentData {
  fundCommitments: Optional<ICommitments>;
  forecastedCapitalCalls: Optional<IForecastedCapCalls>;
}

export interface IFinancingBalance {
  asOfDate: Date;
  firmFinancingBalance: number;
  thirdPartyFinancingBalance: number;
  thirdPartyPendingLoanBalance: number;
  thirdPartyLoanLimit: number;
  thirdPartyRemainingLoanCapacity: number;
  totalFinancingBalance: number;
  totalFinancingBalanceQSTMT: number;
}

export interface IInvestment {
  asOfDate: Date;
  capitalInvested: number;
  netCashFlow: number;
}

export interface IRealizedProceeds {
  asOfDate: Date;
  optionalAndMandatoryInvestments: number;
  returnOfCapital: number;
  gainLoss: number;
  carriedInterest: number;
  total: number;
}

export interface IUnrealizedValue {
  asOfDate: Date;
  optionalAndMandatoryInvestments: number;
  remainingCapitalInvested: number;
  gainLoss: number;
  carriedInterest: number;
  total: number;
}

export interface IInvestmentBreakdownValueSet {
  investment: Optional<IInvestment>;
  realizedProceeds: Optional<IRealizedProceeds>;
  unrealizedValue: Optional<IUnrealizedValue>;
}

export interface IInvestmentBreakdownDatum
  extends IInvestmentBreakdownValueSet {
  period: string;
  treeNumber: TreeNumber;
  treeName: string;
  treeLevel: number;
  fundId: string;
  fundName: string;
  fundShortName: string;
  mdmFundId: number;
  businessUnitId: string;
  businessUnitName: string;
  trancheName: string;
  investmentDate: Date;
}

export interface ISegregatedTrustBalance {
  asOfDate: Date;
  totalHoldbackValue: number;
  holdbackInvested: number;
  holdbackCash: number;
  holdbackPerFund: IHoldbackPerFund[];
  totalCollateralValue: number;
  requiredCollateralization: number;
  excessCollateralization: number;
}

export interface IInvestmentDataDto {
  entityId: string;
  entityType: string;
  entityName: string;
  dateRange: IDateRange;
  commitmentData: ICommitmentData | null;
  financingBalance: IFinancingBalance | null;
  historicalSummary: IInvestmentBreakdownDatum[];
  investmentBreakdown: IInvestmentBreakdownDatum[];
  segregatedTrustBalance: ISegregatedTrustBalance | null;
}

export interface IInvestmentVehicleByClientByPeriod {
  clientId: string;
  investmentVehicleId: string | undefined;
  periodId: PeriodHash;
}

export interface IInteralInvestmentClient {
  id: string;
  mdmId: number;
  name: string;
  shortName: string;
  investmentVehicles: IInvestmentVehicle[];
}

export interface IInternalInvestmentClientDataDownload {
  entityIds: string[];
  periodId: string;
  clientId: string;
  isClientLoaded: boolean;
}

export interface IEmployee {
  id: string;
  name: string;
}

export interface IEmployeeSource {
  employeeId: number;
  employeeName: string;
  lanId: string;
  title: string;
  department: string;
  businessUnit: string;
}
