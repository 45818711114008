import { ThemeOptions } from "@mui/material";

import colors from "../../_colors.scss";

const partialTheme: ThemeOptions = {
  components: {
    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {
          fontSize: "12px",
          "& .MuiSvgIcon-root": {
            height: "18px",
            width: "18px",
          },
          backgroundColor: colors.white,
        },
      },
    },
  },
};

export const CustomInputBaseTheme = partialTheme.components?.MuiInputBase;
