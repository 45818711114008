import {
  DataCard,
  IBaseStore,
  ICommitmentData,
  isInProgress,
  isSomething,
  isUnsuccessful,
  Optional,
  reqInternalInvestmentData,
  selectAnnualCommitments,
  selectInternalInvestmentData,
  selectLifeOfFundCommitments,
  useFetchDatasetIfIdDefined,
} from "common";
import React, { useSelector } from "react-redux";

import { selectCommitmentsForClient } from "../../redux/selectors";
import styles from "./CommitmentsPage.module.scss";
import { AnnualCommitments } from "./components/AnnualCommitments/AnnualCommitments";
import { ForecastedCapitalCalls } from "./components/ForecastedCapitalCalls/ForecastedCapitalCalls";
import { LifeOfFundCommitments } from "./components/LifeOfFundCommitments/LifeOfFundCommitments";

export const Commitments = () => {
  const { filteredBy, internalInvestmentDataLoadStatus } = useSelector(
    (state: IBaseStore) => selectInternalInvestmentData(state)
  );

  const commitmentData: Optional<ICommitmentData> = useSelector(
    (store: IBaseStore) => selectCommitmentsForClient(store)
  );

  const { lifeOfFundCommitments } = useSelector(selectLifeOfFundCommitments);

  const { annualCommitments } = useSelector(selectAnnualCommitments);

  const retryFunction = useFetchDatasetIfIdDefined(
    reqInternalInvestmentData,
    filteredBy,
    internalInvestmentDataLoadStatus
  );

  return (
    <div className={styles.commitments}>
      <div className={styles.widgets}>
        <DataCard
          failed={isUnsuccessful(internalInvestmentDataLoadStatus)}
          loading={isInProgress(internalInvestmentDataLoadStatus)}
          noData={
            !isSomething(commitmentData) ||
            !isSomething(commitmentData.value.forecastedCapitalCalls) ||
            commitmentData.value.forecastedCapitalCalls.value.capitalCalls
              .length === 0
          }
          hideIfNoData={true}
          onReload={retryFunction}
        >
          {
            // if we have data, we can show the forecasted card contents
            isSomething(commitmentData) &&
              isSomething(commitmentData.value.forecastedCapitalCalls) && (
                <ForecastedCapitalCalls
                  forecastedCapitalCalls={
                    commitmentData.value.forecastedCapitalCalls.value
                  }
                />
              )
          }
        </DataCard>
        <DataCard
          failed={isUnsuccessful(internalInvestmentDataLoadStatus)}
          loading={isInProgress(internalInvestmentDataLoadStatus)}
          noData={
            !isSomething(annualCommitments) ||
            annualCommitments.value.commitments.length === 0
          }
          hideIfNoData={true}
          onReload={retryFunction}
        >
          {isSomething(annualCommitments) && (
            <AnnualCommitments annualCommitments={annualCommitments.value} />
          )}
        </DataCard>
        <DataCard
          failed={isUnsuccessful(internalInvestmentDataLoadStatus)}
          loading={isInProgress(internalInvestmentDataLoadStatus)}
          noData={
            !isSomething(lifeOfFundCommitments) ||
            lifeOfFundCommitments.value.commitments.length === 0
          }
          hideIfNoData={true}
          onReload={retryFunction}
        >
          {isSomething(lifeOfFundCommitments) &&
            lifeOfFundCommitments.value.commitments.length > 0 && (
              <LifeOfFundCommitments
                lifeOfFundCommitments={lifeOfFundCommitments.value}
              />
            )}
        </DataCard>
      </div>
    </div>
  );
};
