import "ag-grid-enterprise";

import { Chip } from "@mui/material";
import React from "react";

import { StockSymbol } from "../../constants/enums";
import styles from "./StockChip.module.scss";

const stockSymbolClassMap: Record<StockSymbol, string> = {
  [StockSymbol.BX]: styles.bx,
  [StockSymbol.BXMT]: styles.bxmt,
  [StockSymbol.BXRT]: styles.bxrt,
};

interface IStockChipProps {
  stockSymbol: StockSymbol;
}

export const StockChip = (props: IStockChipProps) => {
  const { stockSymbol } = props;

  const className = `${styles.chip} ${stockSymbolClassMap[stockSymbol] || ""}`;

  return <Chip className={className} size="small" label={stockSymbol} />;
};
