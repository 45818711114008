import {
  IStockDataValue,
  ResponsiveRechartsWrapper,
  stockChartTooltip,
} from "common";
import React from "react";
import { Area, AreaChart, Tooltip, XAxis, YAxis } from "recharts";

import colors from "../../../../../../styles/_colors.scss";
import styles from "./StockChart.module.scss";

interface IStockChartProps {
  chartData: IStockDataValue[];
}

export const StockChart = (props: IStockChartProps) => {
  const { chartData } = props;

  interface IToolTipProps {
    active?: boolean;
    payload?: [{ value: number }];
    label?: Date;
  }

  const CustomTooltip = ({ active, payload, label }: IToolTipProps) => {
    if (active && payload && payload.length > 0) {
      return (
        <div className={styles.tooltip}>
          {stockChartTooltip(payload[0].value, label)}
        </div>
      );
    }
    return null;
  };

  const tooltipPosition = { x: -125, y: -15 };

  return (
    <ResponsiveRechartsWrapper>
      <AreaChart data={chartData}>
        <defs>
          <linearGradient id="chartGradient" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="0%"
              stopColor={colors.darkest_green}
              stopOpacity={1}
            />
            <stop
              offset="7%"
              stopColor={colors.darker_green}
              stopOpacity={0.8}
            />
            <stop
              offset="19%"
              stopColor={colors.dark_green}
              stopOpacity={0.7}
            />
            <stop offset="40%" stopColor={colors.green} stopOpacity={0.5} />
            <stop
              offset="92%"
              stopColor={colors.light_green}
              stopOpacity={0.2}
            />
            <stop
              offset="100%"
              stopColor={colors.lighter_green}
              stopOpacity={0.1}
            />
            <stop
              offset="100%"
              stopColor={colors.lightest_green}
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <Tooltip content={<CustomTooltip />} position={tooltipPosition} />
        <XAxis
          height={0}
          width={0}
          dataKey="asOfDate"
          tick={false}
          axisLine={false}
        />
        <YAxis
          dataKey="value"
          height={0}
          width={0}
          tick={false}
          axisLine={false}
          domain={["dataMin", "dataMax"]}
        />
        <Area
          type="monotone"
          dataKey="value"
          activeDot={{
            fill: colors.darkest_green,
            stroke: colors.darkest_green,
          }}
          stroke={colors.darkest_green}
          fillOpacity={1}
          fill="url(#chartGradient)"
        />
      </AreaChart>
    </ResponsiveRechartsWrapper>
  );
};
