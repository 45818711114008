import ExcelJS from "exceljs";

import {
  OverviewLabel,
  SectionHeader,
} from "../../constants/LabelAndTooltipConstants";
import { ICalloutData, IOverViewValue } from "../../types/dataTypes";
import { WorksheetGeneratorParams } from "../../types/excelTypes";
import { isSomething } from "../../types/typeGuards";
import {
  addIndent,
  ExcelConstants,
  formatHeaderRows,
  getAsOfHeader,
} from "./excelUtils";

const getOverviewColumns = (calloutData: ICalloutData) => {
  return [
    {
      header: `${OverviewLabel.REALIZED_PROCEEDS} ${getAsOfHeader(
        isSomething(calloutData.realized)
          ? calloutData.realized.value.totalRealizedProceeds.asOfDate
          : new Date()
      )}`,
      width: ExcelConstants.SIZES.MEDIUM,
    },
    {
      width: ExcelConstants.SIZES.MEDIUM,
      style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
    },
    {},
    {
      header: `${OverviewLabel.UNREALIZED_VALUE} ${getAsOfHeader(
        isSomething(calloutData.unrealized)
          ? calloutData.unrealized.value.totalUnrealizedValue.asOfDate
          : new Date()
      )}`,
      width: ExcelConstants.SIZES.MEDIUM,
    },
    {
      width: ExcelConstants.SIZES.MEDIUM,
      style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
    },
  ];
};

const addOverviewRows = (
  worksheet: ExcelJS.Worksheet,
  calloutData: ICalloutData
) => {
  const realizedRows: IOverViewValue[] = [];
  const unrealizedRows: IOverViewValue[] = [];
  /*indices for adding indent to rows dynamically, 
  we increment the start indices based on rows added until we hit the row to be indented.
  Then, we set end indices only if we are to add the rows meant to be indented*/
  let rlzStartIndex = 1;
  let unrlzStartIndex = 1;
  let rlzEndIndex = 0;
  let unrlzEndIndex = 0;
  if (
    isSomething(calloutData.realized) &&
    calloutData.realized.value.realizedCarry.value !== 0
  ) {
    rlzStartIndex++;
    realizedRows.push({
      label: OverviewLabel.CARRY_AND_INCENTIVE,
      value: calloutData.realized.value.realizedCarry.value,
    });
  }
  if (
    isSomething(calloutData.unrealized) &&
    calloutData.unrealized.value.unrealizedCarry.value !== 0
  ) {
    unrlzStartIndex++;
    unrealizedRows.push({
      label: OverviewLabel.CARRY,
      value: calloutData.unrealized.value.unrealizedCarry.value,
    });
  }
  if (
    isSomething(calloutData.realized) &&
    calloutData.realized.value.realizedOptionalAndMandatoryInvestments.value !==
      0
  ) {
    rlzStartIndex++;
    realizedRows.push({
      label: OverviewLabel.OPTIONAL_AND_MANDATORY,
      value:
        calloutData.realized.value.realizedOptionalAndMandatoryInvestments
          .value,
    });
  }
  if (
    isSomething(calloutData.unrealized) &&
    calloutData.unrealized.value.unrealizedOptionalAndMandatoryInvestments
      .value !== 0
  ) {
    unrlzStartIndex++;
    unrealizedRows.push({
      label: OverviewLabel.OPTIONAL_AND_MANDATORY,
      value:
        calloutData.unrealized.value.unrealizedOptionalAndMandatoryInvestments
          .value,
    });
  }
  if (
    isSomething(calloutData.realized) &&
    calloutData.realized.value.returnOfCapital.value !== 0
  ) {
    rlzStartIndex++;
    rlzEndIndex = rlzStartIndex;
    realizedRows.push({
      label: OverviewLabel.RETURN_OF_CAPITAL,
      value: calloutData.realized.value.returnOfCapital.value,
    });
  }
  if (
    isSomething(calloutData.unrealized) &&
    calloutData.unrealized.value.unrealizedCapitalInvested.value !== 0
  ) {
    unrlzStartIndex++;
    unrlzEndIndex = unrlzStartIndex;
    unrealizedRows.push({
      label: OverviewLabel.REMANING_CAPITAL_INVESTED,
      value: calloutData.unrealized.value.unrealizedCapitalInvested.value,
    });
  }
  if (
    isSomething(calloutData.realized) &&
    calloutData.realized.value.realizedGainLoss.value !== 0
  ) {
    if (rlzEndIndex === 0) {
      rlzEndIndex = ++rlzStartIndex;
    } else {
      rlzEndIndex++;
    }
    realizedRows.push({
      label: OverviewLabel.GAIN_LOSS,
      value: calloutData.realized.value.realizedGainLoss.value,
    });
  }
  if (
    isSomething(calloutData.unrealized) &&
    calloutData.unrealized.value.unrealizedGainLoss.value !== 0
  ) {
    if (unrlzEndIndex === 0) {
      unrlzEndIndex = ++unrlzStartIndex;
    } else {
      unrlzEndIndex++;
    }
    unrealizedRows.push({
      label: OverviewLabel.GAIN_LOSS,
      value: calloutData.unrealized.value.unrealizedGainLoss.value,
    });
  }
  if (
    isSomething(calloutData.realized) &&
    calloutData.realized.value.totalRealizedProceeds.value !== 0
  ) {
    realizedRows.push({
      label: OverviewLabel.TOTAL_REALIZED_PROCEEDS,
      value: calloutData.realized.value.totalRealizedProceeds.value,
    });
  }
  if (
    isSomething(calloutData.unrealized) &&
    calloutData.unrealized.value.totalUnrealizedValue.value !== 0
  ) {
    unrealizedRows.push({
      label: OverviewLabel.TOTAL_UNREALIZED_VALUE,
      value: calloutData.unrealized.value.totalUnrealizedValue.value,
    });
  }
  if (
    isSomething(calloutData.unrealized) &&
    calloutData.unrealized.value.loans.value !== 0
  ) {
    unrealizedRows.push({
      label: OverviewLabel.LOANS,
      value: calloutData.unrealized.value.loans.value * -1,
    });
  }
  if (
    isSomething(calloutData.unrealized) &&
    calloutData.unrealized.value.netUnrealizedValue.value !== 0
  ) {
    unrealizedRows.push({
      label: OverviewLabel.NET_UNREALIZED_VALUE,
      value: calloutData.unrealized.value.netUnrealizedValue.value,
    });
  }
  const length =
    unrealizedRows.length > realizedRows.length
      ? unrealizedRows.length
      : realizedRows.length;
  for (let i = 0; i < length; i++) {
    worksheet.addRow([
      realizedRows[i]?.label ?? null,
      realizedRows[i]?.value ?? null,
      null,
      unrealizedRows[i]?.label ?? null,
      unrealizedRows[i]?.value ?? null,
    ]);
  }
  rlzEndIndex !== 0 && addIndent(worksheet, 1, rlzStartIndex, rlzEndIndex);
  unrlzEndIndex !== 0 &&
    addIndent(worksheet, 4, unrlzStartIndex, unrlzEndIndex);
};

export const buildOverviewDataWorksheet = (
  params: WorksheetGeneratorParams
) => {
  const { workbook, callout } = params;

  const data = callout.calloutData;
  const worksheet = workbook.addWorksheet(
    SectionHeader.INVESTMENTS_AND_CARRY_OVERVIEW,
    {
      views: [
        {
          state: "frozen",
          ySplit: 1,
        },
      ],
    }
  );
  worksheet.columns = getOverviewColumns(data);
  addOverviewRows(worksheet, data);
  formatHeaderRows(worksheet);
  return workbook;
};
