import { FileDownloadOutlined } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import {
  EquityTooltip,
  IBaseStore,
  IconButtonWithTooltip,
  InfoTooltip,
  SectionHeader,
  StringConstants,
  useExcelDownload,
  worksheetGenerators,
} from "common";
import React from "react";
import { useSelector } from "react-redux";

export const ActiveAwardDetailsCardHeader = () => {
  const { selectedStockSymbol } = useSelector(
    (store: IBaseStore) => store.viewData
  );

  const handleExcelDownload = useExcelDownload();
  const handleDownloadClick = async () => {
    await handleExcelDownload(
      worksheetGenerators.ActiveAwardDetails,
      `${SectionHeader.ACTIVE_AWARD_DETAILS}_${selectedStockSymbol}`,
      { selectedStockSymbol: selectedStockSymbol }
    );
  };

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid container item alignItems="center" xs={10}>
        <Typography variant="h3">
          {SectionHeader.ACTIVE_AWARD_DETAILS}
        </Typography>
        <InfoTooltip tooltipContent={[EquityTooltip.ACTIVE_AWARDS_DETAILS]} />
      </Grid>
      <Grid item>
        <IconButtonWithTooltip
          onClickFunction={handleDownloadClick}
          buttonIcon={FileDownloadOutlined}
          hoverText={StringConstants.DOWNLOAD}
        />
      </Grid>
    </Grid>
  );
};
