import { ThemeOptions } from "@mui/material";

import colors from "../../_colors.scss";

// Shared constants we may want to move in unison

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: "0",
          "& .MuiFormLabel-asterisk": {
            color: "#C12D27",
          },
          "& .MuiOutlinedInput-root": {
            fieldset: {
              borderColor: colors.black,
            },
          },
        },
      },
    },
  },
};

export const CustomFormControlTheme = partialTheme.components?.MuiFormControl;
