import { ThemeOptions } from "@mui/material";

import colors from "../../_colors.scss";
import { breakpointsTheme } from "../sharedStyleConstants";

// Shared constants we may want to move in unison
const Layout = {
  Desktop: {
    MAIN_PADDING: "32px",
    ACTION_BAR_TOP_BOTTOM_PADDING: "20px",
  },
  Mobile: {
    MAIN_PADDING: "20px",
    ACTION_BAR_TOP_BOTTOM_PADDING: "16px",
  },
};

const partialTheme: ThemeOptions = {
  components: {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          [breakpointsTheme.breakpoints.down("md")]: {
            padding: Layout.Mobile.MAIN_PADDING,
          },
          padding: Layout.Desktop.MAIN_PADDING,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          [breakpointsTheme.breakpoints.down("md")]: {
            padding: Layout.Mobile.MAIN_PADDING,
          },
          padding: Layout.Desktop.MAIN_PADDING,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          [breakpointsTheme.breakpoints.down("md")]: {
            paddingTop: Layout.Mobile.ACTION_BAR_TOP_BOTTOM_PADDING,
            paddingBottom: Layout.Mobile.ACTION_BAR_TOP_BOTTOM_PADDING,
            paddingLeft: Layout.Mobile.MAIN_PADDING,
            paddingRight: Layout.Mobile.MAIN_PADDING,
          },
          paddingTop: Layout.Desktop.ACTION_BAR_TOP_BOTTOM_PADDING,
          paddingBottom: Layout.Desktop.ACTION_BAR_TOP_BOTTOM_PADDING,
          paddingLeft: Layout.Desktop.MAIN_PADDING,
          paddingRight: Layout.Desktop.MAIN_PADDING,
          backgroundColor: colors.lightest_grey,
          borderTopColor: colors.bx_slate,
          borderTopStyle: "solid",
          borderTopWidth: "1px",
        },
      },
    },
  },
};

export const CustomDialogTitleTheme = partialTheme.components?.MuiDialogTitle;
export const CustomDialogContentTheme =
  partialTheme.components?.MuiDialogContent;
export const CustomDialogActionsTheme =
  partialTheme.components?.MuiDialogActions;
