import {
  DocumentTypeCategoryName,
  DocumentTypeName,
  TaxDocumentStatus,
} from "../constants/enums";
import { isSomething } from "./typeGuards";

export type JValue<PropertyType> = PropertyType extends DocumentTypeName
  ? DocumentTypeName
  : PropertyType extends DocumentTypeCategoryName
  ? DocumentTypeCategoryName
  : PropertyType extends TaxDocumentStatus
  ? TaxDocumentStatus
  : PropertyType extends number
  ? number
  : PropertyType extends Date // dates are represented as plain strings in JSN
  ? string
  : PropertyType extends string
  ? string
  : PropertyType extends boolean
  ? boolean
  : PropertyType extends Some<infer T>
  ? JValue<T>
  : PropertyType extends Nothing
  ? null | undefined
  : PropertyType extends Array<infer T>
  ? Json<T>[]
  : PropertyType extends object
  ? Json<PropertyType>
  : never;

/**
 * Convert store type to JSON response (i.e. source data)
 * Make everything readonly -- we don't mutate source data
 */
export type Json<Data> = {
  readonly [Key in keyof Data as JValue<Data[Key]> extends never
    ? never
    : Key]: JValue<Data[Key]>;
};

export interface JsonConverter<T> {
  (jsonValue: Json<T>): T;
}

export class LoadError extends Error {
  constructor() {
    super();
  }
}

export type Maybe<T> = T | undefined;

/*
  Optional Helper Types. These are designed to replace nullable values. Nullable values
  in JS can sometimes surprise us. Something may null when expect undefined or vice versa.
  If we trust values from the server implicitly, we may get a undefined where we don't
  expect it. By explicitly controlling nullablity with these helper classes, we can
  hopefully avoid situations where a surprise null value blows up in our faces.
*/

/*
  Optional covers a value of type T that may or may not exist.
  The isSomething type guard can be used to easily determine if the Optional
  type actually contains data.
*/
export type Optional<T> = Some<T> | Nothing;

/*
  Nothing Type creates a value equivalent to undefined or null.
*/
export type Nothing = { __type: "Nothing" };
export const nothing: Nothing = { __type: "Nothing" };

/*
  Some represent a nullable value that does exist.
  Exposes a value field that contains the concrete value.
*/
export type Some<T> = { __type: "Some"; value: T };
export const some = <T>(value: T): Some<T> => ({ __type: "Some", value });

// Flattens Array types to their base type. e.g. Flatten<number[]> returns number. Flatten<number> returns number.
export type Flatten<Type> = Type extends Array<infer Item> ? Item : Type;

// Ensures that an array contains all items in a union type or enum. Useful for making sure we don't accidentally omit keys.
export const arrayOfAll =
  <T>() =>
  <U extends T[]>(
    array: U &
      ([T] extends [U[number]]
        ? unknown
        : "Invalid: Does not contain all Items of type") & { 0: T }
  ) =>
    array;

/*
  The extractable class is a way to interact with Optional objects. It allows
  to the user to extract values from an Optional class directly, without having
  to write multiple isSomething checks.
  
  Functions to extract from the optional can be written as if the object definitely
  exists. If at any point the extractor encounters a value that doesn't actually
  exist, it will return Nothing, otherwise it will return Some<U>

  This hopefully allows to write less verbose code.

  THIS:
  const myNumber: Optional<number> = nothing;
  if (isSomething(optional)) {
    const nestedOptional = optional.value.field.subfield.nestedOptional;
    if (isSomething(nestedOptional)) {
      myNumber = some(nestedOptional.value.nestedField);
    }
  }
  
  BECOMES THIS:
  const extractableThing = extractable(optional);
  const myNumber = extractable
                    .extractOptional(thing => thing.field.subfield.nestedOptional)
                    .extract(nestedthing => nestedThing.nestedField))
*/
type Extractable<T> = {
  // Takes an extractor function that can be written as though the values within
  // definitely exist.
  extract: <U>(extractorFunction: ExtractorFunction<T, U>) => Optional<U>;
  // Extracts an nested Optional, as an Extractable to extractions can easily be
  // chained together.
  extractOptional: <U extends Optional<U>>(
    extractorFunction: ExtractorFunction<T, U>
  ) => Extractable<U>;
  // Easy way to extract a top-level value from the optional.
  extractByKey: <U extends keyof T>(key: U) => Optional<T[U]>;
};

type ExtractorFunction<T, U> = (value: T) => U;

// Creates an Extractable<T> based on the given Optional<T>
export const extractable = <T>(optional: Optional<T>): Extractable<T> => {
  const optionalHasValue = isSomething(optional);

  return {
    extract: <U>(extractorFunction: ExtractorFunction<T, U>) => {
      return optionalHasValue
        ? some(extractorFunction(optional.value))
        : nothing;
    },
    extractOptional: <U>(
      extractorFunction: ExtractorFunction<T, Optional<U>>
    ) => {
      return optionalHasValue
        ? extractable(extractorFunction(optional.value))
        : extractable(nothing);
    },
    extractByKey: <U extends keyof T>(key: U) => {
      return optionalHasValue ? some(optional.value[key]) : nothing;
    },
  };
};
