import { Card } from "@mui/material";
import { InfoTooltip, IStockDataValue } from "common";
import React from "react";

import styles from "./StockCallout.module.scss";
import { StockChart } from "./StockChart/StockChart";

interface ICalloutProps {
  label: string;
  headerTooltipParagraphs?: string[] | undefined;
  value: string;
  showHeaderTooltip?: boolean | undefined;
  showChart?: boolean | undefined;
  chartData?: IStockDataValue[] | undefined;
}

export const StockCallout = (props: ICalloutProps) => {
  const {
    label,
    headerTooltipParagraphs,
    value,
    showHeaderTooltip = false,
    showChart = false,
    chartData = [],
  } = props;

  return (
    <Card className={styles.callout}>
      <span className={styles.calloutSubLeft}>
        <div className={styles.calloutLabel}>
          <span>{label}</span>
          {showHeaderTooltip && (
            <InfoTooltip tooltipContent={headerTooltipParagraphs ?? [""]} />
          )}
        </div>
        <span className={styles.calloutValue}>
          <span>{value}</span>
        </span>
      </span>
      {showChart && (
        <span className={styles.calloutSubRight}>
          <StockChart chartData={chartData} />
        </span>
      )}
    </Card>
  );
};
