import {
  IconButton,
  SvgIconTypeMap,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import React from "react";

import { BreakpointConstants } from "../../constants/BreakpointConstants";
import styles from "./IconButtonWithTooltip.module.scss";

export interface IIconButtonWithTooltipProps {
  onClickFunction: React.MouseEventHandler<HTMLButtonElement> | undefined;
  buttonIcon: OverridableComponent<SvgIconTypeMap<unknown, "svg">> & {
    muiName: string;
  };
  hoverText: string;
  disabled?: boolean;
  className?: string;
  open?: boolean;
}

export const IconButtonWithTooltip = (props: IIconButtonWithTooltipProps) => {
  const className = props.className
    ? `${styles.icon} ${props.className}`
    : styles.icon;

  const isMobile = useMediaQuery(
    `(max-width:${BreakpointConstants.SMALL_MAX_WIDTH}px)`
  );

  return (
    <Tooltip
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      title={props.hoverText}
      disableHoverListener={isMobile || props.disabled}
      disableTouchListener={isMobile || props.disabled}
      open={props.open}
    >
      <span>
        <IconButton
          className={className}
          onClick={props.onClickFunction}
          disabled={props.disabled}
        >
          <props.buttonIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};
