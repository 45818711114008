export const MUIConstants = {
  DASHBOARD_WIDGET_COLUMN_SPACING: 8,
  DASHBOARD_WIDGET_LEFT_ITEM_SIZE: 7,
  DASHBOARD_WIDGET_RIGHT_ITEM_SIZE: 5,
  WIDGET_TREE_COLUMN_LEFT_ITEM_SIZE: 3,
  WIDGET_TREE_COLUMN_CENTER_ITEM_SIZE: 4,
  WIDGET_TREE_COLUMN_RIGHT_ITEM_SIZE: 5,
  ELECTION_WIDGET_COLUMN_SPACING: 2,
  ELECTION_WIDGET_LEFT_ITEM_SIZE: 4,
  ELECTION_WIDGET_RIGHT_ITEM_SIZE: 8,
};
