import {
  LoanBalanceLabel,
  SectionHeader,
  ThirdPartyFinancingLabel,
} from "../../constants/LabelAndTooltipConstants";
import { WorksheetGeneratorParams } from "../../types/excelTypes";
import { isSomething } from "../../types/typeGuards";
import { ExcelConstants, formatHeaderRows, getAsOfHeader } from "./excelUtils";

export const buildLoansWorksheet = (params: WorksheetGeneratorParams) => {
  const { workbook, internalInvestmentData } = params;
  if (isSomething(internalInvestmentData.financingBalance)) {
    const financingBalance = internalInvestmentData.financingBalance.value;
    const worksheet = workbook.addWorksheet(SectionHeader.LOANS, {
      views: [{ state: "frozen", ySplit: 1 }],
    });
    worksheet.columns = [
      { width: ExcelConstants.SIZES.MEDIUM },
      {
        width: ExcelConstants.SIZES.MEDIUM,
        style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
      },
      {},
      { width: ExcelConstants.SIZES.MEDIUM },
      {
        width: ExcelConstants.SIZES.MEDIUM,
        style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
      },
    ];
    worksheet.addRows([
      [
        `${LoanBalanceLabel.LOAN_OVERVIEW} ${getAsOfHeader(
          financingBalance.asOfDate
        )}`,
        null,
        null,
        `${ThirdPartyFinancingLabel.THIRD_PARTY_LOANS} ${getAsOfHeader(
          financingBalance.asOfDate
        )}`,
      ],
      [
        LoanBalanceLabel.FIRM_LOAN,
        financingBalance.firmFinancingBalance ||
          ExcelConstants.VALUES.NULL_VALUE,
        null,
        ThirdPartyFinancingLabel.LOAN_LIMIT,
        financingBalance.thirdPartyLoanLimit ||
          ExcelConstants.VALUES.NULL_VALUE,
      ],
      [
        LoanBalanceLabel.PENDING_THIRD_PARTY_LOAN,
        financingBalance.thirdPartyPendingLoanBalance ||
          ExcelConstants.VALUES.NULL_VALUE,
        null,
        ThirdPartyFinancingLabel.PENDING_THIRD_PARTY_LOAN,
        financingBalance.thirdPartyPendingLoanBalance ||
          ExcelConstants.VALUES.NULL_VALUE,
      ],
      [
        LoanBalanceLabel.THIRD_PARTY_LOAN,
        financingBalance.thirdPartyFinancingBalance ||
          ExcelConstants.VALUES.NULL_VALUE,
        null,
        ThirdPartyFinancingLabel.THIRD_PARTY_LOAN,
        financingBalance.thirdPartyFinancingBalance ||
          ExcelConstants.VALUES.NULL_VALUE,
      ],
      [
        LoanBalanceLabel.TOTAL_LOAN_BALANCE,
        financingBalance.totalFinancingBalance ||
          ExcelConstants.VALUES.NULL_VALUE,
        null,
        ThirdPartyFinancingLabel.AVAILABLE_LOAN_CAPACITY,
        financingBalance.thirdPartyRemainingLoanCapacity ||
          ExcelConstants.VALUES.NULL_VALUE,
      ],
    ]);
    formatHeaderRows(worksheet);
  }
  return workbook;
};
