import { Optional, Some } from "./typeUtils";

export const isDefinedNumber = (value: unknown): value is number => {
  return typeof value === "number" && !isNaN(value);
};

export const isDate = (value: unknown): value is Date => {
  return value instanceof Date;
};

export const isSomething = <T>(value: Optional<T>): value is Some<T> =>
  value.__type === "Some";
