import { ThemeOptions } from "@mui/material";

import { Font } from "../sharedStyleConstants";

// Shared constants we may want to move in unison

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: Font.Family.GUARDIAN_SANS,
          fontSize: "12px",
        },
      },
    },
  },
};

export const CustomTooltipTheme = partialTheme.components?.MuiTooltip;
