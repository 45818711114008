import { Typography } from "@mui/material";
import React, { isValidElement } from "react";

import styles from "./Page.module.scss";

export interface IPageProps {
  pageHeader: string | JSX.Element;
  pageMenu?: JSX.Element;
  content: JSX.Element;
}

export const Page = (props: IPageProps) => {
  const { pageHeader, pageMenu = null, content } = props;

  return (
    <div className={styles.page}>
      <div className={pageMenu ? styles.pageHeaderWithMenu : styles.pageHeader}>
        {pageMenu}
        {isValidElement(pageHeader) ? (
          pageHeader
        ) : (
          <Typography variant="h1">{pageHeader}</Typography>
        )}
      </div>
      <div className={styles.pageContent}>{content}</div>
    </div>
  );
};
