export const StringConstants = {
  BXWEALTH_AMOUNT: "BXWealth Amount",
  DOWNLOAD_COMPLETE: "Download complete!",
  NO_EXCEL_TEMPLATE: "No excel template to download this data.",
  EXPORT_TO_EXCEL: "Export Data To Excel",
  RESET: "Reset",
  APPLY: "Apply",
  LOGGING_YOU_OUT: "Logging you out",
  LOGIN: "Log In",
  HELP: "Help",
  PRIVACY_POLICY: "Privacy Policy",
  BXACCESS: "BXAccess",
  SEARCH_USER_GRID: "Search for user, employee ID or department...",
  CHANGE_USER: "Change User",
  DOWNLOAD: "Download",
  SEARCH_PERF_DETAILS_GRID: "Search for fund, company or investment...",
  CUMULATIVE: "Cumulative",
  WELCOME: "Welcome,",
  BLACKSTONE_GROUP: "The Blackstone Group",
  LOGOUT: "Logout",
  SHOWN_ON_BXWEALTH: "Shown on user's BXWealth account as of",
  COMMITMENTS_PAGE_COMING_SOON: "Commitments Page coming soon...",
  MAIL_TO: "mailto:BXTI-WealthPortal@Blackstone.com",
  APP_SUPPORT_EMAIL: "appsupport@Blackstone.com",
  MAIL_TO_APP_SUPPORT: "mailto:appsupport@Blackstone.com",
  PERFORMANCE: "Performance",
  OVERVIEW: "Overview",
  INVESTMENT_BREAKDOWN: "Investment Breakdown",
  NO_CASH_FLOW_DATA_MESSAGE: "Cash Flow data is not available at this time.",
  INVESTMENTS_AND_CARRY: "Investments and Carry",
  INVESTMENTS_AND_CARRY_DETAILS: "Investments and Carry Details",
  HOLDBACK_CAPITAL_INVESTED: "Holdback Capital Invested",
  HOLDBACK_OVERVIEW: "Holdback Overview",
  TRANCHE: "Tranche",
  EQUITY: "Equity",
  DISTRIBUTIONS: "Distributions",
  COMING_SOON: "Coming Soon",
  ADMIN_DOWNLOAD: "Download Client Data",
  EQUITY_ADMIN_DOWNLOAD: "Download Employee Data",
  CLEAR: "Clear",
  CANCEL: "Cancel",
  SAVE: "Save",
  CONTINUE: "Continue",
  ELECTION_ADMIN: "Sbs Elections Admin",
};
