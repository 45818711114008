import {
  DataLoadStatus,
  EquityDistributionsNoDataLabel,
  FailedToLoadError,
  IBaseStore,
  isSomething,
  LoadingIndicator,
  reqDistributionsData,
  useFetchDatasetIfIdDefined,
} from "common";
import React from "react";
import { useSelector } from "react-redux";

import { Footer } from "../common/Footer/Footer";
import { NoDataPage } from "../common/NoDataPage/NoDataPage";
import { DistributionsGrid } from "./DistributionsGrid/DistributionsGrid";
import { DistributionsHeader } from "./DistributionsHeader/DistributionsHeader";

export const Distributions = () => {
  const { distributionsData, distributionsDataLoadStatus, activeEquityDataId } =
    useSelector((store: IBaseStore) => store.equityData);
  const { selectedStockSymbol } = useSelector(
    (store: IBaseStore) => store.viewData
  );

  const retryFunction = useFetchDatasetIfIdDefined(
    reqDistributionsData,
    activeEquityDataId,
    distributionsDataLoadStatus
  );

  const relevantData = distributionsData[selectedStockSymbol];

  return distributionsDataLoadStatus === DataLoadStatus.NOT_REQUESTED ||
    distributionsDataLoadStatus === DataLoadStatus.LOADING ? (
    <LoadingIndicator />
  ) : isSomething(relevantData) ? (
    <>
      <DistributionsHeader />
      <DistributionsGrid distributionsData={relevantData.value} />
      <Footer />
    </>
  ) : distributionsDataLoadStatus === DataLoadStatus.UNSUCCESSFUL ? (
    <FailedToLoadError retryRequest={retryFunction} />
  ) : (
    <NoDataPage
      message={EquityDistributionsNoDataLabel.NO_DISTRIBUTIONS_DATA(
        selectedStockSymbol
      )}
      contactText={EquityDistributionsNoDataLabel.CONTACT_TEXT}
      emailId={EquityDistributionsNoDataLabel.EMAIL_ID}
    />
  );
};
