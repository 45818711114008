import { IDistributionsDatum } from "../types/dataTypes";
import { Json, Maybe } from "../types/typeUtils";
import { getUser } from "./auth";
import { EnvironmentResolver } from "./environmentResolver";
import { handleResponse } from "./utils";

export type IDistriutionsSourceDatum = Json<IDistributionsDatum>;

export const getDistributionsData = async (
  employeeNumber: string
): Promise<Maybe<IDistriutionsSourceDatum[]>> => {
  const user = getUser();
  const url =
    EnvironmentResolver.ENV.REACT_APP_EQUITY_URL_BASE +
    "/employees/" +
    employeeNumber +
    "/distributions";

  const response = await fetch(url.toString(), {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
    },
  });

  return handleResponse(response);
};
