import {
  combineReducers,
  configureStore,
  createSerializableStateInvariantMiddleware,
  isPlain,
  Tuple,
} from "@reduxjs/toolkit";
import {
  bankAccountsReducer,
  equityDataReducer,
  fetchAdminEntitlements,
  fetchAllClientData,
  fetchDistributionsData,
  fetchEquityData,
  fetchEquityEmployees,
  fetchInternalInvestmentClients,
  fetchInternalInvestmentData,
  fetchStockData,
  internalInvestmentDataReducer,
  reqAllClientData,
  reqAllEntitlements,
  reqDistributionsData,
  reqEquityData,
  reqEquityEmployees,
  reqInternalInvestmentClients,
  reqInternalInvestmentData,
  reqStockData,
} from "common";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { takeLeading } from "redux-saga/effects";

import {
  adminUIClientsReducer,
  adminUIEmployeesReducer,
  adminUIEntitlementReducer,
  adminUISelectedClientReducer,
  adminUISelectedEmployeeReducer,
  adminUIViewDataReducer,
} from "./reducers";

const sagaMiddleware = createSagaMiddleware();

function* rootSaga() {
  yield takeLeading(reqAllEntitlements, fetchAdminEntitlements);
  yield takeLeading(
    reqInternalInvestmentClients,
    fetchInternalInvestmentClients
  );
  yield takeLeading(reqEquityEmployees, fetchEquityEmployees);
  yield takeLeading(reqInternalInvestmentData, fetchInternalInvestmentData);
  yield takeLeading(reqAllClientData, fetchAllClientData);
  yield takeLeading(reqEquityData, fetchEquityData);
  yield takeLeading(reqDistributionsData, fetchDistributionsData);
  yield takeLeading(reqStockData, fetchStockData);
}

const rootReducer = combineReducers({
  entitlements: adminUIEntitlementReducer,
  viewData: adminUIViewDataReducer,
  clients: adminUIClientsReducer,
  employees: adminUIEmployeesReducer,
  internalInvestmentData: internalInvestmentDataReducer,
  equityData: equityDataReducer,
  selectedClient: adminUISelectedClientReducer,
  bankAccounts: bankAccountsReducer,
  selectedEmployee: adminUISelectedEmployeeReducer,
});

export type AdminUIStore = ReturnType<typeof rootReducer>;

const getCustomMiddleware = () => {
  // Allow dates even though they are not JSON serializable.
  // NOTE: This means we have to be vigilant! Don't let someone
  // secretly mutate the store with "date.setDate(5)" or similar!
  const customSerializableMiddleware =
    createSerializableStateInvariantMiddleware({
      isSerializable: (value: unknown) =>
        isPlain(value) || value instanceof Date,
    });

  if (process.env.REACT_APP_SHOW_CONSOLE_LOGGING === "true") {
    return new Tuple(sagaMiddleware, customSerializableMiddleware, logger);
  }

  return new Tuple(sagaMiddleware, customSerializableMiddleware);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(
      getCustomMiddleware()
    ),
});

sagaMiddleware.run(rootSaga);
