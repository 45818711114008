import { Card } from "@mui/material";
import {
  DataCard,
  getActiveAwardsData,
  IBaseStore,
  isEmptyResponse,
  isInProgress,
  isSomething,
  isUnsuccessful,
  reqEquityData,
  reqStockData,
  useFetchDatasetIfIdDefined,
  useFetchDatasetWithoutId,
} from "common";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import styles from "./ActiveAwardDetails.module.scss";
import { ActiveAwardDetailsCardHeader } from "./ActiveAwardDetailsCardHeader/ActiveAwardDetailsCardHeader";
import { ActiveAwardDetailsGrid } from "./ActiveAwardDetailsGrid/ActiveAwardDetailsGrid";

export const ActiveAwardDetails = () => {
  const {
    activeEquityDataId,
    equityData,
    equityDataLoadStatus,
    stockData,
    stockDataLoadStatus,
  } = useSelector((store: IBaseStore) => store.equityData);

  const { selectedStockSymbol } = useSelector(
    (store: IBaseStore) => store.viewData
  );

  const data = useMemo(() => {
    if (!isInProgress(equityDataLoadStatus, stockDataLoadStatus)) {
      const selectedEquityData = equityData[selectedStockSymbol];
      const selectedStockData = stockData[selectedStockSymbol];
      if (isSomething(selectedEquityData) && isSomething(selectedStockData)) {
        return getActiveAwardsData(
          selectedEquityData.value,
          selectedStockData.value,
          selectedStockSymbol
        );
      }
    }
  }, [
    equityData,
    equityDataLoadStatus,
    selectedStockSymbol,
    stockData,
    stockDataLoadStatus,
  ]);

  const equityRetryFunction = useFetchDatasetIfIdDefined(
    reqEquityData,
    activeEquityDataId,
    equityDataLoadStatus
  );

  const stockRetryFunction = useFetchDatasetWithoutId(
    reqStockData,
    stockDataLoadStatus
  );

  return (
    <DataCard
      id={styles.awards}
      failed={isUnsuccessful(equityDataLoadStatus, stockDataLoadStatus)}
      loading={isInProgress(equityDataLoadStatus, stockDataLoadStatus)}
      noData={isEmptyResponse(equityDataLoadStatus, stockDataLoadStatus)}
      onReload={[equityRetryFunction, stockRetryFunction]}
    >
      {data && (
        <Card className={styles.card}>
          <ActiveAwardDetailsCardHeader />
          <ActiveAwardDetailsGrid awardsData={data} />
        </Card>
      )}
    </DataCard>
  );
};
