import { FilterAlt, FilterAltOutlined } from "@mui/icons-material";
import React from "react";
import { renderToStaticMarkup } from "react-dom/server";

import styles from "./FilterIcons.module.scss";

export const filterIconOutlined = renderToStaticMarkup(
  <FilterAltOutlined className={styles.filterIconOutlined} />
);
export const filterIconFilled = renderToStaticMarkup(
  <FilterAlt className={styles.filterIconFilled} />
);
