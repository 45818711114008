import { Grid } from "@mui/material";
import {
  CardFooter,
  HoverableChartLegends,
  IDashboardCardContentProps,
  IHoldbackPerFund,
  ISegregatedTrustBalance,
  mapHoldbackPerFundToChartLegends,
  MUIConstants,
  SegregatedTrustTableLabel,
} from "common";
import React, { useMemo, useState } from "react";

import { finalizeHoldbackPerFund } from "../../utils/segTrustUtils";
import { HoldbackOverviewRow } from "./HoldbackOverviewRow/HoldbackOverviewRow";
import styles from "./SegregatedTrustBalance.module.scss";
import SegregatedTrustBalanceChart from "./SegregatedTrustBalanceChart/SegregatedTrustBalanceChart";

export const SegregatedTrustBalance = (
  props: IDashboardCardContentProps<ISegregatedTrustBalance>
) => {
  const segTrustData = props.data;
  segTrustData;
  const [finalizedHoldbackPerFund, setFinalizedHoldbackPerFund] = useState<
    IHoldbackPerFund[]
  >([]);

  useMemo(() => {
    const filteredHoldbackPerFund = finalizeHoldbackPerFund(
      segTrustData.holdbackPerFund
    );
    setFinalizedHoldbackPerFund(filteredHoldbackPerFund);
  }, [segTrustData, setFinalizedHoldbackPerFund]);

  return (
    <div>
      <Grid
        container
        columnSpacing={MUIConstants.DASHBOARD_WIDGET_COLUMN_SPACING}
        className={styles.container}
      >
        <Grid item xs={12} md={MUIConstants.WIDGET_TREE_COLUMN_LEFT_ITEM_SIZE}>
          {segTrustData.holdbackPerFund?.length > 0 && (
            <HoverableChartLegends
              legends={mapHoldbackPerFundToChartLegends(
                finalizedHoldbackPerFund
              )}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={MUIConstants.WIDGET_TREE_COLUMN_CENTER_ITEM_SIZE}
          className={styles.chartContainer}
        >
          <SegregatedTrustBalanceChart
            holdbackPerFund={finalizedHoldbackPerFund}
            holdBackInvested={segTrustData.holdbackInvested}
          />
        </Grid>
        <Grid item xs={12} md={MUIConstants.DASHBOARD_WIDGET_RIGHT_ITEM_SIZE}>
          <HoldbackOverviewRow segTrustData={segTrustData} />
        </Grid>
      </Grid>
      <CardFooter footerLines={[SegregatedTrustTableLabel.FOOTER_FIRST_LINE]} />
    </div>
  );
};
